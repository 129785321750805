import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const addClient = data => {
    var bodyFormData = new FormData();
    let stringifiedData = "";
    for (var key in data) {
        if (data && data[key] && key !== "logo" && (Array.isArray(data[key]) || typeof data[key] === "object")) {
            try {
                stringifiedData = JSON.stringify(data[key])
            } catch (err) {
                console.error(":::::::::::::::::::::::: error:: JSON.stringify :::::::::::::::::::::::::::::::", err);
            }
            bodyFormData.append(key, stringifiedData);
        } else {
            bodyFormData.append(key, data[key]);
        }
    }

    return axiosInstance.post(
        `${apiAppendPath.clientAppendPath}/client`,
        bodyFormData
    );
};

export const updateClient = (clientId, data) => {
    var bodyFormData = new FormData();
    let stringifiedData = "";
    for (var key in data) {
        if (data && data[key] && key !== "logo" && (Array.isArray(data[key]) || typeof data[key] === "object")) {
            try {
                stringifiedData = JSON.stringify(data[key])
            } catch (err) {
                console.error(":::::::::::::::::::::::: error:: JSON.stringify :::::::::::::::::::::::::::::::", err);
            }
            bodyFormData.append(key, stringifiedData);
        } else {
            bodyFormData.append(key, data[key]);
        }
    }

    return axiosInstance.put(
        `${apiAppendPath.clientAppendPath}/client/${clientId}`,
        bodyFormData
    );
};

export const deleteClient = clientId => {
    return axiosInstance.delete(
        `${apiAppendPath.clientAppendPath}/client/${clientId}`
    );
};

export const getTelemedicineCategoryOfClient = clientId => {
    return axiosInstance.get(
        `${apiAppendPath.clientAppendPath}/telemedicinecategory/${clientId}`
    );
};