import styled from "styled-components";
import { Table, Typography, } from "antd";


const StyledComponentWrapper = styled.div`
background-color: #ffffff;
width: 100%;
max-width: 1480px;
padding: 24px 28px;

@media (max-width:1800px){
  max-width: 1300px;
}

@media (max-width:1550px){
  max-width: 1080px;
}
@media (max-width:1300px){
  max-width: 950px;
}

@media (max-width: 1199px) {
  max-width: 780px;
}
@media (max-width: 788px), (max-width: 922px) {
  max-width: 712px;
}
@media (max-width: 720px) {
  max-width: 372px;
}
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledDiv = styled.div`
  width: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


const SearchFilterWrapper = styled.div`
  display: flex;
`;

export { SearchFilterWrapper, StyledDiv, Title, StyledTable, StyledComponentWrapper };
