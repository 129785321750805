/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Spin, message, Modal, Button, Radio } from "antd";
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  ArrowDownOutlined,
  FileTextOutlined,
  ArrowUpOutlined,
  EditOutlined,
  LoadingOutlined,
  CalendarOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

//import components
import PhysicianFilter from "./PhysicianFilter";
import {
  StyledComponentWrapper,
  StyledTable,
  Title,
  SearchFilterWrapper,
} from "../../components/PhysicianComponent/AllPhysicianComponent";
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import { getPhysicians, updatePhysicianDetails } from "../../api/PhysiciansAPI";
import authorization from "../../auth/authorization";

const { Search } = Input;
const { confirm } = Modal;

const ALL_PHYSICIANS_CURRENT_PAGE = "ALL_PHYSICIANS_CURRENT_PAGE";
const ALL_PHYSICIANS_PAGE_SIZE = "ALL_PHYSICIANS_PAGE_SIZE";

const AllPhysicians = ({
  clients,
  states,
  telemedicineCategory,
  tags,
  onItemClick,
  onEditPhysicianClick,
  onEditPhysicianConsultRate,
  userType,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [activePhysiciansFilter, setActivePhysiciansFilter] = useState(true)
  const [physicians, setPhysicians] = useState([]);
  const [physicianFilters, setFilters] = useState({
    client: "",
    telemedicineCategory: "",
    state: "",
    fetchType: "all",
    tags: []
  });
  const [tablePageSize, setTablePageSize] = useState(localStorage.getItem(ALL_PHYSICIANS_PAGE_SIZE) || 100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchValue] = useState("");
  const [columns, setColumns] = useState([
    {
      title: "Full name",
      width: 250,
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 340,
      key: "email-id",
    },
    {
      title: "Physician Id",
      dataIndex: "_id",
      width: 250,
      key: "physician-id",
    },
    {
      title: "Employment Status",
      dataIndex: "employmentStatus",
      width: 200,
      key: "employment-status",
    },
    {
      title: "Status",
      dataIndex: "",
      width: 100,
      key: "physician-status",
      render: (physician) => (
        <>{physician.status ? physician.status.toUpperCase() : ""}</>
      ),
    },
    {
      title: "Queue",
      dataIndex: "",
      width: 130,
      key: "physician-queue",
      render: (event, record) => (
          <Button
            shape="circle"
            icon={<UnorderedListOutlined />}
            onClick={() => onViewQueueClick(event, record)}
          />
      )
    }
  ]);

  const onPageSizeChange = (current, size) => {
    onChangePage(1, size);
    setTablePageSize(size);
    setCurrentPage(1);
    window.localStorage.setItem(ALL_PHYSICIANS_PAGE_SIZE, size);
  };

  const onChangePage = (value, pageSize) => {
    setCurrentPage(value);
    window.localStorage.setItem(ALL_PHYSICIANS_CURRENT_PAGE, value);
  };

  const updateColumnsInListing = () => {
    const clonnedColumns = columns.slice(0);
    if (authorization.isAllowed(userType, "getPhysicianDetailsForAdmin")) {
      clonnedColumns.push({
        title: "Details",
        dataIndex: "",
        key: "v",
        width: 100,
        align: "center",
        render: (event, record) => (
          <Button
            shape="circle"
            icon={<FileTextOutlined />}
            onClick={() => {
              onTableRowClick(event, record);
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "updatePhysicianDetails")) {
      clonnedColumns.push({
        title: "Edit",
        dataIndex: "",
        width: 100,
        key: "edit-physician",
        align: "center",
        render: (event, record) => (
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              onEditPhysicianClick(event, record);
            }}
          />
        ),
      });
    }

    if (authorization.isAllowed(userType, "updateStedyMdPhysicianDetails")) {
      clonnedColumns.push({
        title: "Edit",
        dataIndex: "",
        width: 100,
        key: "edit-physician",
        align: "center",
        render: (event, record) =>
          event.employmentStatus === "steadymd physicians" && (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                onEditPhysicianClick(event, record);
              }}
            />
          ),
      });
    }
    if (
      authorization.isAllowed(userType, "updateExternalPhysicianPaymentDetails")
    ) {
      clonnedColumns.push({
        title: "Edit Consult Cost",
        dataIndex: "",
        width: 200,
        key: "edit-external-physician-payments",
        align: "center",
        render: (event, record) => (
          <>
            {event.status === "active" &&
            event.employmentStatus === "external physicians" ? (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  onEditPhysicianConsultRate(event, record);
                }}
              />
            ) : (
              "NA"
            )}
          </>
        ),
      });
    }
    if (authorization.isAllowed(userType, "physicianModerationByAdmin")) {
      clonnedColumns.push({
        title: "Archive",
        dataIndex: "",
        width: 100,
        align: "center",
        render: (physician) => (
          <>
            {physician.status === "active" && (
              <Button
                shape="circle"
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  showArchiveConfirmation(physician, { status: "archive" });
                }}
              />
            )}

            {physician.status === "archive" && (
              <Button
                shape="circle"
                icon={<ArrowUpOutlined />}
                onClick={() => {
                  showArchiveConfirmation(physician, { status: "activate" });
                }}
              />
            )}
          </>
        ),
      });
      clonnedColumns.push({
        title: "Delete",
        dataIndex: "",
        width: 100,
        align: "center",
        render: (physician) => (
          <Button
            shape="circle"
            icon={<DeleteFilled />}
            onClick={() => {
              showDeleteConfirmation(physician, { status: "delete" });
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "updatePhysicianSchedule")) {
      clonnedColumns.push({
        title: "Update Schedule",
        dataIndex: "",
        width: 180,
        key: "physician-schedule-update",
        align: "center",
        render: (physician) => (
          <Button
            shape="circle"
            icon={<CalendarOutlined />}
            onClick={(event) => {
              handleScheduleButtonClick(event, physician);
            }}
          />
        ),
      });
    }
    setColumns([...clonnedColumns]);
  };

  const initCallsForComponent = () => {
    updateColumnsInListing();
  };

  const transformPhysicianData = (data) => {
    return data.map(physician => ({
      ...physician,
      key: physician._id,
      fullName: `${physician.firstName} ${physician.lastName}` + (physician.credential ? `, ${physician.credential}` : '')
    }));
  }

  const fetchPhsyicians = () => {
    setLoading(true);
    getPhysicians({ 
      fetchType: physicianFilters.fetchType,
      client: physicianFilters.client,
      teleMedicineCategory: physicianFilters.telemedicineCategory,
      state: physicianFilters.state,
      tags: physicianFilters.tags,
      active: activePhysiciansFilter,
      searchTerm
    })
      .then((res) => {
        if (res.data && res.data.payload) {
          const physicianData = transformPhysicianData(res.data.payload);
          setPhysicians(physicianData);
        } else {
          message.error("Unable to load Physician List");
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error("Unable to load Physician List");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPhsyicians();
  }, [physicianFilters, searchTerm, activePhysiciansFilter]);

  useEffect(initCallsForComponent, [userType]);

  const onFilterChange = (filterValues) => {
    setFilters(filterValues);
  };

  const onTableRowClick = (event, record) => {
    onItemClick(event, record);
  };

  const handleSearchValueChange = (searchTerm) => {
    setSearchValue(searchTerm);
  };

  const handleSearchInputChange = (searchEvent) => {
    if (searchEvent.target.value === "" || searchEvent.target.value === null) {
      setSearchValue(searchEvent.target.value);
    }
  };

  const onViewQueueClick = (e, physician) => {
    history.push(`/physician-case-queue/${physician._id}?physician-name=${physician.firstName}%20${physician.lastName}`);
  }

  const updatePhysician = (physicianId, statusDetails) => {
    updatePhysicianDetails(physicianId, statusDetails)
      .then((res) => {
        if (res.status === 204) {
          if (statusDetails.status === "delete") {
            message.success("Successfully Deleted Physician");
          }

          if (statusDetails.status === "archive") {
            message.success("Successfully Archived Physician");
          }

          if (statusDetails.status === "active") {
            message.success("Successfully Activated Physician");
          }
        } else {
          const { data } = res;
          if (data && data.success) {
            if (statusDetails.status === "delete") {
              message.success("Successfully Deleted Physician");
            }

            if (statusDetails.status === "archive") {
              message.success("Successfully Archived Physician");
            }

            if (statusDetails.status === "active") {
              message.success("Successfully Activated Physician");
            }
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable update physician status");
            }
          }
        }
        fetchPhsyicians();
      })
      .catch((error) => {
        console.log(
          "================== physician status update error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Error occured while updating physician status");
          }
        } else {
          message.error("Error occured while updating physician status");
        }
      });
  };

  const showDeleteConfirmation = (physician, status) => {
    confirm({
      title: `Do you want to delete ${physician.firstName}  ${physician.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        updatePhysician(physician._id, status);
      },
      onCancel() {},
    });
  };

  const showArchiveConfirmation = (physician, statusInfo) => {
    confirm({
      title:
        statusInfo.status === "activate"
          ? `Do you want to activate ${physician.firstName}  ${physician.lastName}?`
          : `Do you want to archive ${physician.firstName}  ${physician.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        updatePhysician(physician._id, statusInfo);
      },
      onCancel() {},
    });
  };

  const handleScheduleButtonClick = (event, physician) => {
    event.stopPropagation();
    const { _id } = physician;
    history.push(`/schedule-availability/${_id}`);
  };

  return (
    <StyledComponentWrapper>
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Title level={2}>All Physicians
              <span style={{ marginLeft: '15px', position: 'relative', bottom: '4px'}}>
                <Radio.Group value={activePhysiciansFilter} size="small" onChange={e => setActivePhysiciansFilter(e.target.value)}>
                  <Radio.Button value={true}>Active</Radio.Button>
                  <Radio.Button value={false}>Inactive</Radio.Button>
                </Radio.Group>
              </span>
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form name="search-form" layout={"vertical"}>
              <Form.Item rules={[{}]}>
                <SearchFilterWrapper>
                  <Search
                    size={"large"}
                    placeholder={"Search by Name or Email"}
                    onChange={handleSearchInputChange}
                    onSearch={handleSearchValueChange}
                  />
                  <PhysicianFilter
                    clients={clients}
                    states={states}
                    telemedicineCategory={telemedicineCategory}
                    tags={tags}
                    onFilter={onFilterChange}
                  />
                </SearchFilterWrapper>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTable
              columns={columns}
              dataSource={physicians}
              pagination={{
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 720 }}
            />
          </Col>
        </Row>
      </Spin>
    </StyledComponentWrapper>
  );
};

export default UserRolesAndRightsHOComponent(AllPhysicians);
