import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getTicketChat = (params) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/fetch-chat-ticket`,{params:params}
  );
};

export const sendChatMessage=(data)=>{
    return axiosInstance.post(
        `${apiAppendPath.caseTicketsAppendPath}/chat-ticket`,data
    );
};