
import React from "react";
import { Select } from "antd";
import { BulkActionEnum } from "../../../constants/enums"
import { StyledSelect } from "./StyledComponents";

const { Option } = Select;

const {
    ARCHIVE,
    RESTORE,
    DELETE,
    RELEASE,
    REASSIGN,
    STATUS,
    CONSULTATION_TYPE,
    EXPORT
  } = BulkActionEnum

const SelectBulkAction = ({ disabled, onChange, checkIsAllowedAuthUser, isArchived }) => {
    return (
        <StyledSelect
          defaultValue="Actions"
          value={"Actions"}
          disabled={disabled}
          onChange={onChange}
        >
          { checkIsAllowedAuthUser("assignPhysician") && <Option key={REASSIGN} value={REASSIGN}>Reassign</Option> }
          { checkIsAllowedAuthUser("caseStatusModification") && <Option key={STATUS} value={STATUS}>Change Status</Option> }
          { checkIsAllowedAuthUser("caseConsultationTypeModification") && <Option key={CONSULTATION_TYPE} value={CONSULTATION_TYPE}>Change Consultation Type</Option> }
          { checkIsAllowedAuthUser("caseStatusModification") && <Option key={RELEASE} value={RELEASE}>Release Cases to Queue</Option> }
          { checkIsAllowedAuthUser("caseStatusModification") && !isArchived && <Option key={ARCHIVE} value={ARCHIVE}>Archive</Option> }
          { checkIsAllowedAuthUser("caseStatusModification") && isArchived && <Option key={RESTORE} value={RESTORE} >Restore</Option> }
          { checkIsAllowedAuthUser("caseStatusModification") && <Option key='Delete' value={DELETE}>Delete</Option> }
          <Option key={EXPORT} value={EXPORT}>Export to CSV</Option>
        </StyledSelect>
    )
}

export default SelectBulkAction;
