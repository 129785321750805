/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  List,
  TimePicker,
  Button,
  Modal,
  Select,
  Form,
  Typography,
  message,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import moment from 'moment-timezone';

// import functions
import { updateScheduleDays } from "../../api/PhysiciansAPI";
import _ from "lodash";
import converterTimeZone from "../../utils/converterTimeZone";

//import components
import { StyledAvailabilityCardComponent } from "../../components/ScheduleComponent";

const { RangePicker } = TimePicker;
const { Option } = Select;

const { Title } = Typography;

const weakDays = [
  { id: 1, day: "Sunday" },
  { id: 2, day: "Monday" },
  { id: 3, day: "Tuesday" },
  { id: 4, day: "Wednesday" },
  { id: 5, day: "Thursday" },
  { id: 6, day: "Friday" },
  { id: 7, day: "Saturday" },
];

const GeneralDays = ({ generalDays, setdataEdited, physicianId, offSet,timeZone }) => {
  const [editDays, setEditDays] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [generalTime, setGeneralTime] = useState();
  const [generalTimeView, setGeneralTimeView] = useState();
  const [unavailabledays, setUnAvailableDays] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();


  const localTimeZone = moment.tz.guess(true);

  const convertLocalUtc = (date) => {
    const dateTimeString = moment(date).format("YYYY-MM-DD HH:mm");
    const toLocalTimeZone = moment
      .tz(dateTimeString, localTimeZone)
      .utc()
      .format();
    return toLocalTimeZone;
  };

  useEffect(() => {
    const available = [];
    const unavailable = [];
    const general = [];

    if (timeZone && generalDays) {

    for (let key in generalDays) {
      weakDays.forEach((week) => {
        if (week.day === generalDays[key].day) {
          general.push({
            id: week.id,
            day: week.day,
            startTime: converterTimeZone(
              generalDays[key].startTime,
              localTimeZone,
              timeZone
            ),
            endTime: converterTimeZone(
              generalDays[key].endTime,
              localTimeZone,
              timeZone
            ),

          });
        }
      });
    }

    setGeneralTimeView(general);

    general.forEach((item) => {
      available.push(item.id);
    });

    weakDays.forEach((item) => {
      if (!available.includes(item.id)) {
        unavailable.push(item);
      }
    });
    setUnAvailableDays(unavailable);
  }
  }, [generalDays,timeZone]);

  useEffect(() => {
    setGeneralTime(generalTimeView);
  }, [generalTimeView]);

  const deleteGeneralDays = (id, day) => {
    setGeneralTime(generalTime.filter((item) => item.id !== id));
    const unavail = unavailabledays;
    unavail.push({ id: id, day: day });
    setUnAvailableDays(unavail);
  };

  const editGeneralDays = () => {
    setEditDays(false);
  };

  const changeGeneralTime = (time, timeString, key) => {
    const changeData = [];
    generalTime.forEach((item) => {
      if (item.id === key) {
        changeData.push({
          id: item.id,
          day: item.day,
          startTime: convertLocalUtc(time[0]),
          endTime: convertLocalUtc(time[1])
,
        });
      } else {
        changeData.push({
          id: item.id,
          day: item.day,
          startTime: item.startTime,
          endTime: item.endTime,
        });
      }
    });
    setGeneralTime(changeData);
  };

  const editCancel = () => {
    setEditDays(true);
    const general = [];
    const unavailable = [];
    const available = [];

    for (let key in generalDays) {
      weakDays.forEach((week) => {
        if (week.day === generalDays[key].day) {
          general.push({
            id: week.id,
            day: week.day,
            startTime: converterTimeZone(
              generalDays[key].startTime,
              localTimeZone,
              timeZone
            ),
            endTime: converterTimeZone(
              generalDays[key].endTime,
              localTimeZone,
              timeZone
            ),
          });
        }
      });
    }

    general.forEach((item) => {
      available.push(item.id);
    });

    weakDays.forEach((item) => {
      if (!available.includes(item.id)) {
        unavailable.push(item);
      }
    });

    setGeneralTimeView(general);
    setUnAvailableDays(unavailable);
  };

  const addGeneralDays = () => {
    setModalVisible(true);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleModalOk = () => {
    const formData = form.getFieldsValue();
    if (formData.day && formData.time) {
      form.resetFields();
      setModalVisible(false);
      setGeneralTimeView(generalTimeView);
      const changeData = generalTime;
      unavailabledays.forEach((item) => {
        if (item.id === formData.day) {
          changeData.push({
            id: item.id,
            day: item.day,
            startTime: convertLocalUtc(formData.time[0]),
            endTime: convertLocalUtc(formData.time[1]),

          });
          setUnAvailableDays(
            unavailabledays.filter((unavail) => unavail.id !== item.id)
          );
        }
      });
      setGeneralTime(changeData);
    }
  };

  const submitChanges = () => {
    const general = [];
    setButtonLoading(true);

    for (let key in generalDays) {
      weakDays.forEach((week) => {
        if (week.day === generalDays[key].day) {
          general.push({
            id: week.id,
            day: week.day,
            startTime: converterTimeZone(
              generalDays[key].startTime,
              localTimeZone,
              timeZone
            ),
            endTime: converterTimeZone(
              generalDays[key].endTime,
              localTimeZone,
              timeZone
            ),
          });
        }
      });
    }

    const requestData = {};
    const specificDays = {};
    generalTime.forEach((item) => {
      specificDays[item.day] = [converterTimeZone(item.startTime, timeZone, localTimeZone),
        converterTimeZone(item.endTime, timeZone, localTimeZone),
];
    });

    if (!_.isEqual(generalTime, general)) {
      requestData.physicianId = physicianId;
      requestData["schedule"] = specificDays;
      requestData["schedule-type"] = "general";
      requestData["offSet"] = offSet;

      updateScheduleDays(requestData)
        .then((res) => {
          if (res.data.success) {
            setEditDays(true);
            setdataEdited();
            message.success("SuccessFully Updated");
            setButtonLoading(false);
          } else {
            message.error(res.data.message);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          const { response } = error;
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to update General Availability,Please try again !"
              );
            }
          } else {
            message.error(
              "Unable to update General Availability,Please try again !"
            );
          }
        });
    } else {
      message.info("Please provide changes before you save");
      setButtonLoading(false);
    }
  };

  return (
    <StyledAvailabilityCardComponent>
      <div className="general-availability-header">
        <Title level={3}>
          <b>General Availability</b>
        </Title>
        <Button type="primary" onClick={() => editGeneralDays()}>
          <EditFilled />
          Edit
        </Button>
      </div>

      <div className="general-availability-button-add">
        {!editDays && (
          <Button type="primary" onClick={() => addGeneralDays()}>
            <PlusOutlined />
            Add
          </Button>
        )}
      </div>

      <Modal
        title="Add General Days"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="day" label="Select Available Days" span={24}>
            <Select placeholder="Select Day">
              {unavailabledays &&
                unavailabledays.map((item) => {
                  return <Option value={item.id}>{item.day}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item name="time" label="Provide Available Time" span={24}>
            <RangePicker format="HH:mm"></RangePicker>
          </Form.Item>
        </Form>
      </Modal>
      <List
        className="general-availability-list"
        itemLayout="horizontal"
        dataSource={generalTime}
        renderItem={(item) => (
          <List.Item
            actions={[
              !editDays && (
                <DeleteFilled
                  style={{ fontSize: "18px", color: "#7BAD7E" }}
                  onClick={() => deleteGeneralDays(item.id, item.day)}
                />
              ),
            ]}
          >
            <Title level={4} style={{ width: 200 }}>
              {item.day}
            </Title>
            {!editDays ? (
              <RangePicker
                defaultValue={[
                  moment(
                    moment.utc(item.startTime).local().format("HH:mm"),
                    "HH:mm "
                  ),
                  moment(
                    moment.utc(item.endTime).local().format("HH:mm"),
                    "HH:mm"
                  ),
                ]}
                size="large"
                format="HH:mm"
                bordered={false}
                disabled={editDays}
                style={{ width: 200 }}
                allowClear={false}
                onChange={(time, timeString) =>
                  changeGeneralTime(time, timeString, item.id)
                }
              />
            ) : (
              <>
                <Title level={4} style={{ margin: "unset" }}>
                  <FieldTimeOutlined /> <b>From :</b>{" "}
                  {moment.utc(item.startTime).local().format("HH:mm")}{" "}
                  <b>To :</b> {moment.utc(item.endTime).local().format("HH:mm")}{" "}
                </Title>
              </>
            )}
          </List.Item>
        )}
      />

      <div className="general-availability-button">
        {!editDays && (
          <>
            <Button type="primary" size="large" onClick={() => editCancel()}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              loading={buttonLoading}
              onClick={() => submitChanges()}
            >
              Save Changes
            </Button>
          </>
        )}
      </div>
    </StyledAvailabilityCardComponent>
  );
};

export default GeneralDays;
