import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const addCaseStatus = data => {
    return axiosInstance.post(
        `${apiAppendPath.caseStatusAppendPath}/case-status`,
        data
    );
};

export const updateCaseStatus = (caseStatusId, data) => {
    return axiosInstance.put(
        `${apiAppendPath.caseStatusAppendPath}/case-status/${caseStatusId}`,
        data
    );
};

export const deleteCaseStatus = caseStatusId => {
    return axiosInstance.delete(
        `${apiAppendPath.caseStatusAppendPath}/case-status/${caseStatusId}`
    );
};