import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const login = data => {
    return axiosInstance.post(
        `${apiAppendPath.commonAppendPath}/user/login`,
        data,
        {
            headers: { 'X-User-Type': 'admin' }
        }
    );
};

export const passwordResetRequest = data => {
    return axiosInstance.post(
        `${apiAppendPath.userApiAppendPath}/password-reset-request`,
        data
    );
}

export const passwordReset = data => {
    return axiosInstance.post(
        `${apiAppendPath.userApiAppendPath}/password-reset`,
        data
    );
}
