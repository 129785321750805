import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography, Spin, message, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

//import common functions
import { getPharmacyDetails } from "../../api/PharmacyAPI";

const { Text, Title } = Typography;

const StyledText = styled(Text)`
  &.anticon-copy {
    font-size: 20px;
  }

  color: #7bad7e !important;
`;

const StyledTitle = styled(Title)`
  color: #7bad7e !important;
`;

const StyledForm = styled(Form)`
  margin-top: ${(props) => (props.topmarginrequired === 'true' ? "20px" : "0")};
`;

const StyledPTag = styled.p`
  text-align: center;
  color: #7bad7e !important;
`;

const PharmacyDetails = ({
  caseDetails,
  title,
  topMarginRequired,
  contentSize = "small",
  copyDetails = true,
  splitFormDetails = false,
}) => {
  const [pharmacy, setPharmacyDetails] = useState(null);
  const [pharmacyError, setPharmacyError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formColSize, setFormColSize] = useState(24);

  const getPharamcyDetails = pharmacyId => {
    setLoading(true);
    setPharmacyDetails({});
    setPharmacyError("");

    getPharmacyDetails(pharmacyId)
      .then(({ data: { error, message, pharmacy } }) => {
        if (error) {
          const errorMessage = message || "Unable to fetch the requested pharamcy details. Please try again!"
          message.warning(errorMessage);
          return setPharmacyError(errorMessage);
        }

        setPharmacyDetails(pharmacy);
      })

      .catch((error) => {
        const errorMessage = error?.response?.data?.message
          ? error.response.data.message
          : "Error occured while fetching requested Pharmacy Details. Please try again later!"

        message.error(errorMessage);
        setPharmacyError(errorMessage);
      })
      .finally(() => setLoading(false))
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (caseDetails?.pharmacyId) getPharamcyDetails(caseDetails.pharmacyId);
  }, [caseDetails]);

  useEffect(() => setFormColSize(splitFormDetails ? 12 : 24), [splitFormDetails]);

  const PharmacyDetail = ({ pharmacy, detail, label }) => {
    return (
      <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
        <Form.Item label={label || detail} size={contentSize} style={{ textTransform: 'capitalize'}}>
          <StyledText strong copyable={copyDetails}>
            {pharmacy[detail] || "N/A"}
          </StyledText>
        </Form.Item>
      </Col>
    )
  }

  return (
    <Spin spinning={loading} size="medium" indicator={<LoadingOutlined />}>
      <StyledForm
        topmarginrequired={topMarginRequired.toString()}
        name="requested-pharmacy-details"
      >
        <Row>
          {!!title && (
            <Col span={24}>
              <StyledTitle level={4}>{title}</StyledTitle>
            </Col>
          )}
          {pharmacy ? (
            <>
              <PharmacyDetail pharmacy={pharmacy} detail="name" label="Name"/>
              <PharmacyDetail pharmacy={pharmacy} detail="address1" />
              <PharmacyDetail pharmacy={pharmacy} detail="address2" />
              <PharmacyDetail pharmacy={pharmacy} detail="city" />
              <PharmacyDetail pharmacy={pharmacy} detail="state" />
              <PharmacyDetail pharmacy={pharmacy} detail="zipCode" label="Zip Code"/>
              <PharmacyDetail pharmacy={pharmacy} detail="phoneOrFax" />
              <PharmacyDetail pharmacy={pharmacy} detail="pharmacyId" />
            </>
          ) : (
            <Col span={24}>
              {pharmacyError ? (
                <Alert
                  message="Error: Requested Pharmacy details"
                  description={pharmacyError}
                  type="error"
                  showIcon
                />
              ) : (
                <StyledPTag>No requested pharamcy details available</StyledPTag>
              )}
            </Col>
          )}
        </Row>
      </StyledForm>
    </Spin>
  );
};

export default PharmacyDetails;
