//import packages
import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Select,
  Form,
  Row,
  Col,
  Button,
  message,
} from "antd";
import styled from "styled-components";
// import components
import LoadBalancerConfiguration from "./LoadBalancerConfiguration";
import AddConfiguration from "./AddConfiguration";
import { lookUp } from "../../../api/LookUpAPI";
import { addLoadBalancerConfiguration } from "../../../api/LoadBalancerConfigurationAPI";

//import other files and API functions

const { Title } = Typography;
const StyledFormItemWrapper = styled(Form.Item)`
  text-align: right;
  .ant-btn {
    margin-left: 20px;
  }
`;

const StateConfigurationAction = ({
  actionType,
  editId,
  editConfigurationData,
  performConfigurationAction,
  performEditCancel,
  configurationListData,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState();
  const [
    physicianGroupConfiguration,
    setPhysicianGroupConfiguration,
  ] = useState([]);
  const [editSubmit, setEditSubmit] = useState(false);
  const [viewAddConfigurationModal, setViewAddConfigurationModal] = useState(
    false
  );
  const [physicianGroupList, setPhysicianGroupList] = useState([]);
  const [
    physicianGroupListForSelection,
    setPhysicianGroupListForSelection,
  ] = useState([]);
  const [stateGroupListForSelection, setStateGroupListForSelection] = useState(
    []
  );
  const [editingConfiguration, setConfigurationToEdit] = useState(null);
  const [viewEditConfigurationModal, setViewEditConfigurationModal] = useState(
    false
  );
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [deletedList, setDeletedList] = useState([]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const fetchStates = () => {
    const stateList = [];
    lookUp("state").then((res) => {
      res.data.payload.map((state) => {
        return stateList.push({
          key: state._id,
          value: state.name,
          text: state.name,
        });
      });
      setStates(stateList);
    });
  };

  const updateStateForSelection = () => {
    const selectableState = states.filter(
      (eachState) =>
        !configurationListData.some(
          (eachConfigState) => eachConfigState.id === eachState.key
        )
    );
    setStateGroupListForSelection(selectableState);
  };

  useEffect(() => {
    if (states && configurationListData) {
      if (actionType === "edit") {
        setStateGroupListForSelection(states);
      } else {
        updateStateForSelection();
      }
    }else{
      setStateGroupListForSelection(states)
    }
    // eslint-disable-next-line
  }, [configurationListData, states, actionType]);
  const fetchPhysicianGroup = () => {
    lookUp("physician-group")
      .then((res) => {
        if (res.data && res.data.payload) {
          const physicianGroupData = res.data.payload.map((eachGroup) => ({
            ...eachGroup,
            physicianGroupId: eachGroup._id,
            physicianGroupName: eachGroup.name,
            key: eachGroup._id,
          }));
          setPhysicianGroupList(physicianGroupData);
        }
      })
      .catch((error) => {
        message.error(
          "Unable to fetch Physician Group. Please try refreshing!"
        );
      });
  };

  const handleComponentInit = () => {
    fetchPhysicianGroup();
    fetchStates();
  };

  function sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  }

  useEffect(() => {
    if (editId) {
      setDeletedList([]);
      const editValue = editConfigurationData
        .slice(0)
        .sort(sortByProperty("priority"));
      setPhysicianGroupConfiguration(editValue);
      form.setFieldsValue({ state: editId });
    }
    // eslint-disable-next-line
  }, [editId, editConfigurationData]);

  useEffect(handleComponentInit, []);

  const handleConfigurationAdd = (values) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    console.log(
      "============== form submission values ================",
      values
    );
    clonnedPhysicianGroupConfiguration.push({
      ...values,
      internalTurnAroundTime:
        !values.internalTurnAroundTime &&
        values.physicianGroupDetails.label.props.children.includes("wheel")
          ? 0
          : values.internalTurnAroundTime,
      physicianGroupName: values.physicianGroupDetails.label.props.children,
      physicianGroupId: values.physicianGroupDetails.value,
      key: values.physicianGroupDetails.key,
      index: clonnedPhysicianGroupConfiguration.length,
      priority: clonnedPhysicianGroupConfiguration.length + 1,
    });
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setViewAddConfigurationModal(false);
    setEditSubmit(true);
  };

  const updatePhysicianGroupListForSelection = () => {
    const checkAddedPhysician = (accumulator, currentGroup) => {
      const foundPhysicianGroup = physicianGroupConfiguration.find(
        (eachSelectedGroup) =>
          currentGroup.physicianGroupId === eachSelectedGroup.physicianGroupId
      );
      if (!foundPhysicianGroup) {
        accumulator.push(currentGroup);
      }
      return accumulator;
    };
    const physicianGroupForSelection = physicianGroupList.reduce(
      checkAddedPhysician,
      []
    );
    setPhysicianGroupListForSelection(physicianGroupForSelection);
  };

  useEffect(updatePhysicianGroupListForSelection, [
    physicianGroupList,
    physicianGroupConfiguration,
  ]);

  const handleConfigurationDelete = (selectedConfiguration) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    if (selectedConfiguration.id) {
      const listToDelete = deletedList.slice(0);
      listToDelete.push(selectedConfiguration.id);
      setDeletedList(listToDelete);
    }
    const findDeletingItemIndex = clonnedPhysicianGroupConfiguration.findIndex(
      (eachConfiguration) =>
        eachConfiguration.physicianGroupId ===
        selectedConfiguration.physicianGroupId
    );
    if (findDeletingItemIndex !== -1) {
      clonnedPhysicianGroupConfiguration.splice(findDeletingItemIndex, 1);
    }
    clonnedPhysicianGroupConfiguration.map((item, index) => {
      return (item.priority = index + 1);
    });
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setEditSubmit(true);
  };

  const handleConfigurationEdit = (selectedConfiguration) => {
    setConfigurationToEdit(selectedConfiguration);
    setViewEditConfigurationModal(true);
  };

  const handleAddConfigurationClickCallback = () => {
    setViewAddConfigurationModal(true);
  };

  const handleConfigurationPriorityChange = (updatedConfigurationList) => {
    setPhysicianGroupConfiguration([...updatedConfigurationList]);
    setEditSubmit(true);
  };

  const handleConfigurationEditCancel = () => {
    setViewEditConfigurationModal(false);
    setConfigurationToEdit(null);
  };

  const handleConfigurationEditSave = (values) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    const itemFind = clonnedPhysicianGroupConfiguration.find(
      (eachConfiguration) =>
        eachConfiguration.physicianGroupId ===
        (values.physicianGroupDetails.value ||
          values.physicianGroupDetails.physicianGroupId)
    );
    if (itemFind) {
      itemFind.turnAroundTime = values["turnAroundTime"];
      itemFind.asyncConsultCost = values["asyncConsultCost"];
      itemFind.syncConsultCost = values["syncConsultCost"];
      itemFind.internalTurnAroundTime = values["internalTurnAroundTime"];
    }
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setConfigurationToEdit(null);
    setViewEditConfigurationModal(false);
    setEditSubmit(true);
  };

  const onSubmitConfiguration = (values) => {
    const physicianGroupData = [];
    if (actionType === "edit" && editSubmit === false) {
      message.info("Please update configuration before submit");
    } else if (physicianGroupConfiguration.length !== 0) {
      setSubmitButtonLoading(true);
      physicianGroupConfiguration.map((item) => {
        return physicianGroupData.push({
          physicianGroupId: item.physicianGroupId,
          stateId: values.state,
          priority: item.priority,
          turnAroundTime: item.turnAroundTime,
          internalTurnAroundTime: item.internalTurnAroundTime,
          physicianGroupName: item.physicianGroupName,
          id: item.id,
        });
      });

      const responseData = {
        loadBalancerConfig: physicianGroupData,
        deletedIds: deletedList,
      };

      addLoadBalancerConfiguration(responseData)
        .then((response) => {
          const { data } = response;
          setSubmitButtonLoading(false);
          if (data && data.success) {
            message.success(
              "Successfully Added State Load Balancer Configuration"
            );
            setPhysicianGroupConfiguration([]);
            form.resetFields();
            performConfigurationAction();
            performEditCancel();
            setDeletedList([]);
            setEditSubmit(false);
          } else {
            if (data && data.message) {
              message.success(data.message);
              setPhysicianGroupConfiguration([]);
              form.resetFields();
              performConfigurationAction();
              performEditCancel();
              setDeletedList([]);
              setEditSubmit(false);
            } else {
              message.error(
                "Unable to Add State Load Balancer Configuration. Please try again!"
              );
            }
          }
        })
        .catch((error) => {
          console.log(
            "================== error ========================",
            error
          );
          setSubmitButtonLoading(false);
          const { response } = error;
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to Add State Load Balancer Configuration. Please try again!"
              );
            }
          } else {
            message.error(
              "Unable to Add State Load Balancer Configuration. Please try again!"
            );
          }
        });
    } else {
      message.info("Please Add Physician Group Configuration!");
    }
  };

  const onClickCancel = () => {
    setPhysicianGroupConfiguration([]);
    performEditCancel();
    form.resetFields();
    setDeletedList([]);
    setEditSubmit(false);
  };

  return (
    <>
      <Title level={2}>
        {actionType === "edit"
          ? "Edit State Configuration"
          : "Add State Configuration"}
      </Title>

      <Form
        {...layout}
        form={form}
        layout={"vertical"}
        name="add-state-configuration"
        onFinish={onSubmitConfiguration}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select the state for Configuration"
                disabled={actionType === "edit" && true}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {stateGroupListForSelection &&
                  stateGroupListForSelection.map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.key}>
                        {item.text}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <LoadBalancerConfiguration
              configurationTitle="Physician Group Configuration"
              configurationsList={physicianGroupConfiguration}
              updateConfigurationGroup={handleConfigurationPriorityChange}
              handleAddConfigurationButtonClick={
                handleAddConfigurationClickCallback
              }
              handleConfigurationDelete={handleConfigurationDelete}
              handleConfigurationEdit={handleConfigurationEdit}
              selectablePhysicianGroupList={physicianGroupListForSelection}
            />

            <Modal
              destroyOnClose
              width={750}
              visible={viewAddConfigurationModal}
              onCancel={() => setViewAddConfigurationModal(false)}
              footer={[]}
            >
              <AddConfiguration
                onConfigurationAdd={handleConfigurationAdd}
                physicianGroupList={physicianGroupList}
                edit={false}
                selectablePhysicianGroupList={physicianGroupListForSelection}
              />
            </Modal>

            {editingConfiguration ? (
              <Modal
                destroyOnClose
                width={750}
                visible={viewEditConfigurationModal}
                onCancel={() => {
                  setConfigurationToEdit(null);
                  setViewEditConfigurationModal(false);
                }}
                footer={[]}
              >
                <AddConfiguration
                  onConfigurationAdd={handleConfigurationAdd}
                  physicianGroupList={physicianGroupList}
                  selectablePhysicianGroupList={physicianGroupListForSelection}
                  editingConfiguration={editingConfiguration}
                  edit={true}
                  handleEditCancel={handleConfigurationEditCancel}
                  handleEditSubmit={handleConfigurationEditSave}
                />
              </Modal>
            ) : (
              <></>
            )}
          </Col>

          <Col span={24}>
            <StyledFormItemWrapper>
              {actionType === "edit" && (
                <Button
                  size="large"
                  onClick={() => {
                    onClickCancel();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={submitButtonLoading}
              >
                Submit Configuration
              </Button>
            </StyledFormItemWrapper>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default StateConfigurationAction;
