/* eslint-disable no-template-curly-in-string */
import React from "react";
import styled from "styled-components";
import { Form, Input, Button, Row, Col, Typography } from "antd";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const AddTelemedicine = (props) => {
  const { addState } = props;
  const [form] = Form.useForm()
  const onFinish = (values) => {
    const { state } = values;
    const { stateName } = state;
    const stateDetails = {
      name: stateName,
    };
    addState(stateDetails);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Title level={2}>Add Telemedicine Categories</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name={["state", "stateName"]}
            label="Category Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter Telemedicine Category"/>
          </Form.Item>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol}}>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Add
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default AddTelemedicine;
