import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import config from "../../../config";


import { Typography, Spin, Radio, Tooltip } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
am5.addLicense(config.apiKeys.amCharts5);

const { Title, Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 12px #ccc;
  padding: 20px;
  background: #fff;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 7px;

  & h4 {
    margin-bottom: 0;
  }
`

const Chart = styled.div`
  height: 800px;
  margin-bottom: 20px;
  text-align: center;
  background: ${ props => props.loading && 'rgba(0, 0, 0, 0.2)' };
  border-radius: 4px;
  position: relative;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${props => !props.loading && 'none'};
`

const StyledRadioButton = styled(Radio.Button)`
    & * {
      user-select: none;
    }
`

const ReportQueueXYChart = ({
  title,
  id,
  data,
  selectedPhysicians,
  loading,
  chartType,
  sortBy,
  selectChartType
}) => {

  const [root, setRoot] = useState(null)

  const mapCompletionData = () => {
    const selectedPhysicianIds = selectedPhysicians.map(physician => physician._id)

    const totals = {
      'rx change': 0,
      'rx renewal': 0,
      'case': 0,
    }

    const mappedData = data
      .filter(physicianData => selectedPhysicianIds?.includes(physicianData.key))
      .map(physicianData => {

        function calcTotal(caseType) {
          let total = 0
          Object.values(caseType).forEach(modality => {
            total += (modality['consult complete'] || 0) + (modality['consult complete no rx'] || 0)
          })
          return total
        }

        const rxChangeCount = calcTotal(physicianData['rx change'])
        const rxRenewalCount = calcTotal(physicianData['rx renewal'])
        const caseCount = calcTotal(physicianData['case'])

        totals['rx change'] += rxChangeCount
        totals['rx renewal'] += rxRenewalCount
        totals['case'] += caseCount

        return {
          [physicianData.physician]: {
            'rx change': rxChangeCount,
            'rx renewal': rxRenewalCount,
            'case': caseCount,
          }
        }
      })
      .sort((a, b) => Object.values(b)[0][sortBy] - Object.values(a)[0][sortBy])

    return mappedData.map(physicianData => {
      const key = Object.keys(physicianData)[0]
      physicianData[key].percentages = {
        'rx change': Math.floor(totals['rx change'] / selectedPhysicians.length) || 0,
        'rx renewal': Math.floor(totals['rx renewal'] / selectedPhysicians.length) || 0,
        'case': Math.floor(totals['case'] / selectedPhysicians.length) || 0,
      }
      return physicianData
    })
  };

  const mapHandleTimeData = () => {

    const totals = {
      'rx change': 0,
      'rx renewal': 0,
      'case': 0,
    }

    const selectedPhysicianIds = selectedPhysicians.map(physician => physician._id)
    const mappedData = data
      .filter(physician => selectedPhysicianIds?.includes(physician.key))
      .map(physicianData => {

        const rxChangeTotal = Number(physicianData.totals['rx change'].toFixed(2))
        const rxRenewalTotal = Number(physicianData.totals['rx renewal'].toFixed(2))
        const caseTotal = Number(physicianData.totals['case'].toFixed(2))

        totals['rx change'] += rxChangeTotal
        totals['rx renewal'] += rxRenewalTotal
        totals['case'] += caseTotal

        return {
          [physicianData.physician]: {
            'rx change': rxChangeTotal,
            'rx renewal': rxRenewalTotal,
            'case': caseTotal,
          }
        }
      })
      .sort((a, b) => b[sortBy] - a[sortBy])

    return mappedData.map(physicianData => {
      const key = Object.keys(physicianData)[0]
      physicianData[key].percentages = {
        'rx change': Math.floor(totals['rx change'] / selectedPhysicians.length) || 0,
        'rx renewal': Math.floor(totals['rx renewal'] / selectedPhysicians.length) || 0,
        'case': Math.floor(totals['case'] / selectedPhysicians.length) || 0,
      }
      return physicianData
    })
  }

  useEffect(
    () => {
      root?.dispose();
      setRoot(null)

      if (data?.length) {
        const root = am5.Root.new(id)
        setRoot(root)

        root.setThemes([am5themes_Animated.new(root)]);

        let mappedData = chartType === 'case'
          ? mapCompletionData()
          : mapHandleTimeData()

        const formattedData = mappedData.map((i, n) => {
          return {
            [`${[Object.keys(i)[0]]}`]: {
              ...i[Object.keys(i)[0]]
            }
          }
        })

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none'
          })
        );

        chart.get('colors').set('colors', [
          am5.color('#59D2FE'),
          am5.color('#7BAC7D'),
          am5.color('#F55D3E'),
        ]);

        let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
        cursor.lineY.set('visible', false);

        let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({ text: '' });

        let xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: 'category',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {
              labelText: '{realName}'
            })
          })
        );

        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {})
          })
        );

        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: 'Provider',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            sequencedInterpolation: true,
            categoryXField: 'category',
            tooltip: am5.Tooltip.new(root, {
              labelText: '{physician} {realName}: {valueY}'
            })
          })
        );

        series.columns.template.setAll({
          fillOpacity: 0.9,
          strokeOpacity: 0
        });

        series.columns.template.adapters.add('fill', (fill, target) => {
          return chart.get('colors').getIndex(series.columns.indexOf(target) % 3);
        });

        series.columns.template.adapters.add('stroke', (stroke, target) => {
          return chart.get('colors').getIndex(series.columns.indexOf(target) % 3);
        });

        let bulletTemplate = am5.Template.new({
          fill: am5.color(0x414141)
        });

        function createLineSeries({ name, color, field }) {
          const lineSeries = am5xy.LineSeries.new(root, {
            name,
            xAxis,
            yAxis,
            valueYField: field,
            sequencedInterpolation: true,
            stroke: color,
            fill: color,
            categoryXField: 'category',
            tooltip: am5.Tooltip.new(root, { labelText: '{valueY}' })
          })

          lineSeries.strokes.template.set('strokeWidth', 2);

          lineSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 1,
              locationX: undefined,
              sprite: am5.Circle.new(root, {
                radius: 5,
                templateField: "bulletSettings"
              }, bulletTemplate),
            });
          });

          lineSeries.events.on('datavalidated', function () {
            am5.array.each(series.dataItems, dataItem => dataItem.set('locationX', 0))
          })

          lineSeries.appear(1000);
          return lineSeries
        }

        let chartData = [];

        for (let provider of formattedData) {
          const providerName = Object.keys(provider)[0]
          const providerData = Object.values(provider)[0]
          let tempArray = []

          for (let completionType in providerData) {
            if (completionType !== 'percentages') {
              tempArray.push({
                category: providerName + '_' + completionType,
                realName: completionType,
                value: providerData[completionType],
                provider: providerName,
              });
            }
          }

          tempArray[0].rxChangePercentage = providerData.percentages['rx change'];
          tempArray[0].count = 3
          tempArray[1].rxRenewalPercentage = providerData.percentages['rx renewal'];
          tempArray[1].count = 3
          tempArray[2].casePercentage = providerData.percentages['case'];
          tempArray[2].count = 3


          am5.array.each(tempArray, item => chartData.push(item))

          let range = xAxis.makeDataItem({});
          xAxis.createAxisRange(range);
          range.set('category', tempArray[0].category);
          range.set('endCategory', tempArray[tempArray.length - 1].category);

          let label = range.get('label');

          label.setAll({
            text: tempArray[0].provider,
            dy: 30,
            fontWeight: 'bold',
            tooltipText: tempArray[0].provider,
            rotation: -60,
          });

          let grid = range.get('grid');
          grid.setAll({ strokeOpacity: 1 });
        }

        // adds rightward-most vertical line to graph
        let range = xAxis.makeDataItem({});
        xAxis.createAxisRange(range);
        range.set('category', chartData[chartData.length - 1]?.category);
        let grid = range.get('grid');
        grid.setAll({ strokeOpacity: 1, location: 1 });

        // set data to series'
        xAxis.data.setAll(chartData);
        series.data.setAll(chartData);

        const rxChangeLineSerices = chart.series.push(createLineSeries({
          name: 'Rx Change',
          color: am5.color('#59D2FE'),
          field: 'rxChangePercentage'
        }))

        const rxRenewalLineSeries = chart.series.push(createLineSeries({
          name: 'Rx Renewal',
          color: am5.color('#7BAC7D'),
          field: 'rxRenewalPercentage'
        }))

        const caseLineSeries = chart.series.push(createLineSeries({
          name: 'New Case',
          color: am5.color('#F55D3E'),
          field: 'casePercentage'
        }))

        rxRenewalLineSeries.data.setAll(chartData);
        rxChangeLineSerices.data.setAll(chartData)
        caseLineSeries.data.setAll(chartData)


        series.appear(1000);
        chart.appear(1000, 100);
      }
      return () => root?.dispose()
    },
    [data, selectedPhysicians, sortBy]
  );

  const handleSelectChartType = e => {
    selectChartType(e.target.value)
  }

  return (
    <Container>
      <Header>
        <Title level={4}>
          {title}
          <Tooltip
            title="Handle time numbers may be skewed if provider has not completed many cases for the period"
          >
            <InfoCircleOutlined style={{ marginLeft: '5px'}}/>
          </Tooltip>
        </Title>

        <Radio.Group value={chartType} onChange={handleSelectChartType} buttonStyle='solid'>
          <StyledRadioButton value='case'>Handled Cases</StyledRadioButton>
          <StyledRadioButton value='time'>Average Handle Time</StyledRadioButton>
        </Radio.Group>
      </Header>
      { !data && loading && <Spin /> }
      { data && Object.keys(data).length > 0 ? (
        <Chart id={id} style={{ textTransform: 'capitalize' }} loading={loading}>
          <StyledSpin spinning={loading} />
        </Chart>
      ) : (
        !loading && !data?.length && <Text>No Data</Text>
      )}
    </Container>
  );
};

export default memo(ReportQueueXYChart);
