//import packages
import React from "react";
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Typography, Card, Button } from "antd";
import {
  UsergroupAddOutlined,
  SnippetsOutlined,
  GlobalOutlined,
  MedicineBoxOutlined,
  ArrowRightOutlined,
  FileDoneOutlined,
  MessageOutlined,
  UserAddOutlined,
  ReconciliationOutlined,
  DollarOutlined,
  SettingOutlined
} from "@ant-design/icons";
import styled from "styled-components";

//import images

import dictionaryList from "./dictionaryList";

//import components
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import authorization from "../../auth/authorization";

const { Content } = Layout;
const { Title, Text } = Typography;

const StyledTitle = styled(Typography.Title)`
  && {
    font-weight: 700;
    padding: 0 8px;
  }
`;

const StyledCard = styled(Card)`
  background-color: #ffffff;
  padding: 8px 12px;
  height: 100%;
  & .ant-card-body {
    padding: 12px 8px;
    height: 100%;
  }
`;

const StyledIconWrapperCol = styled(Col)`
  text-align: center;
  color: #7bad7e;
  font-size: 100px;
`;

const StyledTextWrapperCol = styled(Col)`
  padding: 0 8px 0 0;
`;

const StyledCardContentWrapper = styled(Row)`
  margin: 0 0 12px 0;
`;

const StyledComponentWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const DictionaryComponent = (props) => {
  const history = useHistory();
  const { userType } = props;
  const onCardClick = (path) => {
    history.push(path);
  };

  const selectImage = (imageDetails) => {
    switch (imageDetails) {
      case "clients":
        return <UsergroupAddOutlined />;
      case "states":
        return <GlobalOutlined />;
      case "telemedicine":
        return <MedicineBoxOutlined />;
      case "case-status":
        return <SnippetsOutlined />;
      case "questionnaire":
        return <FileDoneOutlined />;
      case "templates":
        return <MessageOutlined />;
      case "users":
        return <UserAddOutlined />;
      case "medications-directions":
        return <ReconciliationOutlined />;
      case "global-configurations":
        return <SettingOutlined />;
      case 'consultation-rates':
        return <DollarOutlined />
      default:
        return "";
    }
  };
  return (
    <>
      <Content>
        <StyledComponentWrapper>
          <StyledTitle level={1}>Manage Lists</StyledTitle>
          <Row>
            {dictionaryList.map((eachCard) => {
              return (
                <React.Fragment key={`card-${eachCard.imageUrl}`}>
                  {authorization.hasAccessToModule(
                    userType,
                    eachCard.rights
                  ) ? (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ padding: "12px" }}
                      key={eachCard.imageUrl}
                    >
                      <StyledCard key={`card-${eachCard.imageUrl}`}>
                        <Row>
                          <Col>
                            <Title level={3}>{eachCard.title}</Title>
                          </Col>
                        </Row>
                        <StyledCardContentWrapper>
                          <StyledTextWrapperCol
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Text>{eachCard.description}</Text>
                          </StyledTextWrapperCol>
                          <StyledIconWrapperCol
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            {selectImage(eachCard.imageUrl)}
                          </StyledIconWrapperCol>
                        </StyledCardContentWrapper>
                        <Row>
                          <Col>
                            <Button
                              type="primary"
                              block
                              icon={<ArrowRightOutlined />}
                              size={"large"}
                              onClick={() => {
                                onCardClick(eachCard.goToPath);
                              }}
                            >
                              {eachCard.goToLabel}
                            </Button>
                          </Col>
                        </Row>
                      </StyledCard>
                    </Col>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </Row>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(DictionaryComponent);
