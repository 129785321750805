import React, { useState, useMemo } from "react";
import { Form, Row, Col, InputNumber, Input, Button, message, Select, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { requestRxChange } from "../../api/CasesAPI";
import { debounce } from 'lodash';
import { getPharmacies } from '../../api/PharmacyAPI';
import styled from "styled-components";
import { StyledText, StyledTitle } from "../../components/LayoutComponent";

const { TextArea } = Input;
const { Option } = Select;

const StyledFormItemWrapper = styled(Form.Item)`
  text-align: right;
`;

const StyledTextArea = styled(TextArea)`
  &.ant-input-disabled {
    color: #7c7c7c;
    color: #7c7c7c;
    background-color: #ffffff;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const CaseRequestMedication = ({
  caseDetails,
  fetchCaseDetails,
  setLoading,
  caseId,
}) => {
  const [form] = Form.useForm();
  const [pharmacy, setPharmacy] = useState();
  const [pharmacies, setPharmacies] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line
      number: "${label} is not a validate number!",
    },
  };

  const fetchPharmacies = (name) => {
    if (name) {
      setPharmacies([]);
      setIsSearching(true);

      getPharmacies(name)
        .then(({ data: { error, pharmacies } }) => {
          if (error || !pharmacies) return message.error("Unable to fetch Pharamacy List. Please try again!");

          setPharmacies(pharmacies.map((pharmacy) => ({
            ...pharmacy,
            name: pharmacy.StoreName,
            key: pharmacy.PharmacyId,
            value: pharmacy.PharmacyId,
            state: pharmacy.State,
          })));

          message.success("Successfully fetched Pharamacy List");
        })
        .catch(({ response }) => {
          const errorMessage = "Error occured while fetching Pharamacy List. Please try again!"
          message.error(response?.data?.message || errorMessage);
        })
        .finally(() => setIsSearching(false));
    } 
  };

  const debounceFetchPharmacies = useMemo(() => debounce(fetchPharmacies, 1245), [])

  const handleStatusUpdate = (value) => {
    const data = {
      ...value,
      caseId: caseId,
    };

    if (pharmacy) data.pharmacyId = pharmacy;

    if (
      caseDetails &&
      caseDetails.status &&
      (caseDetails.status === "consult complete no rx" ||
        caseDetails.status === "consult complete")
    ) {
      setLoading(true);
      requestRxChange(data)
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.success) {
            form.resetFields();
            message.success("Successfully Requested Rx Change");
            fetchCaseDetails();
          } else {
            message.error("Unable to Request Rx Change");
          }
        })
        .catch(() => {
          setLoading(false);
          message.error("Error occured while requesting Rx Change");
        });
    } else {
      message.error(
        "Can only Request For Rx-Change when the Case Status is Consult Complete No Rx or Consult Complete"
      );
      return false;
    }
  };

  return (
    <>
      <hr></hr>
      <StyledTitle level={4}>Request for Rx-Change</StyledTitle>
      <Form
        {...layout}
        form={form}
        layout={"vertical"}
        name="rx-change-request"
        onFinish={handleStatusUpdate}
        validateMessages={validateMessages}
      >
        <Row gutter={24} style={{ marginBottom: 20 }} >
          <Col span={24}>
            <Spin
              spinning={isSearching}
              size="small"
              indicator={<LoadingOutlined />}
            >
              <div className='ant-form-item-label'>
                <label for='pharmacy'>Pharmacy (Optional)</label>
              </div>
              <Select
                id='pharmacy'
                size="large"
                showSearch
                allowClear
                placeholder='Find a Pharmacy'
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={debounceFetchPharmacies}
                onChange={setPharmacy}
                value={pharmacy}
                notFoundContent={null}
                style={{ width: '100%' }}
              >
                {pharmacies.map((pharmacy) => (
                  <Option
                    key={pharmacy.key}
                    value={pharmacy.value}
                  >
                    {pharmacy.name}
                  </Option>
                ))}
              </Select>
            </Spin>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="drugName"
              label="Drug Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size={"large"} placeholder="Enter Drug Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="dosage"
              label="Dosage"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size={"large"} placeholder="Enter Dosage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="refills"
              label="Refills"
              rules={[
                {
                  required: true,
                  type: "number",
                },
              ]}
            >
              <StyledInputNumber size={"large"} placeholder="Enter Refills" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="dispense"
              label="Dispense"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size={"large"} placeholder="Enter Dispense" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="daysSupply"
              label="Days Supply"
              rules={[
                {
                  required: true,
                  type: "number",
                },
              ]}
            >
              <StyledInputNumber
                size={"large"}
                placeholder="Enter Days Supply"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="ndc"
              label="NDC"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size={"large"} placeholder="Enter NDC" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="pillCount"
              label="Quantity"
              rules={[
                {
                  required: true,
                  type: "number",
                },
              ]}
            >
              <StyledInputNumber
                size={"large"}
                placeholder="Enter Quantity"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Reason Notes"
              name="rxChangeReason"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                },
              ]}
              extra={
                <StyledText strong>
                  Once you updated the case for Rx-Change, Please wait for the
                  Physician to update the prescription and move the case to
                  completed!. Then you can again review the prescription and
                  move the case to Rx-Change if required
                </StyledText>
              }
            >
              <StyledTextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            <StyledFormItemWrapper wrapperCol={{ span: 24 }}>
              <Button type="primary" size="large" htmlType="submit">
                Submit Rx-Change Request
              </Button>
            </StyledFormItemWrapper>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CaseRequestMedication;
