import React, { useState } from "react";
import { Button, Typography, Table, Modal } from "antd";
import {
  EditOutlined,
  DeleteFilled,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;

const { Title } = Typography;

const ViewStateConfiguration = ({
  configurationListData,
  viewPhysicianConfigurationList,
  performConfigurationEdit,
  performConfigurationDelete,
}) => {
  const [viewConfigurationModal, setViewConfigurationModal] = useState(false);
  const columns = [
    {
      title: "Configured State",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "View Configurations",
      dataIndex: "",
      key: "view",
      render: (configuration, record) => (
        <Button
          shape="circle"
          icon={<FileTextOutlined />}
          onClick={() => {
            viewPhysicianConfigurationList(configuration.id);
          }}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "edit",
      render: (configuration, record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            performConfigurationEdit(configuration.id);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "delete",
      render: (configuration, record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => showDeleteConfirmation(configuration)}
        />
      ),
    },
  ];

  const showDeleteConfirmation = (configuration) => {
    confirm({
      title: `Do you want to delete state ${configuration.name} Configurations?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        performConfigurationDelete(configuration);
      },
      onCancel: () => {
        console.log("======== cancelled delete ===========");
      },
    });
  };

  return (
    <>
      <Title level={2}>State Configurations</Title>
      <Table
        dataSource={configurationListData}
        pagination={false}
        columns={columns}
        width="max-content"
      />
      <Modal
        destroyOnClose
        width={750}
        visible={viewConfigurationModal}
        onCancel={() => setViewConfigurationModal(false)}
        footer={[]}
      ></Modal>
    </>
  );
};

export default ViewStateConfiguration;
