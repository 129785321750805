import React from 'react';
import styled from 'styled-components';

export const StyledFiltersSection = styled.section`
  display: flex;
  justify-content: space-between; 
  margin-bottom: 20px;
  text-transform: capitalize;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;

  &.span {
    margin-right: 20px;
  }
`;

export const Label = ({ label, children, ...props }) => {
  return (
    <StyledLabel {...props}>
      <span>{label}</span>
      {children}
    </StyledLabel>
  )
}