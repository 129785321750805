import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";



const UpdateConsultationType = ({ setBulkUpdateParams }) => {
    const [consultationType, setConsultationType] = useState("store and forward")

    useEffect(() => setBulkUpdateParams({ newConsultationType: consultationType }), [consultationType])

    return (
        <Form>
          <Select
            style={{ width: '100%'}}
            value={consultationType}
            onChange={setConsultationType}
          >
            <Select.Option value="store and forward">Store and Forward</Select.Option>
            <Select.Option value="video">Video</Select.Option>
            <Select.Option value="audio">Audio</Select.Option>
          </Select>
        </Form>
    )
}

export default UpdateConsultationType
