import React, { useState, useEffect } from "react";
import { List, Typography, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { getNotes } from "../../api/CasesAPI";
import HTMLReactParser from 'html-react-parser';

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  && {
    color: #7eae80 !important;
  }
`;

const StyledSpan = styled.span`
  color: #7eae80 !important;
`;

const CasePhysicianNotes = ({ caseId }) => {
  const [physicianNotes, setPhysicianNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);

  const getAllPhysicianNoteRelatedToCase = (caseId) => {
    setNotesLoading(true);
    getNotes(caseId)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          const { payload } = data;
          if (payload && Array.isArray(payload)) {
            const notesList = payload.map((eachItem) => ({
              ...eachItem,
              createdDate: eachItem.createdDate
                ? dateTimeFormatter(eachItem.createdDate)
                : "NA",
              updatedDate: eachItem.updatedDate
                ? dateTimeFormatter(eachItem.updatedDate)
                : "NA",
            }));
            setPhysicianNotes(notesList);
          }
          message.success("Successfully fetched the physician notes");
        } else {
          if (data && data.message) {
            message.warn(data.message);
          } else {
            message.warn("Unable to fetch Physician notes. Please try again!");
          }
        }
        setNotesLoading(false);
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete state. Please try again!");
          }
        } else {
          message.error("Unable to delete state. Please try again!");
        }
        setNotesLoading(false);
      });
  };

  const initialNetworkRequest = () => {
    if (caseId) {
      getAllPhysicianNoteRelatedToCase(caseId);
    }
  };

  useEffect(initialNetworkRequest, [caseId]);

  return (
    <Spin spinning={notesLoading} indicator={<LoadingOutlined />}>
      <article>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={physicianNotes}
          header={
            <StyledTitle level={4}>Physician Notes Related to Case</StyledTitle>
          }
          renderItem={(item) => (
            <List.Item key={item._id} actions={null}>
              <List.Item.Meta
                title={<Text strong>{item.title}</Text>}
                description={
                  <>
                    {item.rxRequest && (
                      <div className='title-color' style={{ marginTop: -10, marginBottom: 10 }}>
                        <StyledSpan>associated with {item.rxRequest.requestType} request from {dateTimeFormatter(item.rxRequest.createdAt)}</StyledSpan>
                      </div>
                    )} 
                    Created by: <StyledSpan>{item.authorName}</StyledSpan>,
                    Created Date: <StyledSpan>{item.createdDate}</StyledSpan>,
                    Last Updated Date:{" "}
                    <StyledSpan>{item.updatedDate}</StyledSpan>
                  </>
                }
              />
              <Text>{HTMLReactParser(item.content)}</Text>
            </List.Item>
          )}
        />
      </article>
    </Spin>
  );
};

export default CasePhysicianNotes;
