export const sorters = {
  alphanumeric: (a, b) => a > b ? 1 : -1,
  dates: (a, b) => a - b,
  favorite: (template) => template.isFavorite ? -1 : 1,
}

export const getTextFieldSorter = (fieldName) => (a, b) => {
  return sorters.alphanumeric(a[fieldName] || '', b[fieldName] || '');
};

export const getDateFieldSorter = (fieldName) => (a, b) => {
  return sorters.dates(
    a[fieldName] ? new Date(a[fieldName]) : null,
    b[fieldName] ? new Date(b[fieldName]) : null,
  );
};

export const getSortOrder = (sortOrder, sortedBy, fieldName) => {
  return sortedBy === fieldName ? sortOrder : null
}
