/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  Modal,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

//import icons
import LogoIcon from "../assets/icons/LogoIcon";

//import functions
import { login, passwordResetRequest } from "../api/AuthAPI";
import auth from "../auth/authorization";

const { Content } = Layout;

const StyledOuterLayout = styled(Layout)`
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled(Content)`
  flex: unset;
`;

const StyledComponentWrapper = styled.div`
  width: 100%;
  max-width: 514px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;
const Title = styled(Typography.Title)`
  && {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  margin: 12px 0 0;
  width: 100%;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
`;

const StyledLinkButton = styled(Button)`
  width: 100%;
  font-size: 16px;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  height: 56px;
  border: 1px solid #c5c7d5 !important;
`;

const StyledPasswordField = styled(Input.Password)`
  font-size: 16px;
  height: 56px;
  border: 1px solid #c5c7d5 !important;
  > .ant-input-suffix {
    font-size: 24px;
    margin-left: unset;
    margin: 4px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  text-align: right;
`;

const StyledForgetPasswordButton = styled(Button)`
  margin-left: 8px;
`;

const LoginComponent = () => {
  const [loading, setLoading] = useState(false);
  const [formloading, setFormLoading] = useState(false);
  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] =
    useState(false);
  const history = useHistory();

  const reRouteIfAlreadyLoggedIn = () => {
    if (auth.isAuthenticated()) {
      const userType = auth.getUserType();
      if (
        userType === "admin" ||
        userType === "manager" ||
        userType === "admin support" ||
        userType === "super admin" ||
        userType === "provider" ||
        userType === "steadymd admin support"
      ) {
        history.push("/");
      } else {
        message.error("Login failed. Invalid Email or Password!");
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  };

  useEffect(() => {
    reRouteIfAlreadyLoggedIn();
  }, []);

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const forgetPasswordFormLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    required: "Please enter your ${label}",
    types: {
      email: "${label} is not valid email!",
    },
  };

  const forgetPasswordValidateMessages = {
    required: "Please enter your email address",
    types: {
      email: "Email address is not valid!",
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    const { user } = values;
    login({
      ...user,
    })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          if (data.payload && data.payload.sendVia) {
            message.success(data.payload.sendVia);
            history.push("/verify-otp");
            localStorage.setItem("_id", data.payload._id);
            localStorage.setItem("type", data.payload.type);
          } else if (data && data.token) {
            const { token, type, userEmail } = data;
            localStorage.setItem("userToken", token);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("type", type);
            history.push("/");
          } else {
            message.error("Login Failed! Please check your email or password");
          }
        } else {
          message.error("Login Failed! Please check your email or password");
        }
      })
      .catch(() => {
        message.error("Login Failed! Please check your email or password");
        setLoading(false);
      });
  };

  const onForgetPasswordFinish = (values) => {
    setFormLoading(true);
    const { forgetPassword } = values;
    passwordResetRequest({
      ...forgetPassword,
    })
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(
            "Successfully identified your account and initiated password reset! Reset link has been sent to your Email"
          );
          setForgetPasswordModalVisible(false);
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Failed to find you account! Please check your email"
            );
          }
        }
        setFormLoading(false);
      })
      .catch(() => {
        message.error(
          "Failed to find your account! Please check your email or try again later"
        );
        setFormLoading(false);
      });
  };

  const handleForgetPasswordLink = () => {
    setForgetPasswordModalVisible(true);
  };

  const handleForgetPasswordModalCancel = () => {
    setForgetPasswordModalVisible(false);
  };

  return (
    <StyledOuterLayout>
      <StyledContent>
        <StyledComponentWrapper>
          <Form
            {...layout}
            layout={"vertical"}
            name="login-form"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Title level={2}>
              <LogoIcon width="224px" height="80px" />
            </Title>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={["user", "email"]}
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <StyledInput
                    placeholder="Enter Email Id"
                    disabled={loading}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={["user", "password"]}
                  label="Password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledPasswordField
                    placeholder="Enter Password"
                    disabled={loading}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    LOGIN
                  </StyledButton>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                  <StyledLinkButton
                    type="link"
                    onClick={handleForgetPasswordLink}
                  >
                    Forgotten password?
                  </StyledLinkButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            visible={forgetPasswordModalVisible}
            title="Find Your Account"
            maskClosable={false}
            closable={false}
            footer={null}
            centered
            destroyOnClose
          >
            <Spin
              spinning={formloading}
              size="large"
              indicator={<LoadingOutlined />}
            >
              <Form
                {...forgetPasswordFormLayout}
                layout={"vertical"}
                name="forget-password-form"
                onFinish={onForgetPasswordFinish}
                validateMessages={forgetPasswordValidateMessages}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name={["forgetPassword", "email"]}
                      label="Please enter your email address to search for your account."
                      rules={[
                        {
                          required: true,
                          type: "email",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email Id" disabled={loading} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <StyledFormItem
                      wrapperCol={{ ...forgetPasswordFormLayout.wrapperCol }}
                    >
                      <StyledForgetPasswordButton
                        type="secondary"
                        size="large"
                        onClick={handleForgetPasswordModalCancel}
                      >
                        Cancel
                      </StyledForgetPasswordButton>
                      <StyledForgetPasswordButton
                        type="primary"
                        htmlType="submit"
                        size="large"
                      >
                        Submit
                      </StyledForgetPasswordButton>
                    </StyledFormItem>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Modal>
        </StyledComponentWrapper>
      </StyledContent>
    </StyledOuterLayout>
  );
};

export default LoginComponent;
