import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import { 
    searchMedications,
    getMedicationByDispensableDrugId
 } from '../../../../../api/PrescriptionsApi'

export const useSearchMedications = ({ 
    form,
    selectedTemplate
 }) => {
    const [medicationSearchMatches, setMedicationSearchMatches] = useState([])
    const [medicationSearchLoading, setMedicationSearchLoading] = useState(false)
    const [availableMedicationStrengths, setAvailableMedicationStrengths] = useState([]);

    const abortController = useRef(null)

    const fetchMedications = useCallback(async (searchStr) => {
        setMedicationSearchMatches([])
        setAvailableMedicationStrengths([])

        form.setFieldsValue({
            'ndc': null,
            'strength': null,
            'dispensableDrugId': null,
            'ingredients': null,
            'dispenseUnitId': null,
            'schedule': null,
        })

        if (searchStr?.length <= 2) {
            setMedicationSearchLoading(false);
            return;
        }

        try {
            abortController.current?.abort()
            abortController.current = new AbortController()

            setMedicationSearchLoading(true)
            const { data: { medications } } = await searchMedications(searchStr, abortController.current?.signal)

            if (abortController.current?.signal.aborted || !medications?.length) {
                return
            }

            setMedicationSearchMatches(medications); 
        } catch (e) {
            console.error(e);
            message.error('Error fetching medication matches from prescription service');
        } finally {
            setMedicationSearchLoading(false);
        }
    }, [])

    const handleSelectMedication = medicationName => {
        form.setFieldsValue({
            'ndc': null,
            'strength': null,
            'dispensableDrugId': null,
            'ingredients': null,
            'dispenseUnitId': null,
            'schedule': null,
        })

        setAvailableMedicationStrengths([])

        const medicationMatch = medicationSearchMatches.find(medication => medication.name === medicationName);
        if (!medicationMatch) return;

        const isCompound = medicationMatch.ingredients?.length

        if (isCompound) {            
            form.setFieldsValue({ 
                strength: 'compound', 
                ingredients: medicationMatch.ingredients,
                dispenseUnitId: medicationMatch.dispenseUnitId 
            })

            return setAvailableMedicationStrengths([{ strength: 'compound' }]);
        } 

        setAvailableMedicationStrengths(medicationMatch.strengths);
    };

    const handleSelectStrength = strength => {
        const medication = availableMedicationStrengths.find(medStrength => medStrength.strength === strength)

        if (!medication) return;

        form.setFieldsValue({ 
            ndc: medication.ndc,
            dispenseUnitId: medication.dispenseUnitId,
            dispensableDrugId: medication.dispensableDrugId,
            schedule: medication.schedule
        })
    }

    const getTemplateMedicationAndStrength = async dispensableDrugId => {
        try {
            const { data } = await getMedicationByDispensableDrugId(dispensableDrugId)
            return data.medication || null
        } catch (e) {
            console.error(e);
        }
    }

    const debounceFetchMedications = useMemo(() => debounce(fetchMedications, 1000), []);

    useEffect(() => {
        if (selectedTemplate?.medication.dispensableDrugId) {
            form.setFieldsValue({
                dispensableDrugId: selectedTemplate.medication.dispensableDrugId,
            })
        }
    }, [selectedTemplate])

    return { 
        fetchMedications: debounceFetchMedications, 
        medicationSearchMatches, 
        medicationSearchLoading,
        handleSelectMedication,
        handleSelectStrength,
        setMedicationSearchMatches,
        setMedicationSearchLoading,
        availableMedicationStrengths,
        getTemplateMedicationAndStrength,
    }
}