//import packages
import React, { useState, useEffect } from "react";
import { message, Typography, Spin, Modal } from "antd";
import styled from "styled-components";
// import components
import StateConfigurationAction from "./StateConfigurationAction";
import ViewStateConfiguration from "./ViewStateConfiguration";
import ViewPhysicianConfiguration from "./ViewPhysicianConfiguration";
import {
  getLoadBalancerConfigurationDetails,
  getStateBasedLoadBalancerConfigurationList,
} from "../../../api/LoadBalancerConfigurationAPI";
import { LoadingOutlined } from "@ant-design/icons";
import { addLoadBalancerConfiguration } from "../../../api/LoadBalancerConfigurationAPI";

//import other files and API functions

const { Title } = Typography;

const StyledComponentWrapper = styled.article`
  width: 100%;
`;
const StyledAddSection = styled.section`
  background-color: #ffffff;
  padding: 24px 28px;
`;

const StyledViewSection = styled.section`
  background-color: #ffffff;
  padding: 24px 28px;
  margin-top: 24px;
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledHelperNoteListItem = styled.li`
  text-align: justify;
`;

const StateConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [viewConfigurationLoading, setViewConfigurationLoading] = useState(
    false
  );
  const [configurationListData, setConfigurationListData] = useState();
  const [viewConfigurationModal, setViewConfigurationModal] = useState();
  const [singleConfigurationData, setSingleConfigurationData] = useState();
  const [actionType, setActionType] = useState();
  const [editId, setEditId] = useState();
  const fetchConfigurationList = () => {
    setLoading(true);
    getStateBasedLoadBalancerConfigurationList()
      .then((res) => {
        const { data } = res;
        setLoading(false);
        if (data && data.success) {
          const { payload } = data;
          if (payload) {
            setConfigurationListData(payload);
          }
          message.success(
            "Successfully fetched State Load Balancer Configuration"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to load State Load Balancer Configuration. Please try again!"
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to load State Load Balancer Configuration. Please try again!"
        );
      });
  };

  useEffect(fetchConfigurationList, []);

  const performConfigurationAction = () => {
    fetchConfigurationList();
    setActionType();
  };

  const viewPhysicianConfigurationList = (stateId) => {
    setViewConfigurationModal(true);
    const params = {
      "state-flag": true,
      stateId: stateId,
    };
    setViewConfigurationLoading(true);
    getLoadBalancerConfigurationDetails(params)
      .then((res) => {
        const { data } = res;
        setViewConfigurationLoading(false);
        if (data && data.success) {
          const { payload } = data;
          if (payload && payload.length) {
            setSingleConfigurationData(payload);
          }
          message.success(
            "Successfully fetched State Physician Load Balancer Configuration"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to load State Physician Load Balancer Configuration. Please try again!"
            );
          }
        }
      })
      .catch((error) => {
        setViewConfigurationLoading(false);
        message.error(
          "Unable to load State Physician Load Balancer Configuration. Please try again!"
        );
      });
  };

  const performConfigurationDelete = (configData) => {
    const responseData = {
      deletedIds: configData.loadBalancerId,
    };
    addLoadBalancerConfiguration(responseData)
      .then((response) => {
        const { data } = response;
        if (data && data.success) {
          message.success(
            "Successfully Deleted State Load Balancer Configuration"
          );
          fetchConfigurationList();
        } else {
          if (data && data.message) {
            message.success(data.message);
            fetchConfigurationList();
          } else {
            message.error(
              "Unable to Delete State Load Balancer Configuration. Please try again!"
            );
          }
        }
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Delete State Load Balancer Configuration. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Delete State Load Balancer Configuration. Please try again!"
          );
        }
      });
  };

  const performEditCancel = () => {
    setEditId();
    setActionType();
  };

  const performConfigurationEdit = (stateId) => {
    const params = {
      "state-flag": true,
      stateId: stateId,
    };
    setActionType("edit");
    getLoadBalancerConfigurationDetails(params)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload && payload.length) {
            setSingleConfigurationData(payload);
            setEditId(stateId);
          }
          message.success(
            "Successfully fetched State Physician Load Balancer Configuration"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to load State Physician Load Balancer Configuration. Please try again!"
            );
          }
        }
      })
      .catch((error) => {
        message.error(
          "Unable to load State Physician Load Balancer Configuration. Please try again!"
        );
      });
  };

  return (
    <StyledComponentWrapper>
      <StyledAddSection>
        <StyledTitle level={2}>Load Balancer State Configurations</StyledTitle>

        <Title level={4}>Helper Notes:</Title>
        <ol>
          <StyledHelperNoteListItem>
            <strong>Priority</strong> will be used while assigning the cases to
            a physician group. For example, If we assign{" "}
            <b>Internal Physicians Group</b> with the highest priority, then the
            created cases will get assigned to Internal Physicians Group first.
            The physician group with <strong>lower priority value</strong> is
            considered as the <strong>high prior physician group</strong> and
            will be choosen first for case assignment.
          </StyledHelperNoteListItem>
          <StyledHelperNoteListItem>
            <strong>Turn Around Time SLA</strong> will be added for each
            physician group. So that, if the assigned cases to a specific{" "}
            <b>physician group</b> don't make any progress within the provided{" "}
            <strong>Turn Around Time SLA</strong>, the allocated case will be
            reassigned to the next prior <b>physician group</b>.
          </StyledHelperNoteListItem>
          <StyledHelperNoteListItem>
            <strong>Internal Turn Around Time SLA</strong> will be added for
            each physician group. So that, if the assigned cases to a specific{" "}
            <b>physician</b> within a <b>physician group</b> doesn't start the
            consult within the provided{" "}
            <strong>Internal Turn Around Time SLA</strong>, the allocated case
            will be reassigned to another <b>physician</b> within the{" "}
            <b>physician group</b>.
          </StyledHelperNoteListItem>
        </ol>

        <StateConfigurationAction
          actionType={actionType}
          editId={editId}
          editConfigurationData={singleConfigurationData}
          performConfigurationAction={performConfigurationAction}
          performEditCancel={performEditCancel}
          configurationListData={configurationListData}
        />
      </StyledAddSection>

      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <StyledViewSection>
          <ViewStateConfiguration
            configurationListData={configurationListData}
            performConfigurationEdit={performConfigurationEdit}
            performConfigurationDelete={performConfigurationDelete}
            viewPhysicianConfigurationList={viewPhysicianConfigurationList}
          />
        </StyledViewSection>
        <Modal
          destroyOnClose
          width={750}
          visible={viewConfigurationModal}
          onCancel={() => setViewConfigurationModal(false)}
          footer={[]}
        >
          <Spin
            spinning={viewConfigurationLoading}
            size="large"
            indicator={<LoadingOutlined />}
          >
            <ViewPhysicianConfiguration
              singleConfigurationData={singleConfigurationData}
            />
          </Spin>
        </Modal>
      </Spin>
    </StyledComponentWrapper>
  );
};

export default StateConfiguration;
