import React, { useState } from "react";
import { useEffect } from "react";
import { lookUp } from "../../api/LookUpAPI";
import { DatePicker, Select, Button, Form, Input, Col, Row } from "antd";
import styled from "styled-components";
import moment from "moment";
const { Option } = Select;

const StyledDatePicker = styled(DatePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

const StyledPickerFormItem = styled(Form.Item)`
  margin: 0px;
`;

const StyledCol = styled(Col)`
  display: flex;

  .ant-form-item {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const StyledDatePickerFormItem = styled(Form.Item)`
  width: 60%;
  @media screen and (max-width: 900px) {
    width: auto;
  }
`;

const AllocationCaseFilter = ({ fetchCaseChartReportData }) => {
  const [form] = Form.useForm();
  const [picker, setPicker] = useState("date");
  const [fetchCaseStatus, setFetchCaseStatus] = useState();
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    const caseStatus = [];

    lookUp("case-status").then((res) => {
      res.data.payload.map((status) => {
        return caseStatus.push({
          key: status.name,
          value: status.name,
          text: status.displayName,
        });
      });
      setFetchCaseStatus(caseStatus);
    });
  }, []);

  function handleChange(value) {
    setPicker(value);
    form.resetFields(["pickerValue"])
  }

  const applyCaseFilter = (value) => {
    const filter = {};
    if (value.pickerValue) {
      if (picker === "date") {
        filter["filterType"] = "day";
        filter["filter"] = moment(value.pickerValue).format("YYYY-MM-DD");
      }

      if (picker === "week") {
        filter["filterType"] = "week";
        filter["startDate"] = moment(value.pickerValue)
          .startOf("week")
          .format("YYYY-MM-DD");
        filter["endDate"] = moment(value.pickerValue)
          .endOf("week")
          .format("YYYY-MM-DD");
      }

      if (picker === "month") {
        filter["filterType"] = "month";
        filter["filter"] = moment(value.pickerValue).format("YYYY-MM");
      }

      if (picker === "year") {
        filter["filterType"] = "year";
        filter["filter"] = moment(value.pickerValue).format("YYYY");
      }
    }

    if (value.status) {
      filter["status"] = value.status;
    }

    fetchCaseChartReportData(filter);
  };

  const onClearFilter = () => {
    form.resetFields();
    setIsFilter(false);
    fetchCaseChartReportData();
  };

  const applyFilterOnChange = () => {
    setIsFilter(true);
  };

  return (
    <Form
      name="case-chart-filter"
      layout={"vertical"}
      form={form}
      onFinish={applyCaseFilter}
      onFieldsChange={applyFilterOnChange}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Form.Item name="status" label="Case Status">
            <Select
              placeholder="Filter by Case status"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {fetchCaseStatus &&
                fetchCaseStatus.map((eachStatus) => (
                  <Select.Option key={eachStatus.key} value={eachStatus.value}>
                    {eachStatus.text}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <StyledPickerFormItem label="Time Range">
            <Input.Group compact>
              <Form.Item>
                <Select
                  defaultValue={picker}
                  onChange={(value) => handleChange(value)}
                >
                  <Option value="date">Date</Option>
                  <Option value="week">Week</Option>
                  <Option value="month">Month</Option>
                  <Option value="year">Year</Option>
                </Select>
              </Form.Item>
              <StyledDatePickerFormItem name="pickerValue">
                <StyledDatePicker picker={picker}></StyledDatePicker>
              </StyledDatePickerFormItem>
            </Input.Group>
          </StyledPickerFormItem>
        </Col>
        <StyledCol xs={8} sm={8} md={4} lg={3} xl={2}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Filter
            </Button>
          </Form.Item>
        </StyledCol>
        <StyledCol xs={8} sm={8} md={4} lg={3} xl={2}>
          <Form.Item>
            <Button
              type="secondary"
              disabled={!isFilter}
              onClick={() => onClearFilter()}
            >
              Clear
            </Button>
          </Form.Item>
        </StyledCol>
      </Row>
    </Form>
  );
};

export default AllocationCaseFilter;
