import { Modal, Col, Divider, Typography} from "antd";
import styled from "styled-components";

const { Text, Title } = Typography;

const StyledSubTitle = styled(Title)`
  margin-bottom: 0px !important;
`;

const StyledCol = styled(Col)`
  margin-bottom: 30px;
  margin-top: 30px;
`;

const StyledDivider = styled(Divider)`
  background: lightgrey;
  margin: 4px 0;
`;
const StyledText = styled(Text)`
  color: #7bad7e;
  font-weight: 600;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 20px 10px 20px 10px;
  }
  .ant-modal-content .ant-modal-close {
    padding-top: 20px;
    padding-right: 20px;
  }
`;

export { StyledDivider, StyledCol, StyledSubTitle, StyledText, StyledModal };
