import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import './App.css';


import ContentComponent from './content';
import LoginComponent from './auth';
import ResetPasswordComponent from "./auth/ResetPasswordComponent";
import ProtectedRoutes from './routes/ProtectedRoutes';

//Theming fonts
import fontTheme from './utils/fontTheme';
import GlobalStyles from './utils/GlobalStyles';
import VerificationOTP from './auth/VerificationOTP';
import { useVersionUpdater } from './hooks/useVersionUpdater';

function App() {
  useVersionUpdater();

  return (
    <ThemeProvider theme={fontTheme}>
      <Router>
        <Suspense fallback="loading">
          <Switch>
            <Route exact path="/login" component={LoginComponent} />
            <Route exact path="/verify-otp" component={VerificationOTP}/>
            <Route exact path="/reset-password" component={ResetPasswordComponent} />
            <ProtectedRoutes adminComponent={ContentComponent}/>
          </Switch>
        </Suspense>
      </Router>
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default App;
