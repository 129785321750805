//import packages
import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "antd";

//import components
import SocketContext from "../context/socketContext";
import Sidebar from "../layout/Sidebar";
import MainDrawer from "../layout/MainDrawer";
import MainHeader from "../layout/MainHeader";
import MainMenu from "../layout/MainMenu";
import MainDictionaryComponent from "./Dictionary";
import PhysicianComponent from "./Physicians";
import ManageCases from "./Cases/ManageCases";
import ReportComponent from "./Reports";
import CaseTicketComponent from "./CaseTickets";
import PhysicianCaseQueue from "./PhysicianCaseQueue"
import { getCaseTicketNotifications } from "../api/CaseTicketsAPI";
import {
  StyledInnerLayout,
  StyledOuterLayout,
} from "../components/LayoutComponent";
import ProfileView from "../components/ProfileComponent/ProfileView";
import ReportChartComponent from "./ReportCharts";
import CaseDetails from "./Cases/CaseDetails";
import SchedulesComponent from "./Schedules";
import UserRolesAndRightsHOComponent from "../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import UnauthorizedComponent from "../components/UnauthorizedComponent/UnauthorizedComponent";
import authorization from "../auth/authorization";
import Articles from "./Articles";
import ArticleView from "./Articles/ArticleView";
import { AppointmentCalendar } from './AppointmentCalendar';
import socket, { socket_init } from "../api/socket";
import { PhysicianQueueSocketProvider } from "../context/PhysicianQueueSocketContext";
import { PhysicianReportSocketProvider } from "../context/PhysicianReportSocketContext";
import { CustomReportSocketProvider } from "../context/CustomReportSocketContext";

const ContentComponent = ({ userDetails, userType }) => {
  const [showSidebar, setSidebarDisplay] = useState(false);
  const [collapsedSidebar, setSidebarCollapseStatus] = useState(false);
  const [showSidebarDrawer, setSidebarDrawerStatus] = useState(false);
  const [notificationCount, setNotificationCount] = useState();

  const changeSidebarStatus = (status) => {
    setSidebarDisplay(status);
    setSidebarCollapseStatus(status);
  };

  const changeSidebarDrawerStatus = (status) => {
    setSidebarDrawerStatus((previousDrawerStatus) => {
      return !previousDrawerStatus;
    });
  };

  const getCaseTicketNotificationCount = () => {
    getCaseTicketNotifications()
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload) {
            setNotificationCount(payload.totalCount);
          }
        } else {
          console.log("Unable to get Case Tickets Notification Count");
        }
      })
      .catch((error) => {
        console.log(
          "Error occured while getting Case Ticket Notification Count"
        );
      });
  };

  const initiateSocket = () => {
    socket.connect();
    socket_init();
  };

  useEffect(() => {
    if (authorization.hasAccessToModule(userType, ["caseTicketListing"])) {
      getCaseTicketNotificationCount();
      initiateSocket();

      socket.on("newTicketChatMessageSend", (data) => {
        console.log(
          "============================== new Messages event data ==============================="
        );
        getCaseTicketNotificationCount();
      });

      socket.on("clearTicketNotificationSend", (data) => {
        console.log(
          "============================== clearNotification event data ==============================="
        );

        if (authorization.getUserId() === data.userId) {
          getCaseTicketNotificationCount();
        }
      });

      socket.on("error", (err) => {
        console.log(
          "============================== socket error =====================================",
          err
        );
      });

      return () => {
        socket.removeAllListeners([
          "newTicketChatMessageSend",
          "clearTicketNotificationSend",
        ]);
        socket.close();
      };
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <SocketContext.Provider value={socket}>
        <CustomReportSocketProvider>
        <PhysicianReportSocketProvider>
        <PhysicianQueueSocketProvider>
        <StyledOuterLayout>
          <MainHeader
            userDetails={userDetails}
            changeSidebarCollapseState={changeSidebarDrawerStatus}
          />
          <Layout>
            {showSidebar && (
              <MainDrawer
                sidebarDrawerStatus={showSidebarDrawer}
                changeSidebarDrawerState={changeSidebarDrawerStatus}
              >
                <MainMenu notificationCount={notificationCount} />
              </MainDrawer>
            )}
            <Sidebar
              collapsed={collapsedSidebar}
              changeCollapseState={changeSidebarStatus}
            >
              <MainMenu notificationCount={notificationCount} />
            </Sidebar>
            <StyledInnerLayout>
              <Route
                path="/dictionary"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "createClient",
                        "getCategoryOfClient",
                        "updateClient",
                        "deleteClient",
                        "getAdminUserClients",
                        "getAdminUserClientDetails",
                        "createState",
                        "updateState",
                        "deleteState",
                        "searchState",
                        "getStates",
                        "createTeleMedicineCategory",
                        "updateTeleMedicineCategory",
                        "deleteTeleMedicineCategory",
                        "getTeleMedicineCategory",
                        "createCaseStatus",
                        "updateCaseStatus",
                        "deleteCaseStatus",
                        "getMessageTemplate",
                        "getMessageTemplateDetails",
                        "saveMessageTemplate",
                        "updateMessageTemplate",
                        "deleteMessageTemplate",
                        "getAdminUsers",
                        "adminUserView",
                        "registerAdminUser",
                        "adminUserUpdate",
                        "adminUserDelete",
                      ]) ? (
                        <MainDictionaryComponent />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/physicians"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "getPhysicianForAdmin",
                        "physicianModerationByAdmin",
                        "registerPhysician",
                        "getPhysicianDetailsForAdmin",
                        "updatePhysicianDetails",
                        "updatePhysicianSchedule",
                      ]) ? (
                        <PhysicianComponent />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/cases"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "getCases",
                        "getCasesDetails",
                        "updateCase",
                        "caseStatusModification",
                      ]) ? (
                        <ManageCases
                          userDetails={userDetails}
                        />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/report-charts"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "consultCompleteReport",
                        "consultNoRXReport",
                        "clientCasesReport",
                        "physicianCaseReport",
                        "physicianPaymentReport",
                      ]) ? (
                        <ReportChartComponent />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/report"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "consultCompleteReport",
                        "consultNoRXReport",
                        "clientCasesReport",
                        "physicianCaseReport",
                        "physicianPaymentReport",
                      ]) ? (
                        <ReportComponent />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/case-tickets"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "caseTicketListing",
                      ]) ? (
                        <CaseTicketComponent
                          notificationCount={notificationCount}
                        />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                path="/cases/:id"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "getCases",
                        "getCasesDetails",
                      ]) ? (
                        <CaseDetails
                          userDetails={userDetails}
                          getCaseTicketNotificationCount={
                            getCaseTicketNotificationCount
                          }
                        />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/schedule-availability/:id"
                render={() => {
                  return (
                    <>
                      {authorization.hasAccessToModule(userType, [
                        "updatePhysicianSchedule",
                        "getPhysicianAvailability",
                      ]) ? (
                        <SchedulesComponent />
                      ) : (
                        <UnauthorizedComponent />
                      )}
                    </>
                  );
                }}
              />
              <Route exact path="/my-profile" component={ProfileView} />
              <Route
                path="/"
                exact
                render={() => {
                  return userType && userType === "provider" ? (
                    <Redirect to="/physicians" />
                  ) : (
                    <Redirect to="/report-charts" />
                  );
                }}
              />
              <Route
                exact
                path="/articles"
                render={() => {
                  return <Articles />;
                }}
              />
              <Route
                path="/articles/:id"
                render={() => {
                  return <ArticleView />;
                }}
              />
              <Route
                path='/report-bug'
                render={() => <><iframe title='report-bug' class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/f/87e83-4515/BPU3C0VWPIV1BW3GZP" onwheel="" width="100%" height="100%" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script></>}
              />
              <Route
                path='/request-feature'
                render={() => <><iframe title='request-feature' class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/f/87e83-5474/2JM4D4HDKY3AELIE9F" onwheel="" width="100%" height="100%" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script></>}
              />
              <Route
                path='/appointments'
                render={(props) => <AppointmentCalendar {...props} />}
              />
              <Route
                path='/physician-case-queue/:physicianId'
                render={(props) => <PhysicianCaseQueue {...props} />}
              />
            </StyledInnerLayout>
          </Layout>
        </StyledOuterLayout>
        </PhysicianQueueSocketProvider>
        </PhysicianReportSocketProvider>
        </CustomReportSocketProvider>
      </SocketContext.Provider>
    </>
  );
};

export default UserRolesAndRightsHOComponent(ContentComponent);
