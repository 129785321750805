//import packages
import React, { useState, useEffect } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  message,
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";
import AddMedicationsAndDirections from "./AddMedicationsAndDirections";
import EditMedicationsAndDirections from "./EditMedicationsAndDirections";
import {
  addMedicationsAndDirections,
  getMedicationsAndDirections,
  updateMedicationsAndDirections,
  deleteMedicationsAndDirections,
} from "../../../api/MedicationsAndDirectionsApi";

const { confirm } = Modal;
const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const MedicationsAndDirectionsComponent = () => {
  const [medicationsAndDirections, setMedicationsAndDirections] = useState([]);
  const [editId, setEditId] = useState("");
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);

  const fetchMedicationsAndDirections = () => {
    getMedicationsAndDirections().then((res) => {
      const { data } = res;
      if (data && data.success) {
        let mappedData = [];
        const { payload } = data;
        if (payload && payload.length) {
          mappedData = [...payload];
        }
        setMedicationsAndDirections(mappedData);
      }
    });
  };

  const fetchTelecategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const telemedicineData = res.data.payload.map(
          (eachMedicineCategory) => ({
            ...eachMedicineCategory,
            key: eachMedicineCategory._id,
          })
        );
        setTelemedicineCategory(telemedicineData);
      }
    });
  };

  const initNetworkRequest = () => {
    fetchTelecategory();
    fetchMedicationsAndDirections();
  };

  useEffect(initNetworkRequest, []);

  const onAddingMedicationsAndDirections = (templateMessage) => {
    addMedicationsAndDirections(templateMessage)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully added medication and its directions");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Add medication and its direction. Please try again!"
            );
          }
        }
        fetchMedicationsAndDirections();
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Add medication and its direction. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Add medication and its direction. Please try again!"
          );
        }
      });
  };

  const onUpdatingMedicationsAndDirections = (id, templateMessage) => {
    updateMedicationsAndDirections(id, templateMessage)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully Updated medication and its directions");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update medication and its directions. Please try again!"
            );
          }
        }
        fetchMedicationsAndDirections();
        setEditId("");
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Update medication and its directions. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Update medication and its directions. Please try again!"
          );
        }
      });
  };

  const onDeletingMedicationsAndDirections = (medicationId) => {
    deleteMedicationsAndDirections(medicationId)
      .then((res) => {
        const { status } = res;
        if (status && status === 204) {
          message.success("Successfully Deleted medication and its directions");
          setEditId();
        } else {
          const { data } = res;
          if (data && data.success) {
            message.success(
              "Successfully Deleted medication and its directions"
            );
            setEditId();
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to delete medication and its directions. Please try again!"
              );
            }
          }
        }
        fetchMedicationsAndDirections();
      })
      .catch((error) => {
        console.log(
          "================== delete template message error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to delete medication and its directions. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to delete medication and its directions. Please try again!"
          );
        }
      });
  };

  const showDeleteConfirmation = (medicationDirection, medicationId) => {
    confirm({
      title: `Do you want to delete ${medicationDirection.medication}  medication and its direction?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        onDeletingMedicationsAndDirections(medicationId);
      },
      onCancel() {},
    });
  };

  function showTelemedicineCategories(teleMedicineCategories) {
    Modal.info({
      title: "Associated Telemedicine Category",
      content: (
        <>
          {teleMedicineCategories && teleMedicineCategories.length ? (
            <ul>
              {teleMedicineCategories.map((eachCategory) => (
                <li>
                  <b>{eachCategory.name}</b>
                </li>
              ))}
            </ul>
          ) : (
            <p>
              <b>No telemedicine categories to list</b>
            </p>
          )}
        </>
      ),
      onOk() {},
    });
  }

  const onMessageEdit = (messageId) => {
    setEditId(messageId);
  };

  const columns = [
    {
      title: "Medication",
      dataIndex: "medication",
      key: "medication",
      width: 230,
      fixed: "left",
    },
    {
      title: "Display Name",
      dataIndex: "name",
      key: "display-name",
      width: 240,
    },
    {
      title: "Dosage(mg)",
      dataIndex: "dosage",
      key: "dosage",
      width: 250,
      align: "center",
    },
    {
      title: "Usage Directions for Medication",
      dataIndex: "directions",
      key: "directions",
      width: 600,
    },
    {
      title: "View Associated Categories",
      dataIndex: "",
      key: "t",
      width: 250,
      align: "center",
      render: (medicationAndDirection, record) => (
        <Button
          shape="circle"
          icon={<EyeOutlined />}
          onClick={() => {
            showTelemedicineCategories(
              medicationAndDirection.teleMedicineCategories
            );
          }}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "e",
      width: 100,
      align: "center",
      render: (medicationAndDirection, record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            onMessageEdit(medicationAndDirection._id);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "x",
      width: 100,
      align: "center",
      render: (medicationAndDirection, record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            showDeleteConfirmation(
              medicationAndDirection,
              medicationAndDirection._id
            );
          }}
        />
      ),
    },
  ];

  const afterEdit = () => {
    setEditId("");
  };

  return (
    <>
      <Content>
        <StyledComponentWrapper>
          {editId ? (
            <EditMedicationsAndDirections
              messageId={editId}
              afterEdit={afterEdit}
              telemedicineCategory={telemedicineCategory}
              updateMedicationsAndDirections={
                onUpdatingMedicationsAndDirections
              }
            />
          ) : (
            <AddMedicationsAndDirections
              addMedicationsAndDirections={onAddingMedicationsAndDirections}
              telemedicineCategory={telemedicineCategory}
            />
          )}
        </StyledComponentWrapper>

        <StyledComponentWrapper>
          <Title level={2}>Medications and Directions</Title>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={medicationsAndDirections}
                pagination={false}
                scroll={{ x: 720 }}
              />
            </Col>
          </Row>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default MedicationsAndDirectionsComponent;
