import React, { useState, useMemo } from "react";
import moment from "moment-timezone";
import { Row, Col, Form, DatePicker, Select, Tag, Button, Checkbox, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  getAllPhysiciansCaseCompletionCounts,
  getAllPhysiciansAverageCaseHandleTimes,
} from "../../api/ReportApi";
import { usePhysicianQueueContext } from "../../context/PhysicianQueueSocketContext";
import ReportQueueXYChart from "./ReportQueueXYChartComponent";
import { LOCAL_STORAGE_KEY, REPORT_TYPE } from "../../constants/enums";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";

const Wrapper = styled.div`
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 12px #ccc;
  padding: 20px;
  background: #fff;
`;

const Filter = styled(Form)`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const StyledSelect = styled(Select)`
  &:hover {
    boder-color: 'red';
  }
`

const StyledDatePicker = styled(DatePicker)`
`

const Column = ({ children }) => (
  <Col
    className="gutter-row"
    xs={{ span: 24 }}
    sm={{ span: 24 }}
    md={{ span: 18 }}
    lg={{ span: 20 }}
    xl={{ span: 20 }}
  >{children}</Col>
)

const tagRender = (props) => {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const PhysicianQueuePerformance = () => {
  const {
    completionCounts,
    handleTimes,
    loading,
    physicians,
    selectedPhysicians,
    requestId,
    isAppend,
    fetchedPhysicianIds,
    cancelRequest,
    setSelectedPhysicians,
    setLoading,
    resetData,
    handleData,
  } = usePhysicianQueueContext()

  const [hideInactiveProviders, setHideInactiveProviders] = useState(true)
  const selectedDateLS = getLocalStorage(LOCAL_STORAGE_KEY.Date, 'date');
  const [selectedDate, selectDate] = useState(selectedDateLS ? moment(selectedDateLS).endOf('day') : moment().endOf('day'))
  const [timeSpan, selectTimeSpan] = useState(getLocalStorage(LOCAL_STORAGE_KEY.Timespan, 'timeSpan') || 'date')

  const [sortBy, setSortBy] = useState('case')
  const [chartType, selectChartType] = useState('case')

  const retrievePhysiciansAverageCaseHandleTimes = (physicianIds, date) => {
    getAllPhysiciansAverageCaseHandleTimes({
      timeSpan,
      endDate: date.format('MM-DD-YYYY'),
      physicianIds,
      requestId: requestId.current.averageCaseHandleTimes,
      reportType: REPORT_TYPE.QUEUE,
    }, {
      timeout: 10_000,
    });
  }

  const retrievePhysiciansCaseCompletionCounts = async (physicianIds, date) => {
    getAllPhysiciansCaseCompletionCounts({
      filterType: timeSpan,
      filter: date.format('MM-DD-YYYY'),
      physicianIds,
      requestId: requestId.current.completionCounts,
      reportType: REPORT_TYPE.QUEUE,
    }, {
      timeout: 10_000,
    });
  }

  const retrieveReports = async (physicianIds, date, append = false) => {
    if (!physicianIds?.length || !date) {
      return;
    }
    fetchedPhysicianIds.current = physicianIds
    isAppend.current = append
    setLoading(true)
    retrievePhysiciansCaseCompletionCounts(physicianIds, date)
    retrievePhysiciansAverageCaseHandleTimes(physicianIds, date)
  }

  const handleChangeSelectedPhysicians = async (newlySelectedPhysicianIds) => {
    const currentlySelectedPhysicianIds = selectedPhysicians.map(physician => physician._id)
    const newPhysicianIds = newlySelectedPhysicianIds.filter(id => !currentlySelectedPhysicianIds.includes(id))

    if (newPhysicianIds.length) {
      await retrieveReports(newPhysicianIds, selectedDate, true)
    } else {
      const handler = (items) => {
        return items?.filter(item => newlySelectedPhysicianIds.includes(item.key)) || null
      }
      handleData(handler);
    }

    const newlySelectedPhysicians = physicians.filter(physician => newlySelectedPhysicianIds.includes(physician._id))
    setLocalStorage(LOCAL_STORAGE_KEY.SelectedPhysicians, { selectedPhysicians: newlySelectedPhysicians })
    setSelectedPhysicians(newlySelectedPhysicians)
  }

  const handleFetchReports = () => {
    resetData()
    const selectedPhysicianIds = selectedPhysicians.map(physician => physician._id)
    retrieveReports(selectedPhysicianIds, selectedDate)
  }

  const handleDateChange = (date) => {
    const newDate = moment(date).endOf(timeSpan)
    setLocalStorage(LOCAL_STORAGE_KEY.Date, { date: newDate })
    selectDate(newDate)
  }

  const handleTimeSpanChange = (timeSpan) => {
    setLocalStorage(LOCAL_STORAGE_KEY.Timespan, { timeSpan })
    selectTimeSpan(timeSpan)
  }



  const xyChartDataSet = useMemo(() => {
    const filterData = (data) => {
      if (!data) return null
      if (hideInactiveProviders) return data.filter(provider => Object.values(provider.totals).reduce((a, b) => a + b) > 0)
      return data
  }
    return filterData(chartType === 'case' ? completionCounts : handleTimes)
  }, [completionCounts, handleTimes, hideInactiveProviders, chartType])

  return (
    <Wrapper className="article-wrapper">
      <Spin
        size="large"
        spinning={loading}
        tip={<span>Continue your Work.<br/> You will be notified when Report is ready</span>}
        indicator={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LoadingOutlined />
            <Button style={{ marginTop: 60 }} onClick={cancelRequest} children="Cancel Loading" />
          </div>
        }
      >
      <Row gutter={[16, 16]} wrap justify="space-around">
      <Column>
          <Container>
            <Filter layout="vertical">
              <Form.Item label="Period Length" style={{ width: "30%" }}>
                <StyledSelect
                    size="large"
                    placeholder="Select Timespan"
                    value={timeSpan}
                    showArrow
                    onChange={handleTimeSpanChange}
                    disabled={loading}
                  >
                      <Select.Option value='date'>Day</Select.Option>
                      <Select.Option value='week'>Week</Select.Option>
                      <Select.Option value='month'>Month</Select.Option>
                </StyledSelect>
              </Form.Item>
              <Form.Item label="Period" style={{ width: "30%", background: '#fff' }}>
                <StyledDatePicker
                  size="large"
                  style={{ width: "100%"}}
                  onChange={handleDateChange}
                  defaultValue={selectedDate}
                  value={selectedDate}
                  picker={timeSpan}
                  allowClear={false}
                  disabledDate={(date) => date > moment().endOf("month")}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item label="Sort By" style={{ width: "30%" }}>
                <Select
                    size="large"
                    placeholder="Sort By"
                    value={sortBy}
                    showArrow
                    onChange={setSortBy}
                    disabled={loading}
                  >
                      <Select.Option value='case'>New Cases</Select.Option>
                      <Select.Option value='rx renewal'>Rx Renewals</Select.Option>
                      <Select.Option value='rx change'>Rx Changes</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ width: "10%" }}>
                <Button
                  type="primary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={handleFetchReports}
                >
                  Fetch
                </Button>
              </Form.Item>
            </Filter>
            <Checkbox checked={hideInactiveProviders} onClick={e => setHideInactiveProviders(e.target.checked)}>
              Hide Inactive Providers
            </Checkbox>
          </Container>
        </Column>
        <Column>
          <ReportQueueXYChart
            id="queuePerformance"
            title="Physician Queue Performance"
            data={xyChartDataSet}
            selectedPhysicians={selectedPhysicians}
            chartType={chartType}
            sortBy={sortBy}
            selectChartType={selectChartType}
          />
        </Column>
        <Column>
          <Container>
            <Select
                  size="large"
                  placeholder="Select Physician"
                  value={selectedPhysicians.map(physician => physician._id)}
                  showArrow
                  onChange={handleChangeSelectedPhysicians}
                  disabled={loading}
                  mode='multiple'
                  tagRender={tagRender}
                >
                  {physicians?.map((physician) => (
                    <Select.Option key={physician._id} value={physician._id}>
                      {`${physician.firstName} ${physician.lastName}`}
                    </Select.Option>
                  ))}
                </Select>
          </Container>
        </Column>
      </Row>
      </Spin>
    </Wrapper>
  );
};

export default PhysicianQueuePerformance;
