//Unused. Just imported without visible modal
import React, { useState } from "react";
import { Form, Spin, message, Modal, Select, DatePicker, Checkbox } from "antd";
import moment from "moment";
import { scheduleAppointment } from "../../../api/CasesAPI";

const timeZoneList = moment.tz.names();

const ScheduleConsultationModal = ({ visible, setVisible, caseId, fetchCases }) => {
    const [fetchLoading, setFetchLoading] = useState(false);

    const [physicianOptions, setPhysicianOptions] = useState([]); //TODO: do we use it?
    const [selectedPhysician, setSelectedphysician] = useState(); //TODO: do we use it?
    const [scheduleForm] = Form.useForm();

    return (
        <Modal
          title='Schedule a Consultation'
          open={visible}
          okText='Confirm'
          closable={false}
          okButtonProps={{ disabled: fetchLoading }}
          cancelButtonProps={{ disabled: fetchLoading }}
          onOk={scheduleForm.submit}
          onCancel={() => {
            scheduleForm.resetFields();
            setVisible(false)
          }}
          destroyOnClose
          zIndex={1010}
        >
          <Spin spinning={fetchLoading}>
            <Form
              form={scheduleForm}
              layout='vertical'
              initialValues={{ timeZone: moment.tz.guess() }}
              onValuesChange={({ timeZone, startTime }, values) => {
                if (timeZone) {
                  scheduleForm.setFieldsValue({
                    startTime: moment(values.startTime).tz(timeZone),
                    endTime: moment(values.endTime).tz(timeZone),
                  })
                }

                if (startTime) {
                  const strippedStartTime = moment(startTime).set({
                    minutes: startTime.minutes() % 10 < 5
                      ? startTime.minutes() - (startTime.minutes() % 10)
                      : startTime.minutes() + (10 - (startTime.minutes() % 10)),
                    seconds: 0,
                    milliseconds: 0
                  });
                  scheduleForm.setFieldsValue({
                    startTime: strippedStartTime,
                    endTime: moment(strippedStartTime).add({ minutes: 10 })
                  });
                }
              }}
              onFinish={(values) => {
                const data = {
                  physicianId: values.physician,
                  startTime: values.startTime.toISOString(),
                  endTime: values.endTime.toISOString(),
                  notifyPatient: !!values.notifyPatient
                }

                setFetchLoading(true)
                return scheduleAppointment(caseId, data)
                  .then(() => {
                    scheduleForm.resetFields();
                    setVisible(false);
                    message.success('Scheduled appointment successfully');
                  })
                  .then(fetchCases)
                  .catch(() => message.error('Unable to schedule the appointment'))
                  .finally(() => setFetchLoading(false));
              }}
            >
              <Form.Item name='timeZone' label='Time Zone'>
                <Select
                  showSearch
                  className="timezone-select"
                  placeholder="Select a time zone"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  optionFilterProp="children"
                >
                  {timeZoneList.map((tz) => (
                    <Select.Option key={tz} value={tz}>{tz}</Select.Option>)
                  )}
                </Select>
              </Form.Item>
              <Form.Item name='physician' label='Physician' rules={[{ required: true }]}>
                <Select
                  showSearch
                  style={{ width: "60%" }}
                  options={physicianOptions}
                  placeholder='Select a physician'
                  onChange={setSelectedphysician}
                  optionFilterProp='label'
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
              <Form.Item name='startTime' label='Start Time' rules={[{ required: true }]}>
                <DatePicker format='MMM DD YYYY hh:mmA' minuteStep={10} showSecond={false} showTime use12Hours />
              </Form.Item>
              <Form.Item name='endTime' label='End Time' rules={[{ required: true }]}>
                <DatePicker format='MMM DD YYYY hh:mmA' minuteStep={10} showSecond={false} showTime use12Hours disabled />
              </Form.Item>
              <Form.Item
                name='notifyPatient'
                valuePropName='checked'
                label='Notify Patient?'
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <Checkbox
                  style={{
                    marginRight: 10,
                    paddingBottom: 8,
                    flexGrow: 'unset'
                  }}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
    )
}

export default ScheduleConsultationModal;
