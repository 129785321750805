import axiosInstance from "./AxiosInstance";
import queryString from "query-string";
import config from "../config";

const { apiAppendPath } = config;

export const getCases = (filterData) => {
    return axiosInstance.get(
        `${apiAppendPath.casesAppendPath}/case`, { params: filterData }
    );
};

export const getCasesV2 = (filterData) => {
    return axiosInstance.get(
        `${apiAppendPath.v2CasesAppendPath}/case`, { params: filterData }
    );
};

export const getCaseDetails = (caseId) => {
    return axiosInstance.get(
        `${apiAppendPath.casesAppendPath}/case/${caseId}`
    );
};

export const updateCaseStatus = (caseId, data) => {
    return axiosInstance.put(
        `${apiAppendPath.casePhysicianAppendPath}/cases/${caseId}`,
        data
    );
}

export const cancelCase = caseId => {
    return axiosInstance.delete(
        `cases/${caseId}`
    );
}

export const restoreCancelledCase = caseId => {
    return axiosInstance.put(
        `cases/${caseId}/restore`
    );
}

export const updateCaseStatusV2 = (caseId, data) => {
  return axiosInstance.patch(
      `/cases/${caseId}`,
      data
  );
}

export const requestRxChange = (data) => {
    return axiosInstance.put(
        `${apiAppendPath.casesAppendPath}/rx-change`,
        data
    );
}

export const caseArchiveAndDelete = (caseId, data) => {
    return axiosInstance.put(
        `${apiAppendPath.casesAppendPath}/update-case/${caseId}`, data
    )
}

export const updateCasePatientDetails = (caseId, data) => {
    return axiosInstance.put(
        `${apiAppendPath.casesAppendPath}/case/${caseId}`, data
    )
}

export const getNotes = (caseId) => {
    return axiosInstance.get(
        `${apiAppendPath.casePhysicianAppendPath}/get-notes-of-case/${caseId}`
    );
}

export const getRxRequestNotes = (caseId) => {
    return axiosInstance.get(
        `${apiAppendPath.casePhysicianAppendPath}/get-rx-request-notes/${caseId}`
    );    
}

export const scheduleAppointment = (caseId, data) => {
    return axiosInstance.post(`/cases/${caseId}/appointments/schedule`, data);
}

export const bulkReassign = data => {
    return axiosInstance.put(`/cases/reassign-many`, data);
}

export const bulkUpdateCaseConsultationType = data => {
    return axiosInstance.put('/cases/bulk-update-consultation-type', data)
}

export const archiveCases = data => {
    return axiosInstance.put(`/cases/archive`, data);
}

export const softDeleteCases = data => {
    const stringifiedIds = queryString.stringify(data)
    return axiosInstance.delete(`/cases/delete?${stringifiedIds}`, data);
}

export const bulkUpdateCaseStatus = data => {
    return axiosInstance.put(`/cases/bulk-update-status`, data);
}

export const getCaseQueueForPhysician = params => {
    return axiosInstance.get('/cases/queue', { params })
}

export const addCaseQueueConfiguration = params => {
    return axiosInstance.post('/cases/queue/config', params)
}

export const getCaseQueueConfigurationDetails = () => {
    return axiosInstance.get('/cases/queue/config')
}

export const releaseCasesToQueue = data => {
    return axiosInstance.put('/cases/bulk-release-to-queue', data)
}

export const getSignedUploadUrl = (caseId, fileName) => {

    return axiosInstance.get(`/cases/${caseId}/documents/upload-url`, {
        params: { fileName } 
    })
}

export const addAdditionalDocument = (caseId, url) => {
    return axiosInstance.patch(`/cases/${caseId}/documents`, {
        name: `${Date.now()}`,
        url 
    })
}

export const addRxRequestNote = (caseId, data) => {
    return axiosInstance.post(
        `${apiAppendPath.casePhysicianAppendPath}/new-note/${caseId}`,
        data
    );    
}
export const updateRxRequestNote = (noteId, data) => {
    return axiosInstance.patch(
        `${apiAppendPath.casePhysicianAppendPath}/update-note/${noteId}`,
        data
    );    
}

export const setPrescriptionStatus = async (caseId, status) => {
    return (await axiosInstance.patch(`/cases/${caseId}/prescription-status`, { status })).data
}