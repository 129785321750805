const config = {
  api: {
    baseUrl: `${process.env.REACT_APP_E_PRESCRIBE_API_URL}`,
    websocketBaseUrl: `${process.env.REACT_APP_E_PRESCRIBE_WS_URL}`,
  },
  apiAppendPath: {
    commonAppendPath: `${process.env.REACT_APP_COMMON_APPEND_PATH}`,
    clientAppendPath: `${process.env.REACT_APP_CLIENT_APPEND_PATH}`,
    teleCategoryAppendPath: `${process.env.REACT_APP_TELE_CATEGORY_APPEND_PATH}`,
    stateAppendPath: `${process.env.REACT_APP_STATE_APPEND_PATH}`,
    caseStatusAppendPath: `${process.env.REACT_APP_CASE_STATUS_APPEND_PATH}`,
    physicianAppendPath: `${process.env.REACT_APP_PHYSICIAN_APPEND_PATH}`,
    casesAppendPath: `${process.env.REACT_APP_CASES_APPEND_PATH}`,
    v2CasesAppendPath: `${process.env.REACT_APP_V2_CASES_APPEND_PATH}`,
    questionnaireAppendPath: `${process.env.REACT_APP_QUESTIONNAIRE_APPEND_PATH}`,
    casePhysicianAppendPath: `${process.env.REACT_APP_CASE_PHYSICIAN_APPEND_PATH}`,
    templatesAppendPath: `${process.env.REACT_APP_TEMPLATES_APPEND_PATH}`,
    reportAppendPath: `${process.env.REACT_APP_CASE_REPORT_APPEND_PATH}`,
    v2ReportAppendPath: `${process.env.REACT_APP_V2_REPORT_APPEND_PATH}`,
    physicianApiAppendPath: `${process.env.REACT_APP_PHYSICIAN_API_APPEND_PATH}`,
    userRoleApiAppendPath: `${process.env.REACT_APP_USER_ROLE_APPEND_PATH}`,
    userTypeApiAppendPath: `${process.env.REACT_APP_USER_TYPE_APPEND_PATH}`,
    userApiAppendPath: `${process.env.REACT_APP_USER_APPEND_PATH}`,
    pharmacyAppendPath: `${process.env.REACT_APP_PHARMACY_APPEND_PATH}`,
    reportChartsAppendPath: `${process.env.REACT_APP_REPORT_CHARTS_APPEND_PATH}`,
    medicationsAppendPath: `${process.env.REACT_APP_MEDICATIONS_APPEND_PATH}`,
    loadBalancerAppendPath: `${process.env.REACT_APP_LOAD_BALANCER_APPEND_PATH}`,
    chatRoomAppendPath: `${process.env.REACT_APP_CHAT_ROOM_APPEND_PATH}`,
    caseTicketsAppendPath: `${process.env.REACT_APP_CASE_TICKETS_APPEND_PATH}`,
    learningManagementSystemAppendPath: `${process.env.REACT_APP_LEARNING_MANAGEMENT_SYSTEM_APPEND_PATH}`,
    reAssignCaseAppendPath: `${process.env.REACT_APP_REASSIGN_CASE_APPEND_PATH}`,
    patientApiAppendPath: process.env.REACT_APP_PATIENT_APPEND_PATH,
    prescriptionsAppendPath: process.env.REACT_APP_PRESCRIPTIONS_APPEND_PATH
  },

  completedCaseCheckDeadlineInDays: `${process.env.REACT_APP_COMPLETED_CASE_CHECK_DEADLINE_IN_DAYS}`,

  apiKeys: {
    tinyEditorKey: `${process.env.REACT_APP_E_PRESCRIBE_TINY_EDITOR_API_KEY}`,
    amCharts5: process.env.REACT_APP_AMCHARTS_5_KEY,
    amCharts4: process.env.REACT_APP_AMCHARTS_4_KEY,
  },
  MARKER_PROJECT_ID: process.env.REACT_APP_MARKER_PROJECT_ID,
  LATEST_SHA: process.env.REACT_APP_LATEST_SHA
};

export default config;
