import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config

export const addMedicationsAndDirections = data => {
    return axiosInstance.post(
        `${apiAppendPath.medicationsAppendPath}/medication`,
        data
    );
};

export const getMedicationsAndDirections = () => {
    return axiosInstance.get(
        `${apiAppendPath.medicationsAppendPath}/medication`
    )
}

export const deleteMedicationsAndDirections = (id) => {
    return axiosInstance.delete(
        `${apiAppendPath.medicationsAppendPath}/medication/${id}`
    )
}


export const updateMedicationsAndDirections = (id, data) => {
    return axiosInstance.put(
        `${apiAppendPath.medicationsAppendPath}/medication/${id}`, data
    )
}

export const getMedicationsAndDirectionsById = (id) => {
    return axiosInstance.get(
        `${apiAppendPath.medicationsAppendPath}/medication/${id}`
    )
}