import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';

export const usePhysicians = () => {
  const [physicians, setPhysicians] = useState([]);

  const getPhysicians = () => axios.get(`${config.api.baseUrl}/v2/physician`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`
      }
    })
    .then(({ data }) => {
      setPhysicians(data.physicians);
      return data.physicians;
    })
    .catch((error) => {
      console.info('Error fetching physicians', error);
      return [];
    });

  useEffect(() => {
    getPhysicians();
  }, [])

  return { physicians, getPhysicians };
}