import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getProfileDetails = () => {
  return axiosInstance.get(`${apiAppendPath.userTypeApiAppendPath}/profile`);
};

export const updateProfileDetails = (data) => {
  return axiosInstance.put(
    `${apiAppendPath.userTypeApiAppendPath}/profile`,
    data
  );
};
