import styled from 'styled-components';
import { Form, List, Badge } from 'antd';

export const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between; 
  margin: 25px 0px;
`;

export const StyledListItem = styled(List.Item)`
  border-bottom: ${props => props.underline ? '1px solid rgba(100,100,100,0.35) !important' : 'none'};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledListItemTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

export const StyledListItemDescriptionContainer = styled.div`
  padding: 5px;
`;

export const StyledListItemTitle = styled.span`
  margin: 0 15px 0 5px;
`;

export const StyledAppointmentsCount = styled.span`
  color: green;
`;

export const StyledBadge = styled(Badge)`
  margin: 0 5px;

  * {
    background-color: #838C94;
  }
`;

export const StyledEventContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;