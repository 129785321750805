import { Form, Col, Button, } from "antd";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 424px;
  @media (max-width: 1200px) {
    max-width: 360px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 320px;
  }
  @media (max-width: 720px) {
    max-width: 280px;
  }
`;

const StyledFilterButton = styled(Button)`
  height: 45px;
  width: 45px;
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const StyledButtonWrap = styled(Col)`
  text-align: right;
`;

export { StyledButton, StyledButtonWrap, StyledFilterButton, StyledForm };