/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Layout, Result, Button } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const UnauthorizedComponent = () => {
  const history = useHistory();
  const handleBackHomeButtonClick = () => {
    history.push("/");
  };

  return (
    <>
      <Content>
        <StyledComponentWrapper>
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" onClick={handleBackHomeButtonClick}>
                Back Home
              </Button>
            }
          />
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default UnauthorizedComponent;
