import React, { useEffect, useState } from "react";
import { Form, Row, Col, message, Modal, Spin, Popover, Timeline, Tooltip } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined, HistoryOutlined, WarningOutlined } from "@ant-design/icons";
import CasePrescribedMedicines from "./CasePrescribedMedicines";
import CaseRequestedMedicines from "./CaseRequestedMedicines";
import CaseRxChangeMedicines from "./CaseRxChangeMedicines";
import PharmacyDetails from "./PharmacyDetails";
import { CaseVisitNotes } from "./CaseVisitNotes";
import moment from "moment";
import { StyledText, StyledTitle } from "../../components/LayoutComponent";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import styled from "styled-components";
import { lookUpDetails } from "../../api/LookUpAPI";
import CaseRxRenewalMedicines from "./CaseRxRenewalMedicines";
import CaseTagsComponent from "../../components/CaseTagsComponent";
import HTMLReactParser from 'html-react-parser';
import { getCaseDetails, cancelCase, restoreCancelledCase, getRxRequestNotes, setPrescriptionStatus } from "../../api/CasesAPI";
import { CaseStatus as CaseStatusEnum } from '../../constants/CaseStatus'
import { CaseStatusColorCodeEnum, PrescriptionStatusEnum} from '../../constants/enums'
const { confirm } = Modal;


const StyledClientLogo = styled.img`
    height: 40px;
`;

const StyledReasonNote = styled.section`
  height: 200px;
  padding: 15px;
  margin: -10px 0px 30px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
`

const StyledButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    opacity: .7;
  }
`

const StatusHistoryIcon = styled(HistoryOutlined)`
  margin: 0 5px;
  & :hover {
    cursor: pointer;
  }
`

const StyledTimeline = styled(Timeline)`
  width: 400px;
  padding-top: 10px !important;
  & .ant-timeline-item-tail {
    border-left: 2px solid rgba(0,0,0,.15) ;
  }

  & .ant-timeline-item:last-of-type {
    padding-bottom: 0;
  }
`

const StatusHistoryPopover = props => {
  let { statusHistory } = props
  return (
    <StyledTimeline mode='left'>
      { statusHistory?.length && statusHistory.map(item => (
        <Timeline.Item
          color={CaseStatusColorCodeEnum[item.status?.toLowerCase()] || '#000'}
          style={{ textTransform: 'capitalize'}}
          label={moment(item.createdAt).format('MMM Do YYYY h:mma')}>
            <p>
              <span style={{ fontWeight: 'bold'}}>{item.status}</span>
              <br />
              { item.physicianName && (
                <span style={{ fontSize: '.8rem'}}>Assigned to: {item.physicianName}</span>
              )}
              { item.reviewedBy && (
                <>
                  <br />
                  <span style={{ fontSize: '.8rem'}}>Reviewed by: {item.reviewedBy}</span>
                </> 
              )}
            </p>
        </Timeline.Item>
      ))}
    </StyledTimeline>
  )
}

const CaseInformation = ({ caseId, caseDetails }) => {
    const [clientLogo, setClientLogo] = useState();
    const [_case, setCase] = useState(caseDetails)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setCase(caseDetails)
    }, [caseDetails])

    const fetchCaseDetails = () => {
      setLoading(true);
      getCaseDetails(caseId)
        .then(async (res) => {
          if (res.data && res.data.payload) {
            const caseData = res.data.payload[0];
            const rxRequestNotes = (await getRxRequestNotes(caseId)).data.payload;
            const rxRequestNotesMap = new Map(rxRequestNotes.map(note => [`${note.rxRequest.requestType}+${note.rxRequest.createdAt}`, note]));

            caseData.requests?.map(request => {
              request.note = rxRequestNotesMap.get(`${request.requestType}+${request.createdAt}`) || null;
            });

            setCase(caseData);
            message.success("Successfully loaded Case Details");
          } else {
            message.error("Unable to load Case details. Please try again!");
          }
          setLoading(false);
        })
        .catch(() => {
          message.error("Unable to load Case details. Please try again!");
          setLoading(false);
        });
    };

    useEffect(() => {
        if (_case.clientId) {
            lookUpDetails("client", _case.clientId)
                .then((res) => {
                    if (res.data && res.data.payload) {
                        const { logoImage } = res.data.payload;
                        setClientLogo(logoImage);
                    }
                });
        }
    }, [_case]);

    const handleCancelOrRestore = async () => {
      if (_case.status !== CaseStatusEnum.CANCELLED) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: 'Are you sure you wish to cancel this case?',
          async onOk() {
            try {
              const res = await cancelCase(_case.caseId)
              const { data } = res
              if (!data.error) {
                  message.success(data.message)
                  fetchCaseDetails()
              } else {
                message.error("Unable to cancel case")
              }
            } catch (e) {
                message.error("Unable to cancel case"); 
                console.log(e);
            }
          }
        })
      } else {
        try {
          const res = await restoreCancelledCase(_case.caseId)
          const { data } = res
          if (!data.error) {
              message.success(data.message)
              fetchCaseDetails()
          } else {
            message.error("Unable to restore cancelled case")
          }
        } catch (e) {
            message.error(e.response.data.message); 
            console.log(e);
        }
      }
    }

  return (
    <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
      <Form
        name="case-details-consultation"
        className="case-detailed-view-part"
      >
        <Row className="case-patient-details">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Case Id">
              <StyledText strong>
                {_case && _case.caseId ? _case.caseId : ""}
              </StyledText>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Wheel Consult Id">
              <StyledText strong>
                {_case && _case.wheel_consultId
                  ? _case.wheel_consultId
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Telemedicine Category">
              <StyledText strong>
                {_case && _case.categoryName
                  ? _case.categoryName.toUpperCase()
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item label="Physician">
              <StyledText strong>
                {_case.physicianName
                  ? _case.physicianName.toUpperCase()
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item label="Created Date">
              <StyledText strong>
                {_case.createdDate
                  ? dateTimeFormatter(_case.createdDate)
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Consultation Type">
              <StyledText strong>
                {_case && _case.consultationType
                  ? _case.consultationType.toUpperCase()
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
          {_case.consultations && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={
                  _case.status === "pending audio consult" ||
                  _case.status === "pending video consult" ||
                  _case.status === "waiting room"
                    ? "Scheduled Consultation"
                    : "Consulted At"
                }
              >
                <StyledText strong>
                  {_case.consultations.startTime &&
                  _case.consultations.endTime
                    ? `${moment(_case.consultations.startTime).format(
                        "LLLL"
                      )} - ${moment(_case.consultations.endTime).format(
                        "LT"
                      )}`
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Case Status">
              <StyledText strong>
                {_case && _case.statusDisplayName
                  ? _case.statusDisplayName.toUpperCase()
                  : ""}
              </StyledText>
              <Popover content={<StatusHistoryPopover statusHistory={_case.statusHistory}/>} title={<div style={{ textAlign: 'center', paddingBottom: '5px', borderBottom: '1px solid rgba(0,0,0,.2)'}}>Case Status History</div>}>
                <StatusHistoryIcon/>
              </Popover>
              
              { _case.prescriptionStatus === PrescriptionStatusEnum.ERRORS && (
                  <Tooltip placement="topLeft" title="Prescription error. See physician notes.">
                    <WarningOutlined 
                      onClick={() => confirm({ 
                        title: 'Reset Prescription Error', 
                        content: 'Click OK to reset the prescription error and allow the provider to retry creating a prescription.', 
                        onOk: async () => {
                          try {
                            const result = await setPrescriptionStatus(_case.caseId, PrescriptionStatusEnum.NOT_PRESCRIBED)
                            message.success(result.message);

                            fetchCaseDetails();
                          }
                          catch (err) {
                            message.error(err.message)
                          }
                        } 
                      })} 
                      style={{ fontSize: "20px", color: "red" }} 
                    />
                  </Tooltip>
              )}
              
              <StyledButton onClick={handleCancelOrRestore}>
                  {_case.status === CaseStatusEnum.CANCELLED && 'restore'}
                  {![CaseStatusEnum.CANCELLED, CaseStatusEnum.CONSULT_COMPLETE, CaseStatusEnum.CONSULT_COMPLETE_NO_RX].includes(_case.status) && 'cancel' }
              </StyledButton>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="State">
              <StyledText strong>
                {_case?.state}
              </StyledText>
            </Form.Item>
          </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Brand">
                    {clientLogo ? (
                        <StyledClientLogo
                            src={clientLogo}
                            alt="Brand Image"
                        />
                    ) : (
                        <StyledText strong>
                            Brand Logo Unavailable
                        </StyledText>
                    )}
                </Form.Item>
            </Col>
          <Col span={24}>
            <Form.Item label="Reason Note">
              <StyledReasonNote>
                {_case?.status === "rx change" 
                  ? HTMLReactParser(_case.rxChangeRequest?.rxChangeReason || 'No reason note available')
                  : HTMLReactParser(_case?.reason || 'No reason note available')
                }
              </StyledReasonNote>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Tags">
              <CaseTagsComponent
                currentTags={_case.tags || []}
                caseId={_case.caseId}
              />
            </Form.Item>
          </Col>
        </Row>

        <CaseVisitNotes caseDetails={caseDetails} />

        <Row className="case-details-row-wrapper">
          <Col span={24}>
            <StyledTitle level={4}>Medical Information</StyledTitle>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Blood Pressure">
              <StyledText strong>
                {_case && _case.bloodPressure
                  ? _case.bloodPressure
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Medications">
              <StyledText strong>
                {_case && _case.medications
                  ? _case.medications
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Allergies">
              <StyledText strong>
                {_case && _case.allergies
                  ? _case.allergies
                  : ""}
              </StyledText>
            </Form.Item>
          </Col>
        </Row>

        <hr></hr>

        <CaseRequestedMedicines caseDetails={_case} fetchCaseDetails={fetchCaseDetails} />
        <hr></hr>

        <PharmacyDetails
          caseDetails={_case}
          title={"Requested Pharmacy"}
          contentSize={"small"}
          copyDetails={false}
          splitFormDetails={true}
          topMarginRequired={true}
        />
        <hr></hr>

        <CasePrescribedMedicines caseDetails={_case} />
        <hr></hr>

        <CaseRxChangeMedicines caseDetails={_case} />
        <hr></hr>

        <CaseRxRenewalMedicines caseDetails={_case} />
      </Form>
    </Spin>
  );
};

export default CaseInformation;
