import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, List, PageHeader } from 'antd';
import { StyledListItem, StyledListItemTitleContainer, StyledListItemTitle, StyledListItemDescriptionContainer, StyledBadge, StyledAppointmentsCount } from './styled';
import moment from 'moment-timezone';
import { pluralize } from './pluralize';

export const ConsultationsList = ({ physicians }) => {
  const [selectedPhysician, setSelectedPhysician] = useState(null);

  return (
    <>
      {!!selectedPhysician && <PageHeader subTitle={`${selectedPhysician.firstName} ${selectedPhysician.lastName}`} onBack={() => setSelectedPhysician(null)} />}
      <List>
        {!!selectedPhysician
          ? selectedPhysician.consultations.map((consultation, indx) => (
            <StyledListItem underline={indx < selectedPhysician.consultations.length - 1}>
              <Link to={`/cases/${consultation.case._id}`} style={{ width: '100%' }}>
                <List.Item.Meta
                  title={
                    <StyledListItemTitleContainer>
                      <StyledListItemTitle>{consultation.patient.firstName} {consultation.patient.lastName}</StyledListItemTitle>
                      <Tooltip
                        title={
                          <span style={{ color: 'white' }}>
                            {moment(consultation.startTime).tz(consultation.physician.availability?.preferredIANATimeZoneName || moment.tz.guess()).format('MM-DD-YYYY hh:mmA (z)')}
                          </span>
                        }
                        style={{ pointerEvents: 'none' }}
                      >
                        <StyledAppointmentsCount>{moment(consultation.startTime).tz(moment.tz.guess()).format('hh:mmA (z)').toString()}</StyledAppointmentsCount>
                      </Tooltip>
                    </StyledListItemTitleContainer>
                  }
                  description={consultation.case?.client.name}
                />
              </Link>
            </StyledListItem>
          ))
          : physicians.map((physician, indx) => (
            <StyledListItem underline={indx < physicians.length - 1} onClick={() => setSelectedPhysician(physician)}>
              <List.Item.Meta
                title={
                  <StyledListItemTitleContainer>
                    <StyledListItemTitle>{physician.firstName} {physician.lastName}</StyledListItemTitle>
                    <StyledAppointmentsCount>{physician.consultations.length} {pluralize('appt', physician.consultations.length)}</StyledAppointmentsCount>
                  </StyledListItemTitleContainer>
                }
                description={
                  <StyledListItemDescriptionContainer>
                    {physician.consultations.map(consultation => (
                      <Tooltip
                        title={
                          <span style={{ color: 'white' }}>
                            {moment(consultation.startTime).tz(consultation.physician.availability?.preferredIANATimeZoneName || moment.tz.guess()).format('MM-DD-YYYY hh:mmA (z)')}
                          </span>
                        }
                      >
                        <StyledBadge count={moment(consultation.startTime).tz(moment.tz.guess()).format('hh:mmA (z)').toString()} style={{ pointerEvents: 'none' }} />
                      </Tooltip>
                    ))}
                  </StyledListItemDescriptionContainer>
                }
              />
            </StyledListItem>
          ))}
      </List>
    </>
  )
}