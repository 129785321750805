import axiosInstance from "./AxiosInstance";

export const getCaseTags = () => {
  return axiosInstance.get(`/case-tags`);
};

export const addCaseTag = (caseId, tag) => {
  return axiosInstance.post(`/case-tags`, { caseId, tag });
};

export const removeCaseTag = (caseId, tag) => {
  return axiosInstance.delete(`/case-tags`, { data: { caseId, tag }});
};