import { Layout,Typography } from "antd";
import styled from "styled-components";

const { Text, Title } = Typography;

const StyledText = styled(Text)`
  color: #7eae80;
`;
const StyledErrorText=styled(Text)`
color:red;
font-weight:bold;
`;

const StyledTitle = styled(Title)`
  color: #7eae80 !important;
  margin-bottom:20px !important;
`;

const StyledOuterLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledInnerLayout = styled(Layout)`
  height: calc(100vh - 73px);
  overflow-y: auto;
`;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const StyledHeaderWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;
export { StyledInnerLayout, StyledOuterLayout, StyledComponentWrapper, StyledHeaderWrapper,StyledText,StyledTitle,StyledErrorText };