/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from "react";
import { Typography, Button, Form, Row, Col, InputNumber, Modal, Select } from "antd";
import styled from "styled-components";

const StyledModal = styled(Modal)`
    & .ant-modal-footer {
        padding: 0
    }
`

const StyledForm = styled(Form)`
    text-tranform: captialize;
    & * {
      text-transform: capitalize;
    }
`

const StyledFormItemWrapper = styled(Form.Item)`
  text-align: center;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const { Title } = Typography;

const AddQueueConfiguration = ({
    onConfigurationAdd,
    unconfiguredTimePeriods,
    viewAddConfigurationModal,
    setViewAddConfigurationModal
}) => {
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line
      number: "${label} is not a validate number!",
    },
  };

  useEffect(() => {
    form.setFieldsValue({
      Name: 'period',
      Id: 'period',
      Value: unconfiguredTimePeriods[0]
    })
  }, [unconfiguredTimePeriods])

  const handleConfigurationChange = ({ physicianGroupDetails, period, quantity }) => {
    if (onConfigurationAdd && typeof onConfigurationAdd === "function") {
      onConfigurationAdd({ physicianGroupDetails, period, quantity });
    }
    return form.resetFields();
  };

  const handleCancel = () => {
    setViewAddConfigurationModal(false)
    form.resetFields();
  }

  return (
    <StyledModal
      destroyOnClose
      width={250}
      visible={viewAddConfigurationModal}
      onCancel={handleCancel}
      footer={[]}
    >
      <StyledForm
        {...layout}
        form={form}
        layout={"vertical"}
        name="add-configuration"
        onFinish={handleConfigurationChange}
        validateMessages={validateMessages}
      >
        <Row>
          <Title level={4} style={{ width: '100%', textAlign: 'center' }}>Add Configuration</Title>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="period"
              id="period"
              label="Period"
              rules={[{ required: true }]}
            >
              <Select size={"large"}>
                {unconfiguredTimePeriods?.map(period => (
                  <Select.Option
                    style={{ textTransform: 'capitalize' }}
                    value={period}>
                    {period}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="quantity"
              label="Max cases per period"
              rules={[{ required: true, type: "number" }]}
            >
              <StyledInputNumber size={"large"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledFormItemWrapper wrapperCol={{ span: 24 }}>
              <Button type="primary" size="large" htmlType="submit">Add Configuration</Button>
            </StyledFormItemWrapper>
          </Col>
        </Row>
      </StyledForm>
    </StyledModal>

  );
};

export default AddQueueConfiguration;

