import React from "react";
import { Table, Typography } from "antd";
const { Title } = Typography;

const ViewPhysicianConfiguration = ({ singleConfigurationData }) => {
  const columns = [
    {
      title: "Physician Group",
      dataIndex: "physicianGroupName",
      key: "physician-group",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Turn Around Time SLA",
      dataIndex: "turnAroundTime",
      key: "turn-around-time-sla",
    },
    {
      title: "Internal Turn Around Time SLA",
      dataIndex: "internalTurnAroundTime",
      key: "internal-turn-around-time-sla",
    },
  ];
  return (
    <>
      <Title level={4}>Configuration List</Title>
      <Table
        pagination={false}
        dataSource={singleConfigurationData}
        columns={columns}
        width="max-content"
        rowKey="index"
        scroll={{ x: 720 }}
      />
    </>
  );
};

export default ViewPhysicianConfiguration;
