//import packages
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { Form, Row, Col, Typography, Spin, message, Table, Button, Tooltip } from "antd";
import { LoadingOutlined, SelectOutlined, FileSearchOutlined, WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CaseTagsComponent from '../../../components/CaseTagsComponent'
import { lookUpDetails } from "../../../api/LookUpAPI";
import HTMLReactParser from 'html-react-parser';

//import API functions
import { getCaseDetails } from "../../../api/CasesAPI";
import {
  dateOfBirthFormatter,
  dateTimeFormatter,
} from "../../../utils/dateFormatter";
import {
  StyledDivider,
  StyledCol,
  StyledSubTitle,
  StyledText,
  StyledModal,
} from "../../../components/DetailViewComponent";
import checkValidJson from "../../../utils/checkValidJson";
import { PrescriptionStatusEnum } from "../../../constants/enums";

const StyledClientLogo = styled.img`
    height: 40px;
`;

const StyledReasonNote = styled.section`
  height: 200px;
  padding: 15px;
  margin: -10px 0px 30px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
`

const { Text, Title } = Typography;

const prescribedMedicinesColumn = [
  {
    title: "Drug Name",
    dataIndex: "drugName",
    key: "drugName",
    width: 400,
  },
  {
    title: "NDC",
    dataIndex: "ndc",
    key: "ndc",
    width: 200,
  },
  {
    title: "Dosage",
    dataIndex: "dosage",
    key: "dosage",
    width: 200,
  },
  {
    title: "Dispense",
    dataIndex: "dispense",
    key: "dispense",
    width: 200,
  },
  {
    title: "Days Supply",
    dataIndex: "daysSupply",
    key: "daysSupply",
    width: 150,
  },
  {
    title: "Refills",
    dataIndex: "refills",
    key: "refills",
    width: 150,
  },
  {
    title: "Quantity",
    dataIndex: "pillCount",
    key: "pillCount",
    width: 150,
  },
];

const ConsolidatedCaseDetails = ({
  caseId,
  viewModal,
  closeModal,
  setBulkAction,
  setSelectedCases,
}) => {
  const [caseDetails, setCaseDetails] = useState({});
  const [clientLogo, setClientLogo] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fetchCaseDetails = useCallback(() => {
    if (!caseId) {
      return;
    }
    setLoading(true);
    getCaseDetails(caseId)
      .then((res) => {
        if (!res.data?.payload) {
          throw Error();
        }
        setCaseDetails(res.data.payload[0]);
      })
      .catch((error) => {
        console.log("Error fetching case details: ", error);
        message.error("Unable to load Case details. Please try again!");
      })
      .finally(() => setLoading(false));
  }, [caseId]);

  useEffect(() => {
    fetchCaseDetails();
  }, [fetchCaseDetails]);

  useEffect(() => {
    if (caseDetails.clientId) {
        lookUpDetails("client", caseDetails.clientId)
            .then((res) => {
                if (res.data?.payload) {
                    const { logoImage } = res.data.payload;
                    setClientLogo(logoImage);
                }
            });
    }
  }, [caseDetails])

  const handleModalClose = () => {
    setCaseDetails({});
    closeModal(false);
  };

  const mapGenderKeyToGender = (genderKey) => {
    switch (genderKey) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "T":
        return "Trans";
      default:
        return "";
    }
  };

  const latestRxRenewalRequest = caseDetails?.rxRenewalRequests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

  return (
    <StyledModal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title level={3}>Case Details</Title> <Button style={{ marginRight: 40 }} onClick={() => history.push(`/cases/${caseId}`)} type='primary' icon={<FileSearchOutlined />}>Detailed View</Button>
        </div>
      }
      open={viewModal}
      footer={null}
      onCancel={handleModalClose}
      width={780}
      style={{ padding: "10 20 10 20" }}
      destroyOnClose
    >
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Form name="physician_details">
          <Row gutter={24}>
            <StyledCol span={24}>
              <StyledSubTitle level={4}>
                Case Information
                 { caseDetails.prescriptionStatus === PrescriptionStatusEnum.ERRORS && (
                 <Tooltip placement="topLeft" title="Prescription error. See physician notes.">
                    <WarningOutlined style={{ fontSize: "20px", color: "red", marginLeft: '5px' }} />
                  </Tooltip>
                )}
              </StyledSubTitle>
              <StyledDivider />
            </StyledCol>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label="Case Id">
                <StyledText>
                  {caseDetails.caseId ? caseDetails.caseId : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item label="Telemedicine Category">
                {caseDetails.categoryName ? (
                  <StyledText key={caseDetails.category}>
                    {caseDetails.categoryName}
                  </StyledText>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Physician">
                <StyledText>
                  {caseDetails.physicianName
                    ? caseDetails.physicianName.toUpperCase()
                    : ""}
                </StyledText>
                <Button
                  shape="circle"
                  icon={<SelectOutlined />}
                  style={{ marginLeft: 10 }}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedCases([caseDetails])
                    setBulkAction('reassign')
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Created Date">
                <StyledText>
                  {caseDetails.createdDate
                    ? dateTimeFormatter(caseDetails.createdDate)
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Status">
                <StyledText>
                  {caseDetails && caseDetails.statusDisplayName
                    ? caseDetails.statusDisplayName.toUpperCase()
                    : "Not Status Available"}
                </StyledText>
                {/* restrict to user role */}
                <Button
                  shape="circle"
                  icon={<SelectOutlined />}
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setBulkAction('status')
                    setSelectedCases([caseDetails])
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Consult Type">
                <StyledText>
                  {caseDetails.consultationType
                    ? caseDetails.consultationType.toUpperCase()
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="State">
                <StyledText>
                  {caseDetails?.state}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Brand">
                    {clientLogo ? (
                        <StyledClientLogo
                            src={clientLogo}
                            alt="Brand"
                        />
                    ) : (
                        <StyledText strong>
                            Brand Logo Unavailable
                        </StyledText>
                    )}

                </Form.Item>
            </Col>
          <Col span={24}>
            <Form.Item label="Reason Note">
              <StyledReasonNote>
                {caseDetails?.status === "rx change"
                  ? HTMLReactParser(caseDetails.rxChangeRequest?.rxChangeReason || 'No reason note available')
                  : HTMLReactParser(caseDetails?.reason || 'No reason note available')
                }
              </StyledReasonNote>
            </Form.Item>
          </Col>
             <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Tags">
              <CaseTagsComponent
                currentTags={caseDetails.tags || []}
                caseId={caseDetails.caseId}
              />
            </Form.Item>
          </Col>
            <StyledCol span={24}>
              <StyledSubTitle level={4}>Patient Information</StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            <Col span={24}>
              <Form.Item label="Patient Id">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient._id
                    ? caseDetails.patient._id
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="First Name">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.firstName
                    ? caseDetails.patient.firstName.toUpperCase()
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Last Name">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.lastName
                    ? caseDetails.patient.lastName.toUpperCase()
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Form.Item label="Age">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.age
                    ? caseDetails.patient.age
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Form.Item label="DOB">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.dob
                    ? dateOfBirthFormatter(caseDetails.patient.dob)
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Form.Item label="Gender">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.gender
                    ? mapGenderKeyToGender(
                      caseDetails.patient.gender.toUpperCase()
                    )
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Email">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.email
                    ? caseDetails.patient.email
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Address Line 1">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.address1
                    ? caseDetails.patient.address1
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Address Line 2 ">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.address2
                    ? caseDetails.patient.address2
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="City">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.city
                    ? caseDetails.patient.city
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="State">
                <StyledText>
                  {caseDetails.state ? caseDetails.state : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Zip Code">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.zipCode
                    ? caseDetails.patient.zipCode
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Form.Item label="Phone">
                <StyledText>
                  {caseDetails.patient && caseDetails.patient.phone
                    ? caseDetails.patient.phone
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <StyledCol span={24}>
              <StyledSubTitle level={4}>Requested Medicine</StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            {caseDetails.requestedMedicine &&
              Object.keys(caseDetails.requestedMedicine).length ? (
              <>
                <Col span={24}>
                  <Form.Item label="Drug Name">
                    <StyledText>
                      {caseDetails.requestedMedicine.drugName
                        ? caseDetails.requestedMedicine.drugName
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Dosage">
                    <StyledText>
                      {caseDetails.requestedMedicine.dosage
                        ? caseDetails.requestedMedicine.dosage
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Dispense">
                    <StyledText>
                      {caseDetails.requestedMedicine.dispense
                        ? caseDetails.requestedMedicine.dispense
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Refills">
                    <StyledText>
                      {caseDetails.requestedMedicine.refills
                        ? caseDetails.requestedMedicine.refills
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Days Supply">
                    <StyledText>
                      {caseDetails.requestedMedicine.daysSupply
                        ? caseDetails.requestedMedicine.daysSupply
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Quantity">
                    <StyledText>
                      {caseDetails.requestedMedicine.pillCount
                        ? caseDetails.requestedMedicine.pillCount
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="NDC">
                    <StyledText>
                      {caseDetails.requestedMedicine.ndc
                        ? caseDetails.requestedMedicine.ndc
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                {caseDetails.openEndedRequest &&
                  checkValidJson(caseDetails.openEndedRequest) ? (
                  Object.keys(caseDetails.openEndedRequest).map((itemKey) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label={itemKey}>
                        <StyledText>
                          {caseDetails.openEndedRequest[itemKey]}
                        </StyledText>
                      </Form.Item>
                    </Col>
                  ))
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Col span={24}>
                <Text>Requested medicines unavailable</Text>
              </Col>
            )}
            <StyledCol span={24}>
              <StyledSubTitle level={4}>Prescriped Medicine</StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            {!(
              caseDetails.prescribedMedicine &&
              caseDetails.prescribedMedicine.length !== 0
            ) &&
              !(
                caseDetails.errorMedicine &&
                caseDetails.errorMedicine.length !== 0
              ) && (
                <Col className="align-text-center" span={24}>
                  <Text>Prescribed medicines unavailable</Text>
                </Col>
              )}

            {caseDetails.prescribedMedicine &&
              caseDetails.prescribedMedicine.length !== 0 && (
                <Col key="prescribed-medicines-table" span={24}>
                  <Table
                    dataSource={caseDetails.prescribedMedicine}
                    columns={prescribedMedicinesColumn}
                    pagination={false}
                    scroll={{ x: 1450 }}
                    sticky
                  />
                </Col>
              )}
            <StyledCol span={24}>
              <StyledSubTitle level={4}>
                Medication Requested through Rx-Change
              </StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            {!!caseDetails?.rxChangeRequest && !!Object.keys(caseDetails.rxChangeRequest).length
              ? <>
                <Col span={24}>
                  <Form.Item label="Drug Name">
                    <StyledText>
                      {caseDetails.rxChangeRequest.drugName
                        ? caseDetails.rxChangeRequest.drugName
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Dosage">
                    <StyledText>
                      {caseDetails.rxChangeRequest.dosage
                        ? caseDetails.rxChangeRequest.dosage
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Dispense">
                    <StyledText>
                      {caseDetails.rxChangeRequest.dispense
                        ? caseDetails.rxChangeRequest.dispense
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Refills">
                    <StyledText>
                      {caseDetails.rxChangeRequest.refills
                        ? caseDetails.rxChangeRequest.refills
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Days Supply">
                    <StyledText>
                      {caseDetails.rxChangeRequest.daysSupply
                        ? caseDetails.rxChangeRequest.daysSupply
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Quantity">
                    <StyledText>
                      {caseDetails.rxChangeRequest.pillCount
                        ? caseDetails.rxChangeRequest.pillCount
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="NDC">
                    <StyledText>
                      {caseDetails.rxChangeRequest.ndc
                        ? caseDetails.rxChangeRequest.ndc
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reason Note">
                    <StyledReasonNote>
                      {HTMLReactParser(caseDetails.rxChangeRequest?.rxChangeReason || 'No reason note available')}
                    </StyledReasonNote>
                  </Form.Item>
                </Col>
              </>
            : (
              <Col span={24}>
                <Text>No Rx Change Request yet</Text>
              </Col>
            )}
            <StyledCol span={24}>
              <StyledSubTitle level={4}>
                Medication Requested through Rx-Renewal
              </StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            {latestRxRenewalRequest ? (
                <>
                    <Col span={24}>
                  <Form.Item label="Drug Name">
                    <StyledText>
                      {latestRxRenewalRequest.drugName
                        ? latestRxRenewalRequest.drugName
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Dosage">
                    <StyledText>
                      {latestRxRenewalRequest.dosage
                        ? latestRxRenewalRequest.dosage
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Dispense">
                    <StyledText>
                      {latestRxRenewalRequest.dispense
                        ? latestRxRenewalRequest.dispense
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Refills">
                    <StyledText>
                      {latestRxRenewalRequest.refills
                        ? latestRxRenewalRequest.refills
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Days Supply">
                    <StyledText>
                      {latestRxRenewalRequest.daysSupply
                        ? latestRxRenewalRequest.daysSupply
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Quantity">
                    <StyledText>
                      {latestRxRenewalRequest.pillCount
                        ? latestRxRenewalRequest.pillCount
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="NDC">
                    <StyledText>
                      {latestRxRenewalRequest.ndc
                        ? latestRxRenewalRequest.ndc
                        : ""}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reason Note">
                    <StyledReasonNote>
                      {HTMLReactParser(caseDetails.rxChangeRequest?.rxChangeReason || 'No reason note available')}
                    </StyledReasonNote>
                  </Form.Item>
                </Col>
                </>
            ) : (
              <Col span={24}>
                <Text>No Rx Renewal Request yet</Text>
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
    </StyledModal>
  );
};

export default ConsolidatedCaseDetails;
