import { Table, Typography, Tabs, Form, Input, Button, Select, DatePicker, Checkbox } from "antd";
import styled from "styled-components";

const { Search } = Input;

export const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

export const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1150px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

export const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

export const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > :nth-child(1) {
    padding-left: unset;
  }
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > td {
    cursor: pointer;
  }
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

export const ColourBorder = styled.div`
  border-left: solid 5px ${(props) => props.inputColor || "white"};
  padding-left: 5px;
`;

export const SearchFilter = styled(Search)`
  > .ant-input-suffix .ant-input-clear-icon {
    color: unset;
  }
`;

export const StyledSelect = styled(Select)`
  width: 200px;
  margin-bottom: 20px;
`;

//----------------------------------------------

export const StyledButton = styled(Button)`
  width: 80px;
  margin-left: 10px;
`;

export const StyledDatePicker = styled(DatePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
`

export const ClearAllContainer = styled(Form.Item)`
  margin-bottom: 0;
  height: 100%;

  & .ant-form-item-control {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: right;
  }
`
