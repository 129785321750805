/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  Select,
  Switch,
  Upload,
  InputNumber,
  Table,
} from "antd";
import { LoadingOutlined, PlusOutlined, DeleteFilled } from "@ant-design/icons";

//import other files and API function
import { lookUpDetails } from "../../../api/LookUpAPI";
import { updateClient } from "../../../api/ClientsAPI";

const { Option } = Select;
const { Text } = Typography;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const StyledPlusButtonColWrapper = styled(Col)`
  text-align: right;
`;

const StyledRowWrapper = styled(Row)`
  margin: 12px 0;
`;

const StyledFormItemWrapper = styled(Form.Item)`
  margin-bottom: unset;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const EditClient = (props) => {
  const {
    clientId,
    afterClientEdit,
    fetchClientsAfterUpdate,
    telemedicineCategory,
  } = props;
  const [form] = Form.useForm();
  const [generalNotificationCheck, setGeneralNotificationCheck] =
    useState(true);
  const [
    prescriptionRelatedNotificationCheck,
    setPrescriptionRelatedNotificationCheck,
  ] = useState(true);
  const [validatePhoneNumber, setValidatePhoneNumber] = useState(true);
  const [imageLoad, setImageLoad] = useState(false);
  const [imageUrl1, setImageUrl1] = useState("");
  const [fields, setClientDetails] = useState([
    {
      name: ["clientName"],
      value: "",
    },
    {
      name: ["address"],
      value: "",
    },
    {
      name: ["city"],
      value: "",
    },
    {
      name: ["state"],
      value: "",
    },
    {
      name: ["zip"],
      value: "",
    },
  ]);

  const [addedTeleMedicineCategory, setAddedTeleMedicineCategory] = useState(
    []
  );
  const [
    teleMedicineCategoryForSelection,
    setTeleMedicineCategoryForSelection,
  ] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [
    addedTelemedicineCategoryValidatedStatus,
    setAddedTelemedicineCategoryValidatedStatus,
  ] = useState(null);
  const [asyncConsultRate, setAsyncConsultRate] = useState(null);
  const [syncConsultRate, setSyncConsultRate] = useState(null);

  const columns = [
    {
      title: "Telemedicine Category",
      width: 100,
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left",
    },
    {
      title: "Async Consult Rate (in $)",
      width: 100,
      dataIndex: "async",
      key: "sync-consult-rate",
      align: "center",
    },
    {
      title: "Sync Consult Rate (in $)",
      dataIndex: "sync",
      key: "sync-consult-rate",
      width: 100,
      align: "center",
    },
    {
      title: "Remove",
      key: "operation-remove",
      width: 100,
      align: "center",
      render: (event, record, index) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            handleTeleMedicineCategoryDelete(index);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    lookUpDetails("client", clientId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const {
            name,
            address,
            enableGeneralNotification,
            enablePrescriptionNotification,
            logoImage,
            enableMobile,
            teleMedicineCategories,
            teleMedicineCategoryName,
            consultRates,
          } = res.data.payload;
          setGeneralNotificationCheck(enableGeneralNotification ? true : false);
          setPrescriptionRelatedNotificationCheck(
            enablePrescriptionNotification ? true : false
          );
          if (teleMedicineCategories && teleMedicineCategories.length) {
            let eachMappedCategory = {};
            const telemedicineCategoryList = teleMedicineCategories.map(
              (eachCategoryId) => {
                eachMappedCategory = {
                  id: null,
                  categoryName: "",
                  async: "",
                  sync: "",
                };
                eachMappedCategory.id = eachCategoryId;
                if (
                  teleMedicineCategoryName &&
                  Object.keys(teleMedicineCategoryName).length
                ) {
                  eachMappedCategory.categoryName = teleMedicineCategoryName[
                    eachCategoryId
                  ]
                    ? teleMedicineCategoryName[eachCategoryId]
                    : "";
                }
                if (consultRates && Object.keys(consultRates).length) {
                  if (consultRates[eachCategoryId]) {
                    eachMappedCategory.async = consultRates[eachCategoryId]
                      .async
                      ? consultRates[eachCategoryId].async
                      : "";
                    eachMappedCategory.sync = consultRates[eachCategoryId].sync
                      ? consultRates[eachCategoryId].sync
                      : "";
                  }
                }
                return { ...eachMappedCategory };
              }
            );
            setAddedTeleMedicineCategory([...telemedicineCategoryList]);
          }
          setValidatePhoneNumber(enableMobile);
          setImageUrl1(logoImage);
          if (address) {
            const separateAddressValues = address.split(",");
            const [exactAddress, city, state, zip] = separateAddressValues;
            setClientDetails([
              { name: ["clientName"], value: name || "" },
              { name: ["clientLogo"], value: logoImage || "" },
              {
                name: ["address"],
                value: exactAddress ? exactAddress.trim() : "",
              },
              {
                name: ["city"],
                value: city ? city.trim() : "",
              },
              {
                name: ["state"],
                value: state ? state.trim() : "",
              },
              {
                name: ["zip"],
                value: zip ? zip.trim() : "",
              },
            ]);
          } else {
            setClientDetails([
              { name: ["clientName"], value: name || "" },
              { name: ["clientLogo"], value: logoImage || "" },
              {
                name: ["address"],
                value: "",
              },
              {
                name: ["city"],
                value: "",
              },
              {
                name: ["state"],
                value: "",
              },
              {
                name: ["zip"],
                value: "",
              },
            ]);
          }
          message.success(
            "Successfully loaded Client details into Edit Client Form"
          );
        }
      })
      .catch(() => {
        message.error("Unable to fetch client details for Editing");
      });
  }, [clientId]);
  const onFinish = (values) => {
    const { clientName, address, city, state, zip, clientLogo } = values;
    if (!addedTeleMedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
      return false;
    } else {
      setAddedTelemedicineCategoryValidatedStatus("");
    }
    const clientDetails = {
      name: clientName,
      enableGeneralNotification: generalNotificationCheck,
      enablePrescriptionNotification: prescriptionRelatedNotificationCheck,
      teleMedicineCategories: [...addedTeleMedicineCategory],
      enableMobile: validatePhoneNumber,
    };
    if (clientLogo && clientLogo.file && clientLogo.file.originFileObj) {
      clientDetails.logo = clientLogo.file.originFileObj;
    }

    if (address || city || state || zip) {
      clientDetails.address = "";
      if (address) {
        clientDetails.address = `${address}`;
      }
      if (city) {
        clientDetails.address = clientDetails.address + `, ${city}`;
      }
      if (state) {
        clientDetails.address = clientDetails.address + `, ${state}`;
      }
      if (zip) {
        clientDetails.address = clientDetails.address + `, ${zip}`;
      }
    } else {
      clientDetails.address = "";
    }
    updateClient(clientId, clientDetails)
      .then((res) => {
        message.success("Successfully updated client");
        afterClientEdit("");
        fetchClientsAfterUpdate();
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to Edit Client. Please try again!");
          }
        } else {
          message.error("Unable to Edit Client. Please try again!");
        }
      });
  };

  const handleNotificationChange = (checkedValue, type) => {
    if (type === "general") {
      setGeneralNotificationCheck(checkedValue);
    }
    if (type === "prescription") {
      setPrescriptionRelatedNotificationCheck(checkedValue);
    }
  };

  const handleValidationChange = (checkedValue) => {
    setValidatePhoneNumber(checkedValue);
  };

  const onCancelingEdit = () => {
    setClientDetails([
      {
        name: ["clientName"],
        value: "",
      },
      {
        name: ["teleMedicineCategories"],
        value: [],
      },
      {
        name: ["address"],
        value: "",
      },
      {
        name: ["city"],
        value: "",
      },
      {
        name: ["state"],
        value: "",
      },
      {
        name: ["zip"],
        value: "",
      },
    ]);
    afterClientEdit("");
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/SVG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImageLoad(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl1(imageUrl),
        setImageLoad(false)
      );
    }
  };

  const uploadButton = (
    <div>
      {imageLoad ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleConsultRate = (value, type) => {
    if (type === "async") {
      setAsyncConsultRate(value);
    } else {
      setSyncConsultRate(value);
    }
  };

  const handleTelemedicineAndConsultRateAddition = () => {
    const telemedicineCategory = {
      id: selectedCategory.value,
      categoryName: selectedCategory.label,
      async: asyncConsultRate,
      sync: syncConsultRate,
    };
    const clonnedSelectedTelemedicineCategory =
      addedTeleMedicineCategory.slice(0);
    clonnedSelectedTelemedicineCategory.push(telemedicineCategory);
    setAddedTeleMedicineCategory(clonnedSelectedTelemedicineCategory);
    setSelectedCategory({});
    setAsyncConsultRate(null);
    setSyncConsultRate(null);
    if (addedTelemedicineCategoryValidatedStatus === "error") {
      setAddedTelemedicineCategoryValidatedStatus("sucess");
    }
  };

  const handleTeleMedicineCategoryDelete = (itemIndex) => {
    const clonnedTelemedicineCategory = addedTeleMedicineCategory.slice(0);
    clonnedTelemedicineCategory.splice(itemIndex, 1);
    if (!clonnedTelemedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
    }
    setAddedTeleMedicineCategory(clonnedTelemedicineCategory);
  };

  const onTeleMedicineCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const updateTeleMedicineCategoryForSelection = () => {
    const checkAddedTeleMedicineCategory = (accumulator, currentCategory) => {
      const foundTelemedicineCategory = addedTeleMedicineCategory.find(
        (eachSelectedCategory) =>
          currentCategory.key === eachSelectedCategory.id
      );
      if (!foundTelemedicineCategory) {
        accumulator.push(currentCategory);
      }
      return accumulator;
    };
    const teleMedicineCategoryForSelection = telemedicineCategory.reduce(
      checkAddedTeleMedicineCategory,
      []
    );
    setTeleMedicineCategoryForSelection(teleMedicineCategoryForSelection);
  };

  useEffect(updateTeleMedicineCategoryForSelection, [
    telemedicineCategory,
    addedTeleMedicineCategory,
  ]);

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => {
        setClientDetails(allFields);
      }}
      validateMessages={validateMessages}
    >
      <Title level={2}>Edit Client</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="clientLogo"
            label="Client Logo"
            rules={[
              {
                required: true,
                message: "'client logo' is required",
              },
            ]}
          >
            <Upload
              name="logo"
              size="large"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl1 ? (
                <img src={imageUrl1} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="clientName"
            label="Client Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter Client Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true }]}
          >
            <Input size={"large"} placeholder="Enter Address" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item name="city" label="City" rules={[{ required: true }]}>
            <Input size={"large"} placeholder="Enter City" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item name="state" label="State" rules={[{ required: true }]}>
            <Input size={"large"} placeholder="Enter State" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item name="zip" label="Zip" rules={[{ required: true }]}>
            <Input size={"large"} placeholder="Enter Zip" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label="Validate Phone Number">
            <Switch
              checked={validatePhoneNumber}
              onChange={handleValidationChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item label="General Email and SMS Notification">
            <Switch
              checked={generalNotificationCheck}
              onChange={(value) => {
                handleNotificationChange(value, "general");
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item label="Prescription Related Notification">
            <Switch
              checked={prescriptionRelatedNotificationCheck}
              onChange={(value) => {
                handleNotificationChange(value, "prescription");
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <StyledFormItemWrapper
            shouldUpdate={() => addedTeleMedicineCategory.length}
            name="telemedicine-consult-rate-mapping"
            label="Add Telemedicine Category and Consult Rate"
            validateStatus={addedTelemedicineCategoryValidatedStatus}
            help={
              addedTelemedicineCategoryValidatedStatus === "error"
                ? "Please select atleast one Telemedicine Category, input Async and Sync consult rate, then add it using `+` button"
                : ""
            }
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (addedTeleMedicineCategory.length) {
                    return Promise.resolve();
                  }

                  setAddedTelemedicineCategoryValidatedStatus("error");
                  return Promise.reject(
                    "Please select atleast one Telemedicine Category, input Async and Sync consult rate, then add it using `+` button"
                  );
                },
              }),
            ]}
          >
            <StyledRowWrapper>
              <Col>
                <Text>
                  Please select a Telemedicine Category, after that input cost
                  of Sync and Async consult, then click on `+` button to add it
                  for the client. Use the delete button in the listing to remove
                  the already added specification.
                </Text>
              </Col>
            </StyledRowWrapper>
            <StyledRowWrapper gutter={[8, 8]} justify="space-between">
              <Col xs={24} sm={24} md={10} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Telemedicine Category">
                  <Select
                    placeholder="Select a Telemedicine Category"
                    showSearch
                    labelInValue
                    size="large"
                    value={selectedCategory}
                    onChange={onTeleMedicineCategoryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {teleMedicineCategoryForSelection.map((eachCategory) => (
                      <Option key={eachCategory._id} value={eachCategory._id}>
                        {eachCategory.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Enter Async Consult Rate (in $)">
                  <StyledInputNumber
                    size={"large"}
                    value={asyncConsultRate}
                    placeholder="Enter Async Rate"
                    onChange={(value) => {
                      handleConsultRate(value, "async");
                    }}
                  />
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Enter Sync Consult Rate (in $)">
                  <StyledInputNumber
                    size={"large"}
                    value={syncConsultRate}
                    placeholder="Enter Sync Rate"
                    onChange={(value) => {
                      handleConsultRate(value, "sync");
                    }}
                  />
                </StyledFormItemWrapper>
              </Col>
              <StyledPlusButtonColWrapper xs={24} sm={24} md={2} lg={2} xl={2}>
                <StyledFormItemWrapper label=" ">
                  <Button
                    type="primary"
                    disabled={
                      !(
                        Object.keys(selectedCategory).length &&
                        asyncConsultRate &&
                        syncConsultRate
                      )
                    }
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={handleTelemedicineAndConsultRateAddition}
                  />
                </StyledFormItemWrapper>
              </StyledPlusButtonColWrapper>
            </StyledRowWrapper>
            <StyledRowWrapper>
              {addedTeleMedicineCategory.length ? (
                <>
                  <Col span={24}>
                    <Text strong>Added Telemedicine Category</Text>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={addedTeleMedicineCategory}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
            </StyledRowWrapper>
          </StyledFormItemWrapper>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="secondary"
              size={"large"}
              onClick={onCancelingEdit}
            >
              Cancel
            </StyledButton>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Save
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default EditClient;
