/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import tinymce from "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/preview";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/lists";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/anchor";
import "tinymce/plugins/charmap";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/legacyoutput";
import "tinymce/plugins/advlist";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/charmap";
import "tinymce/plugins/directionality";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import config from "../../config";

import { Layout, Input, Form, Select, Radio, Button, Row, Col } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { uploadMedia } from "../../api/LmsAPI";
import { EditorContainer } from "./styled";

const { Content } = Layout;
const { TextArea } = Input;

const StyledArticleCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledRow = styled(Row)``;

const StyledFormItemButton = styled(Form.Item)`
  margin: 20px 0px;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
  .ant-btn {
    margin-left: 20px;
  }
`;

const EditorComponent = ({
  setShowEditor,
  operationStatus,
  telemedicineCategory,
  createArticleData,
  editArtcileData,
  editorData,
}) => {
  const [form] = Form.useForm();
  const [articleCategory, setArticleCategory] = useState("training");
  const [editorMode, setEditorMode] = useState(1);
  const {apiKeys}=config;
  const editorRef = useRef(null);

  useEffect(() => {
    if (operationStatus === "edit") {
      form.setFieldsValue(editorData);
      setArticleCategory(editorData.articleCategory);
    }
  }, [operationStatus, editorData]);

  const onArticleAdd = (value) => {
    let editorHtml;
    if (editorRef.current) {
      editorHtml = editorRef.current.getContent().toString();
    }

    const requestData = {
      title: value.title,
      description: value.description,
      articleCategory: articleCategory,
      editorHtmlData: editorHtml,
    };

    if (articleCategory === "clinical protocol") {
      requestData["teleMedicineCategory"] = value.teleMedicineCategory;
    }

    if (operationStatus === "edit") {
      editArtcileData(requestData);
    } else if (operationStatus === "create") {
      createArticleData(requestData);
    }
  };

  const onArticleCategoryChange = (e) => {
    setArticleCategory(e.target.value);
  };

  return (
    <Content>
      <Form
        form={form}
        name="editor"
        layout={"vertical"}
        onFinish={onArticleAdd}
      >
        <StyledRow>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Title"></Input>
            </Form.Item>
          </Col>
          <StyledArticleCol span={12}>
            <Form.Item name="articleCategory">
              <Radio.Group
                onChange={onArticleCategoryChange}
                defaultValue={articleCategory}
              >
                <Radio value="training">Training</Radio>
                <Radio value="clinical protocol">Clinical Protocol</Radio>
              </Radio.Group>
            </Form.Item>
          </StyledArticleCol>

          {articleCategory === "clinical protocol" ? (
            <Col span={12}>
              <Form.Item
                name="teleMedicineCategory"
                label="Telemedicine Category"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select category"
                  options={telemedicineCategory && telemedicineCategory}
                ></Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          <Col span={24}>
            <Form.Item
              name="description"
              label="Short Description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea placeholder="description" />
            </Form.Item>
          </Col>
        </StyledRow>
        <StyledRow style={{ marginBottom: 20 }}>
          <Radio.Group value={editorMode.toString()} onChange={(e) => setEditorMode(parseInt(e.target.value))}>
            <Radio.Button value='1'>Normal</Radio.Button>
            <Radio.Button value='2'>Inline</Radio.Button>
          </Radio.Group>
        </StyledRow>

        <EditorContainer inline={editorMode > 1}>
          <div id='toolbar-anchor' />
          <Editor
            key={editorMode}
            apiKey={apiKeys.tinyEditorKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={editorData?.editorHtmlData || ''}
            init={{
              inline: editorMode === 2,
              fixed_toolbar_container: '#toolbar-anchor',
              menubar: "insert tools format table view",
              toolbar: "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
              contextmenu: editorMode === 2 && 'undo redo | inserttable | cell row column deletetable',
              height: 600,
              skin: false,
              content_css: false,
              plugins: "preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
              /* enable title field in the Image dialog*/
              image_title: true,
              /* enable automatic uploads of images represented by blob or data URIs*/
              automatic_uploads: true,
              media_live_embeds: true,
              spellchecker_rpc_url: 'spellchecker.php',

              file_picker_types: "image media",
              /* and here's our custom image and video picker*/
              file_picker_callback: function (callback, value, meta, editor) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                if (meta.filetype === "image") {
                  input.setAttribute("accept", ".jpg,.png,|image/*");
                }

                if (meta.filetype === "media") {
                  input.setAttribute("accept", "video/*");
                }

                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();

                  reader.onload = function () {
                    // /* call the callback and populate the Title field with the file name */

                    if (meta.filetype === "image") {
                      const isLt5M = file.size / 1024 / 1024 < 2;
                      if (!isLt5M) {
                        tinymce.activeEditor.windowManager.alert(
                          "Media must smaller than 2MB!"
                        );
                      } else {
                        const data = {
                          uploadContent: file,
                        };
                        tinymce.activeEditor.windowManager.alert(
                          "Media Uploading will take time ,please wait!"
                        );

                        uploadMedia(data)
                          .then((res) => {
                            const { data } = res;

                            if (data && data.payload) {
                              callback(data.payload);
                              tinymce.activeEditor.windowManager.alert(
                                "Media Uploaded Successfully"
                              );
                            } else {
                              tinymce.activeEditor.windowManager.alert(
                                "Unable to upload media, Please try again!"
                              );
                            }
                          })
                          .catch(() => {
                            tinymce.activeEditor.windowManager.alert(
                              "Unable to upload media, Please try again!"
                            );
                          });
                      }
                    }

                    if (meta.filetype === "media") {
                      const isLt5M = file.size / 1024 / 1024 < 10;
                      if (!isLt5M) {
                        tinymce.activeEditor.windowManager.alert(
                          "Media must smaller than 10MB!"
                        );
                      } else {
                        const data = {
                          uploadContent: file,
                        };
                        tinymce.activeEditor.windowManager.alert(
                          "Media Uploading will take time ,please wait!"
                        );

                        uploadMedia(data)
                          .then((res) => {
                            const { data } = res;

                            if (data && data.payload) {
                              callback(data.payload);
                              tinymce.activeEditor.windowManager.alert(
                                "Media Uploaded Successfully"
                              );
                            } else {
                              tinymce.activeEditor.windowManager.alert(
                                "Unable to upload media, Please try again!"
                              );
                            }
                          })
                          .catch(() => {
                            tinymce.activeEditor.windowManager.alert(
                              "Unable to upload media, Please try again!"
                            );
                          });
                      }
                    }
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              },

            }}
          />
        </EditorContainer>
        <StyledFormItemButton>
          <Button size="large" onClick={() => setShowEditor(false)}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" size="large">
            {operationStatus === "create" ? (
              <>
                <PlusOutlined /> Create
              </>
            ) : (
              <>
                <EditOutlined /> Save
              </>
            )}
          </Button>
        </StyledFormItemButton>
      </Form>
    </Content>
  );
};

export default EditorComponent;
