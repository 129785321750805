import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getTicket = (filterData) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/get-ticket`,
    { params: filterData }
  );
};

export const updateTicket = (data) => {
  return axiosInstance.put(
    `${apiAppendPath.caseTicketsAppendPath}/update-ticket`,
    data
  );
};

export const getCaseTicketNotifications = () => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/case-ticket-notifications`
  );
};

export const getCaseTicketClearNotification = (ticketId) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/clear-ticket-notifications`,
    { params: ticketId }
  );
};
export const markTicketAsRead = (ticketId) => {
  return axiosInstance.put(
    `${apiAppendPath.caseTicketsAppendPath}/mark-ticket-as-read`,
    { ticketId }
  );
};
