import config from "../config";

const { api} = config;

export const fetchResourcePdf = ({ resourceType, resourceId }) => {

    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('userToken');

        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${api.baseUrl}/labs/${resourceType}s/${resourceId}`);
        xhr.onreadystatechange = handler;
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send();

        function handler() {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              resolve({ data: URL.createObjectURL(this.response) })
            } else {
              reject('Error fetching document')
            }
          }
        }
    })
  }