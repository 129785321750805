import * as am4core from "@amcharts/amcharts4/core";
// Importing geodata (map data)
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import * as am4maps from "@amcharts/amcharts4/maps";

// import theme
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useRef, useLayoutEffect } from "react";
import AllocationCaseFilter from "./AllocationCaseFilter";
import AllocationPhysicianFilter from "./AllocationPhysicianFilter";
import styled from "styled-components";
import { useEffect } from "react";
import {
  getCaseChartReports,
  getPhysicianChartReports,
} from "../../api/ReportChartsAPI";
import { useState } from "react";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const StyledDiv = styled.div`
  background-color: white;
  padding: 24px 28px;
`;

const StyledChartDiv = styled.div`
  width: 100%;
  height: 700px;

  @media screen and (max-width: 1500px) {
    height: 550px;
  }
`;

const AllocationChart = ({ type }) => {
  const chartdata = useRef(null);

  const [mapData, setMapData] = useState();
  const [maxCount, setMaxCount] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "physicians") {
      fetchPhysicianChartReportData();
    } else if (type === "cases") {
      fetchCaseChartReportData();
    }
  }, [type]);

  const fetchCaseChartReportData = (filterData) => {
    setLoading(true);
    getCaseChartReports(filterData)
      .then((res) => {
        const { data } = res;
        if (data && data.payload) {
          setMapData(data.payload.mapData);
          setMaxCount(data.payload.maxCount);
          setLoading(false);
        } else {
          message.error("Unable to load case report data !");
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to load case report data!");
          }
        } else {
          message.error("Unable to load case report data !");
        }
      });
  };

  const fetchPhysicianChartReportData = (filterData) => {
    setLoading(true);
    getPhysicianChartReports(filterData)
      .then((res) => {
        const { data } = res;
        if (data && data.payload) {
          setMapData(data.payload.mapData);
          setMaxCount(data.payload.maxCount);
          setLoading(false);
        } else {
          message.error("Unavle to load physician report data !");
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to load physician report data !");
          }
        } else {
          message.error("Unable to load physician report data !");
        }
      });
  };

  useLayoutEffect(() => {
   
      /* Chart code */
      // Themes begin
      am4core.useTheme(am4themes_animated);

      // Create map instance
      let chart = am4core.create(type, am4maps.MapChart);
      chart.maxZoomLevel = 62;

      // Set map definition
      chart.geodata = am4geodata_usaLow;

      // Set projection
      chart.projection = new am4maps.projections.AlbersUsa();

      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      //Set min/max fill color for each area
      polygonSeries.heatRules.push({
        property: "fill",
        target: polygonSeries.mapPolygons.template,
        min: am4core.color("#7BAD7E").brighten(1),
        max: am4core.color("#7BAD7E").brighten(0),
      });

      // Make map load polygon data (state shapes and names) from GeoJSON
      polygonSeries.useGeodata = true;
      polygonSeries.calculateVisualCenter = true;

      // Set heatmap values for each state

      polygonSeries.data = mapData && mapData;

      // Set up heat legend
      let heatLegend = chart.createChild(am4maps.HeatLegend);
      heatLegend.series = polygonSeries;
      heatLegend.align = "right";
      heatLegend.valign = "bottom";
      heatLegend.width = am4core.percent(20);
      heatLegend.marginRight = am4core.percent(4);
      heatLegend.minValue = 0;
      heatLegend.maxValue = maxCount!==0?maxCount:1;

      // Set up custom heat map legend labels using axis ranges
      let minRange = heatLegend.valueAxis.axisRanges.create();
      minRange.value = heatLegend.minValue;
      minRange.label.text = "Little";
      let maxRange = heatLegend.valueAxis.axisRanges.create();
      maxRange.value = heatLegend.maxValue;
      maxRange.label.text = "A lot!";

      // Blank out internal heat legend value axis labels
      heatLegend.valueAxis.renderer.labels.template.adapter.add(
        "text",
        function (labelText) {
          return "";
        }
      );

      // Configure series tooltip
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}: {value}";
      polygonTemplate.verticalCenter = "middle";
      polygonTemplate.horizontalCenter = "middle";
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeWidth = 0.5;

      //set label in th center of the state

      var labelSeries = chart.series.push(new am4maps.MapImageSeries());
      var labelTemplate = labelSeries.mapImages.template.createChild(
        am4core.Label
      );
      labelTemplate.horizontalCenter = "middle";
      labelTemplate.verticalCenter = "middle";
      labelTemplate.fontSize = 8;
      labelTemplate.nonScaling = true;
      labelTemplate.interactionsEnabled = false;

      polygonSeries.events.on("inited", function () {
        polygonSeries.mapPolygons.each(function (polygon) {
          var label = labelSeries.mapImages.create();
          var state = polygon.dataItem.dataContext.id.split("-").pop();
          var count=polygon.dataItem.dataContext.value
          label.latitude = polygon.visualLatitude;
          label.longitude = polygon.visualLongitude;
          var countString=": "+count
          label.children.getIndex(0).text = state+" "+(count?countString:"");
        });
      });

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#7BAD7E");

      chartdata.current = chart;

      return () => {
        // Themes end
        chart.dispose();
      };    
  }, [type, mapData, maxCount]);

  return (
    <Spin size="large" spinning={loading} indicator={<LoadingOutlined />}>
      <StyledDiv>
        {type === "physicians" && (
          <AllocationPhysicianFilter
            fetchPhysicianChartReportData={fetchPhysicianChartReportData}
          />
        )}
        {type === "cases" && (
          <AllocationCaseFilter
            fetchCaseChartReportData={fetchCaseChartReportData}
          />
        )}

        <StyledChartDiv id={type} />
      </StyledDiv>
    </Spin>
  );
};

export default AllocationChart;
