import axiosInstance from "./AxiosInstance";
import config from "../config";
import { message } from 'antd'

const { apiAppendPath } = config;

export const getPatientDetails = patientId => {
  return axiosInstance.get(`${apiAppendPath.patientApiAppendPath}/${patientId}`);
};

export const getCaseHistory = patientId => {
  return axiosInstance.get(`${apiAppendPath.patientApiAppendPath}/${patientId}/history`);
}

export const getPatientPrescriptionHistory = patientId => {
  return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/patient/${patientId}/prescriptions`);
};