const checkSuperAdminToken = () => {
    const superAdminToken = window.localStorage.getItem("superAdminToken");

    if (superAdminToken) {
        return true;
    } else {
        return false;
    }
};

export default checkSuperAdminToken;