import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../config';

export function AntTinyWrapper({ value, onChange, disabled }) {
  return <Editor
    init={{
      toolbar: 'undo redo | styleselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | bullist',
      menubar: false,
      statusbar: false,
      plugins: ['lists', 'paste'],
      paste_block_drop: true,
      paste_as_text: true
    }}
    disabled={disabled}
    value={value}
    onEditorChange={value => onChange?.(value)}
    apiKey={config.apiKeys.tinyEditorKey}
  />
}