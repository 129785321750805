import React, { forwardRef, useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { StyledForm } from './styled';
import styled from 'styled-components';
import * as LookUpAPI from '../../api/LookUpAPI';
import * as PhysiciansAPI from '../../api/PhysiciansAPI';

const InputWithLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-right: 50px;
`;

const MultipleSelectWithSearch = forwardRef(({ label, children, ...props }, ref) => {
  return (
      <InputWithLabelContainer>
        {!!label && <label>{label}</label>}
      <Form.Item name={props.name} style={{ width: '100%' }}>
        <Select
          ref={ref}
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Search...'
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0}
          {...props}
        >
          {children}
        </Select>
      </Form.Item>
      </InputWithLabelContainer>
  )
})

export const Filters = ({ selected, setSelected }) => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({
    physicians: [],
    states: [],
    clients: [],
    credentials: []
  })

  useEffect(() => {
    PhysiciansAPI.getPhysiciansV2().then(res => setFilters(f => ({ ...f, physicians: res.data.physicians })));
    LookUpAPI.lookUp('state').then((res) => setFilters(f => ({ ...f, states: res.data.payload })));
    LookUpAPI.lookUp('client').then((res) => setFilters(f => ({ ...f, clients: res.data.payload })));
    LookUpAPI.lookUp('credential').then((res) => setFilters(f => ({ ...f, credentials: res.data.payload })));
  }, [])

  return (
    <StyledForm form={form} onValuesChange={(_, values) => setSelected(values)} initialValues={selected}>
      <MultipleSelectWithSearch name='physicians' label='Doctor' >
        {filters.physicians.map((physician) => <Select.Option key={physician._id} value={physician._id}>{physician.firstName} {physician.lastName}</Select.Option>)}
      </MultipleSelectWithSearch>

      <MultipleSelectWithSearch name='states' label='State' >
        {filters.states.map((state) => <Select.Option key={state._id} value={state._id}>{state.name}</Select.Option>)}
      </MultipleSelectWithSearch>

      <MultipleSelectWithSearch name='clients' label='Client' >
        {filters.clients.map((client) => <Select.Option key={client._id} value={client._id}>{client.name}</Select.Option>)}
      </MultipleSelectWithSearch>

      <MultipleSelectWithSearch name='credentials' label='Credentials' >
        {filters.credentials.map((credential) => <Select.Option key={credential._id} value={credential.name}>{credential.name}</Select.Option>)}
      </MultipleSelectWithSearch>
    </StyledForm>
  )
}