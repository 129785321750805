import React, { useEffect, useState } from "react"

import { 
  Form, 
  Input, 
  Row, 
  Col, 
  Select, 
  Button, 
  AutoComplete, 
  Spin,
  Typography,
  Checkbox,
  message
} from "antd"

import { PlusCircleOutlined } from '@ant-design/icons';


import styled from 'styled-components'

import { useSearchMedications } from "../hooks/useSearchMedications";
import { usePharmacies } from "../../../../../hooks/usePharmacies";
import { useTelemedicineCategoryContext } from "../../../../../context/TelemedicineCategoryContext";

const { TextArea } = Input

const StyledFormItem = styled(Col)`
  & label {
    text-transform: capitalize;
  }
`

const StyledButtonGroup = styled(Row)`
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    & button {
        width: 100px;
        background: #fff;
        color: #171c2f;
    }
`

const FormItem = ({ span, children, ...props }) => (
  <StyledFormItem span={span || 12}>
    <Form.Item {...props} label={props.label || props.name}>
      {children}
    </Form.Item>
  </StyledFormItem>
);

export const PrescriptionTemplateForm = ({
  submit,
  selectedTemplate,
  handleFinish
}) => {

  const [showQRD, setShowQRD] = useState(selectedTemplate?.medication?.pillCount)

  const {
    telemedicineCategories,
    getTelemedicineCategoryIdByName,
  } = useTelemedicineCategoryContext()

  const [form] = Form.useForm();

  const {
    fetchMedications,
    medicationSearchMatches,
    availableMedicationStrengths,
    medicationSearchLoading,
    handleSelectMedication,
    handleSelectStrength,
    setMedicationSearchLoading,
    setMedicationSearchMatches,
    getTemplateMedicationAndStrength,
  } = useSearchMedications({
    form,
    selectedTemplate
  })

  const {
    pharmacySearchResults,
    pharmaciesLoading,
    fetchPharmacy,
    selectPharmacyByNcpdpId,
    selectPharmacy,
    selectedPharmacy,
    searchPharmacies
  } = usePharmacies()

  const telemedicineCategoryOptions = telemedicineCategories.map(category => ({
    label: category.name,
    value: category.name
  }))

  const handleSubmit = async () => {
    const values = form.getFieldsValue()

    if (Number.isNaN(values.quantity)) {
      return message.error('Quantity must be a number')
    }

    if (!values.qrd) {
      values.refills = null
      values.pillCount = null
      values.daysSupply = null
    }

    const teleMedicineCategoryIds = values.teleMedicineCategories.map(getTelemedicineCategoryIdByName)

    if (!values.dispensableDrugId && values.strength.toLowerCase() !== 'compound') {
      return message.error('Malformed template. Please retry.')
    }

    let medicationName

    if (values.dispensableDrugId) {
      const medication = await getTemplateMedicationAndStrength(values.dispensableDrugId)
      if (!medication) return message.error('Medication not found.')
      medicationName = medication.displayName || `${medication.name} ${medication.strength}`
    } else {
      medicationName = values.drugName
    }

    if (!window.confirm(`Confirm creation of template for ${medicationName} ?`)) return

    submit({
      ...values,
      pharmacyId: selectedPharmacy?.pharmacyId || null,
      pharmacyName: selectedPharmacy?.name || null,
      teleMedicineCategoryIds,
      setLoading: setMedicationSearchLoading
    })

    setMedicationSearchMatches([])
    form.resetFields()
  }

  const _handleFinish = () => {
    form.resetFields()
    handleFinish()
  }

  useEffect(() => {
    form.resetFields()
    if (selectedTemplate) {
      form.setFieldsValue({
        ...selectedTemplate,
        ...(selectedTemplate.medication || {}),
        teleMedicineCategories: selectedTemplate.teleMedicineCategoryIds?.map(({ name }) => name),
        qrd: !!selectedTemplate?.medication?.pillCount
      })

      if (selectedTemplate.pharmacyId) {
        fetchPharmacy(selectedTemplate.pharmacyId)
          .then(pharmacy => {
            selectPharmacy(pharmacy)
            form.setFieldsValue({ pharmacy: pharmacy.name })
          }
          )
      }

      form.setFieldsValue({
        ...selectedTemplate,
        ...(selectedTemplate.medication || {}),
        teleMedicineCategories: selectedTemplate.teleMedicineCategoryIds?.map(({ name }) => name),
        qrd: !!selectedTemplate?.medication?.pillCount
      })
    } else {
      form.setFieldsValue({
        qrd: false
      })
    }

    
    return () => form.resetFields()
  }, [selectedTemplate])

  const medicationOptions = medicationSearchMatches?.map(({ name }) => ({ value: name }))
  const availableMedicationStrengthsOptions = availableMedicationStrengths?.map(({ strength }) => ({ value: strength }))
  const pharmacyOptions = pharmacySearchResults?.map(({ ncpdpId, name, city, state }) => ({ label: `${name}, ${city} ${state}`, value: ncpdpId }))

  return (
    <Spin spinning={medicationSearchLoading}>
      <Form
        form={form}
        layout="vertical"
        style={{ textTransform: 'capitalize' }}
        validateMessages={{ required: 'required' }}
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <FormItem required span={24} name="title" label="Template Name">
            <Input placeholder="Template Name" />
          </FormItem>
          <FormItem required span={24} name="drugName" label="Medication">
            <AutoComplete
              onSelect={handleSelectMedication}
              onSearch={fetchMedications}
              placeholder="Search medication name"
              options={medicationOptions}
            />
          </FormItem>
          <FormItem
            span={24}
            label="Pseudonyms"
            name="pseudonyms"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Alternative names for requested medication"
            />
          </FormItem>
          <FormItem
            span={24}
            label="Telemedicine Categories"
            name="teleMedicineCategories"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={!!selectedTemplate ? "None selected" : "Select Telemedicine Categories"}
              options={telemedicineCategoryOptions}
            />
          </FormItem>
          <FormItem
            span={12}
            label="Strength"
            name="strength"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select strength"
              onSelect={handleSelectStrength}
              options={availableMedicationStrengthsOptions}
            />
          </FormItem>
          <FormItem span={12} label="Pharmacy" name="pharmacy">
            <AutoComplete
              loading={pharmaciesLoading}
              defaultActiveFirstOption
              onSearch={searchPharmacies}
              onSelect={selectPharmacyByNcpdpId}
              options={pharmacyOptions}
              placeholder="Enter Pharmacy Name"
            />
          </FormItem>
          <FormItem span={8} name="pillCount" label="Quantity" hidden={!showQRD}>
            <Input type="text" placeholder="Enter quantity" min={1} />
          </FormItem>
          <FormItem span={8} name="refills" hidden={!showQRD}>
            <Input type="number" placeholder="Enter Refills" min={0} />
          </FormItem>
          <FormItem span={8} label="Days Supply" name="daysSupply" hidden={!showQRD}>
            <Input type="number" placeholder="Enter Days Supply" min={1} />
          </FormItem>
          <FormItem
            required
            rules={[{ required: true }]}
            span={24}
            label="Patient Directions"
            name="directions"
          >
            <TextArea placeholder="Enter Patient Directions" />
          </FormItem>
          <FormItem span={24} label="Pharmacy Notes" name="pharmacyNotes">
            <TextArea placeholder="Enter Pharmacy Notes" />
          </FormItem>
          <Form.Item
            span={24}
            name="DAW"
            valuePropName="checked"
            style={{ marginLeft: '7px' }}
          >
            <Checkbox>
              <Typography.Text>Dispense as Written</Typography.Text>
            </Checkbox>
          </Form.Item>
          <Form.Item
            span={24}
            name="qrd"
            valuePropName="checked"
            style={{ marginLeft: '7px' }}
            onChange={e => setShowQRD(e.target.checked)}
          >
            <Checkbox>
              <Typography.Text>Use Quantity, Refills, Days Supply</Typography.Text>
            </Checkbox>
          </Form.Item>
          <StyledButtonGroup>
            <Button onClick={_handleFinish}>Cancel</Button>
            <Button
              style={{ background: '#7BAD7E', color: '#fff' }}
              icon={!selectedTemplate && <PlusCircleOutlined />}
              htmlType="submit"
            >
              {selectedTemplate ? 'Save' : 'Create'}
            </Button>
          </StyledButtonGroup>
        </Row>
        <Form.Item hidden name="ndc" />
        <Form.Item hidden name="dispensableDrugId" />
        <Form.Item hidden name="ingredients" />
        <Form.Item hidden name="dispenseUnitId" />
        <Form.Item hidden name="schedule" />
      </Form>
    </Spin>
  );
};
