//import packages
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

//import components
import ClientComponent from "./Clients";
import StateComponent from "./States";
import DictionaryComponent from "./DictionaryComponent";
import TeleMedicineComponent from "./Telemedicine";
import CaseStatusComponent from "./CaseStatuses";
import QuestionnaireComponent from "./Questionnaire";
import Templates from "./Templates";
import Users from "./Users";
import MedicationsAndDirectionsComponent from "./MedicationsAndDirections";
import GlobalLoadBalancerConfiguration from "./GlobalLoadBalancerConfigurations";
import ConsultRates from "./ConsultRates";
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import UnauthorizedComponent from "../../components/UnauthorizedComponent/UnauthorizedComponent";
import authorization from "../../auth/authorization";

const MainDictionaryComponent = ({ userType }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}/clients`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "createClient",
                "getCategoryOfClient",
                "updateClient",
                "deleteClient",
                "getAdminUserClients",
                "getAdminUserClientDetails",
              ]) ? (
                <ClientComponent />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        exact
        path={`${path}/states`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "createState",
                "updateState",
                "deleteState",
                "searchState",
                "getStates",
              ]) ? (
                <StateComponent />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        exact
        path={`${path}/telemedicine`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "createTeleMedicineCategory",
                "updateTeleMedicineCategory",
                "deleteTeleMedicineCategory",
                "getTeleMedicineCategory",
              ]) ? (
                <TeleMedicineComponent />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        exact
        path={`${path}/case-status`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "createCaseStatus",
                "updateCaseStatus",
                "deleteCaseStatus",
              ]) ? (
                <CaseStatusComponent />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        exact
        path={`${path}/templates`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "getMessageTemplate",
                "getMessageTemplateDetails",
                "saveMessageTemplate",
                "updateMessageTemplate",
                "deleteMessageTemplate",
              ]) ? (
                <Templates />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        path={`${path}/questionnaire`}
        component={QuestionnaireComponent}
      />
      <Route
        path={`${path}/users`}
        render={() => {
          return (
            <>
              {authorization.hasAccessToModule(userType, [
                "getAdminUsers",
                "adminUserView",
                "registerAdminUser",
                "adminUserUpdate",
                "adminUserDelete",
              ]) ? (
                <Users />
              ) : (
                <UnauthorizedComponent />
              )}
            </>
          );
        }}
      />
      <Route
        path={`${path}/medications-and-directions`}
        component={MedicationsAndDirectionsComponent}
      />
      <Route
        path={`${path}/external-consult-rates`}
        component={ConsultRates}
      />
      <Route
        path={`${path}/global-configurations`}
        component={GlobalLoadBalancerConfiguration}
      />
      <Route exact path={path} component={DictionaryComponent} />
    </Switch>
  );
};

export default UserRolesAndRightsHOComponent(MainDictionaryComponent);
