/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  AutoComplete,
  Select,
  Spin,
  Table,
  Switch,
  Modal
} from "antd";
import { PlusOutlined, LoadingOutlined, DeleteFilled } from "@ant-design/icons";
import { debounce } from "lodash";

//import other files and API function
import { lookUpDetails } from "../../../api/LookUpAPI";
import { updateState } from "../../../api/StatesAPI";
import { useBulkCasePharmacyUpdate } from './useBulkCasePharmacyUpdate';

// Ant D components
const { Option } = Select;
const { Text } = Typography;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 0 0 8px;
  width: 96px;
`;

const StyledPlusButtonColWrapper = styled(Col)`
  text-align: right;
`;

const StyledRowWrapper = styled(Row)`
  margin: 12px 0;
`;

const StyledFormItemWrapper = styled(Form.Item)`
  margin-bottom: unset;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const EditState = (props) => {
  const {
    stateId,
    afterStateEdit,
    fetchStatesAfterUpdate,
    states,
    telemedicineCategory,
    consultationType,
    listOfPharmacies,
    fetchPharmacies,
    pharmacyLoading,
  } = props;
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [
    teleMedicineCategoryForSelection,
    setTeleMedicineCategoryForSelection,
  ] = useState([]);
  const [addedTeleMedicineCategory, setAddedTeleMedicineCategory] = useState(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedConsultation, setSelectedConsultation] = useState({});
  const [selectedRenewalConsultation, setSelectedRenewalConsultation] = useState({});
  const [
    addedTelemedicineCategoryValidatedStatus,
    setAddedTelemedicineCategoryValidatedStatus,
  ] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState({});
  const [selectPharmacySearchValue, setSelectPharmacySearchValue] = useState(
    ""
  );

  const [form] = Form.useForm();
  const [fields, setStateDetails] = useState([
    {
      name: ["stateName"],
      value: "",
    },
  ]);

  const { addCategory, removeCategory, hasCategoryUpdates, bulkUpdateCasePharmacies } = useBulkCasePharmacyUpdate();

  const columns = [
    {
      title: "Telemedicine Category",
      width: 100,
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Initial Consultation Type",
      width: 100,
      dataIndex: "consultationType",
      key: "consultationType",
    },
    {
      title: "Renewal Consultation Type",
      width: 100,
      dataIndex: "renewalConsultationType",
      key: "renewalConsultationType",
    },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacyName",
      key: "pharmacyName",
      width: 100,
    },
    {
      title: "Remove",
      key: "operation-remove",
      width: 100,
      render: (event, record, index) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            handleTeleMedicineCategoryDelete(index);
          }}
        />
      ),
    },
  ];

  const handlePharmacySearch = (value) => {
    setSelectPharmacySearchValue(value);
  };

  const searchPharmacy = (searchPharmacyValue) => {
    fetchPharmacies(searchPharmacyValue);
  };

  const debounceSearchPharmacies = useCallback(
    debounce(searchPharmacy, 1250),
    []
  );

  useEffect(() => {
    debounceSearchPharmacies(selectPharmacySearchValue);
  }, [selectPharmacySearchValue]);

  const handlePharmacySelectionChange = (value) => {
    setSelectedPharmacy(value);
  };

  useEffect(() => {
    lookUpDetails("state", stateId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const { name, consultationType, renewalConsultationType, pharmacy, pdmpResource } = res.data.payload;
          setStateDetails([{ name: ["stateName"], value: name || ""}, { name: ["pdmpResource"], value: pdmpResource || ""}]);
          const consultationTypeDetails = [];
          if (pharmacy && pharmacy.length) {
            consultationType.forEach((eachConsultationType) => {
              const correspondingPharmacy = pharmacy.filter(
                (eachPharmacy) =>
                  eachPharmacy.categoryId === eachConsultationType.categoryId
              );
              if (correspondingPharmacy && correspondingPharmacy.length) {
                consultationTypeDetails.push({
                  ...eachConsultationType,
                  renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
                  pharmacyName: correspondingPharmacy[0].pharmacyName,
                  pharmacyId: correspondingPharmacy[0].pharmacyId,
                });
              } else {
                consultationTypeDetails.push({
                  ...eachConsultationType,
                  renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
                });
              }
            });
          } else {
            consultationType.forEach((eachConsultationType) => {
              consultationTypeDetails.push({
                ...eachConsultationType,
                renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
              });
            });
          }
          setAddedTeleMedicineCategory(consultationTypeDetails);
          message.success(
            "Successfully loaded State details into Edit State Form"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Unable to fetch state details for Editing");
      });
  }, [stateId]);

  const onFinish = (values) => {
    const { stateName, shouldBulkUpdatePharmacy, pdmpResource } = values;
    if (!addedTeleMedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
      return false;
    } else {
      setAddedTelemedicineCategoryValidatedStatus("");
    }
    const consultationTypeDetails = addedTeleMedicineCategory.map(
      (eachCategory) => ({
        categoryId: eachCategory.categoryId,
        categoryName: eachCategory.categoryName,
        consultationType: eachCategory.consultationType,
      })
    );

    const renewalConsultationTypeDetails = addedTeleMedicineCategory.filter(category => category.renewalConsultationType).map(
      (eachCategory) => ({
        categoryId: eachCategory.categoryId,
        categoryName: eachCategory.categoryName,
        consultationType: eachCategory.renewalConsultationType,
      })
    );

    const pharmacyDetails = addedTeleMedicineCategory.map((eachCategory) => ({
      categoryId: eachCategory.categoryId,
      pharmacyName: eachCategory.pharmacyName,
      pharmacyId: eachCategory.pharmacyId,
    }));

    const stateDetails = {
      name: stateName,
      consultationType: [...consultationTypeDetails],
      renewalConsultationType: [...renewalConsultationTypeDetails],
      pharmacy: [...pharmacyDetails],
      pdmpResource
    };

    updateState(stateId, stateDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully updated state");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to update State. Please try again!");
          }
        }
        afterStateEdit("");
        fetchStatesAfterUpdate();
      })
      .then(async () => {
        if (shouldBulkUpdatePharmacy && hasCategoryUpdates) {
          const res = await bulkUpdateCasePharmacies(stateId);
          message.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to Edit State. Please try again!");
          }
        } else {
          message.error("Unable to Edit State. Please try again!");
        }
      });
  };

  const onCancelingEdit = () => {
    setStateDetails([
      {
        name: ["stateName"],
        value: "",
      },
    ]);
    afterStateEdit("");
  };

  const updateTeleMedicineCategoryForSelection = () => {
    const checkAddedTeleMedicineCategory = (accumulator, currentCategory) => {
      const foundTelemedicineCategory = addedTeleMedicineCategory.find(
        (eachSelectedCategory) =>
          currentCategory.key === eachSelectedCategory.categoryId
      );
      if (!foundTelemedicineCategory) {
        accumulator.push(currentCategory);
      }
      return accumulator;
    };
    const teleMedicineCategoryForSelection = telemedicineCategory.reduce(
      checkAddedTeleMedicineCategory,
      []
    );
    setTeleMedicineCategoryForSelection(teleMedicineCategoryForSelection);
  };

  useEffect(() => {
    updateTeleMedicineCategoryForSelection();
  }, [telemedicineCategory, addedTeleMedicineCategory]);

  const onConsultationTypeChange = (value) => {
    setSelectedConsultation(value);
  };

  const onRenewalConsultationTypeChange = (value) => {
    setSelectedRenewalConsultation(value);
  };

  const onTeleMedicineCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleDaysAvailableAddition = () => {
    const telemedicineCategory = {
      categoryId: selectedCategory.value,
      pharmacyId: selectedPharmacy.value,
      pharmacyName: selectedPharmacy.label,
      categoryName: selectedCategory.label,
      consultationType: selectedConsultation.value,
      renewalConsultationType: selectedRenewalConsultation.value,
    };
    const clonnedSelectedTelemedicineCategory = addedTeleMedicineCategory.slice(
      0
    );
    clonnedSelectedTelemedicineCategory.push(telemedicineCategory);
    setAddedTeleMedicineCategory(clonnedSelectedTelemedicineCategory);
    setSelectedCategory({});
    setSelectedConsultation({});
    setSelectedRenewalConsultation({});
    setSelectedPharmacy({});
    addCategory(selectedCategory.value);

    if (addedTelemedicineCategoryValidatedStatus === "error") {
      setAddedTelemedicineCategoryValidatedStatus("sucess");
    }
  };

  const handleTeleMedicineCategoryDelete = (itemIndex) => {
    const clonnedTelemedicineCategory = addedTeleMedicineCategory.slice(0);
    const category = clonnedTelemedicineCategory.splice(itemIndex, 1);
    removeCategory(category.categoryId)

    if (!clonnedTelemedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
    }
    setAddedTeleMedicineCategory(clonnedTelemedicineCategory);
  };

  const updateAutoCompleteOptions = () => {
    const options = states.map((eachState) => ({
      key: eachState._id,
      value: eachState.name,
    }));
    setAutoCompleteOptions(options);
  };

  useEffect(() => {
    updateAutoCompleteOptions();
  }, [states]);

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => {
        setStateDetails(allFields);
      }}
      validateMessages={validateMessages}
    >
      <Title level={2}>Edit State</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="stateName"
            label="State Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoComplete
              options={autoCompleteOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            >
              <Input size={"large"} placeholder="Enter State Name" />
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="pdmpResource"
            label="Controlled Substances PDMP Resource (URL)"
          >
              <Input size={"large"} placeholder="PDMP Resource (Required for states where controlled substances are available)" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            shouldUpdate={() => addedTeleMedicineCategory.length}
            name="telemedicine-consultation-mapping"
            label="Add Telemedicine Category"
            validateStatus={addedTelemedicineCategoryValidatedStatus}
            help={
              addedTelemedicineCategoryValidatedStatus === "error"
                ? "Please select atleast one Telemedicine Category, Consultation Type and pharmacy, then add it using `+` button"
                : ""
            }
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (addedTeleMedicineCategory.length) {
                    return Promise.resolve();
                  }

                  setAddedTelemedicineCategoryValidatedStatus("error");
                  return Promise.reject(
                    "Please select atleast one Telemedicine Category, Consultation Type and pharmacy, then add it using `+` button"
                  );
                },
              }),
            ]}
          >
            <StyledRowWrapper>
              <Col>
                <Text>
                  Please select a Telemedicine Category, a Consultation type and
                  a Pharmacy, then click on `+` button to add it to the selected
                  state. Use the delete button in the listing to remove the
                  Added Telemedicine category from this state.
                </Text>
              </Col>
            </StyledRowWrapper>
            <StyledRowWrapper gutter={[8, 8]} justify="space-between">
              <Col xs={24} sm={24} md={10} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Telemedicine Category">
                  <Select
                    placeholder="Select a Telemedicine Category"
                    showSearch
                    labelInValue
                    size="large"
                    value={selectedCategory}
                    onChange={onTeleMedicineCategoryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {teleMedicineCategoryForSelection.map((eachCategory) => (
                      <Option key={eachCategory._id} value={eachCategory._id}>
                        {eachCategory.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select an Initial Consultation Type">
                  <Select
                    size="large"
                    labelInValue
                    value={selectedConsultation}
                    placeholder="Select Consultation Type"
                    onChange={onConsultationTypeChange}
                  >
                    {consultationType.map((eachType) => (
                      <Option key={eachType.key} value={eachType.value}>
                        {eachType.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Renewal Consultation Type">
                  <Select
                    size="large"
                    labelInValue
                    value={selectedRenewalConsultation}
                    placeholder="Select Consultation Type"
                    onChange={onRenewalConsultationTypeChange}
                  >
                    {consultationType.map((eachType) => (
                      <Option key={eachType.key} value={eachType.value}>
                        {eachType.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Pharmacy">
                  <Spin
                    spinning={pharmacyLoading}
                    size="small"
                    indicator={<LoadingOutlined />}
                  >
                    <Select
                      size="large"
                      showSearch
                      value={selectedPharmacy}
                      placeholder={"Enter Pharmacy Name"}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handlePharmacySearch}
                      onChange={handlePharmacySelectionChange}
                      notFoundContent={null}
                      labelInValue
                    >
                      {listOfPharmacies.map((eachPharmacy) => (
                        <Option
                          key={eachPharmacy.key}
                          value={eachPharmacy.value}
                        >
                          {eachPharmacy.name}
                        </Option>
                      ))}
                    </Select>
                  </Spin>
                </StyledFormItemWrapper>
                {/* <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                <Row gutter={2} align='top' justify='start'>
                  <Col span={6}>
                    <Form.Item name='shouldBulkUpdatePharmacy' valuePropName='checked'>
                      <Switch onClick={(checked) => {
                        if (checked) {
                          Modal.confirm({
                            content: 'Cases will be updated to the selected pharmacy at 11:00PM PDT.',
                            onCancel: () => form.setFieldsValue({ shouldBulkUpdatePharmacy: false })
                          })
                        }
                      }} />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <span style={{ fontSize: '.75rem', color: 'grey' }}>Update All Open Cases In This Category/State To The Selected Pharmacy</span>
                  </Col>
                </Row>
                {/* </div> */}
              </Col>
              <StyledPlusButtonColWrapper xs={24} sm={24} md={2} lg={2} xl={2}>
                <StyledFormItemWrapper label=" ">
                  <Button
                    type="primary"
                    disabled={
                      !(
                        Object.keys(selectedCategory).length &&
                        Object.keys(selectedConsultation).length
                      )
                    }
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={handleDaysAvailableAddition}
                  />
                </StyledFormItemWrapper>
              </StyledPlusButtonColWrapper>
            </StyledRowWrapper>
            <StyledRowWrapper>
              {addedTeleMedicineCategory.length ? (
                <>
                  <Col span={24}>
                    <Text strong>Added Telemedicine Category</Text>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={addedTeleMedicineCategory}
                      scroll={{ x: 720 }}
                      pagination={false}
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
            </StyledRowWrapper>
          </Form.Item>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="secondary"
              size={"large"}
              onClick={onCancelingEdit}
            >
              Cancel
            </StyledButton>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Save
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default EditState;
