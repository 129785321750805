export const months = {
    January: { key: 'jan', value: 0 },
    February: { key: 'feb', value: 1 },
    March: { key: 'mar', value: 2 },
    April: { key: 'april', value: 3 },
    May: { key: 'may', value: 4 },
    June: { key: 'jun', value: 5 },
    July: { key: 'july', value: 6 },
    August: { key: 'aut', value: 7 },
    September: { key: 'sept', value: 8 },
    October: { key: 'oct', value: 9 },
    November: { key: 'nov', value: 10 },
    December: { key: 'dec', value: 11 }
}