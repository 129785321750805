/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  List,
  TimePicker,
  Button,
  Modal,
  Form,
  DatePicker,
  message,
  Typography,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import converterTimeZone from "../../utils/converterTimeZone";

// import APIs
import { updateScheduleDays } from "../../api/PhysiciansAPI";
import _ from "lodash";

//import components
import { StyledAvailabilityCardComponent } from "../../components/ScheduleComponent";

const { RangePicker } = TimePicker;
const { Title } = Typography;

const SpecialDays = ({
  specialDates,
  setdataEdited,
  physicianId,
  offSet,
  timeZone,
}) => {
  const [editDays, setEditDays] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const [specialDayView, setSpecialDayView] = useState();
  const [specialDays, setSpecialDays] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const localTimeZone = moment.tz.guess(true);
  useEffect(() => {
    if (timeZone && specialDates) {
      const changeData = [];
      for (let key in specialDates) {
        changeData.push({
          date: specialDates[key].date,
          startTime: converterTimeZone(
            specialDates[key].startTime,
            localTimeZone,
            timeZone
          ),
          endTime: converterTimeZone(
            specialDates[key].endTime,
            localTimeZone,
            timeZone
          ),
        });
      }
      setSpecialDayView(changeData);
    }
  }, [specialDates, timeZone]);

  useEffect(() => {
    setSpecialDays(specialDayView);
  }, [specialDayView]);

  const editSpecialDays = () => {
    setEditDays(false);
  };

  const addSpecialDays = () => {
    setModalVisible(true);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleModalOk = () => {
    const formData = form.getFieldsValue();
    const changeData = specialDays;
    if (formData.time && formData.date) {
      changeData.push({
        date: moment(formData.date).format("YYYY-MM-DD"),
        startTime: moment.utc(formData.time[0]).format(),
        endTime: moment.utc(formData.time[1]).format(),
      });

      setSpecialDays(changeData);
      form.resetFields();
      setModalVisible(false);
    }
  };

  const deleteSpecialDays = (date) => {
    setSpecialDays(specialDays.filter((item) => item.date !== date));
  };

  function disabledDate(current) {
    // Can not select days before today and today
    if (
      (current && current < moment().endOf("day")) ||
      specialDays.find((d) => moment(current).format("YYYY-MM-DD") === d.date)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const changeSpecialTime = (time, timeString, key) => {
    const changeData = [];
    specialDays.forEach((item) => {
      if (item.date === key) {
        changeData.push({
          date: moment(item.date).format("YYYY-MM-DD"),
          startTime: moment.utc(time[0]).format(),
          endTime: moment.utc(time[1]).format(),
        });
      } else {
        changeData.push({
          date: item.date,
          startTime: item.startTime,
          endTime: item.endTime,
        });
      }
    });
    setSpecialDays(changeData);
  };

  const editCancel = () => {
    setEditDays(true);
    const changeData = [];
    for (let key in specialDates) {
      changeData.push({
        date: specialDates[key].date,
        startTime: converterTimeZone(
          specialDates[key].startTime,
          localTimeZone,
          timeZone
        ),
        endTime: converterTimeZone(
          specialDates[key].endTime,
          localTimeZone,
          timeZone
        ),
      });
    }
    setSpecialDays(changeData);
  };

  const submitChange = () => {
    const requestData = {};
    setButtonLoading(true);
    const specialresDays = {};
    const changeData = [];
    for (let key in specialDates) {
      changeData.push({
        date: specialDates[key].date,
        startTime: converterTimeZone(
          specialDates[key].startTime,
          localTimeZone,
          timeZone
        ),
        endTime: converterTimeZone(
          specialDates[key].endTime,
          localTimeZone,
          timeZone
        ),
      });
    }

    specialDays.forEach((item) => {
      specialresDays[item.date] = [
        converterTimeZone(item.startTime, timeZone, localTimeZone),
        converterTimeZone(item.endTime, timeZone, localTimeZone),
      ];
    });

    if (!_.isEqual(specialDays, changeData)) {
      requestData.physicianId = physicianId;
      requestData["schedule"] = specialresDays;
      requestData["schedule-type"] = "specific";
      requestData["offSet"] = offSet;

      updateScheduleDays(requestData)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setdataEdited();
            setEditDays(true);
            message.success("SuccessFully Updated ");
            setButtonLoading(false);
          } else {
            message.error(res.data.message);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          const { response } = error;
          setButtonLoading(false);
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to update Special Availability, Please try again !"
              );
            }
          } else {
            message.error(
              "Unable to update Special Availability , Please try again !"
            );
          }
        });
    } else {
      message.info("Please provide changes before you save");
      setButtonLoading(false);
    }
  };

  return (
    <StyledAvailabilityCardComponent>
      <div className="special-availability-header">
        <Title level={3}>
          <b>Special Availability</b>
        </Title>
        <Button type="primary" onClick={() => editSpecialDays()}>
          <EditFilled />
          Edit
        </Button>
      </div>

      <div className="general-availability-button-add">
        {!editDays && (
          <Button type="primary" onClick={() => addSpecialDays()}>
            <PlusOutlined />
            Add
          </Button>
        )}
      </div>

      <Modal
        title="Add General Days"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="date" label="Select Special Available Day" span={24}>
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item>

          <Form.Item name="time" label="Provide Available Time " span={24}>
            <RangePicker format="HH:mm"></RangePicker>
          </Form.Item>
        </Form>
      </Modal>

      <List
        className="general-availability-list"
        itemLayout="horizontal"
        dataSource={specialDays}
        renderItem={(item) => (
          <List.Item
            actions={[
              !editDays && (
                <DeleteFilled
                  style={{ fontSize: "18px", color: "#7BAD7E" }}
                  onClick={() => deleteSpecialDays(item.date)}
                />
              ),
            ]}
          >
            <Title level={4} style={{ width: 200 }}>
              {moment(item.date).format("MMMM Do YYYY")}
            </Title>
            {!editDays ? (
              <RangePicker
                defaultValue={[
                  moment(
                    moment.utc(item.startTime).local().format("HH:mm"),
                    "HH:mm "
                  ),
                  moment(
                    moment.utc(item.endTime).local().format("HH:mm"),
                    "HH:mm"
                  ),
                ]}
                size="large"
                format="HH:mm"
                bordered={false}
                disabled={editDays}
                style={{ width: 200 }}
                allowClear={false}
                onChange={(time, timeString) =>
                  changeSpecialTime(time, timeString, item.date)
                }
              />
            ) : (
              <>
                <Title level={4} style={{ margin: "unset" }}>
                  <FieldTimeOutlined /> <b>From :</b>{" "}
                  {moment.utc(item.startTime).local().format("HH:mm")}{" "}
                  <b>To :</b> {moment.utc(item.endTime).local().format("HH:mm")}{" "}
                </Title>
              </>
            )}
          </List.Item>
        )}
      />
      <div className="general-availability-button">
        {!editDays && (
          <>
            <Button type="primary" size="large" onClick={() => editCancel()}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              loading={buttonLoading}
              onClick={() => submitChange()}
            >
              Save Changes
            </Button>
          </>
        )}
      </div>
    </StyledAvailabilityCardComponent>
  );
};

export default SpecialDays;
