import React, { useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Table, Row, Col, Select, Typography } from 'antd';
import styled from 'styled-components';

const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-content {
    background-color: white;
  }
`

const StyledTable = styled(Table)`
  .table-row-red {
    font-weight: bold;
    background-color: #FF9494;
    color: white;
    pointer-events: none;
  }
`
const { Title } = Typography;

export const Report = ({ states, categories }) => {
  const [filters, setFilters] = useState({
    states: states.map(state => state._id),
    categories: categories.map(category => category._id),
  });

  const [expandedPanels, setExpandedPanels] = useState(states.map(state => state._id));

  const categoryLookup = useMemo(() => categories.reduce((lookup, category) => {
    lookup[category._id] = category;
    return lookup;
  }, {}), [categories])

  const filteredStates = useMemo(() => states.filter(state => filters.states.find((stateId) =>
    state._id === stateId &&
    state.teleMedicineCategories.find(categoryId => filters.categories.find(_categoryId => categoryId === _categoryId))))
  , [states, filters])

  useEffect(() => {
    setExpandedPanels(filters.states);
  }, [filters])

  return (
    <>
      <Row align='top' justify='space-between' style={{ marginBottom: 20 }} gutter={100}>
        <Col span={12}>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span>Categories:</span>
            <Select 
              mode='multiple' 
              value={filters.categories} 
              onChange={categories => setFilters({ ...filters, categories })} 
              showSearch
              optionFilterProp='categoryName'
              filterOption={(input, option) => option.categoryName.toLowerCase().includes(input.toLowerCase())}
              maxTagCount={4} 
              maxTagTextLength={10}
              dropdownMatchSelectWidth 
              style={{ width: '100%' }} 
            >
              {categories.map(category => <Select.Option key={category._id} value={category._id} categoryName={category.name}>{category.name}</Select.Option>)}
            </Select>
          </label>
          <div>
            <Button onClick={() => setFilters({ ...filters, categories: categories.map(category => category._id) })}>Select All</Button>
            <Button onClick={() => setFilters({ ...filters, categories: [] })}>Deselect All</Button>
          </div>
        </Col>
        <Col span={12}>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span>States:</span>
            <Select 
              mode='multiple' 
              value={filters.states} 
              onChange={states => setFilters({ ...filters, states })} 
              showSearch
              optionFilterProp='stateName'
              filterOption={(input, option) => option.stateName.toLowerCase().includes(input.toLowerCase())}
              maxTagCount={5} 
              maxTagTextLength={10}
              dropdownMatchSelectWidth 
              style={{ width: '100%' }} 
            >
              {states.map(state => <Select.Option key={state._id} value={state._id} stateName={state.name} >{state.name}</Select.Option>)}
            </Select>
          </label>
          <div>
            <Button onClick={() => setFilters({ ...filters, states: states.map(state => state._id) })}>Select All</Button>
            <Button onClick={() => setFilters({ ...filters, states: [] })}>Deselect All</Button>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }} justify='space-between'>
        <span>{filteredStates.length} state(s) found</span>
        <div>
          <Button onClick={() => setExpandedPanels(filters.states)}>Expand All</Button>
          <Button onClick={() => setExpandedPanels([])}>Collapse All</Button>
        </div>
      </Row>
      <Collapse activeKey={expandedPanels} onChange={(setExpandedPanels)}>
        {filteredStates.map(state => (
          <StyledPanel key={state._id} header={<Title level={4} style={{ color: '#7BAD7E' }}>{state.name}</Title>} showArrow={false} style={{ backgroundColor: 'white' }}>
            <StyledTable
              rowClassName={(record) => !record.pharmacy && 'table-row-red'}
              columns={[{
                title: 'Category',
                dataIndex: 'category',
                width: '50%',
                align: 'left'

              }, {
                title: 'Pharmacy',
                dataIndex: 'pharmacy',
                align: 'left'
              }, {
                title: 'Modality',
                dataIndex: 'modality',
                align: 'left',
              }]}
              dataSource={state.teleMedicineCategories
                .filter((categoryId) => filters.categories.find(_categoryId => categoryId === _categoryId))
                .map((categoryId) => ({ category: categoryLookup[categoryId]?.name, pharmacy: state.pharmacy[categoryId]?.name, modality: state.consultationType[categoryId] === 'store and forward' ? 'async' : 'sync' }))}
              pagination={false}
            />
          </StyledPanel>
        ))}
      </Collapse>
    </>
  )
}