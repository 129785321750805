import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getCaseMessages = (id) => {
  return axiosInstance.get(
    `${apiAppendPath.chatRoomAppendPath}/admin/messages?id=${id}`
  );
};
