import React from "react";

import styled from "styled-components";
import { Menu, Dropdown, Button, message } from "antd";
import { withRouter } from "react-router";
import { logOut } from "../api/UserTypeAPI";
import { socket_close } from "../api/socket";

const UserOptionsMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background-color: #e8ecef;
  }
`;
const UserDropDown = styled(Menu.Item)`
  width: 230px;
`;

const UserOptions = ({ children, history, userDetails }) => {
  const handleLogout = (e) => {
    e.preventDefault();
    logOut()
      .then((res) => {
        const { data } = res;
        if (data.success) {
          localStorage.clear();
          socket_close();
          history.push("/login");
        } else {
          message.error("unable to logout,Please try again!");
        }
      })
      .catch(() => {
        message.error("unable to logout,Please try again!");
      });
  };
  const handleProfileView = (e) => {
    e.preventDefault();
    history.push("/my-profile");
  };

  const menu = (
    <UserOptionsMenu>
      <UserDropDown key="my-profile">
        <Button type="link" onClick={handleProfileView}>
          My Profile
        </Button>
      </UserDropDown>
      <UserDropDown key="logout">
        <Button type="link" onClick={handleLogout}>
          Logout
        </Button>
      </UserDropDown>
    </UserOptionsMenu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{ width: "230px" }}
    >
      <Button type="link">{children}</Button>
    </Dropdown>
  );
};

export default withRouter(UserOptions);
