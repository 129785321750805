import React, { useCallback, useMemo } from "react";
import {  Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FileTextOutlined } from "@ant-design/icons";
import authorization from "../../../../auth/authorization";
import { getDateFieldSorter, getTextFieldSorter, getSortOrder } from '../../../../utils/sorters';
import { ColourBorder } from "../StyledComponents";

export const useColumns = ({ sortOrder, sortBy, TableDataMap, userType, onPatientEditIconClick, onCaseDetailsViewClick }) => {
    const getTableColumns = useCallback((sortOrder, sortBy) => {
        const columns = [
          {
            title: "Case Id",
            width: 280,
            fixed: "left",
            key: TableDataMap.CASE_ID,
            render: (_case) => (
              <ColourBorder inputColor={_case.colourCode}>
                {_case.caseId || ""}
              </ColourBorder>
            ),
          },
          {
            title: "Category",
            width: 250,
            key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
          },
          {
            title: "Physician Name",
            width: 240,
            key: TableDataMap.PHYSICIAN_ID,
          },
          {
            title: "Patient Name",
            width: 240,
            key: TableDataMap.PATIENT_NAME,
          },
          {
            title: "Patient Email",
            width: 370,
            key: TableDataMap.PATIENT_EMAIL,
          },
          {
            title: "Patient Id",
            width: 280,
            key: TableDataMap.PATIENT_ID,
          },
          {
            title: "State",
            width: 280,
            key: TableDataMap.STATE_ID,
          },
          {
            title: "Created Date",
            width: 230,
            key: TableDataMap.CREATED_DATE,
          },
          {
            title: "Updated Date",
            width: 230,
            key: TableDataMap.UPDATED_DATE,
          },
          {
            title: "Consult Type",
            width: 230,
            key: TableDataMap.CONSULTATION_TYPE,
          },
          {
            title: "Status",
            width: 340,
            key: TableDataMap.STATUS,
          },
          {
            title: "Assigned Time",
            width: 230,
            key: TableDataMap.ASSIGNED_TIME,
          },
          {
            title: "Assigned Group",
            width: 250,
            key: TableDataMap.ASSIGNED_GROUP,
          },
          {
            title: "Time of Group Assignment",
            width: 220,
            key: TableDataMap.ASSIGNED_GROUP_TIME,
          },
        ]

        columns.forEach((c) => {
          c.dataIndex = c.key;
          if ([
            TableDataMap.CASE_ID,
            TableDataMap.PATIENT_ID,
          ].includes(c.key)) {
            return;
          }
          c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
          const isDateField = [
            TableDataMap.CREATED_DATE,
            TableDataMap.UPDATED_DATE,
            TableDataMap.ASSIGNED_TIME,
            TableDataMap.ASSIGNED_GROUP_TIME,
          ].includes(c.key);
          c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
        });

        const getViewDetailsColumn = (onCaseDetailsViewClick) => ({
          title: "View Details",
          dataIndex: "",
          width: 150,
          key: "t",
          align: "center",
          render: (_case) => (
            <Button
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                onCaseDetailsViewClick(_case[TableDataMap.CASE_ID].caseId);
              }}
            />
          ),
        })

        const getEditPatientColumn = (onPatientEditIconClick) => ({
          title: "Edit Patient",
          dataIndex: "",
          width: 150,
          key: "e",
          align: "center",
          render: (_case) => (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                onPatientEditIconClick(_case[TableDataMap.CASE_ID].caseId);
              }}
            />
          ),
        })

        return {
          columns,
          getViewDetailsColumn,
          getEditPatientColumn,
        }
    }, [TableDataMap]);

    const checkIsAllowedAuthUser = useCallback((action) => {
      return authorization.isAllowed(userType, action);
    }, [userType]);

    const columns = useMemo(() => {
      const { columns, getViewDetailsColumn, getEditPatientColumn } = getTableColumns(sortOrder, sortBy);
      if (checkIsAllowedAuthUser("getCasesDetails")) {
        const viewDetailsColumn = getViewDetailsColumn(onCaseDetailsViewClick);
        columns.push(viewDetailsColumn);
      }
      if (checkIsAllowedAuthUser("updateCase")) {
        const editPatientColumn = getEditPatientColumn(onPatientEditIconClick);
        columns.push(editPatientColumn);
      }
      return columns;
    }, [sortOrder, sortBy, onCaseDetailsViewClick, onPatientEditIconClick, checkIsAllowedAuthUser, getTableColumns]);

    return columns;
}
