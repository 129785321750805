import React from "react";
import { Menu } from "antd";
import { HistoryOutlined, TableOutlined } from "@ant-design/icons";
import styled from 'styled-components'

import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import authorization from "../../auth/authorization";

const { SubMenu } = Menu;

const StyledMenuItem = styled(Menu.Item)`
 
`
const StyledSubMenu = styled(SubMenu)`
`

const ReportMenu = ({ selectedReport, setSelectedReport, userType }) => {
  const handleClick = (e) => setSelectedReport(e.key);

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[selectedReport]}
      mode="horizontal"
      style={{ marginBottom: "10px",  }}
    >
      <StyledSubMenu key="custom" icon={<TableOutlined />} title="Custom Reports">
        <Menu.ItemGroup>
          { authorization.isAllowed(userType, "consultCompleteReport") && <Menu.Item key="custom-consult-time">Consult Complete</Menu.Item> }
          { authorization.isAllowed(userType, "consultNoRXReport") && <Menu.Item key="custom-consult-time-no-rx">Consult Complete No Rx</Menu.Item> }
          { authorization.isAllowed(userType, "clientCasesReport") && <Menu.Item key="custom-clients-case-count">Clients</Menu.Item> }
          { authorization.isAllowed(userType, "physicianCaseReport") && <Menu.Item key="custom-physicians-case-count">Physicians</Menu.Item> }
          { authorization.isAllowed(userType, "physicianPaymentReport") && <Menu.Item key="custom-physicians-reconciliation">Physician Payments</Menu.Item> }
          { authorization.isAllowed(userType, "incompleteCasesReport") && <Menu.Item key="custom-incomplete-cases">Incomplete Cases</Menu.Item> }
        </Menu.ItemGroup>
      </StyledSubMenu>
      {/* <StyledSubMenu
        key="generated"
        icon={<HistoryOutlined />}
        title="Generated Reports"
      >
        <Menu.ItemGroup>
          { authorization.isAllowed(userType, "consultCompleteReport") && <Menu.Item key="generated-consult-time">Consult Complete</Menu.Item> }
          { authorization.isAllowed(userType, "consultNoRXReport") && <Menu.Item key="generated-consult-time-no-rx">Consult Complete No Rx</Menu.Item> }
          { authorization.isAllowed(userType, "clientCasesReport") && <Menu.Item key="generated-clients-case-count">Clients</Menu.Item> }
          { authorization.isAllowed(userType, "physicianCaseReport") && <Menu.Item key="generated-physicians-case-count">Physicians</Menu.Item> }
          { authorization.isAllowed(userType, "physicianPaymentReport") && <Menu.Item key="generated-physicians-reconciliation">Physician Payments</Menu.Item> }
        </Menu.ItemGroup>
      </StyledSubMenu> */}
      { authorization.isAllowed(userType, "physicianCaseReport") && <StyledMenuItem key="physician-reporting-dashboard">Physician's Reporting Dashboard</StyledMenuItem> }
      { authorization.isAllowed(userType, "physicianCaseReport") && <StyledMenuItem key="physicians-queue-performance">Queue</StyledMenuItem> }
    </Menu>
  );
};

export default UserRolesAndRightsHOComponent(ReportMenu);
