import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../auth/authorization";
const ProtectedRoutes = ({
  adminComponent: AdminComponent,
  adminPath,
  providerPath,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      exact
      path="/"
      render={(props) => {
        if (auth.isAuthenticated()) {
          if (
            auth.getUserType() === "super admin" ||
            auth.getUserType() === "admin" ||
            auth.getUserType() === "admin support" ||
            auth.getUserType() === "manager" ||
            auth.getUserType() === "provider" ||
            auth.getUserType() === "steadymd admin support"
          ) {
            return (
              <AdminComponent userDetails={auth.getUserDetails()} {...props} />
            );
          } else {
            return <Redirect to={{ pathname: "/login" }} />;
          }
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
};

export default ProtectedRoutes;
