import React, { useState, useEffect } from "react";
import { Table, Row, Col, Typography, Select, message, Button } from "antd";
import styled from "styled-components";
import { getPhysiciansAsAdmin } from "../../api/PhysiciansAPI";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import config from "../../config";
import moment from "moment-timezone";

const { Title, Text } = Typography;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;
const Header = styled(Title)`
  margin-bottom: 0 !important;
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledText = styled(Text)`
  display: inline-block;
  margin-bottom: 40px !important;
  font-weight: 400;
`;

const FilterContainer = styled.div`
  width: 100%;
  justify-content: left;
  margin-bottom: 20px;
  & .ant-select {
    width: 100%;
  }
`;

const FilterHeader = styled(Text)`
  font-weight: bold;
  display: inline-block;
  margin: 5px 0;
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;

const PhysicianFilter = ({ physicians, selectPhysician, selectedPhysician, handleSearch }) => (
  <div style={{ display: 'flex', gap: '20px'}}>
      <Select
        style={{ textTransform: "capitalize", width: '300px' }}
        placeholder="Physician"
        allowClear
        onChange={selectPhysician}
      >
        {physicians?.length &&
          physicians.map((physician) => (
            <Select.Option
              key={physician._id}
              value={physician._id}
            >{`${physician.firstName} ${physician.lastName}`}</Select.Option>
          ))}
      </Select>
      <Button type='primary' disabled={!selectedPhysician} onClick={handleSearch}>Search</Button>
  </div>
);

const IncompleteCases = ({ report, count, filterParams, handleFetchReport }) => {
  const [physicians, setPhysicians] = useState([]);
  const [selectedPhysician, selectPhysician] = useState(null);

  const reportFilterParams = {
    status: "pending prescription",
    "additional-status":
      "pending video consult,pending audio consult,rx change,rx renewal",
    "consultation-start": true,
    "last-updated": moment()
      .subtract(config.completedCaseCheckDeadlineInDays, "days")
      .toISOString(),
  };

  useEffect(() => {
    fetchPhysicians();
  }, []);

  const fetchPhysicians = async () => {
    try {
      const response = await getPhysiciansAsAdmin({
        fields: ["firstName", "lastName", "_id"],
      });
      const physicians = response.data?.payload;
      setPhysicians(physicians);
    } catch (e) {
      message.error("Error fetching physicians list");
    }
  };

  const handleSearch = () => {
    handleFetchReport({ ...filterParams,  "physician-id": selectedPhysician })
  }

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseId",
      key: "case-id",
      fixed: "left",
    },
    {
      title: "Physician Name",
      dataIndex: "physicianName",
      key: "physician-name",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patient-name",
    },
    {
      title: "Last Updated",
      dataIndex: "updatedDate",
      key: "updatedDate",
      defaultSortOrder: "ascend",
      sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
      render: (_, caseDetails) => dateTimeFormatter(caseDetails.updatedDate),
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      key: "status",
    },
  ];

  const onChangePage = (index, size) => {
    handleFetchReport({
      ...reportFilterParams,
      ...filterParams,
      "physician-id": selectedPhysician,
      "case-count": size,
      index,
    });
  };

  const onPageSizeChange = (_, size) => {
    handleFetchReport({
      ...reportFilterParams,
      ...filterParams,
      "physician-id": selectedPhysician,
      "case-count": size,
      index: 1,
    });
  };

  return (
    <StyledComponentWrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Header level={2}>Incomplete Cases</Header>
          <StyledText>
            No action taken within {config.completedCaseCheckDeadlineInDays}{" "}
            days of starting a consultation
          </StyledText>
        </Col>

        <FilterContainer layout={"vertical"}>
          <Row>
            <Col xs={16}>
              <PhysicianFilter
                physicians={physicians}
                selectPhysician={selectPhysician}
                selectedPhysician={selectedPhysician}
                handleSearch={handleSearch}
              />
            </Col>
          </Row>
        </FilterContainer>

        <Col span={24}>
          <StyledTable
            columns={columns}
            dataSource={report || []}
            pagination={{
              total: count || 0,
              showTotal: () => `Total ${count || 0} Cases`,
              pageSizeOptions: ["5", "10", "20", "50", "100"],
              showSizeChanger: true,
              pageSize: filterParams["case-count"] || 10,
              onChange: onChangePage,
              onShowSizeChange: onPageSizeChange,
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </StyledComponentWrapper>
  );
};

export default IncompleteCases;
