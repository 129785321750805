/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Form, Row, Col } from "antd";
import { dateOfBirthFormatter } from "../../utils/dateFormatter";
import { StyledText } from "../../components/LayoutComponent";

const CasePatientDetails = ({ caseDetails }) => {
  const { patient } = caseDetails;

  const heightInfo = Number(caseDetails?.heightFt)
    ? Number(caseDetails?.heightIn)
      ? `${caseDetails.heightFt} ft ${caseDetails.heightIn} in`
      : `${caseDetails.heightFt} ft`
    : null;

  const weightInfo = Number(caseDetails?.weight)
    ? `${caseDetails.weight} lbs`
    : null

  const mapGenderKeyToGender = (genderKey) => {
    switch (genderKey) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "T":
        return "Trans";
      case "O":
        return "Other";
      default:
        return "";
    }
  };
  return (
    <Form name="case-details-patient">
      <Row className="case-patient-details">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Patient Id">
            <StyledText strong>
              {patient && patient._id ? patient._id : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label="First Name">
            <StyledText strong>
              {patient && patient.firstName
                ? patient.firstName.toUpperCase()
                : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Last Name">
            <StyledText strong>
              {patient && patient.lastName
                ? patient.lastName.toUpperCase()
                : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Age">
            <StyledText strong>
              {patient && patient.age ? patient.age : ""}
            </StyledText>
          </Form.Item>
        </Col>

        {!!heightInfo && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Height">
              <StyledText strong>{heightInfo}</StyledText>
            </Form.Item>
          </Col>
        )}
        {!!weightInfo && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Weight">
              <StyledText strong>{weightInfo}</StyledText>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Gender">
            <StyledText strong>
              {patient && patient.gender
                ? mapGenderKeyToGender(patient.gender.toUpperCase())
                : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="DOB">
            <StyledText strong>
              {patient && patient.dob ? dateOfBirthFormatter(patient.dob) : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email">
            <StyledText strong>
              {patient && patient.email ? patient.email : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="First Address">
            <StyledText strong>
              {patient && patient.address1 ? patient.address1 : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Second Address">
            <StyledText strong>
              {patient && patient.address2 ? patient.address2 : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="City">
            <StyledText strong>
              {patient && patient.city ? patient.city : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="State">
            <StyledText strong>
              {patient && patient.state ? patient.state : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Zip Code">
            <StyledText strong>
              {patient && patient.zipCode ? patient.zipCode : ""}
            </StyledText>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Phone">
            <StyledText strong>
              {patient && patient.phone ? patient.phone : ""}
            </StyledText>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CasePatientDetails;
