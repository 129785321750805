/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Row, Col, Tooltip, Menu, Dropdown, message } from "antd";
import { SendOutlined, CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import styled from "styled-components";


//import components
import MessageComponent from "../CaseMessageComponent/MessageComponent";
import isValidUrl from "../../utils/isValidURL";

const { TextArea } = Input;

const StyledMessageWrapper = styled.div`
  height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  background: #e8ecef;
  width: 100%;
`;

const StyledNoMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  background: #e8ecef;
`;

const StyledButton = styled(Button)`
  color: #49548d;
  height: 100%;
  width: 100%;
`;

const AdminPhysicianChatListingComponent = ({
  roomId,
  authorId,
  sendMessage,
  messages,
  clearTicketNotifications,
  updateTicket
}) => {
  const [messageEntered, setMessageEntered] = useState("");
  const messageListingWrapperRef = useRef(null);

  // handling message sending
  const handleMessageSendButtonClick = (event) => {
    event.stopPropagation();
    if (messageEntered) {
      const data = {};
      data.chatRoomId = roomId;
      data.author = authorId;
      data.message = messageEntered;
      data.type = "text";
      data.key = null;
      sendMessage(data);
      setMessageEntered("");
    } else {
      return false;
    }
  };

  const handleMessageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const handleTextAreaChange = (event) => {
    event.stopPropagation();
    setMessageEntered(event.target.value);
  };

  const handleTextAreaFocus = (event) => {
    event.stopPropagation();
    clearTicketNotifications();
  };

  const scrollToBottom = () => {
    messageListingWrapperRef.current.scrollTop =
      messageListingWrapperRef.current.scrollHeight;
  };

  const handleUpdateTicketStatus = async status => {
    try {
      await updateTicket({ status, ticketId: roomId })
      message.success(`Ticket successfully closed as ${status}`)
    } catch (e) {
      message.error('Error closing ticket')
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getName = ({ email, firstName, lastName, type } = {}) => {
    if(email === authorId) {
      return "You"
    }
    const fullName = `${firstName} ${lastName}`
    return type ? fullName + " : " + type : fullName;
  }

  return (
    <>
      <StyledMessageWrapper ref={messageListingWrapperRef}>
        {messages.data?.length ? (
          messages.data.map((eachMessage) => (
            <MessageComponent
              key={eachMessage._id}
              name={getName(eachMessage.chatOwnerDetails)}
              avatarUrl={
                isValidUrl(eachMessage.chatOwnerDetails.profileImage) &&
                eachMessage.chatOwnerDetails.profileImage
              }
              time={
                eachMessage.createAt
                  ? moment(eachMessage.createAt).format("LLL")
                  : moment().format("LLL")
              }
              messageText={eachMessage.message}
              imageUrl={eachMessage.key || null}
              handleMessageClick={handleMessageClick}
            />
          ))
        ) : (
          <StyledNoMessageWrapper>
            <span>No messages yet</span>
          </StyledNoMessageWrapper>
        )}
      </StyledMessageWrapper>
      <Row>
        <Col span={19}>
          <TextArea
            value={messageEntered}
            placeholder="Send a message"
            onChange={handleTextAreaChange}
            autoSize={{ minRows: 2, maxRows: 2 }}
            onFocus={handleTextAreaFocus}
          />
        </Col>
        <Col span={2}>
          <Tooltip placement="topLeft" title="Send Message">
            <StyledButton
              type="secondary"
              onClick={handleMessageSendButtonClick}
              icon={<SendOutlined />}
            />
          </Tooltip>
        </Col>
        <Col span={3}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleUpdateTicketStatus('closed')}>Closed</Menu.Item>
                <Menu.Item onClick={() => handleUpdateTicketStatus('solved')}>Solved</Menu.Item>
              </Menu>
            }
            icon={<CheckCircleOutlined />}
        >
          <StyledButton>Close as<DownOutlined /></StyledButton>
        </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default AdminPhysicianChatListingComponent;
