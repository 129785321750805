import axios from 'axios'
import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config
let source

export const create = (type, data) => axiosInstance.post(
	`${apiAppendPath.templatesAppendPath}`,
	data,
	{ params: { type } }
)

export const getMany = params => {

	source?.cancel();
	source = axios.CancelToken.source();

	try {
		return axiosInstance.get(
			`${apiAppendPath.templatesAppendPath}`,
			{ 
				params,
				cancelToken: source.token
			}
		)
	} catch (e) {
		if (!axios.isCancel(e)) throw e; 
	}
}


export const getOne = (id) => axiosInstance.get(
	`${apiAppendPath.templatesAppendPath}/${id}`
)

export const deleteOne = (id) => axiosInstance.delete(
	`${apiAppendPath.templatesAppendPath}/${id}`
)

export const update = (id, data) => axiosInstance.put(
	`${apiAppendPath.templatesAppendPath}/${id}`,
	data
)