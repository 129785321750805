import React, { useState, useEffect } from "react";
import { Select } from "antd";
import styled from "styled-components";
import { CaseStatus } from "../../constants/CaseStatus";

const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 100%
`

const UpdateStatusModal = ({ setBulkUpdateParams }) => {
  const [selectedStatus, setSelectedStatus] = useState('consult complete');

  useEffect(() => setBulkUpdateParams({ newStatus: selectedStatus }), [])

  const handleSelectStatus = status => {
    setSelectedStatus(status)
    setBulkUpdateParams({ newStatus: status })
  }

  const statusOptions = Object.values(CaseStatus).sort().map((val) => (
    <Option style={{ textTransform: "capitalize" }} key={val} value={val}>{val}</Option>
  ))

  return (
      <StyledSelect
          value={selectedStatus}
          onChange={handleSelectStatus}
      >
        {statusOptions}
      </StyledSelect>
  );
};

export default UpdateStatusModal;
