import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config

export const addLoadBalancerConfiguration = data => {
    return axiosInstance.post(
        `${apiAppendPath.loadBalancerAppendPath}/load-balancer`,
        data
    );
};

export const getLoadBalancerConfigurationDetails = (paramsData) => {
    return axiosInstance.get(
        `${apiAppendPath.loadBalancerAppendPath}/load-balancer`,{params:paramsData}
    )
}

export const getStateBasedLoadBalancerConfigurationList = () => {
    return axiosInstance.get(
        `${apiAppendPath.loadBalancerAppendPath}/get-states`
    )
}