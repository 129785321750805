/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  TimePicker,
  Card,
  Button,
  Collapse,
  Select,
  Modal,
  message,
  Tooltip
} from "antd";
import {
  EditFilled,
  FileDoneOutlined,
  DeleteFilled,
  FieldTimeOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";

const createSchedule = (availability, timeZone) => Object.entries(availability.reduce((schedule, timeFrame, index) => {
  const _id = timeFrame._id;
  const startTime = moment(timeFrame.startTime).toDate();
  const endTime = moment(timeFrame.endTime).toDate();
  const isoDay = moment(startTime).tz(timeZone).isoWeekday();

  if (!(isoDay in schedule)) {
    schedule[isoDay] = [];
  }

  schedule[isoDay].push({ startTime, endTime, index, _id });

  return schedule;
}, {}))
  .map(([isoDay, timeFrames]) => {
    return [
      isoDay,
      timeFrames.sort((timeFrameA, timeFrameB) => timeFrameA.startTime < timeFrameB.startTime ? -1 : 1)
    ]
  })
  .sort(([isoDayA], [isoDayB]) => isoDayA < isoDayB ? -1 : 1)

const DEFAULT_NEW_ISO_DAY = '1';

const GeneralDays = ({ generalAvailability, addTimeFrame, updateTimeFrame, deleteTimeFrame, timeZone }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newISODay, setNewISODay] = useState(DEFAULT_NEW_ISO_DAY);
  const [newStartTimeMoment, setNewStartTimeMoment] = useState();
  const [newEndTimeMoment, setNewEndTimeMoment] = useState();

  const schedule = createSchedule(generalAvailability, timeZone);


  const resetEditState = () => {
    setIsEditing(!isEditing);
    setNewISODay(DEFAULT_NEW_ISO_DAY);
    setNewStartTimeMoment();
    setNewEndTimeMoment();
  }

  return (
    <Card style={{ margin: '20px 0' }}>
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>General</h2>
        <div>
          <Button
            type='primary'
            onClick={() => resetEditState()}
            icon={isEditing ? <FileDoneOutlined /> : <EditFilled />}
          >
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        </div>
      </section>

      <Collapse className='general-availability-list' defaultActiveKey={[1, 2, 3, 4, 5, 6, 7]}>
        {
          isEditing
            ? schedule.map(([isoDay, timeFrames]) => (
              <Collapse.Panel header={moment().isoWeekday(+isoDay).format('dddd')} key={+isoDay}>
                {timeFrames.map(timeFrame => (
                  <div key={timeFrame._id}>
                    <TimePicker
                      showNow={false}
                      showSecond={false}
                      allowClear={false}
                      minuteStep={30}
                      use12Hours
                      format='hh:mm A'
                      value={moment(timeFrame.startTime).tz(timeZone)}
                      onChange={(startTimeMoment) => {
                        const endTimeMoment = moment(timeFrame.endTime);
                        const startTime = startTimeMoment.toISOString()
                        const endTime = startTimeMoment.hour() < endTimeMoment.hour()
                          ? endTimeMoment.day(startTimeMoment.day()).toISOString()
                          : moment(startTimeMoment).add({ hour: 24 - (startTimeMoment.hour() - endTimeMoment.hour()) }).toISOString();

                        updateTimeFrame('general', timeFrame._id, { startTime, endTime })
                      }}
                    />
                    <TimePicker
                      showNow={false}
                      showSecond={false}
                      minuteStep={30}
                      allowClear={false}
                      use12Hours
                      format='hh:mm A'
                      value={moment(timeFrame.endTime).tz(timeZone)}
                      onChange={(endTimeMoment) => {
                        const startTimeMoment = moment(timeFrame.startTime)

                        const startTime = timeFrame.startTime.toISOString();
                        const endTime = startTimeMoment.hour() < endTimeMoment.hour()
                          ? endTimeMoment.day(startTimeMoment.day()).toISOString()
                          : moment(startTimeMoment).add({ hours: 24 - (startTimeMoment.hour() - endTimeMoment.hour()) }).toISOString();

                        updateTimeFrame('general', timeFrame._id, { startTime, endTime })
                      }}
                    />
                    <Tooltip title='delete time frame'>
                      <DeleteFilled
                        style={{ marginLeft: 10, cursor: 'pointer', color: 'grey' }}
                        onClick={() => Modal.confirm({
                            title: `Cancel General Time Frame`,
                            centered: true,
                            content: (
                              <div style={{ fontSize: '.9rem' }}>
                                {moment(timeFrame.startTime).tz(timeZone).format('ddd h:mm A')} - {moment(timeFrame.endTime).tz(timeZone).format('ddd h:mm A')}
                              </div>
                            ),
                            onOk: () => {
                              deleteTimeFrame('general', timeFrame._id);
                            }
                          })
                        }
                      />
                    </Tooltip>
                  </div>
                ))}
              </Collapse.Panel>
            ))
            : schedule.map(([isoDay, timeFrames]) => (
              <Collapse.Panel header={moment().isoWeekday(+isoDay).format('dddd')} key={+isoDay}>
                {timeFrames.map(timeFrame => (
                  <div key={timeFrame.index}>
                    {moment(timeFrame.startTime).tz(timeZone).format('hh:mm A')}
                    &nbsp;-&nbsp;
                    {moment(timeFrame.endTime).tz(timeZone).format('hh:mm A')}
                    <FieldTimeOutlined style={{ marginLeft: 10 }} />
                  </div>
                ))}
              </Collapse.Panel>
            ))
        }
      </Collapse>
      {isEditing && (
        <section style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select value={newISODay} onChange={setNewISODay}>
              <Select.Option value='1'>Monday</Select.Option>
              <Select.Option value='2'>Tuesday</Select.Option>
              <Select.Option value='3'>Wednesday</Select.Option>
              <Select.Option value='4'>Thursday</Select.Option>
              <Select.Option value='5'>Friday</Select.Option>
              <Select.Option value='6'>Saturday</Select.Option>
              <Select.Option value='7'>Sunday</Select.Option>
            </Select>
            <TimePicker
              showNow={false}
              showSecond={false}
              allowClear={false}
              minuteStep={30}
              use12Hours
              format='hh:mm A'
              placeholder='start time'
              value={newStartTimeMoment?.tz(timeZone)}
              onChange={setNewStartTimeMoment}
            />
            <TimePicker
              showNow={false}
              showSecond={false}
              allowClear={false}
              disabled={!newStartTimeMoment}
              minuteStep={30}
              use12Hours
              format='hh:mm A'
              placeholder='end time'
              value={newEndTimeMoment?.tz(timeZone)}
              onChange={setNewEndTimeMoment}
            />
            <Button
              type='primary'
              icon={<PlusCircleOutlined />}
              onClick={() => {
                if (!newStartTimeMoment || !newEndTimeMoment) return message.error('please select a start time and end time');
                const startTime = moment(newStartTimeMoment).isoWeekday(+newISODay).second(0).millisecond(0).toISOString();
                const endTime = newStartTimeMoment.hour() < newEndTimeMoment.hour()
                  ? moment(startTime).set({ hour: newEndTimeMoment.hour(), minute: newEndTimeMoment.minute() })
                  : moment(startTime).add({ hour: 24 - (newStartTimeMoment.hour() - newEndTimeMoment.hour()) })

                addTimeFrame('general', { startTime, endTime });
              }}
            >
              Add
            </Button>

          </div>
        </section>
      )}
    </Card>
  )
};

export default GeneralDays;