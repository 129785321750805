import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const searchMedications = (searchString) => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/medications`, {
        params: { name: searchString },
    });
}

export const getMedicationByNameAndStrength = (name, strength) => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/medications/medication`, {
        params: { name, strength },
    });
}

export const getMedicationByDispensableDrugId = (dispensableDrugId) => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/medications/${dispensableDrugId}`);
}

export const getAllAvailableDispenseUnits = () => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/medications/dispense-units`);
}

export const getPatientPrescriptionHistory = patientId => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/patient/${patientId}?source=front-end`);
}

export const updatePatient = (patientId, update) => {
    return axiosInstance.put(`${apiAppendPath.prescriptionsAppendPath}/patient/${patientId}`, {
        data: update,
    });
}

export const updatePatientDefaultPharmacy = (patientId, pharmacyId) => {
    return axiosInstance.put(`${apiAppendPath.prescriptionsAppendPath}/patient/${patientId}/default-pharmacy`, {
        data: { pharmacyId }
    });
}

export const getRxErrors = () => {
    return axiosInstance.get(`${apiAppendPath.prescriptionsAppendPath}/rx-errors`);
}

export const acknowledgeError = errorId => {
    return axiosInstance.put(`${apiAppendPath.prescriptionsAppendPath}/rx-errors/${errorId}`);
}

export const requeueErrorPrescription = prescriptionId => {
    return axiosInstance.put(`${apiAppendPath.prescriptionsAppendPath}/${prescriptionId}`);
}

export const rollBackCase = (caseId, errorId, providerId) => {
    return axiosInstance.put(`${apiAppendPath.prescriptionsAppendPath}/rx-errors/rollback/${caseId}`, {
        data: { caseId, errorId, providerId },
    });
}

export const cancelPrescription = prescriptionId => {
    return axiosInstance.delete(`${apiAppendPath.prescriptionsAppendPath}/${prescriptionId}`);
}


