/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { Typography, Button, Form, Row, Col, InputNumber, Select } from "antd";
import styled from "styled-components";

const StyledFormItemWrapper = styled(Form.Item)`
  text-align: right;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledPhysicianGroupNameWrapper = styled.span`
  text-transform: capitalize;
`;

const StyledButton = styled(Button)`
  margin-left: 12px;
`;

const { Title } = Typography;
const { Option } = Select;

const AddConfiguration = (props) => {
  const [form] = Form.useForm();
  const {
    onConfigurationAdd,
    physicianGroupList,
    editingConfiguration,
    edit,
    handleEditCancel,
    unconfiguredTimePeriods,
    selectPhysicianGroup,
    selectedPhysicianGroup
  } = props;
  const [fields, setConfigurationDetails] = useState([]);

  const onEditingConfigurationChange = () => {
    if (editingConfiguration) {
      const {
        physicianGroupDetails: detailsOfPhysicianGroup,
        turnAroundTime,
        internalTurnAroundTime,
        physicianGroupId,
        physicianGroupName,
      } = editingConfiguration;
      let mappedPhysicianGroupDetails = {};
      if (
        detailsOfPhysicianGroup &&
        Object.keys(detailsOfPhysicianGroup).length
      ) {
        mappedPhysicianGroupDetails = detailsOfPhysicianGroup;
      } else {
        mappedPhysicianGroupDetails = {
          key: physicianGroupId,
          physicianGroupId: physicianGroupId,
          physicianGroupName: physicianGroupName,
        };
      }

      setConfigurationDetails([
        {
          name: ["physicianGroupDetails"],
          value: mappedPhysicianGroupDetails
            ? mappedPhysicianGroupDetails
            : physicianGroupId,
        },
        {
          name: ["turnAroundTime"],
          value: turnAroundTime,
        },
        {
          name: ["internalTurnAroundTime"],
          value: internalTurnAroundTime,
        },
      ]);
    }
  };

  useEffect(onEditingConfigurationChange, [editingConfiguration]);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line
      number: "${label} is not a validate number!",
    },
  };

  const handleConfigurationChange = ({ physicianGroupDetails, period, quantity }) => {
    if (onConfigurationAdd && typeof onConfigurationAdd === "function") {
      return onConfigurationAdd({ physicianGroupDetails, period, quantity });
    } 
  };

  const handleConfigurationEditCancel = () => {
    if (edit) {
      if (handleEditCancel && typeof handleEditCancel === "function") {
        handleEditCancel();
      }
    }
  };

  const populateOptions = () => {
    return physicianGroupList.map(({ physicianGroupName, physicianGroupId }) => (
      <Option
        key={physicianGroupName}
        value={physicianGroupId}
      >
        <StyledPhysicianGroupNameWrapper>
          {physicianGroupName}
        </StyledPhysicianGroupNameWrapper>
      </Option>
    ));
  };

  return (
    <article style={{ display: 'flex', alignItems: 'center'}}>
      <section style={{ width: '100%'}}>
        <Form
          {...layout}
          form={form}
          layout={"vertical"}
          name="add-edit-configuration"
          onFinish={handleConfigurationChange}
          fields={fields}
          onFieldsChange={(_, allFields) => setConfigurationDetails(allFields)}
          validateMessages={validateMessages}
        >
            <Row>
                <Title level={4}>
                    {edit ? "Edit Configuration" : "Add Configuration"}
                </Title>
            </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="physicianGroupDetails"
                label="Physician Group"
                rules={[{ required: true }]}
              >
                <Select
                  size={"large"}
                  labelInValue
                  placeholder="Select physician group"
                  value={selectedPhysicianGroup}
                  onChange={selectPhysicianGroup}
                >
                  {populateOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="period"
                    label="Period"
                    rules={[{ required: true }]}
                >
                    <Select
                        size={"large"}
                        placeholder="Period"
                    >
                        { unconfiguredTimePeriods.map(period => (
                          <Select.Option 
                            style={{ textTransform: 'capitalize'}}
                            value={period}>
                              {period}
                          </Select.Option>
                        )) }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="quantity"
                label="Max cases per period"
                rules={[{ required: true, type: "number" }]}
              >
                <StyledInputNumber
                  size={"large"}
                  placeholder="Maximum cases per period"
                />
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={24}>
              <StyledFormItemWrapper wrapperCol={{ span: 24 }}>
                {edit ? (
                  <StyledButton
                    type="secondary"
                    size="large"
                    onClick={handleConfigurationEditCancel}
                  >
                    Cancel
                  </StyledButton>
                ) : (
                  <></>
                )}
                <StyledButton type="primary" size="large" htmlType="submit">
                  {edit ? "Save Changes" : "Add Configuration"}
                </StyledButton>
              </StyledFormItemWrapper>
            </Col>
          </Row>
        </Form>
      </section>
    </article>
  );
};

export default AddConfiguration;
