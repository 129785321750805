import React from "react";

import ReportList from "./ReportList";
import ReportAllocated from "./ReportAllocated";
import ReportPayments from "./ReportPayments";
import IncompleteCasesReport from "./IncompleteCasesReport";
import PhysiciansReportingDashboard from './PhysiciansReportingDashboard'
import PhysicianQueuePerformance from './PhysicianQueuePerformance'

const Report = ({
  selectedReport,
  reportPeriod,
  setReportPeriod,
  reportYear,
  setReportYear,
  reportPeriodNumber,
  setReportPeriodNumber,
  report,
  totalCount,
  filterParams,
  clearFilter,
  setFilterParams,
  handleFetchReport,
  loading,
}) => {
  if (selectedReport) {
    switch (selectedReport) {
      case "custom-incomplete-cases":
        return (
          <IncompleteCasesReport
            report={report}
            count={totalCount}
            filterParams={filterParams}
            handleFetchReport={handleFetchReport}
          />
        );
      case "generated-physicians-case-count":
      case "custom-physicians-case-count":
      case "generated-clients-case-count":
      case "custom-clients-case-count":
        return (
          <ReportAllocated
            selectedReport={selectedReport}
            report={report}
            handleFetchReport={handleFetchReport}
            loading={loading}
          />
        );
      case "generated-physicians-reconciliation":
      case "custom-physicians-reconciliation":
        return (
          <ReportPayments
            selectedReport={selectedReport}
            report={report}
            totalCount={totalCount}
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
            reportYear={reportYear}
            setReportYear={setReportYear}
            reportPeriodNumber={reportPeriodNumber}
            setReportPeriodNumber={setReportPeriodNumber}
            filterParams={filterParams}
            clearFilter={clearFilter}
            handleFetchReport={handleFetchReport}
          />
        );
      case 'physician-reporting-dashboard':
        return <PhysiciansReportingDashboard />
      case 'physicians-queue-performance':
        return  <PhysicianQueuePerformance/>
      case "generated-consult-time-no-rx":
      case "custom-consult-time-no-rx":
      case "generated-consult-time":
      case "custom-consult-time":
      default:
        return (
          <ReportList
            selectedReport={selectedReport}
            report={report}
            totalCount={totalCount}
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
            reportYear={reportYear}
            setReportYear={setReportYear}
            reportPeriodNumber={reportPeriodNumber}
            setReportPeriodNumber={setReportPeriodNumber}
            filterParams={filterParams}
            clearFilter={clearFilter}
            handleFetchReport={handleFetchReport}
          />
        );
    }
  }
};

export default Report;
