import XLSX from 'xlsx-js-style'

export const generateSpreadsheet = (docName, filteredReport, parseItem) => {
    const reportType = docName.includes('client') ? 'Client' : 'Physician'

    // CREATE WORKBOOK AND WORKSHEET
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheet, docName);
    

    // CREATE AND ADD HEADERS
    const customMergeHeaders = [
      "",
      "Cases",
      "",
      "",
      "Renewals",
      "",
      "",
      "Rx Changes",
      "",
      ""
    ];

    const customHeader = [
      reportType,
      "Allocated",
      "Completed",
      "No Rx",
      "Allocated",
      "Completed",
      "No Rx",
      "Allocated",
      "Completed",
      "No Rx",
    ];

    XLSX.utils.sheet_add_aoa(worksheet, [customMergeHeaders, customHeader]);

    // BUILD DATA OBJECT
    const excelData = {}

    filteredReport.forEach(data => {
      const parsedData = parseItem(data)

      excelData[data[reportType.toLowerCase()]] = { 
        header: data[reportType.toLowerCase()], 
        casesAllocated: '', 
        casesCompleted: '', 
        casesNoRx: '', 
        renewalsAllocated: '', 
        renewalsCompleted: '', 
        renewalsNoRx: '', 
        rxChangesAllocated: '', 
        rxChangesCompleted: '', 
        rxChangesNoRx: '' 
      }

      parsedData.forEach(modality => {
        const { key, type } = modality
        delete modality.key
        delete modality.type
        excelData[key] = { header: `     ${type}`, ...modality }
      })
    })

    // APPLY DATA OBJECT
    XLSX.utils.sheet_add_json(worksheet, Object.values(excelData), { skipHeader: true, origin: -1 });

    // MERGE TOP-LEVEL HEADERS
    worksheet["!merges"] = [
      { s: { c: 1, r: 0 }, e: { c: 3, r: 0 } },
      { s: { c: 4, r: 0 }, e: { c: 6, r: 0 } },
      { s: { c: 7, r: 0 }, e: { c: 9, r: 0 } },
    ];

    // STYLE TABLE
    const range = XLSX.utils.decode_range(worksheet["!ref"] ?? "");
    const columnCount = range.e.c;
    const rowCount = range.e.r

    const mainHeaderStyle = {
      alignment: { horizontal: "center" }, 
      fill: { fgColor: { rgb: "7BAD7E" } },
      font: { bold: true, color: { rgb: "FFFFFF" } },
      border: { left: { color: { rgb: 'FFFFFF'} } }
    }

    const subHeaderStyle = {
      alignment: { horizontal: "center" }, 
      fill: { fgColor: { rgb: "F4F4F4" } },
      font: { bold: true, color: { rgb: "848C94" } },
      border: { left: { color: { rgb: 'FFFFFF'} } }
    }

    const physicianLineStyle = {
      alignment: { horizontal: "center" }, 
      fill: { fgColor: { rgb: "A2BD9E" } },
      font: { bold: true, color: { rgb: "FFFFFF" } }
    }

    const modalityLineStyle = {
      alignment: { horizontal: "center" }, 
      fill: { fgColor: { rgb: "D7E3D5" } },
      font: { bold: true, color: { rgb: "666666" } }
    }

    const totalLineStyle = {
      alignment: { horizontal: "center" }, 
      fill: { fgColor: { rgb: "BDD0BA" } },
      font: { bold: true, color: { rgb: "666666" } }
    }

    for (let col = 0; col <= columnCount; col++) {
      const headerCellRef = XLSX.utils.encode_cell({ r: 0, c: col });
      worksheet[headerCellRef] && (worksheet[headerCellRef].s = mainHeaderStyle)
      const subHeaderCellRef = XLSX.utils.encode_cell({ r: 1, c: col });
      worksheet[subHeaderCellRef] && (worksheet[subHeaderCellRef].s = subHeaderStyle)
    }

    for (let row = 2; row <= rowCount; row++) {
      for (let col = 0; col <= columnCount; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
        if ( worksheet[cellRef]) {
          if ((row - 2) % 4 === 0) worksheet[cellRef].s = physicianLineStyle
          else if ((row - 2) % 4 !== 3) worksheet[cellRef].s = modalityLineStyle
          else worksheet[cellRef].s = totalLineStyle
        }
      }
    }

    // DOWNLOAD FILE
    XLSX.writeFile(workbook, `${docName}.xlsx`);
}