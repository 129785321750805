import React, { useEffect, useState } from "react";
import {
  List,
  Button,
  Modal,
  Form,
  DatePicker,
  message,
  Typography,
} from "antd";
import { EditFilled, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import moment from 'moment-timezone';
import _ from "lodash";

// import APIs
import { updateScheduleDays } from "../../api/PhysiciansAPI";

//import components
import { StyledAvailabilityCardComponent } from "../../components/ScheduleComponent";

const { Title } = Typography;

const OffDays = ({ offDay, setdataEdited, physicianId,offSet }) => {
  const [editDays, setEditDays] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [offDayView, setOffDayView] = useState();
  const [offDays, setOffDays] = useState(offDayView);
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const changeData = [];

    for (let key in offDay) {
      changeData.push({
        day: moment(offDay[key]).format("dddd"),
        date: offDay[key],
      });
    }

    setOffDayView(changeData);
  }, [offDay]);

  useEffect(() => {
    setOffDays(offDayView);
  }, [offDayView]);

  const editOffDays = () => {
    setEditDays(false);
  };

  const addOffDays = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleModalOk = () => {
    const formData = form.getFieldsValue();
    const changeData = offDays;
    if (formData.day) {
      changeData.push({
        day: moment(formData.day).format("dddd"),
        date: moment(formData.day).format("YYYY-MM-DD"),
      });
      setOffDays(changeData);
      form.resetFields();
      setModalVisible(false);
    }
  };

  const deleteOffDays = (date) => {
    setOffDays(offDays.filter((item) => item.date !== date));
  };

  const editCancel = () => {
    setEditDays(true);
    const changeData = [];
    for (let key in offDay) {
      changeData.push({
        day: moment(offDay[key]).format("dddd"),
        date: offDay[key],
      });
    }
    setOffDayView(changeData);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    if (
      (current && current < moment().endOf("day")) ||
      offDays.find((d) => moment(current).format("YYYY-MM-DD") === d.date)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const submitData = () => {
    const changeData = [];
    setButtonLoading(true);
    for (let key in offDay) {
      changeData.push({
        day: moment(offDay[key]).format("dddd"),
        date: offDay[key],
      });
    }

    const requestData = {};
    const offresDays = [];
    offDays.forEach((element) => {
      offresDays.push(element.date);
    });

    if (!_.isEqual(offDays, changeData)) {
      requestData.physicianId = physicianId;
      requestData["schedule"] = offresDays;
      requestData["schedule-type"] = "offdays";
      requestData["offSet"] = offSet;

      updateScheduleDays(requestData)
        .then((res) => {
          if (res.data.success) {
            setdataEdited();
            setEditDays(true);
            message.success("SuccessFully Updated");
            setButtonLoading(false);
          } else {
            message.error(res.data.message);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          const { response } = error;
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable to update Offdays, Please try again !");
            }
          } else {
            message.error("Unable to update Offdays, Please try again !");
          }
        });
    } else {
      message.info("Please provide changes before you save");
      setButtonLoading(false);
    }
  };

  return (
    <StyledAvailabilityCardComponent>
      <div className="off-availability-header">
        <Title level={3}>
          <b>Off Days</b>
        </Title>
        <Button type="primary" onClick={() => editOffDays()}>
          <EditFilled />
          Edit
        </Button>
      </div>

      <div className="general-availability-button-add">
        {!editDays && (
          <Button type="primary" onClick={() => addOffDays()}>
            <PlusOutlined />
            Add
          </Button>
        )}
      </div>

      <Modal
        title="Add General Days"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="day" label="Select Off Days" span={24}>
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item>
        </Form>
      </Modal>

      <List
        className="general-availability-list"
        itemLayout="horizontal"
        dataSource={offDays}
        renderItem={(item) => (
          <List.Item
            actions={[
              !editDays && (
                <DeleteFilled
                  style={{ fontSize: "18px", color: "#7BAD7E" }}
                  onClick={() => deleteOffDays(item.date)}
                />
              ),
            ]}
          >
            <Title level={4} style={{ margin: "unset", width: 200 }}>
              {item.day}
            </Title>
            <Title level={4} style={{ margin: "unset", width: 200 }}>
              {moment.utc(item.date).local().format("MMMM Do YYYY")}
            </Title>
          </List.Item>
        )}
      />

      <div className="general-availability-button">
        {!editDays && (
          <>
            <Button type="primary" size="large" onClick={() => editCancel()}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              loading={buttonLoading}
              onClick={() => submitData()}
            >
              Save Changes
            </Button>
          </>
        )}
      </div>
    </StyledAvailabilityCardComponent>
  );
};

export default OffDays;
