/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  Spin,
  Alert,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

//import icons
import LogoIcon from "../assets/icons/LogoIcon";

//import functions
import { passwordReset } from "../api/AuthAPI";
import auth from "../auth/authorization";
import { isValidPassword, PasswordChecker } from "../components/PasswordChecker/PasswordChecker";

const { Content } = Layout;
const { Title } = Typography;

const StyledOuterLayout = styled(Layout)`
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled(Content)`
  flex: unset;
`;

const StyledComponentWrapper = styled.div`
  width: 100%;
  max-width: 514px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledTitleLevel = styled(Title)`
  text-align: center;
`;

const StyledAlert = styled(Alert)`
  margin: 16px 0;
`;

const StyledFormItem = styled(Form.Item)`
  text-align: center;
`;

const StyledForgetPasswordButton = styled(Button)`
  margin-left: 8px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPasswordComponent = () => {
  const history = useHistory();
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState('');

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    required: "Please enter your ${label}",
    types: {
      email: "${label} is not valid email!",
    },
  };

  const redirectToLogin = (type, displayMessage) => {
    message[type](displayMessage);
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const onFinish = (values) => {
    setLoading(true);
    const { password, confirmPassword } = values;
    passwordReset({
      password,
      confirmPassword,
    })
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(
            "Successfully Updated your password! Please login using the new password"
          );
          localStorage.clear();
          redirectToLogin(
            "success",
            "You will get redirected to the login page soon!"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Failed reset password! Please make sure your reset password request is valid"
            );
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Failed reset password! Please make sure your reset password request is valid"
            );
          }
        } else {
          message.error(
            "Failed reset password! Please make sure your reset password request is valid"
          );
        }
        setLoading(false);
      });
  };

  const handlePasswordResetCancel = () => {
    history.push("/login");
  };

  const tokenVerification = () => {
    console.log(
      "================================= token verification ================================"
    );
    const token = query.get("webtoken");
    if (token) {
      localStorage.setItem("resetToken", token);
      history.replace("/reset-password");
    } else {
      const resetTokenFromLocalStorage = localStorage.getItem("resetToken");
      if (!resetTokenFromLocalStorage) {
        message.error("We can't identify your password reset request");
        localStorage.clear();
        redirectToLogin(
          "error",
          "You will be redirected to the login page soon!"
        );
        return false;
      }
    }
    const decodedResetToken = auth.decodeResetToken();
    const { user } = decodedResetToken;
    if (user && user.type && user.name) {
      setUsername(user.name);
    } else {
      message.error("We can't identify your password reset request");
      redirectToLogin(
        "error",
        "You will be redirected to the login page soon!"
      );
    }
  };

  useEffect(() => {
    tokenVerification();
    return () => {
      localStorage.clear();
    };
  }, []);

  return (
    <StyledOuterLayout>
      <StyledContent>
        <StyledComponentWrapper>
          <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
            <Form
              {...layout}
              layout={"vertical"}
              name="login-form"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledTitle level={2}>
                <LogoIcon width="224px" height="80px" />
              </StyledTitle>
              <StyledTitleLevel level={3}>Reset Password</StyledTitleLevel>
              {username && (
                <StyledAlert message={`Hello! ${username}`} type="success" />
              )}
              <PasswordChecker password={newPassword} />
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter new password"
                      onChange={e => setNewPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="confirmPassword"
                    label="Confirm New Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Re-enter the new password"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <StyledFormItem wrapperCol={{ ...layout.wrapperCol }}>
                    <StyledForgetPasswordButton
                      size="large"
                      type="secondary"
                      onClick={handlePasswordResetCancel}
                    >
                      Cancel
                    </StyledForgetPasswordButton>
                    <StyledForgetPasswordButton
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={!isValidPassword(newPassword)}
                    >
                      Submit
                    </StyledForgetPasswordButton>
                  </StyledFormItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </StyledComponentWrapper>
      </StyledContent>
    </StyledOuterLayout>
  );
};

export default ResetPasswordComponent;
