//import packages
import React, { useState, useEffect } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  message,
  Tabs
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

//import components
import AddState from "./AddStates";
import EditState from "./EditState";
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import StateDetailsComponent from "../../../components/StateDetailsComponent/StateDetailsComponent";

//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";
import { getPharmacies } from "../../../api/PharmacyAPI";
import { addState, deleteState, getAllStates } from "../../../api/StatesAPI";
import authorization from "../../../auth/authorization";
import { BulkUpdate } from "./BulkUpdate";
import { Report } from "./Report";

const { confirm } = Modal;
const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 24px 28px;
`;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StateComponent = (props) => {
  const { userType } = props;
  const [states, setStates] = useState([]);
  const [allUSStates, setAllUSStates] = useState([]);
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [editId, setEditId] = useState("");
  const [listOfPharmacies, setListOfPharmacies] = useState([]);
  const [pharmacyLoading, setPharmacyLoading] = useState(false);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [stateDetailId, setStateDetailId] = useState(null);
  const [columns, setColumns] = useState([
    { title: "Name", dataIndex: "name", key: "name", width: 150 },
    {
      title: "View details",
      dataIndex: "d",
      key: "d",
      width: 120,
      align: "center",
      render: (userClients, record) => (
        <Button
          shape="circle"
          icon={<FileTextOutlined />}
          onClick={() => {
            singleStateView(record._id);
          }}
        />
      ),
    },
  ]);

  const updateColumnsInListing = () => {
    const clonnedColumns = columns.slice(0);
    if (authorization.isAllowed(userType, "updateState")) {
      clonnedColumns.push({
        title: "Edit",
        dataIndex: "",
        key: "e",
        width: 120,
        align: "center",
        render: (state, record) => (
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              onStateEdit(record.key);
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "deleteState")) {
      clonnedColumns.push({
        title: "Delete",
        dataIndex: "",
        key: "x",
        width: 120,
        align: "center",
        render: (state, record) => (
          <Button
            shape="circle"
            icon={<DeleteFilled />}
            onClick={() => {
              showDeleteConfirmation(state, record.key);
            }}
          />
        ),
      });
    }
    setColumns([...clonnedColumns]);
  };

  const consultationType = [
    {
      key: "store-and-forward",
      name: "STORE AND FORWARD",
      value: "store and forward",
    },
    { key: "audio", name: "AUDIO", value: "audio" },
    { key: "video", name: "VIDEO", value: "video" },
  ];

  const fetchPharmacies = (name) => {
    if (name) {
      setListOfPharmacies([]);
      setPharmacyLoading(true);

      getPharmacies(name)
        .then(({ data: { error, pharmacies } }) => {
            if (error || !pharmacies) return message.error("Unable to fetch Pharamacy List. Please try again!");
    
            const fetchPharmaciesList = pharmacies.map((eachPharmacy) => ({
              ...eachPharmacy,
              name: `${eachPharmacy.name} (${eachPharmacy.state})`,
              key: eachPharmacy.pharmacyId,
              value: eachPharmacy.pharmacyId,
              state: eachPharmacy.state,
            }));

            setListOfPharmacies(fetchPharmaciesList);
            message.success("Successfully fetched Pharamacy List");
        })
        .catch(({ response }) => {
          const errorMessage = "Error occured while fetching Pharamacy List. Please try again!"
          message.error(response?.data?.messsage || errorMessage)
        })
        .finally(() => setPharmacyLoading(false))
    }
  };

  const fetchStates = () => {
    lookUp("state").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setStates(stateData);
      }
    });
  };

  const fetchAllUSStates = () => {
    getAllStates("").then((res) => {
      if (res.data && res.data.payload) {
        const allStateData = res.data.payload.map((eachState) => ({
          name: eachState,
          key: `key-${eachState}`,
        }));
        setAllUSStates(allStateData);
      }
    });
  };
  const fetchTelecategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const telemedicineData = res.data.payload.map(
          (eachMedicineCategory) => ({
            ...eachMedicineCategory,
            key: eachMedicineCategory._id,
          })
        );
        setTelemedicineCategory(telemedicineData);
      }
    });
  };

  useEffect(() => {
    fetchStates();
    fetchTelecategory();
  }, []);

  const initCallsForComponent = () => {
    if (userType && authorization.isAllowed(userType, "searchState")) {
      fetchAllUSStates();
    }
    updateColumnsInListing();
  };

  // eslint-disable-next-line
  useEffect(initCallsForComponent, [userType]);

  const onAddingState = async (stateDetails) => {
    try {
      const { data } = await addState(stateDetails);
      if (!data?.success) throw new Error(data.message || "Unable to add State. Please try again!");
      fetchStates();
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message)
    }
  }

  const onDeletingState = (stateId) => {
    deleteState(stateId)
      .then((res) => {
        const { status } = res;
        if (status && status === 204) {
          message.success("Successfully Deleted State");
          setEditId();
        } else {
          const { data } = res;
          if (data && data.success) {
            message.success("Successfully Deleted State");
            setEditId();
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable to delete State. Please try again!");
            }
          }
        }
        fetchStates();
      })
      .catch((error) => {
        console.log(
          "================== delete state error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete state. Please try again!");
          }
        } else {
          message.error("Unable to delete state. Please try again!");
        }
      });
  };

  const showDeleteConfirmation = (state, stateId) => {
    confirm({
      title: `Do you want to delete ${state.name} state?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        onDeletingState(stateId);
      },
      onCancel() {},
    });
  };

  const onStateEdit = (stateId) => {
    setEditId(stateId);
  };

  const singleStateView = (id) => {
    setStateDetailId(id);
    setViewDetailModal(true);
  };

  return (
    <>
      <Content>
        <StyledTabWrapper>
        <StyledTabs defaultActiveKey="add-edit" type='card'>
          <Tabs.TabPane key='add-edit' tab='Add/Edit States'>
              {authorization.isAllowed(userType, "updateState") ||
                authorization.isAllowed(userType, "createState") ? (
                <StyledComponentWrapper>
                  {editId && authorization.isAllowed(userType, "updateState")
                    ? (
                      <EditState
                        stateId={editId}
                        afterStateEdit={onStateEdit}
                        fetchStatesAfterUpdate={fetchStates}
                        states={allUSStates}
                        telemedicineCategory={telemedicineCategory}
                        consultationType={consultationType}
                        listOfPharmacies={listOfPharmacies}
                        fetchPharmacies={fetchPharmacies}
                        pharmacyLoading={pharmacyLoading}
                      />
                    ) : (
                      <>
                        {authorization.isAllowed(userType, "createState") ? (
                          <AddState
                            addState={onAddingState}
                            states={allUSStates}
                            telemedicineCategory={telemedicineCategory}
                            consultationType={consultationType}
                            listOfPharmacies={listOfPharmacies}
                            fetchPharmacies={fetchPharmacies}
                            pharmacyLoading={pharmacyLoading}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                </StyledComponentWrapper>
              ) : (
                <></>
              )}
              <StyledComponentWrapper>
                <Title level={2}>States</Title>
                <Row>
                  <Col span={24}>
                    <Table columns={columns} dataSource={states} pagination={false} />
                  </Col>
                </Row>
              </StyledComponentWrapper>
              <Modal
                destroyOnClose
                width={1000}
                visible={viewDetailModal}
                onOk={() => {
                  setViewDetailModal(false);
                }}
                onCancel={() => setViewDetailModal(false)}
                footer={[]}
              >
                <StateDetailsComponent stateDetailId={stateDetailId} />
              </Modal>
            </Tabs.TabPane>
            <Tabs.TabPane key='bulk-update' tab='Bulk Update'>
              <StyledComponentWrapper>
                <BulkUpdate states={states} fetchStates={fetchStates} categories={telemedicineCategory}/>
              </StyledComponentWrapper>
            </Tabs.TabPane>

            <Tabs.TabPane key='report' tab='Report'>
              <StyledComponentWrapper>
                <Report states={states} categories={telemedicineCategory} />
              </StyledComponentWrapper>
            </Tabs.TabPane>
          </StyledTabs>

        </StyledTabWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(StateComponent);
