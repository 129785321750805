import jwtDecode from 'jwt-decode';
import moment from 'moment';
import userRolesAndRights from "../constants/userRolesAndRights";

class Auth {
    decodeToken() {
        const authToken = localStorage.getItem('userToken');
        let decodedToken = {};
        try {
            decodedToken = jwtDecode(authToken);
        } catch (error) {
            console.log("=========================== Token decoding error ==========================", error);
            return {};
        }
        return decodedToken;
    }

    decodeResetToken() {
        const resetToken = localStorage.getItem('resetToken');
        let decodedResetToken = {};
        try {
            decodedResetToken = jwtDecode(resetToken);
        } catch (error) {
            console.log("=========================== Reset Token decoding error ==========================", error);
            return {};
        }
        return decodedResetToken;
    }

    isAuthenticated() {
        const authToken = localStorage.getItem('userToken');
        if (!authToken) {
            return false;
        }
        const decodedToken = this.decodeToken();
        const { user, exp } = decodedToken;
        if (decodedToken && user && exp && (moment(exp * 1000).diff(moment(), "minutes") > 10)) {
            return true;
        }
        return false;
    }

    getUserType() {
        const decodedToken = this.decodeToken();
        const { user } = decodedToken;
        if (user && user.type) {
            return user.type;
        }
        return "no user";
    }

    getUserId() {
        const decodedToken = this.decodeToken();
        const { user } = decodedToken;
        if (user && user._id) {
            return user._id;
        }
        return "no user";
    }

    getUserDetails() {
        const decodedToken = this.decodeToken();
        const { user } = decodedToken;
        if (user && user.type && user.email) {
            return {
                userType: user.type,
                userEmail: user.email,
            };
        }
        return {
            userType: "",
            userEmail: "",
        };
    }

    isAllowed(userType, featureRight) {
        const userRights = userRolesAndRights[userType].rights;
        return userRights.includes(featureRight);
    }

    hasAccessToModule(userType, featureRightsList) {
        if(featureRightsList && featureRightsList.length === 0 && userType === "super admin") {
            return true;
        }
        const userRights = userRolesAndRights[userType].rights;
        return userRights.some(right => featureRightsList.includes(right));
    }

    getAllowedUsers(userType) {
        return userRolesAndRights[userType].allowedUsers;
    }
}

    export default new Auth();
