/* eslint-disable no-template-curly-in-string */
import React, { useState } from "react";
import styled from "styled-components";
import { Form, Spin, Input, Button, Row, Col, Typography, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isValidPassword, PasswordChecker } from "../PasswordChecker/PasswordChecker";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const ChangePasswordComponent = ({
  loadingIndicator,
  resetPasswordCallback,
}) => {
  // notification message configuration
  message.config({
    duration: 2.5,
  });

  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = useState('');
  const onFinish = (values) => {
    const { resetPassword } = values;
    const { currentPassword, newPassword, confirmNewPassword } = resetPassword;
    if (newPassword !== confirmNewPassword) {
      message.warn(
        "New Password does not matches with the Confirmed Password. Please retry!"
      );
      return false;
    }
    const passwordDetails = {
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    resetPasswordCallback(passwordDetails);
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
  };

  return (
    <Spin
      size="large"
      spinning={loadingIndicator}
      indicator={<LoadingOutlined />}
    >
      <Form
        {...layout}
        form={form}
        layout={"vertical"}
        name="reset-password"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Title level={2}>Change Password</Title>
        <PasswordChecker password={newPassword} />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["resetPassword", "currentPassword"]}
              label="Current Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type="password"
                placeholder="Enter Current Password"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={["resetPassword", "newPassword"]}
              label="New Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type="password"
                placeholder="Enter New Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={["resetPassword", "confirmNewPassword"]}
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      !value ||
                      getFieldValue(["resetPassword", "newPassword"]) === value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "The two new passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input
                size={"large"}
                type="password"
                placeholder="Confirm New Password"
              />
            </Form.Item>
          </Col>
          <ButtonContainer span={24}>
            <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
              <Button type="primary" size={"large"} htmlType="submit" disabled={!isValidPassword(newPassword)}>
                Change Password
              </Button>
            </Form.Item>
          </ButtonContainer>
        </Row>
      </Form>
    </Spin>
  );
};

export default ChangePasswordComponent;
