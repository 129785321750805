import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const addQuestionnaire = data => {
    return axiosInstance.post(
        `${apiAppendPath.questionnaireAppendPath}/question`,
        data
    );
};