import React, { useState } from "react";
import { Row, Col, Typography, Form, Tabs, Button, Tooltip, Input } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { StyledTitle } from "../../components/LayoutComponent";
import moment from 'moment';
import styled from 'styled-components';
import { addRxRequestNote, updateRxRequestNote } from "../../api/CasesAPI";

const { Text } = Typography;
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  & .ant-tabs-bar.ant-tabs-top-bar {
    padding-top: 0px;
  }

  & .ant-tabs-tabpane {
    display: flex;
  }

  & .ant-tabs-content {
    padding: 0px;
  }
`

const RxRequestNote = ({ caseId, request, fetchCaseDetails }) => {
  const [note, setNote] = useState(request.note?.content || '');

  const addNote = () => addRxRequestNote(caseId, { title: 'Admin Note', content: note, rxRequest: request }).then(fetchCaseDetails);
  const updateNote = () => updateRxRequestNote(request.note._id, { content: note }).then(fetchCaseDetails);

  return (
    <>
      <Input.TextArea
          strong
          className="sub-content-color"
          value={note}
          onChange={e => setNote(e.target.value)}
      />
      <Button 
        type='primary' 
        icon={<FormOutlined />}
        onClick={!!request.note?.content.length ? updateNote : addNote }
      >
        { !!request.note?.content.length ? 'Update' : 'Add' } Note
      </Button>
    </>
  )
}

const CaseRequestedMedicines = ({
  caseDetails,
  typeFilters,
  statusFilters,
  fetchCaseDetails,
  ...props
}) => {
  const typeFiltersSet = new Set(
    typeFilters || ["initial", "rx change", "rx renewal"]
  );
  const statusFiltersSet = new Set(statusFilters || []);

  const requests = (caseDetails?.requests || [])
    .filter(({ requestType, status }) => {
      return (
        typeFiltersSet.has(requestType) &&
        (!statusFiltersSet.size || statusFiltersSet.has(status))
      );
    })
    .sort((reqA, reqB) => new Date(reqB.createdAt) - new Date(reqA.createdAt));

  return (
    <>
      <Row className="case-details-row-wrapper">
        <Col span={24}>
          <StyledTitle level={4}>Requested Medicine</StyledTitle>
        </Col>
        <Col span={24}>
          {requests.length ? (
            <StyledTabs defaultActiveKey="0">
              {requests.map((request, i) => (
                <TabPane
                  key={i}
                  tab={
                    <span>
                      {request.requestType.toUpperCase()} {moment(request.createdAt).format("(MM/DD/YYYY)")} {!!request.status && `● ${request.status}`} 
                    </span>
                  }
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Tabs defaultActiveKey="0">
                    {request.medicines.map((medicine, j) => (
                      <TabPane tab={medicine.drugName} key={j}>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <Form.Item label="Drug Name">
                                  <Text strong className="sub-content-color">
                                    {medicine.drugName}
                                  </Text>
                                </Form.Item>
                              </Row>
                              <Row>
                                <Form.Item label="Dosage">
                                  <Text strong className="sub-content-color">
                                    {medicine.dosage}
                                  </Text>
                                </Form.Item>
                              </Row>
                              { medicine.dispense && <Row>
                                <Form.Item label="Dispense">
                                  <Text strong className="sub-content-color">
                                    {medicine.dispense}
                                  </Text>
                                </Form.Item>
                              </Row> }
                              <Row>
                                <Form.Item label="Refills">
                                  <Text strong className="sub-content-color">
                                    {medicine.refills}
                                  </Text>
                                </Form.Item>
                              </Row>
                              <Row>
                                <Form.Item label="Days Supply">
                                  <Text strong className="sub-content-color">
                                    {medicine.daysSupply}
                                  </Text>
                                </Form.Item>
                              </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Row>
                                <Form.Item label="Quantity">
                                  <Text strong className="sub-content-color">
                                    {medicine.pillCount}
                                  </Text>
                                </Form.Item>
                              </Row>
                              <Row>
                                <Form.Item label="NDC">
                                  <Text strong className="sub-content-color">
                                    {medicine.ndc}
                                  </Text>
                                </Form.Item>
                              </Row>
                              <Row>
                                <Form.Item
                                  label='Admin Note'
                                >
                                  <RxRequestNote caseId={caseDetails.caseId} request={request} fetchCaseDetails={fetchCaseDetails} />
                                </Form.Item>
                              </Row>
                              {request.reason && (
                                <Row>
                                  <Form.Item label="Reason Note">
                                    <Text strong className="sub-content-color">
                                      {request.reason}
                                    </Text>
                                  </Form.Item>
                                </Row>
                              )}
                              {request.openEndedRequest &&
                                Object.keys(request.openEndedRequest).map(
                                  (itemKey) => (
                                    <Row>
                                      <Form.Item
                                        label={itemKey}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <Text
                                          strong
                                          className="sub-content-color"
                                        >
                                          {
                                            caseDetails.openEndedRequest[
                                              itemKey
                                            ]
                                          }
                                        </Text>
                                      </Form.Item>
                                    </Row>
                                  )
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                    ))}
                  </Tabs>
                </TabPane>
              ))}
            </StyledTabs>
          ) : (
            <Col className="align-text-center" span={24}>
              <Text>Requested medicines unavailable</Text>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CaseRequestedMedicines;
