import React, { useState } from "react";
import { Card, Table, Button, DatePicker, Modal, message, Select } from 'antd';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
import moment from 'moment-timezone';


export const AVAILABILITY_TYPES = { OFF: 'off', SPECIAL: 'special' };
const OFF_REASONS = new Set(['vacation', 'sick', 'other']);
const TABLE_FILTERS = { PAST: 'past', FUTURE: 'future' };
const REASON_PLACEHOLDER = 'please select a reason';

const AvailabilityTable = ({ availabilityType, timeFrames, deleteTimeFrame, addTimeFrame, timeZone }) => {
  const [tableFilter, setTableFilter] = useState({ start: [TABLE_FILTERS.FUTURE] });
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState([]);
  const [reason, setReason] = useState(REASON_PLACEHOLDER);
  const [notes, setNotes] = useState('');

  const title = availabilityType[0].toUpperCase() + availabilityType.slice(1);

  const resetModalState = () => {
    setTableFilter({ start: [TABLE_FILTERS.FUTURE] });
    setIsSchedulingModalOpen(false);
    setDateTimeRange([]);
    setReason(REASON_PLACEHOLDER);
    setNotes('');
  }

  return (
    <Card style={{ margin: '20px 0' }}>
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>{title}</h2>
        <div>
          <Button
            type='primary'
            icon={<PlusCircleOutlined />}
            onClick={() => setIsSchedulingModalOpen(true)}
          >
            Add
          </Button>
        </div>
      </section>

      <Table
        onChange={(_, filters) => setTableFilter(filters)}
        columns={[
          ...(
            availabilityType === 'off'
              ? [{
                  key: 'reason',
                  dataIndex: 'reason',
                  title: 'Reason'
                },
                { 
                  key: 'notes',
                  dataIndex: 'notes',
                  title: 'Description'
                }]
              : []
          ),
          { 
            key: 'start', 
            dataIndex: 'start', 
            title: 'Start Time', 
            defaultSortOrder: 'ascend',
            filteredValue: tableFilter.start,
            filters: [
              { text: 'show past', value: TABLE_FILTERS.PAST }, 
              { text: 'show upcoming', value: TABLE_FILTERS.FUTURE }
            ], 
            sorter: ({ startTime: startTimeA }, { startTime: startTimeB }) => new Date(startTimeA) - new Date(startTimeB),
            onFilter: (value, record) => {
              const endTime = new Date(record.endTime);
              const now = new Date();
              if (value === TABLE_FILTERS.PAST) return endTime < now;
              if (value === TABLE_FILTERS.FUTURE) return now < endTime;
            }
          },
          { key: 'end', dataIndex: 'end' , title: 'End Time' },
          { 
            key: 'cancel', 
            title: 'Cancel', 
            render: (_, record) => (
              <DeleteFilled 
                onClick={() => Modal.confirm({
                  title: `Cancel ${title} Time Frame`,
                  centered: true,
                  content: (
                    <div style={{ fontSize: '.9rem' }}>
                      {record.start} - {record.end}
                    </div>
                  ),
                  onOk: () => {
                    deleteTimeFrame(availabilityType, record._id);
                  }
                })} 
              /> 
            )
          }
        ]}
        dataSource={timeFrames
          .map((timeFrame, index) => ({
            key: index,
            start: moment(timeFrame.startTime).tz(timeZone).format('MM-DD-YYYY h:mm A'),
            end: moment(timeFrame.endTime).tz(timeZone).format('MM-DD-YYYY h:mm A'),
            ...timeFrame
          }))
        }
      />
      <Modal 
        title={`Schedule ${title} Time Frame`}
        visible={isSchedulingModalOpen} 
        bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
        centered
        onCancel={() => resetModalState()}
        onOk={() => {
          if (dateTimeRange.length < 2) return message.error('please select a start and end');
          const [startTimeMoment, endTimeMoment] = dateTimeRange;
          const startTime = startTimeMoment.second(0).millisecond(0).toISOString();
          const endTime = endTimeMoment.second(0).millisecond(0).toISOString();
          const timeFrame = { startTime, endTime }

          if (availabilityType === AVAILABILITY_TYPES.OFF) {
            if (!OFF_REASONS.has(reason)) return message.error('must select a reason');
            timeFrame.reason = reason;
            timeFrame.notes = notes;
          }

          addTimeFrame(availabilityType, timeFrame);
          resetModalState();
        }}
      >
        <DatePicker.RangePicker 
          allowClear={false}
          showTime={{ format: 'hh:mm A', minuteStep: 30, use12Hours: true, showSecond: false }} 
          format='YYYY-MM-DD h:mm A'
          style={{ width: '100%' }}
          value={dateTimeRange}
          onChange={setDateTimeRange}
        />
        {availabilityType === AVAILABILITY_TYPES.OFF && (
          <>
            <Select value={reason} onChange={setReason} style={{ margin: '20px 0' }}>
              {[...OFF_REASONS.values()].map((value) => <Select.Option key={value} value={value}>{value}</Select.Option>)}
            </Select>
            <textarea value={notes} onChange={e => setNotes(e.target.value)} placeholder='notes' />
          </>
        )}
      </Modal>
    </Card>
  )
};

export default AvailabilityTable;