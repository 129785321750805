//import packages
import React from "react";
import { Layout, Tabs } from "antd";
import styled from "styled-components";
import AllocationChart from "./AllocationChart";

const { Content } = Layout;
const { TabPane } = Tabs;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;
const ReportChartComponent = () => {
  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            <TabPane tab="Physician" key="physicians">
              <AllocationChart type={"physicians"} />
            </TabPane>

            <TabPane tab="Case" key="cases">
              <AllocationChart type={"cases"} />
            </TabPane>
          </StyledTabs>
        </StyledTabWrapper>
      </Content>
    </>
  );
};

export default ReportChartComponent;
