import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Typography,
  Form,
} from "antd";
import styled from "styled-components";

import moment from "moment";
import { lookUp } from "../../api/LookUpAPI";

import CustomPaymentsFilter from './CustomPaymentsFilter'
import GeneratedReportFilter from './GeneratedReportFilter'

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;
const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;

const ReportPayments = ({
  selectedReport,
  reportPeriod,
  setReportPeriod,
  reportYear,
  setReportYear,
  reportPeriodNumber,
  setReportPeriodNumber,
  report,
  totalCount,
  filterParams,
  clearFilter,
  handleFetchReport
}) => {

  const [form] = Form.useForm();
  const [filteredReport, setFilteredReport] = useState([])
  const [count, setCount] = useState()
  const [picker, setPicker] = useState("date");
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [states, setStates] = useState([]);

  const isGeneratedReport = selectedReport.includes('generated')

  const columns = [
    {
      title: "Physicians",
      dataIndex: "physicians",
      key: "physicians",
    },
    {
      title: "Async Consults Cost",
      dataIndex: "async",
      key: "async-consults-cost",
      align: "center",
    },
    {
      title: "Sync Consults Cost",
      dataIndex: "sync",
      key: "sync-consults-cost",
      align: "center",
    },
    {
      title: "Total Cost",
      dataIndex: "total",
      key: "total-cost",
      align: "center",
    },
  ];

  useEffect(() => {
    setFilteredReport(report || [])
    setCount(totalCount)
  }, [report, totalCount])

  useEffect(() => {
    lookUp("state").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setStates(stateData);
      }
    })
  }, [])

  function handleChange(value) {
    setPicker(value);
    form.resetFields(["pickerValue"]);
  }

  const onSearch = async value => {
    const {
      stateId,
      reportState,
      pickerValue,
      type
    } = value

    if (isGeneratedReport && reportState) {
      return filterByState(reportState)
    }

    const filter = {
      index: 1,
      count: 10,
      stateId,
    }

    setCurrentPage(1);
    setTablePageSize(10);

    switch(type) {
        case "date":
        case "day":
          filter.filterType = "day"
          filter.filter = moment(pickerValue).format("MM-DD-YYYY");
          break
        case "month":
          filter.filterType = "month";
          filter.filter = moment(pickerValue).format("MM-01-YYYY");
          break
        case "year":
          filter.filterType = "year";
          filter.filter = moment(pickerValue).format("01-01-YYYY");
          break
        default:
          filter.filterType = "week";
          filter.startDate = moment(pickerValue)
            .startOf("week")
            .format("MM-DD-YYYY");
          filter.endDate = moment(pickerValue)
            .endOf("week")
            .format("MM-DD-YYYY");
    }

    handleFetchReport(filter)
  }

  const filterByState = stateId => {
    const stateFilteredReport = report.filter(physician => {
      const physicianStates = Object.keys(physician.states)
      return physicianStates.includes(stateId)
    })

    const stateReport = stateFilteredReport.map(physician => {
      return { ...physician, ...physician.states[stateId]}
    })

    setFilteredReport(stateReport)
  }

  const onChangePage = (index, size) => {
    setCurrentPage(index);
    if (isGeneratedReport) {
      const startIndex = (index - 1) * size
      const endIndex = startIndex + size
      setFilteredReport(report.slice(startIndex, endIndex))
    } else {
      handleFetchReport({
        ...filterParams,
        count: size,
        index
      })
    }
  };

  const onPageSizeChange = (_, size) => {
    setCurrentPage(1);
    setTablePageSize(size);
    if (isGeneratedReport) {
      setFilteredReport(report.slice(0, size))
    } else {
      handleFetchReport({
        ...filterParams,
        count: size,
        index: 1
      })
    }
  };

  return (
      <StyledComponentWrapper>
        <Col span={24}>
          <Title level={2}>Physician Payment Details</Title>
        </Col>
        { isGeneratedReport ? (
            <GeneratedReportFilter
              type='physician-payments'
              onSearch={onSearch}
              clearFilter={clearFilter}
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
              reportYear={reportYear}
              setReportYear={setReportYear}
              reportPeriodNumber={reportPeriodNumber}
              setReportPeriodNumber={setReportPeriodNumber}
              states={states}
            />
        ) : (
          <CustomPaymentsFilter
            onSearch={onSearch}
            clearFilter={clearFilter}
            picker={picker}
            handleChange={handleChange}
            states={states}
          />
        )}

        <Col span={24}>
          <StyledTable
            columns={columns}
            dataSource={[ ...filteredReport ]}
            pagination={{
              total: count,
              showTotal: () => `Total ${count} Physicians`,
              current: currentPage,
              pageSize: tablePageSize,
              pageSizeOptions: ["5", "10", "20", "50", "100"],
              showSizeChanger: true,
              onChange: onChangePage,
              onShowSizeChange: onPageSizeChange,
            }}
            scroll={{ x: 660 }}
          />
        </Col>
      </StyledComponentWrapper>
  );
};

export default ReportPayments;
