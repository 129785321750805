import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config


export const addtelecategory = data => {
    return axiosInstance.post(
        `${apiAppendPath.teleCategoryAppendPath}/tele-medicine-category`,
        data
    );
};

export const deletetelecategory = id=> {
    return axiosInstance.delete(
        `${apiAppendPath.teleCategoryAppendPath}/tele-medicine-category/${id}`
       
    );
};

export const updatetelecategory =(id,data)=> {
    return axiosInstance.put(
        `${apiAppendPath.teleCategoryAppendPath}/tele-medicine-category/${id}`,
        data
       
    );
};