import React from 'react';
import ReactDOM from 'react-dom';
import markerSDK from '@marker.io/browser';
import config from './config';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (config.MARKER_PROJECT_ID) {
  markerSDK.loadWidget({
    project: config.MARKER_PROJECT_ID,
    customData: {
      currentSHA: config.LATEST_SHA
    }
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
