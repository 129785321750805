const dictionaryList = [
    {
        title: 'Manage Clients',
        description: 'Manage Clients by adding, updating and deleting them within the application',
        imageUrl: 'clients',
        goToPath: '/dictionary/clients',
        goToLabel: 'Clients',
        rights: [
            "createClient", "getCategoryOfClient", "updateClient", "deleteClient", "getAdminUserClients", "getAdminUserClientDetails"
        ]
    },
    {
        title: 'Manage States',
        description: 'Manage States by adding, updating and deleting them within the application',
        imageUrl: 'states',
        goToPath: '/dictionary/states',
        goToLabel: 'States',
        rights: [
            "createState", "updateState", "deleteState", "searchState", "getStates"
        ]
    },
    {
        title: 'Manage Telemedicine Categories',
        description: 'Manage Telemedicine Categories by adding, updating and deleting them within the application',
        imageUrl: 'telemedicine',
        goToPath: '/dictionary/telemedicine',
        goToLabel: 'Telemedicine Categories',
        rights: [
            "createTeleMedicineCategory", "updateTeleMedicineCategory", "deleteTeleMedicineCategory", "getTeleMedicineCategory"
        ]
    },
    {
        title: 'Manage Case Status',
        description: 'Manage Case Status by adding, updating and deleting them within the application',
        imageUrl: 'case-status',
        goToPath: '/dictionary/case-status',
        goToLabel: 'Case Status',
        rights: [
            "createCaseStatus", "updateCaseStatus", "deleteCaseStatus",
        ]
    },
    {
        title: 'Manage Questionnaire',
        description: 'Upload Questionnaire in JSON format for by mapping Clients and  Telemedicine Category',
        imageUrl: 'questionnaire',
        goToPath: '/dictionary/questionnaire',
        goToLabel: 'Questionnaire',
        rights: [

        ]
    },
    {
        title: "Manage Templates",
        description: 'Manage Templates by adding, updating, and deleting with respect to Telemedicine Category',
        imageUrl: 'templates',
        goToPath: '/dictionary/templates',
        goToLabel: "Templates",
        rights: [
            "getMessageTemplate", "getMessageTemplateDetails", "saveMessageTemplate", "updateMessageTemplate", "deleteMessageTemplate",
        ]
    },
    {
        title: "Manage Users",
        description: 'Manage Users by adding ,updating and deleting with respect to clients',
        imageUrl: 'users',
        goToPath: '/dictionary/users',
        goToLabel: "Users",
        rights: [
            "getAdminUsers", "adminUserView", "registerAdminUser", "adminUserUpdate", "adminUserDelete",
        ]
    },
    {
        title: "Manage Medications and Usage Directions",
        description: 'Manage Medications and Usage directions by adding ,updating and deleting with respect to Telemedicine Category',
        imageUrl: 'medications-directions',
        goToPath: '/dictionary/medications-and-directions',
        goToLabel: "Medications & Directions",
        rights: [

        ]
    },
    {
        title: "Manage External Physician Consultation Rates",
        description: 'Manage the consultation rates of all external physicians for different clients and telemedicine categories',
        imageUrl: 'consultation-rates',
        goToPath: '/dictionary/external-consult-rates',
        goToLabel: "Consultation Rates",
        rights: [

        ]
    },
    {
        title: "Manage Global Configurations",
        description: 'Manage global and load balancer configuration of the Application',
        imageUrl: 'global-configurations',
        goToPath: '/dictionary/global-configurations',
        goToLabel: "Global Configurations",
        rights: [

        ]
    },
]

export default dictionaryList;