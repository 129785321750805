import React from 'react';
import { Form, Row, Col, Button, Select, message } from 'antd';
import { bulkUpdatePharmacy } from '../../../api/StatesAPI';

const PHARMACIES = {
  '245770': { id: '245770', name: 'CareFirst Specialty Pharmacy' },
  '245312':{ id: '245312' , name: 'GoGoMeds' },
  '247998': { id: '247998', name: 'Pharmco'},
  '42678': { id: '42678', name: 'Transition Pharmacy' },
  '264388': { id: '264388', name: 'TruePill Pharmacy' },
  '254295': { id: '254295', name: 'Honeybee Health' }
}

export const BulkUpdate = ({ states, fetchStates, categories }) => {

  return (
    <Form layout='vertical' onFinish={({ states, categories, pharmacy }) => {
      bulkUpdatePharmacy(states, categories, PHARMACIES[pharmacy])
        .then(fetchStates)
        .then(() => message.success('Successfully updated the configured pharmacy for the selected states and categories'))
        .catch(() => message.error('Failed to update the configured pharmacy for the selected states and categories'))
    }}>
      <Row align='middle' justify='space-between'>
        <Col span={7}>
          <Form.Item name='categories' label='Categories:'>
            <Select mode='multiple' allowClear>
              {categories.map(category => <Select.Option key={category._id} value={category._id}>{category.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name='states' label='States:'>
            <Select mode='multiple' allowClear>
              {states.map(state => <Select.Option key={state._id} value={state._id}>{state.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name='pharmacy' label='Order Type Pharmacy:'>
            <Select>
              {Object.values(PHARMACIES).map(pharmacy => <Select.Option key={pharmacy.id} value={pharmacy.id}>{pharmacy.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Button type='primary' htmlType='submit' style={{ marginTop: 6 }}>Update</Button>
      </Row>
    </Form>
  )
}