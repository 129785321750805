import { Card } from "antd";
import styled from "styled-components";

const StyledAvailabilityCardComponent = styled(Card)`
    background: #ffffff;
    margin-top: 35px;
    > .ant-card-body {
        padding: unset;
    }
`;

export { StyledAvailabilityCardComponent }