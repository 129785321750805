import React, { useCallback } from "react";
import { Form, Row, Col, Select, Button, Input } from "antd";
import styled from "styled-components";
import { debounce } from "lodash";
const StyledButton = styled(Button)`
  width: 80px;
  margin-top: 28px;
`;

const ArticletFilter = ({
  onFilterData,
  onClear,
  telemedicineCategory,
  articleCategories,
}) => {
  const [form] = Form.useForm();

  const onFilterArticles = () => {
    const data = form.getFieldsValue();
    const filter = {};
    filter["title"] = data.title;
    filter["teleMedicineCategory"] = data.telemedicinecategory;
    filter["articleCategory"] = data.category;
    onFilterData(filter);
  };

  // eslint-disable-next-line
  const debounceId = useCallback(debounce(onFilterArticles, 3000), []);

  const onClearFilter = () => {
    form.resetFields();
    onClear();
  };

  return (
    <Form form={form} name="articl_details" layout={"vertical"}>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={8} lg={8} xl={5}>
          <Form.Item name="title" label="Title">
            <Input
              placeholder="Search by title"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={debounceId}
            />
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item name="telemedicinecategory" label="Telemedicine Category">
            <Select
              placeholder="Filter by telemedicine category"
              onChange={onFilterArticles}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {telemedicineCategory &&
                telemedicineCategory.map((item) => {
                  return (
                    <Select.Option value={item.key}>{item.value}</Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={12} lg={10} xl={8}>
          <Form.Item name="category" label="Article Category">
            <Select
              placeholder="Filter by Article Category"
              onChange={onFilterArticles}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {articleCategories &&
                articleCategories.map((item) => {
                  return (
                    <Select.Option value={item.key}>{item.value}</Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={2} xl={2}>
          <Form.Item>
            <StyledButton type="secondary" onClick={() => onClearFilter()}>
              Clear
            </StyledButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ArticletFilter;
