/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  AutoComplete,
  Select,
  Spin,
  Table,
  message
} from "antd";
import { PlusOutlined, LoadingOutlined, DeleteFilled } from "@ant-design/icons";
import { debounce } from "lodash";

const { Option } = Select;
const { Text } = Typography;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 0 0 8px;
  width: 96px;
`;

const StyledPlusButtonColWrapper = styled(Col)`
  text-align: right;
`;

const StyledRowWrapper = styled(Row)`
  margin: 12px 0;
`;

const StyledFormItemWrapper = styled(Form.Item)`
  margin-bottom: unset;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const AddState = (props) => {
  const {
    addState,
    states,
    telemedicineCategory,
    consultationType,
    listOfPharmacies,
    fetchPharmacies,
    pharmacyLoading,
  } = props;
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [
    teleMedicineCategoryForSelection,
    setTeleMedicineCategoryForSelection,
  ] = useState([]);
  const [addedTeleMedicineCategory, setAddedTeleMedicineCategory] = useState(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedConsultation, setSelectedConsultation] = useState({});
  const [
    addedTelemedicineCategoryValidatedStatus,
    setAddedTelemedicineCategoryValidatedStatus,
  ] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState({});
  const [selectPharmacySearchValue, setSelectPharmacySearchValue] = useState(
    ""
  );

  const columns = [
    {
      title: "Telemedicine Category",
      width: 100,
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left",
    },
    {
      title: "Consultation Type",
      width: 100,
      dataIndex: "consultationType",
      key: "consultationType",
    },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacyName",
      key: "pharmacyName",
      width: 100,
    },
    {
      title: "Remove",
      key: "operation-remove",
      width: 100,
      render: (event, record, index) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            handleTeleMedicineCategoryDelete(index);
          }}
        />
      ),
    },
  ];

  const handlePharmacySearch = (value) => {
    setSelectPharmacySearchValue(value);
  };

  const searchPharmacy = (searchPharmacyValue) => {
    fetchPharmacies(searchPharmacyValue);
  };

  const debounceSearchPharmacies = useCallback(
    debounce(searchPharmacy, 1250),
    []
  );

  useEffect(() => {
    debounceSearchPharmacies(selectPharmacySearchValue);
  }, [selectPharmacySearchValue]);

  const handlePharmacySelectionChange = (value) => {
    setSelectedPharmacy(value);
  };

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const { state } = values;
    const { stateName } = state;
    if (!addedTeleMedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
      return false;
    } else {
      setAddedTelemedicineCategoryValidatedStatus("");
    }

    const consultationTypeDetails = addedTeleMedicineCategory.map(
      (eachCategory) => ({
        categoryId: eachCategory.categoryId,
        categoryName: eachCategory.categoryName,
        consultationType: eachCategory.consultationType,
      })
    );

    const pharmacyDetails = addedTeleMedicineCategory.map((eachCategory) => ({
      categoryId: eachCategory.categoryId,
      pharmacyName: eachCategory.pharmacyName,
      pharmacyId: eachCategory.pharmacyId,
    }));

    const stateDetails = {
      name: stateName,
      consultationType: [...consultationTypeDetails],
      pharmacy: [...pharmacyDetails],
    };

    try {
      await addState(stateDetails)
      form.resetFields()
      setAddedTeleMedicineCategory([]);
      setSelectedCategory({});
      setSelectedConsultation({});
      message.success("Successfully Added State");
    } catch (e) {
      message.error(e.message)
    }
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const updateTeleMedicineCategoryForSelection = () => {
    const checkAddedTeleMedicineCategory = (accumulator, currentCategory) => {
      const foundTelemedicineCategory = addedTeleMedicineCategory.find(
        (eachSelectedCategory) =>
          currentCategory.key === eachSelectedCategory.categoryId
      );
      if (!foundTelemedicineCategory) {
        accumulator.push(currentCategory);
      }
      return accumulator;
    };
    const teleMedicineCategoryForSelection = telemedicineCategory.reduce(
      checkAddedTeleMedicineCategory,
      []
    );
    setTeleMedicineCategoryForSelection(teleMedicineCategoryForSelection);
  };

  useEffect(() => {
    updateTeleMedicineCategoryForSelection();
  }, [telemedicineCategory, addedTeleMedicineCategory]);

  const onConsultationTypeChange = (value) => {
    setSelectedConsultation(value);
  };

  const onTeleMedicineCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleDaysAvailableAddition = () => {
    const telemedicineCategory = {
      categoryId: selectedCategory.value,
      pharmacyId: selectedPharmacy.value,
      pharmacyName: selectedPharmacy.label,
      categoryName: selectedCategory.label,
      consultationType: selectedConsultation.value,
    };
    const clonnedSelectedTelemedicineCategory = addedTeleMedicineCategory.slice(
      0
    );
    clonnedSelectedTelemedicineCategory.push(telemedicineCategory);
    setAddedTeleMedicineCategory(clonnedSelectedTelemedicineCategory);
    setSelectedCategory({});
    setSelectedConsultation({});
    setSelectedPharmacy({});
    if (addedTelemedicineCategoryValidatedStatus === "error") {
      setAddedTelemedicineCategoryValidatedStatus("sucess");
    }
  };

  const handleTeleMedicineCategoryDelete = (itemIndex) => {
    const clonnedTelemedicineCategory = addedTeleMedicineCategory.slice(0);
    clonnedTelemedicineCategory.splice(itemIndex, 1);
    if (!clonnedTelemedicineCategory.length) {
      setAddedTelemedicineCategoryValidatedStatus("error");
    }
    setAddedTeleMedicineCategory(clonnedTelemedicineCategory);
  };

  const updateAutoCompleteOptions = () => {
    const options = states.map((eachState) => ({
      key: eachState._id,
      value: eachState.name,
    }));
    setAutoCompleteOptions(options);
  };

  useEffect(() => {
    updateAutoCompleteOptions();
  }, [states]);

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Title level={2}>Add State</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name={["state", "stateName"]}
            label="State Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoComplete
              options={autoCompleteOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            >
              <Input size={"large"} placeholder="Enter State Name" />
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="pdmpResource"
            label="Controlled Substances PDMP Resource (URL)"
          >
              <Input size={"large"} placeholder="PDMP Resource (Required for states where controlled substances are available)" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <StyledFormItemWrapper
            shouldUpdate={() => addedTeleMedicineCategory.length}
            name="telemedicine-consultation-mapping"
            label="Add Telemedicine Category"
            validateStatus={addedTelemedicineCategoryValidatedStatus}
            help={
              addedTelemedicineCategoryValidatedStatus === "error"
                ? "Please select at least one Telemedicine Category, Consultation Type and pharmacy, then add it using `+` button"
                : ""
            }
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (addedTeleMedicineCategory.length) {
                    return Promise.resolve();
                  }

                  setAddedTelemedicineCategoryValidatedStatus("error");
                  return Promise.reject(
                    "Please select atleast one Telemedicine Category, Consultation Type and pharmacy, then add it using `+` button"
                  );
                },
              }),
            ]}
          >
            <StyledRowWrapper>
              <Col>
                <Text>
                  Please select a Telemedicine Category, a Consultation type and
                  a Pharmacy, then click on `+` button to add it to the selected
                  state. Use the delete button in the listing to remove the
                  Added Telemedicine category from this state.
                </Text>
              </Col>
            </StyledRowWrapper>
            <StyledRowWrapper gutter={[8, 8]} justify="space-between">
              <Col xs={24} sm={24} md={10} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Telemedicine Category">
                  <Select
                    placeholder="Select a Telemedicine Category"
                    showSearch
                    labelInValue
                    size="large"
                    value={selectedCategory}
                    onChange={onTeleMedicineCategoryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {teleMedicineCategoryForSelection.map((eachCategory) => (
                      <Option key={eachCategory._id} value={eachCategory._id}>
                        {eachCategory.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Consultation Type">
                  <Select
                    size="large"
                    labelInValue
                    value={selectedConsultation}
                    placeholder="Select Consultation Type"
                    onChange={onConsultationTypeChange}
                  >
                    {consultationType.map((eachType) => (
                      <Option key={eachType.key} value={eachType.value}>
                        {eachType.name}
                      </Option>
                    ))}
                  </Select>
                </StyledFormItemWrapper>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                <StyledFormItemWrapper label="Select a Pharmacy">
                  <Spin
                    spinning={pharmacyLoading}
                    size="small"
                    indicator={<LoadingOutlined />}
                  >
                    <Select
                      size="large"
                      showSearch
                      value={selectedPharmacy}
                      placeholder={"Enter Pharmacy Name"}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handlePharmacySearch}
                      onChange={handlePharmacySelectionChange}
                      notFoundContent={null}
                      labelInValue
                    >
                      {listOfPharmacies.map((eachPharmacy) => (
                        <Option
                          key={eachPharmacy.key}
                          value={eachPharmacy.value}
                        >
                          {eachPharmacy.name}
                        </Option>
                      ))}
                    </Select>
                  </Spin>
                </StyledFormItemWrapper>
              </Col>
              <StyledPlusButtonColWrapper xs={24} sm={24} md={2} lg={2} xl={2}>
                <StyledFormItemWrapper label=" ">
                  <Button
                    type="primary"
                    disabled={
                      !(
                        Object.keys(selectedCategory).length &&
                        Object.keys(selectedConsultation).length &&
                        selectedPharmacy
                      )
                    }
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={handleDaysAvailableAddition}
                  />
                </StyledFormItemWrapper>
              </StyledPlusButtonColWrapper>
            </StyledRowWrapper>
            <StyledRowWrapper>
              {addedTeleMedicineCategory.length ? (
                <>
                  <Col span={24}>
                    <Text strong>Added Telemedicine Category</Text>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={addedTeleMedicineCategory}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
            </StyledRowWrapper>
          </StyledFormItemWrapper>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Add
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default AddState;
