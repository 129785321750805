import { useState, useEffect } from 'react';

export const useLocalStorage = (key, initialState, parser=value => value, serializer=JSON.stringify) => {
  const [state, setState] = useState(
      window.localStorage.getItem(key) 
        ? parser(window.localStorage.getItem(key)) 
        : initialState
  )

  const removeItem = () => {
    setState(null);
    window.localStorage.removeItem(key);
  }

  useEffect(() => {
    window.localStorage.setItem(key, serializer(state));
  }, [key, state])

  return [state, setState, removeItem];
}