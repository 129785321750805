/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Checkbox,
  Button,
  Row,
  Col,
  Typography,
  Select,
  message
} from "antd";
import { DeleteFilled } from '@ant-design/icons';
import { AntTinyWrapper } from '../../../components/AntTinyWrapper';
import { showVariableList } from './showVariableList';
import * as TemplateAPI from "../../../api/TemplateAPI";
import * as LookupAPI from "../../../api/LookUpAPI";
import { TemplateTypeEnum } from "../../../constants/enums";

const { Option } = Select;
const { TextArea } = Input;


const TemplateForm = ({ 
  mode, 
  type,
  template, 
  handleFinish 
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [caseStatuses, setCaseStatuses] = useState([]);

  const title = `${mode} ${type[0].toUpperCase()}${type.slice(1)} Template`;
  const isViewMode = mode === 'View';
  const isTypeCase = type === TemplateTypeEnum.CASE
  const isTypeVisit = type === TemplateTypeEnum.VISIT

  const onFinish = (values) => {
    if (mode === 'Add') {
      return TemplateAPI
        .create(type, values)
        .then(handleFinish)
        .then(form.resetFields)
    }
    else if (mode === 'Edit') {
      return TemplateAPI
        .update(template._id, values)
        .then(handleFinish)
        .then(form.resetFields)
    }
  }

  const handleDelete = () => {
    return TemplateAPI
      .deleteOne(template._id)
      .then(handleFinish)
      .catch(err => message.error({ content: err.response?.data?.message }));
  }

  useEffect(() => {
    if ([TemplateTypeEnum.CASE, TemplateTypeEnum.VISIT].includes(type)) {
      LookupAPI.lookUp('tele-medicine-category')
        .then(res => setCategories(res.data.payload));

      type === TemplateTypeEnum.CASE && LookupAPI.lookUp('case-status')
        .then(res => setCaseStatuses(res.data.payload));
    }
  }, [type])

  useEffect(() => {
    form.setFieldsValue({
      title: template?.title,
      teleMedicineCategoryIds: template?.teleMedicineCategoryIds?.map(category => category._id),
      statusId: template?.statusId?._id,
      message: template?.message,
    });
  }, [template, form])

  const CaseFields = () => (
    <>
      <Col span={24}>
        <Form.Item
          name="teleMedicineCategoryIds"
          label={
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 15 }}>Telemedicine Categories</span>
              <Checkbox 
                onChange={(e) => form.setFieldsValue({ 
                  teleMedicineCategoryIds: e.target.checked 
                    ? categories.map(category => category._id) 
                    : [] 
                  })
                }
              >Select All</Checkbox>
            </div>
          }
          rules={[{ required: true }]}
        >
          <Select
            size="large"
            placeholder="Select Telemedicine Category"
            disabled={isViewMode}
            mode='multiple'
          >
            {categories.map((category) => <Option value={category._id} key={category._id}>{category.name}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="statusId"
          label="Case Status"
          rules={[{ required: true }]}
        >
          <Select
            size="large"
            placeholder="Select Case Status"
            disabled={isViewMode}
          >
            {caseStatuses.map((status) => <Option value={status._id} key={status._id}>{status.name}</Option>)}
          </Select>
        </Form.Item>
      </Col>
    </>
  )

  const VisitFields = () => (
    <>
      <Col span={24}>
        <Form.Item
          name="teleMedicineCategoryIds"
          label={
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 15 }}>Telemedicine Categories</span>
              <Checkbox 
                onChange={(e) => form.setFieldsValue({ 
                  teleMedicineCategoryIds: e.target.checked 
                    ? categories.map(category => category._id) 
                    : [] 
                  })
                }
              >Select All</Checkbox>
            </div>
          }
          rules={[{ required: true }]}
        >
          <Select
            size="large"
            placeholder="Select Telemedicine Category"
            disabled={isViewMode}
            mode='multiple'
          >
            {categories.map((category) => <Option value={category._id} key={category._id}>{category.name}</Option>)}
          </Select>
        </Form.Item>
      </Col>
    </>
  )

  return (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={2} >{title}</Typography.Title>
        </Col>
        {
          mode === 'Edit' &&
          <Col>
            <Button onClick={handleDelete} type='danger' icon={<DeleteFilled />}>Delete</Button>
          </Col>
        }

      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true }]}
          >
            <TextArea
              placeholder="Enter the title for Template Message"
              readOnly={isViewMode}
              autoSize
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </Col>
        {isTypeCase && <CaseFields />}
        {isTypeVisit && <VisitFields />}
        <Col span={24}>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            { isTypeVisit
              ?  <TextArea
                    placeholder="Enter your templated message"
                    readOnly={isViewMode}
                    autoSize
                    style={{ resize: 'none' }}
                  />
              : <AntTinyWrapper disabled={isViewMode} />
            }
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col>
          <Button onClick={() => showVariableList(type)}>Template Variables</Button>
        </Col>
        <Col>
          <Row justify='end'>
            {
              isViewMode
                ? <Col>
                  <Button type='primary' onClick={handleFinish}>Close</Button>
                </Col>
                : <>
                  <Col style={{ marginRight: 10 }}>
                    <Button onClick={handleFinish}>Cancel</Button>
                  </Col>
                  <Col>
                    <Button type='primary' onClick={form.submit} >{mode === 'Add' ? 'Create' : 'Save'}</Button>
                  </Col>
                </>
            }
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default TemplateForm;
