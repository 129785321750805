import React from "react";
import { Row, Col, Typography, Table, Modal, message, Button } from "antd";
import styled from "styled-components";
import { ProfileOutlined } from "@ant-design/icons";

//import components
import { StyledTitle } from "../../components/LayoutComponent";
import PharmacyDetails from "./PharmacyDetails";

//import common functions
import { dateTimeFormatter } from "../../utils/dateFormatter";

const { Text } = Typography;
const StyledTable = styled(Table)`
  margin: 30px 0px;
`;

const CasePrescribedMedicines = ({ caseDetails }) => {
  const pharmacyInfo = () => {
    return Modal.info({
      title: "Pharmacy used for Prescription",
      width: 900,
      content: (
        <PharmacyDetails
          caseDetails={caseDetails}
          topMarginRequired={false}
          contentSize="small"
          copyDetails={false}
          splitFormDetails={true}
        />
      ),
      onOk() {},
    })
  }

  const handlePharmacyDetailsButtonClick = () => pharmacyInfo(caseDetails?.pharmacyId)

  const prescribedMedicinesColumn = [
    {
      title: "Drug Name",
      dataIndex: "drugName",
      key: "drugName",
      width: 400,
    },
    {
      title: "NDC",
      dataIndex: "ndc",
      key: "ndc",
      width: 200,
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
      key: "dosage",
      width: 200,
    },
    {
      title: "Dispense",
      dataIndex: "dispense",
      key: "dispense",
      width: 200,
    },
    {
      title: "Days Supply",
      dataIndex: "daysSupply",
      key: "daysSupply",
      width: 150,
    },
    {
      title: "Refills",
      dataIndex: "refills",
      key: "refills",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "pillCount",
      key: "pillCount",
      width: 150,
    },
    {
      title: "Pharmacy Details",
      dataIndex: "",
      width: 180,
      key: "e",
      render: (prescriptionDetails) => (
        <Button
          shape="circle"
          icon={<ProfileOutlined />}
          onClick={(event) => {
            event.stopPropagation();
            handlePharmacyDetailsButtonClick(prescriptionDetails);
          }}
        />
      ),
    },
    {
      title: "Directions",
      dataIndex: "directions",
      key: "patient-directions",
      width: 400,
    },
    {
      title: "Medication Status",
      dataIndex: "medicationStatus",
      key: "medication-status",
      width: 200,
    },
    {
      title: "Written Date",
      dataIndex: "",
      width: 200,
      key: "written-date",
      render: (prescriptionDetails) => (
        <>
          {prescriptionDetails.writtenDate
            ? dateTimeFormatter(prescriptionDetails.writtenDate)
            : "NA"}
        </>
      ),
    },
    {
      title: "Effective Date",
      dataIndex: "",
      width: 200,
      key: "effective-date",
      render: (prescriptionDetails) => (
        <>
          {prescriptionDetails.effectiveDate
            ? dateTimeFormatter(prescriptionDetails.effectiveDate)
            : "NA"}
        </>
      ),
    },
    {
      title: "Prescription Id",
      dataIndex: "prescriptionId",
      key: "prescription-id",
      width: 150,
    },
    {
      title: "Pharmacy ID",
      dataIndex: "pharmacyId",
      key: "pharmacy-id",
      width: 150,
    },
  ];
  return (
    <>
      <Row className="case-details-row-wrapper">
        <Col span={24}>
          <StyledTitle level={4}>Prescribed Medicines</StyledTitle>
        </Col>
        { caseDetails?.prescribedMedicine?.length !== 0 && (
            <>
              <Col span={24}>
                <StyledTable
                  dataSource={caseDetails.prescribedMedicine}
                  columns={prescribedMedicinesColumn}
                  pagination={false}
                  scroll={{ x: 2550 }}
                  sticky
                />
              </Col>
            </>
          )}
        { caseDetails?.prescribedMedicine?.length === 0 && caseDetails?.errorMedicine?.length === 0 && (
          <Col className="align-text-center" span={24}>
            <Text>Prescribed medicines unavailable</Text>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CasePrescribedMedicines;
