//import packages
import React, { useState, useEffect } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  message,
  Spin,
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  KeyOutlined,
  UserSwitchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import {
  getUsersList,
  deleteUsers,
  switchUser,
} from "../../../api/UserTypeAPI";
import { passwordResetRequest } from "../../../api/AuthAPI";
import { getUserRoles } from "../../../api/UserRoleAPI";
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import authorization from "../../../auth/authorization";
import checkSuperAdminToken from "../../../utils/checkSuperAdminToken";

const { confirm } = Modal;
const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const Users = ({ userType: userRoleType }) => {
  const [userType, setuserType] = useState([]);
  const [editId, setEditId] = useState("");
  const [clients, setclients] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userSwitchUserId, setUserSwitchUserId] = useState();
  const [allowedUserRoles, setAllowedUserRoles] = useState([]);
  const history = useHistory();

  const fetchuserType = () => {
    getUsersList().then((res) => {
      const data = [];
      res.data.payload.forEach((element) => {
        const clientItems = [];
        const ItemMessage = element;
        ItemMessage["name"] =
          element.firstName && element.firstName.concat("  ", element.lastName);
        clients.forEach((item) => {
          element.clients.forEach((checkClient) => {
            if (item._id === checkClient) {
              clientItems.push(item.name);
            }
          });
        });

        ItemMessage["client"] = clientItems;
        data.push(ItemMessage);
      });
      setuserType(data);
    });
  };

  const fetchClients = () => {
    lookUp("client").then((res) => {
      if (res.data && res.data.payload) {
        const clientData = res.data.payload.map((clientItem) => ({
          ...clientItem,
          name: clientItem.name,
          key: clientItem._id,
        }));

        setclients(clientData);
      }
    });
  };

  const fetchUserRoles = () => {
    getUserRoles().then((res) => {
      if (res.data.success === true) {
        setUserRoles(res.data.payload);
        const allowedUserRoles = authorization.getAllowedUsers(userRoleType);
        const { data } = res;
        if (data && data.payload) {
          const { payload } = data;
          const allowedUserList = payload.filter((eachUserRole) =>
            allowedUserRoles.includes(eachUserRole.name)
          );
          setAllowedUserRoles(allowedUserList);
        }
      }
    });
  };

  const initNetworkRequest = () => {
    fetchClients();
    fetchUserRoles();
  };

  // eslint-disable-next-line
  useEffect(initNetworkRequest, []);

  useEffect(() => {
    if (clients.length !== 0 && userRoles.length !== 0) {
      fetchuserType();
    }
  }, [clients, userRoles]); // eslint-disable-line react-hooks/exhaustive-deps

  function showClients(clients) {
    Modal.info({
      title: "User Clients",
      content: (
        <ul>
          {clients.map((item) => {
            return (
              <li>
                <b>{item}</b>
              </li>
            );
          })}
        </ul>
      ),
      onOk() {},
    });
  }

  const onAddinguser = () => {
    fetchuserType();
  };

  const onUpdatinguserType = (id, userType) => {
    fetchuserType();
  };

  const onDeletinguserType = (userId) => {
    deleteUsers(userId)
      .then((res) => {
        const { status } = res;
        if (status && status === 204) {
          message.success("Successfully Deleted the User");
          setEditId();
        } else {
          const { data } = res;
          if (data && data.success) {
            message.success("Successfully Deleted the User");
            setEditId();
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable to delete the user. Please try again!");
            }
          }
        }
        fetchuserType();
      })
      .catch((error) => {
        console.log(
          "================== delete user error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete the user. Please try again!");
          }
        } else {
          message.error("Unable to delete the user. Please try again!");
        }
      });
  };

  const handlePasswordReset = (userDetails) => {
    passwordResetRequest({
      email: userDetails.email,
    })
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(
            "Successfully identified the user account and initiated password reset! Reset link has been sent to the corresponding Email"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Failed to find account for password reset! Please check the user details"
            );
          }
        }
      })
      .catch(() => {
        message.error(
          "Failed to find account for password reset! Please check the user details"
        );
      });
  };

  const onPasswordResetConfirmation = (userDetails) => {
    confirm({
      title: `Do you want to intiate the password reset for ${userDetails.userType} User : ${userDetails.firstName}  ${userDetails.lastName}? The user will be getting the password reset email containing a link through which he/she can update the password`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        handlePasswordReset(userDetails);
      },
      onCancel() {},
    });
  };

  const showDeleteConfirmation = (userType, userId) => {
    confirm({
      title: `Do you want to delete User ${userType.firstName}  ${userType.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        onDeletinguserType(userId);
      },
      onCancel() {},
    });
  };

  const showUserSwitchConfirmation = (userType, userId) => {
    confirm({
      title: `Are you sure to Switch to User ${userType.firstName} ${userType.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        fetchSwitchUserDetails({ userId: userId }, userType);
        setUserSwitchUserId(userId);
      },
      onCancel() {},
    });
  };

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onMessageEdit = (userId) => {
    setEditId(userId);
  };

  const fetchSwitchUserDetails = (data, userType) => {
    switchUser(data)
      .then((res) => {
        const { payload } = res.data;
        if (payload) {
          const superAdminToken = window.localStorage.getItem("userToken");
          const superAdminEmail = window.localStorage.getItem("userEmail");
          const superAdminType = window.localStorage.getItem("type");

          window.localStorage.setItem("superAdminToken", superAdminToken);
          window.localStorage.setItem("superAdminEmail", superAdminEmail);
          window.localStorage.setItem("superAdminType", superAdminType);

          window.localStorage.setItem("type", userType.userType);
          window.localStorage.setItem("userEmail", userType.email);
          window.localStorage.setItem("userToken", payload);

          history.push("/login");
          setUserSwitchUserId();
        }
      })
      .catch((error) => {
        const { response } = error;
        setUserSwitchUserId();
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Switch to respective user, Please try again !"
            );
          }
        } else {
          message.error("unable to Switch respective user, Please try again !");
        }
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 250,
    },
    { title: "Email Id", dataIndex: "email", key: "email", width: 380 },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      width: 260,
    },
    {
      title: "Created Date",
      key: "created-date",
      width: 300,
      dataIndex: "",
      render: (userDetails) => (
        <>
          {userDetails.createDate
            ? moment(userDetails.createDate).format("LLLL")
            : ""}
        </>
      ),
    },
    {
      title: "Last Login",
      key: "last-login",
      width: 300,
      dataIndex: "",
      render: (userDetails) => (
        <>
          {userDetails.lastLogin
            ? moment(userDetails.lastLogin).format("LLLL")
            : ""}
        </>
      ),
    },
    {
      title: "Account Status",
      key: "status",
      dataIndex: "status",
      width: 150,
    },
    {
      title: "View Clients",
      key: "client-view",
      width: 120,
      align: "center",
      dataIndex: "client",
      render: (userDetails) => (
        <Button
          shape="circle"
          icon={<EyeOutlined />}
          onClick={() => {
            showClients(userDetails);
          }}
        />
      ),
    },

    {
      title: "Edit",
      key: "edit",
      width: 120,
      dataIndex: "",
      align: "center",
      render: (userDetails) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            onMessageEdit(userDetails._id);
          }}
        />
      ),
    },
    {
      title: "Reset Password",
      key: "reset-password",
      width: 150,
      align: "center",
      dataIndex: "",
      render: (userDetails) => {
        return userDetails.userType === "manager" ||
          userDetails.userType === "admin" ||
          userDetails.userType === "admin support" ||
          userDetails.userType === "super admin" ||
          userDetails.userType === "provider" ||
          userDetails.userType === "steadymd admin support" ? (
          <Button
            shape="circle"
            icon={<KeyOutlined />}
            onClick={() => {
              onPasswordResetConfirmation(userDetails);
            }}
          />
        ) : (
          <>
            <span>NA</span>
          </>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      width: 120,
      align: "center",
      dataIndex: "",
      render: (userDetails) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            showDeleteConfirmation(userDetails, userDetails._id);
          }}
        />
      ),
    },
    {
      title: "Switch User",
      width: 120,
      align: "center",
      dataIndex: "",
      key: "s",
      render: (userDetails) =>
        (userDetails.userType === "manager" ||
          userDetails.userType === "admin" ||
          userDetails.userType === "admin support" ||
          userDetails.userType === "provider" ||
          userDetails.userType === "steadymd admin support") &&
        !checkSuperAdminToken() ? (
          userSwitchUserId === userDetails._id ? (
            <Spin indicator={spinIcon}></Spin>
          ) : (
            <Button
              type="primary"
              shape="circle"
              icon={<UserSwitchOutlined />}
              disabled={userSwitchUserId ? true : false}
              onClick={() => {
                showUserSwitchConfirmation(userDetails, userDetails._id);
              }}
            />
          )
        ) : (
          <>
            <span>NA</span>
          </>
        ),
    },
  ];

  const afterEdit = () => {
    setEditId("");
  };

  return (
    <>
      <Content>
        <StyledComponentWrapper>
          {editId ? (
            <EditUser
              userId={editId}
              afterEdit={afterEdit}
              userRoles={allowedUserRoles}
              clients={clients}
              updateUser={onUpdatinguserType}
            />
          ) : (
            <AddUser
              adduser={onAddinguser}
              clients={clients}
              userRoles={allowedUserRoles}
            />
          )}
        </StyledComponentWrapper>

        <StyledComponentWrapper>
          <Title level={2}>Users</Title>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={userType}
                pagination={{
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                  showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(Users);
