import React from "react";
import { Typography, Table, Alert } from "antd";
import styled from "styled-components";
const { Text } = Typography

const StyledAlert = styled(Alert)`
  background-color: #fff1f0 !important;
  border: 1px solid #ffa39c !important;
  height: 200px;
  margin-top: 10px;
`

const StyledTable = styled(Table)`
    height: 200px;
    
    & * {
        background: none !important;
        border: none !important;
    }

    & .ant-table-container, .ant-table-body {
        height: 200px;
    }
`

const StyledText = styled(Text)`
    display: block;
    width: 100%;
`

const BulkUpdateResultsComponent = ({ successCount, failures, message }) => {

    const errorTableColumns = [
        {
            title: 'Case',
            dataIndex: 'caseId',
            key: `caseId`,
            width: 100
        },
        {
            title: 'Reason',
            dataIndex: 'message',
            key: 'message',
            width: 100
        }
    ];

    const ErrorTable = () => (
        <StyledAlert type="error" message={
            <>
                <StyledText strong>{failures.length} Case{failures.length > 1 ? 's' : ''} Unable To Be Reassigned</StyledText>
                <hr />
                <StyledTable
                    columns={errorTableColumns}
                    dataSource={failures}
                    pagination={false}
                    scroll={{ y: 100 }}
                    style={{ background: 'none' }}
                />
            </>
        } />
    )

    return (
        <>
            { successCount > 0 ? <Alert message={<Typography.Text strong>{successCount} case{successCount !== 1 ? 's' : ''} updated</Typography.Text>} /> : <></>}
            { failures && failures.length > 0 && <ErrorTable /> }
            { message }
        </>
    )
}

export default BulkUpdateResultsComponent