import React, { useState, useEffect, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal, Slider } from 'antd';

const ImageCropperModal = ({ isVisible, setIsVisible, imgSrc, setImgSrc }) => {
  const [scale, setScale] = useState(1);
  const editorRef = useRef();

  const handleCrop = (e) => {
    e.preventDefault();
    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    fetch(canvas)  
      .then(res => res.blob())
      .then(blob => {
        setImgSrc(blob)
        setIsVisible(false);
      })
  }

  useEffect(() => {
    setScale(1)
  }, [isVisible]);

  return (
    <Modal 
      visible={isVisible}
      closable
      maskClosable
      onOk={handleCrop}
      onCancel={() => setIsVisible(false)}
      bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 50 }}
    >
      <div>
        <AvatarEditor
          ref={editorRef}
          image={imgSrc}
          width={300}
          height={300}
          color={[0, 0, 0, 0.6]}
          scale={scale}
        />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <span>Zoom</span>
          <Slider min={1} max={2} step={.1} value={scale} onChange={setScale} style={{ width: '100%' }} marks={{ 1: 'min', 2: 'max' }} />
        </div>
      </div>
    </Modal>
  )
}

export default ImageCropperModal;