import React from "react";
import { Button, Table } from "antd";
import styled from "styled-components";

const StyledTable = styled(Table)`
    & .ant-table-selection {
        display: none;
    }
`

const StyledButton = styled(Button)`
    height: 22px;
    font-size: 12px;
`

const ProviderTable = ({ providers, selectProvider, selectedProvider }) => {

    const columns = [
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: `lastName`,
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        }
    ];

    const renderTableCell = (_, selected) => {
        if (selectedProvider && selectedProvider.key === selected.key)
            return <div style={{ fontSize: '12px' }}>Selected</div>

        return (
            <StyledButton
                onClick={() => selectProvider(selected)}
                type="primary"
                size="small">
                    Select
            </StyledButton>
        )
    }

    return (
        <StyledTable
            columns={columns}
            dataSource={providers.map(provider => { return { ...provider, key: provider._id } })}
            pagination={false}
            rowSelection={{
                columnWidth: 100,
                renderCell: renderTableCell
            }}
            rowClassName={(_, index) => {
                return selectedProvider && Number(selectedProvider.key) === index + 1 && 'ant-table-row-selected'
            }}
            scroll={{
                y: 100
            }}
        />
    )
}

export default ProviderTable