/* eslint-disable no-template-curly-in-string */
import React from "react";
import { Layout } from "antd";
import ChangePassword from "./ChangePassword";
import ProfileData from "./ProfileData";
const { Content } = Layout;

const ProfileView = () => {
  return (
    <Content>
      <ProfileData />
      <ChangePassword />
    </Content>
  );
};

export default ProfileView;
