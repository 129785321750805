import React, { useState, useEffect, useRef } from 'react';
import { Layout, Modal, Spin } from 'antd';
import FullCalendar from '@fullcalendar/react';
import { Filters } from './Filters';
import { ConsultationsList } from './ConsultationsList';
import { StyledTabWrapper, StyledEventContainer } from './styled';

import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';
import axios from '../../api/AxiosInstance';
import Color from 'color';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { pluralize } from './pluralize';

export const AppointmentCalendar = () => {
  const calendar = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [events, setEvents] = useState([]);
  const [calendarViewDates, setCalendarViewDates] = useState(null);
  const [filters, setFilters] = useLocalStorage('appointment-calendar-filters', {
    physicians: [],
    states: [],
    clients: [],
    credentials: []
  }, JSON.parse)
  const [selectedConsultations, setSelectedConsultations] = useState({
    date: null,
    physicians: []
  })

  const fetchAppointments = (dates) => {
    setIsFetching(true)
    const { physicians, states, clients, credentials } = filters;
    axios.get('/consultations/v2', { params: { startTime: dates.startStr, endTime: dates.endStr, physicians, states, clients, credentials } })
      .then(async res => {
        setIsFetching(false);
        const { consultations } = res.data;

        /* reduce consultations to 
          {
            ...,
            [yearMonthDayHour]: {
              [physicianId]: {
                physician: physicianData,
                consultations: [consultationData] // all consultations taking place in YYYY MM DD HH
              }
            },
          }
        */
        const groupedByHour = consultations.reduce((grouped, consultation) => {
          const consultationStartMoment = moment(consultation.startTime);
          const key = consultationStartMoment.format('YYYYMMDDHH');

          if (!(key in grouped)) {
            grouped[key] = {};
          }

          if (!(consultation.physicianId in grouped[key])) {
            grouped[key][consultation.physicianId] = { physician: consultation.physician, consultations: [] }
          }

          grouped[key][consultation.physicianId].consultations.push(consultation);

          return grouped;
        }, {})

        // transform grouped consults into event objects
        setEvents(Object.entries(groupedByHour))
      });


  }

  useEffect(() => {
    if (calendarViewDates) {
      fetchAppointments(calendarViewDates)
    }
  }, [calendarViewDates, filters])

  return (
    <Layout.Content>
      <StyledTabWrapper>
        <Filters selected={filters} setSelected={setFilters} />
        <Spin spinning={isFetching} size='large'>
          <FullCalendar
            ref={calendar}
            headerToolbar={{ left: false }}
            allDaySlot={false}
            height='75vh'
            datesSet={dates => setCalendarViewDates(dates)}
            events={events}
            eventDataTransform={([key, physicians]) => {
              const event = moment(key, 'YYYYMMDDHH');
              const consultationsCount = Object.values(physicians).reduce((count, { consultations }) => count += consultations.length, 0);
              const base = Color('lightgrey');
              const eventColor = base.darken(.1 * consultationsCount);

              return {
                title: `${consultationsCount} ${pluralize('appt', consultationsCount)}`,
                start: event.toDate(),
                end: event.add({ hours: 1 }).toDate(),
                extendedProps: { physicians },
                color: eventColor.hex(),
              }
            }}
            eventClick={({ event }) => {
              const physiciansGroupedByHour = Object.entries(event.extendedProps.physicians).map(([_, consultationsDict]) => ({
                ...consultationsDict.physician,
                consultations: consultationsDict.consultations
              }))

              setSelectedConsultations({ date: event.start, physicians: physiciansGroupedByHour });
            }}
            eventContent={({ event }) => {
              return (
                <StyledEventContainer>
                  <b>{event.title}</b>
                </StyledEventContainer>
              )
            }}
            plugins={[timeGridPlugin, dayGridPlugin, momentTimezonePlugin]}
          />
          <Modal
            title={`${moment(selectedConsultations.date).format('MMMM DD YYYY hh:mmA')} - ${moment(selectedConsultations.date).add({ hours: 1 }).format('hh:mmA')}`}
            visible={selectedConsultations.physicians.length}
            onCancel={() => setSelectedConsultations({ date: null, physicians: [] })}
            footer={null}
            destroyOnClose
            centered
          >
            <ConsultationsList physicians={selectedConsultations.physicians} />
          </Modal>
        </Spin>

      </StyledTabWrapper>
    </Layout.Content>
  )
}