import React from "react";
import { Form, Row, Col, Select, Button, Input, DatePicker } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 80px;
  margin-top: 28px;
`;

const StyledDatePicker = styled(DatePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  gap: 1em;
  .ant-form-item {
    margin-top: auto;
  }
`;

const StyledPickerFormItem = styled(Form.Item)`
  margin: 0px 10px;
  width: fit-content;
`;

const PaymentsFilter = ({
  onSearch,
  clearFilter,
  picker,
  handleChange,
  states,
}) => {
  const [form] = Form.useForm();

  return (
    <Form layout={"vertical"} form={form} onFinish={onSearch}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={5} xl={4}>
              <Form.Item name="stateId" label="States">
                <Select
                  allowClear
                  placeholder="Filter by State"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {states.map((state) => (<Select.Option key={state.key} value={state.key}>
                      {state.name}
                    </Select.Option>)
                  )}
                </Select>
              </Form.Item>
            </Col>
            <StyledPickerFormItem label="Time Range">
              <Input.Group compact>
                <Form.Item name="type">
                  <Select defaultValue={picker} onChange={handleChange}>
                    <Select.Option value="date">Date</Select.Option>
                    <Select.Option value="week">Week</Select.Option>
                    <Select.Option value="month">Month</Select.Option>
                    <Select.Option value="year">Year</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="pickerValue">
                  <StyledDatePicker
                    allowClear={true}
                    picker={picker}
                  ></StyledDatePicker>
                </Form.Item>
              </Input.Group>
            </StyledPickerFormItem>
            <StyledCol xs={24} sm={24} md={24} lg={2} xl={2}>
              <Form.Item>
                <StyledButton type="primary" htmlType="submit">
                  Search
                </StyledButton>
              </Form.Item>
              <Form.Item>
                <StyledButton
                  type="secondary"
                  onClick={clearFilter}
                >
                  Clear
                </StyledButton>
              </Form.Item>
            </StyledCol>
          </Row>
        </Form>
  );
};

export default PaymentsFilter;
