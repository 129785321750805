import React, { useState, useEffect } from "react";
import { Layout, Table, Spin, Select, message, Typography, Tooltip } from "antd";
import { useHistory, useParams, useLocation } from 'react-router'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { CaseStatusEnum } from "../../constants/enums"
import { LoadingOutlined, ReloadOutlined, PhoneOutlined, VideoCameraOutlined, MedicineBoxOutlined, SwapOutlined } from "@ant-design/icons";
import { dateTimeFormatter } from "../../utils/dateFormatter";

import { getCaseQueueForPhysician } from "../../api/CasesAPI";

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1150px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-bottom: 20px;
`

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > :nth-child(1) {
    padding-left: unset;
  }
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > td {
    cursor: pointer;
  }
`;

const StyledVideoIcon = styled(VideoCameraOutlined)`
  color: rgba(124, 124, 124) !important;
`

const PhysicianCaseQueue = () => {
  const [queueCases, setQueueCases] = useState([])
  const [queueCount, setQueueCount] = useState(null)
  const [externalQueueCount, setExternalQueueCount] = useState(null)
  const [sortDirection, setSortDirection] = useState('ascend')
  const [caseStatusFilter, setCaseStatusFilter] = useState(null)
  const [caseQueueListLoading, setCaseQueueListLoading] = useState(false)
  const [caseQueueCountLoading, setCaseQueueCountLoading] = useState(false)

  const history = useHistory()
  const { physicianId } = useParams()
  const { Content } = Layout;

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery()
  const physicianName = query.get('physician-name')

  const renderIcon = status => {
    switch(status) {
      case CaseStatusEnum.PENDING_SCHEDULE:
        return (
          <Tooltip title="Audio Case">
            <PhoneOutlined style={{ fontSize: '18px'}}/> / <StyledVideoIcon style={{ fontSize: '18px'}} />
          </Tooltip>
        )
      case CaseStatusEnum.RX_RENEWAL:
        return (
          <Tooltip title="Rx Renewal">
            <ReloadOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
      case CaseStatusEnum.RX_CHANGE:
        return (
          <Tooltip title="Rx Change">
            <SwapOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
      case CaseStatusEnum.PENDING_PRESCRIPTION:
      case CaseStatusEnum.CASE_CREATED:
      default:
        return (
          <Tooltip title="New Async Case">
            <MedicineBoxOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
    }
  }

  const columns = [
    {
      colSpan: 1,
      dataIndex: "status",
      key: "status_icon",
      width: 100,
      render: (val, i) => renderIcon(val),
    },
    {
      title: "Case No",
      colSpan: 1,
      dataIndex: "_id",
      key: "_id",
      render: (val, i) => <span className={'new-case'}>{val}</span>,
    },
    {
      title: "Patient Name",
      dataIndex: "patient",
      key: "patientId",
      render: (val, i) => <>{val?.name}</>,
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "categoryId",
      render: val => <>{val?.name}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 140,
      render: val => <>{val}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 230,
      render: val => val && (
        <div>
          <div>{dateTimeFormatter(val)}</div>
          <div>({moment(val).fromNow()})</div>
        </div>
      ),
      defaultSortOrder: 'ascend',
      sorter: () => {}
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"   
    }
  ];

  const getCaseQueueList = () => {
    const caseFilter = {
        page: 1, // strict limit of 50 per page, no pagination
        limit: 50, // strict limit of 50 per page, no pagination
        sortBy: 'createdAt',
        sortDirection: sortDirection === 'ascend' ? 1 : -1,
        status: caseStatusFilter,
        physicianId
    };

    setCaseQueueListLoading(true)
    setCaseQueueCountLoading(true)

    Promise.all([
      getCaseQueueForPhysician({ physicianId, count: 'internal' })
        .then(res => setQueueCount(res.data?.count))
        .catch(e => message.error(e.message)),
      getCaseQueueForPhysician({ physicianId, count: 'external' })
        .then(res => setExternalQueueCount(res.data?.count))
        .catch(e => message.error(e.message))
    ]).then(() => setCaseQueueCountLoading(false))
    

    getCaseQueueForPhysician(caseFilter)
      .then(res => setQueueCases(res.data?.cases))
      .catch(e => message.error(e.message))
      .finally(() => setCaseQueueListLoading(false))  
  }

  // eslint-disable-next-line
  useEffect(getCaseQueueList, [
    sortDirection,
    caseStatusFilter
  ]);

  const CountDisplay = () => {
    return (
      !caseQueueCountLoading
        ? <><span style={{ fontWeight: 'bold'}}>{queueCount || 0}</span> cases in internal provider queue<br /><span style={{ fontWeight: 'bold'}}>{externalQueueCount || 0}</span> cases in  external provider queue</>
        : <Spin />
    )
  }

  return (
    <Content>
      <StyledComponentWrapper style={{ maxWidth: '100%'}}>
        <div style={{ marginBottom: '20px'}}><Typography.Text style={{ textTransform: 'uppercase'}}><CountDisplay/></Typography.Text></div>
        <Title>{physicianName}'s Case Queue</Title>
        <StyledSelect style={{ width: '200px', textTransform: 'capitalize' }} value={caseStatusFilter} onChange={setCaseStatusFilter} disabled={caseQueueListLoading}>
            <Select.Option value={null}>All</Select.Option>
            <Select.Option value={CaseStatusEnum.PENDING_PRESCRIPTION}><MedicineBoxOutlined style={{ marginRight: '4px'}}/> New Async Cases</Select.Option>
            <Select.Option value={CaseStatusEnum.RX_RENEWAL}><ReloadOutlined style={{ marginRight: '8px'}}/>Rx Renewals</Select.Option>
            <Select.Option value={CaseStatusEnum.RX_CHANGE}><SwapOutlined style={{ marginRight: '8px'}}/>Rx Changes</Select.Option>
            <Select.Option value={CaseStatusEnum.PENDING_SCHEDULE}><PhoneOutlined/> / <StyledVideoIcon style={{ fontSize: '12px', marginRight: '8px'}} /> Sync Cases</Select.Option>
        </StyledSelect>
        <div style={{ marginTop: 0 }}>
          <Spin
          size="large"
          spinning={caseQueueListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <StyledTable
              style={{ textTransform: 'capitalize'}}
              columns={columns}
              dataSource={queueCases}
              scroll={{ x: 900 }}
              onChange={(_, __, sorter) => setSortDirection(sorter.order)}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 50
              }}
              onRow={_case => {
                return {
                  onClick: () => history.push(`/cases/${_case?._id}`),
                };
              }}
            />
          </div>
          </Spin>
        </div>
      </StyledComponentWrapper>
    </Content>
  );
};


export default PhysicianCaseQueue
