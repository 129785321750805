//import packages
import React, { useState, useEffect } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  message,
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

//import components
import AddClient from "./AddClient";
import EditClient from "./EditClient";
import ClientDetailView from "../../../components/ClientComponent/ClientDetailView";
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import authorization from "../../../auth/authorization";
import { lookUp } from "../../../api/LookUpAPI";
import { addClient, deleteClient } from "../../../api/ClientsAPI";
import { getUserClients } from "../../../api/UserTypeAPI";

const { confirm } = Modal;
const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const ClientComponent = (props) => {
  const { userType } = props;
  const [clients, setClients] = useState([]);
  const [editId, setEditId] = useState("");
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [clientDetailId, setClientDetaild] = useState();
  const [columns, setColumns] = useState([
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "View details",
      dataIndex: "d",
      key: "d",
      align: "center",
      render: (userClients, record) => (
        <Button
          shape="circle"
          icon={<FileTextOutlined />}
          onClick={() => {
            singleClientView(record._id);
          }}
        />
      ),
    },
    {
      title: "Token",
      dataIndex: "",
      key: "t",
      align: "center",
      render: (client, record) => (
        <Button
          shape="circle"
          icon={<EyeOutlined />}
          onClick={() => {
            showToken(client.token);
          }}
        />
      ),
    },
  ]);

  const fetchClients = () => {
    getUserClients().then((res) => {
      if (res.data && res.data.payload) {
        const clientData = res.data.payload.map((eachClient) => ({
          ...eachClient,
          key: eachClient._id,
        }));
        setClients(clientData);
      }
    });
  };

  const fetchTelecategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const telemedicineData = res.data.payload.map(
          (eachMedicineCategory) => ({
            ...eachMedicineCategory,
            key: eachMedicineCategory._id,
          })
        );
        setTelemedicineCategory(telemedicineData);
      }
    });
  };

  const updateColumnsInListing = () => {
    const clonnedColumns = columns.slice(0);
    if (authorization.isAllowed(userType, "updateClient")) {
      clonnedColumns.push({
        title: "Edit",
        dataIndex: "",
        key: "e",
        align: "center",
        render: (client, record) => (
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              onClientEdit(record.key);
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "deleteClient")) {
      clonnedColumns.push({
        title: "Delete",
        dataIndex: "",
        key: "x",
        align: "center",
        render: (client, record) => (
          <Button
            shape="circle"
            icon={<DeleteFilled />}
            onClick={() => {
              showDeleteConfirmation(client, record.key);
            }}
          />
        ),
      });
    }
    setColumns([...clonnedColumns]);
  };

  useEffect(() => {
    fetchClients();
    fetchTelecategory();
  }, []);

  // eslint-disable-next-line
  useEffect(updateColumnsInListing, [userType]);

  const onAddingClient = (clientDetails) => {
    addClient(clientDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully Added Client");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to Add Client. Please try again!");
          }
        }
        fetchClients();
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to Add client. Please try again!");
          }
        } else {
          message.error("Unable to Add client. Please try again!");
        }
      });
  };

  const onDeletingClient = (clientId) => {
    deleteClient(clientId)
      .then((res) => {
        const { status } = res;
        if (status && status === 204) {
          message.success("Successfully Deleted Client");
          setEditId();
        } else {
          const { data } = res;
          if (data && data.success) {
            message.success("Successfully Deleted Client");
            setEditId();
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable to delete Client. Please try again!");
            }
          }
        }
        fetchClients();
      })
      .catch((error) => {
        console.log(
          "================== delete client error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete client. Please try again!");
          }
        } else {
          message.error("Unable to delete client. Please try again!");
        }
      });
  };

  const showDeleteConfirmation = (client, clientId) => {
    confirm({
      title: `Do you want to delete ${client.name} client?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        onDeletingClient(clientId);
      },
      onCancel() {},
    });
  };

  function showToken(clientToken) {
    Modal.info({
      title: "Authorization Token",
      content: (
        <p>
          <b>{clientToken}</b>
        </p>
      ),
      onOk() {},
    });
  }

  const onClientEdit = (clientId) => {
    setEditId(clientId);
  };

  const singleClientView = (id) => {
    setClientDetaild(id);
    setViewDetailModal(true);
  };
  return (
    <>
      <Content>
        {authorization.isAllowed(userType, "updateClient") ||
        authorization.isAllowed(userType, "createClient") ? (
          <StyledComponentWrapper>
            {editId && authorization.isAllowed(userType, "updateClient") ? (
              <EditClient
                clientId={editId}
                afterClientEdit={onClientEdit}
                fetchClientsAfterUpdate={fetchClients}
                telemedicineCategory={telemedicineCategory}
              />
            ) : (
              <>
                {authorization.isAllowed(userType, "createClient") ? (
                  <AddClient
                    addClient={onAddingClient}
                    telemedicineCategory={telemedicineCategory}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </StyledComponentWrapper>
        ) : (
          <></>
        )}
        <StyledComponentWrapper>
          <Title level={2}>Clients</Title>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={clients}
                pagination={false}
              />
            </Col>
          </Row>
        </StyledComponentWrapper>
        <Modal
          destroyOnClose
          width={800}
          visible={viewDetailModal}
          onOk={() => {
            setViewDetailModal(false);
          }}
          onCancel={() => setViewDetailModal(false)}
          footer={[]}
        >
          <ClientDetailView clientDetailId={clientDetailId} />
        </Modal>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(ClientComponent);
