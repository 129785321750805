import React, { useCallback, useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, message } from "antd";
import moment from 'moment'
import { getCaseTags } from "../../../api/CaseTagsAPI"
import { StyledButton, StyledDatePicker, StyledCheckbox, ClearAllContainer } from "./StyledComponents"

const { Option } = Select;

const LS = {
  CASE_FILTERS: "caseFilters",
}

const IncrementOption = {
  DAY: 'day',
  HOUR: 'hour',
}

const { DAY, HOUR } = IncrementOption

const UnhandledDefault = {
  SPAN: 2,
  INCREMENT: DAY,
}

const CaseFilter = ({
  clients,
  states,
  telemedicineCategory,
  caseStatus,
  onFilter,
  clearFilter,
  onSearchCases,
  form,
}) => {
  const [tags, setTags] = useState([])
  const [availableCaseTags, setAvailableCaseTags] = useState([])
  const [filterByUnhandled, setFilterByUnhandled] = useState(false)
  const [unhandledWithinIncrement, setUnhandledWithinIncrement] = useState(UnhandledDefault.INCREMENT)
  const [unhandledWithinSpan, setUnhandledWithinSpan] = useState(UnhandledDefault.SPAN)

  // Add filters for on change of each of the filter items
  // Use the clear button for common filter clearing
  const onFilterCase = useCallback(() => {
    const data = form.getFieldsValue();
    const filter = {};
    filter["client"] = data.client;
    filter["telemedicine-category"] = data.telemedicineCategory;
    filter["case-id"] = data.caseId;
    filter["state-ids"] = data.stateIds;
    filter["physician-id"] = data.physicianId;
    filter["patient-id"] = data.patientId;
    filter["patient-first-name"] = data.patientFirstName;
    filter["patient-last-name"] = data.patientLastName;
    filter["patient-email"] = data.patientEmail;
    filter["physician-name"] = data.physicianName;
    filter["status"] = data.status;
    filter["type"] = data.type;
    filter["isArchived"] = data.archived;
    filter["created-date"] =
    data.createdDate && moment(data.createdDate).format("YYYY-MM-DD");
    filter["updated-date"] =
    data.updatedDate && moment(data.updatedDate).format("YYYY-MM-DD");
    filter["exclude-cancelled"] = data.excludeCancelled;
    filter["tags"] = data.tags

    if (data.unhandled) {
      filter["unhandledWithinSpan"] = unhandledWithinSpan
      filter["unhandledWithinIncrement"] = unhandledWithinIncrement
    }

    for(let [key, value] of Object.entries(filter)) {
      if (typeof value === 'string' && value?.trim().length === 0) {
        delete filter[key]
      }
    }

    // persist case filters
    localStorage.setItem(LS.CASE_FILTERS, JSON.stringify(data));
    onFilter(filter);
  }, [form, onFilter, unhandledWithinSpan, unhandledWithinIncrement]);

  const onClearFilter = () => {
    localStorage.removeItem(LS.CASE_FILTERS);
    form.resetFields();
    setTags([])
    setFilterByUnhandled(false)
    setUnhandledWithinSpan(UnhandledDefault.SPAN)
    setUnhandledWithinIncrement(UnhandledDefault.INCREMENT)
    clearFilter();
  };

  const fetchAvailableCaseTags = () => {
    getCaseTags()
      .then(res => {
        if (res.data) {
          const { caseTags } = res.data
          setAvailableCaseTags(caseTags.map(tagObj => tagObj.tag))
        }
      })
      .catch(err => {
        message.error("Error retrieving available case tags.");
      })
  }

  // load the previously used case filters from localStorage
  useEffect(() => {
    const filtersString = localStorage.getItem(LS.CASE_FILTERS);
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      filters.createdDate && (filters.createdDate = moment(filters.createdDate))
      filters.updatedDate && (filters.updatedDate = moment(filters.updatedDate))
      form.setFieldsValue(filters);
      filters["completed-within-span"] && setUnhandledWithinSpan(filters["completed-within-span"])
      filters["completed-within-increment"] && setUnhandledWithinIncrement(filters["completed-within-increment"])
      filters["tags"] && setTags(filters["tags"])
    }
    fetchAvailableCaseTags()
  }, [form])

  useEffect(() => {
    onFilterCase();
  }, [tags, filterByUnhandled, onFilterCase])

  const onChangeUnhandledCheckbox = (e) => setFilterByUnhandled(e.target.checked)

  return (
    <Form form={form} name="physician_details" layout={"vertical"}>
      <Row gutter={24} justify="space-between">
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="caseId" label="Case Id">
            <Input placeholder="Filter by Case Id" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Item name="physicianId" label="Physician Id">
            <Input placeholder="Filter by Physician Id" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="physicianName" label="Physician Name">
            <Input placeholder="Filter by Physician Name" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} xl={7}>
          <Form.Item name="telemedicineCategory" label="Category">
            <Select
              allowClear
              placeholder="Filter by telemedicine category"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={onFilterCase}
            >
              {telemedicineCategory.map((c) => <Option key={c.key} value={c._id}>{c.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="patientId" label="Patient Id">
            <Input placeholder="Filter by Patient Id" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="patientFirstName" label="Patient First Name">
            <Input placeholder="Filter by Patient First Name" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="patientLastName" label="Patient Last Name">
            <Input placeholder="Filter by Patient Last Name" onChange={onFilterCase} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="patientEmail" label="Patient Email">
            <Input placeholder="Filter by Patient Email" onChange={onFilterCase} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-around">
        <Col xs={12} sm={12} md={12} lg={6} xl={8}>
          <Form.Item name="status" label="Status(es)">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={[]}
              onChange={onFilterCase}
            >
              {caseStatus.map(status => <Option key={status._id} value={status.name}>{status.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={8}>
          <Form.Item name="stateIds" label="State(s)">
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={[]}
            onChange={onFilterCase}
          >
            {states.map(state => <Option key={state.key}>{state.name}</Option>)}
          </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={5} xl={8}>
          <Form.Item name="client" label="Clients">
            <Select
              allowClear
              placeholder="Filter by Clients"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={onFilterCase}
            >
              {clients.map(({ _id, name }) => <Option key={_id} value={_id}>{name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} justify="space-around"> 
        <Col xs={12} sm={12} md={12} lg={5} xl={8}>
          <Form.Item name="createdDate" label="Created Date">
            <StyledDatePicker
              placeholder="Filter by Created Date"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={onFilterCase}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={5} xl={8}>
          <Form.Item name="updatedDate" label="Updated Date">
            <StyledDatePicker
              placeholder="Filter by Updated Date"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={onFilterCase}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
      <Col xs={14} sm={14} md={7} lg={6} xl={5}>
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Form.Item name="archived" valuePropName="checked" noStyle>
            <StyledCheckbox onChange={onFilterCase}>Archived</StyledCheckbox>
          </Form.Item>
          <Form.Item name="excludeCancelled" valuePropName="checked" noStyle>
            <StyledCheckbox onChange={onFilterCase}>Exclude Cancelled</StyledCheckbox>
          </Form.Item>
          <div style={{ width: '400px', display: 'flex', gap: '5px', alignItems: 'center'}}>
            <Form.Item name="unhandled" valuePropName="checked">
              <StyledCheckbox onChange={onChangeUnhandledCheckbox} checked={filterByUnhandled}>Cases not handled within</StyledCheckbox>
            </Form.Item>
            <Form.Item name="completed-within-span" style={{ width: '60px'}}>
              <Select onChange={setUnhandledWithinSpan} defaultValue={unhandledWithinSpan}>
                {Array.from({length: 24}, (_, i) => <Option key={i} value={i + 1}>{i + 1}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item name="completed-within-increment" >
              <Select onChange={setUnhandledWithinIncrement} defaultValue={unhandledWithinIncrement} style={{ width: '100px'}}>
                <Option key={HOUR} value={HOUR}>Hours</Option>
                <Option key={DAY} value={DAY}>Days</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        </Col>
        <Col xs={10} sm={10} md={{ span: 4, offset: 13}} lg={{ margin: 2, offset: 13}} xl={{ margin: 2, offset: 15}}>
          <ClearAllContainer>
            <StyledButton type="secondary" onClick={onClearFilter}>
              Clear all
            </StyledButton>
            <StyledButton type="primary" onClick={onSearchCases}>
              Search
            </StyledButton>
          </ClearAllContainer>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={12} lg={5} xl={6}>
          <Form.Item name="tags" label="Tags">
            <Select
              mode="tags"
              placeholder="Filter by Case Tags"
              onChange={setTags}
            >
              {availableCaseTags?.map(tag => <Option key={tag}>{tag}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CaseFilter;
