import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Form, Typography, message, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { lookUpDetails } from "../../api/LookUpAPI";
import {
  StyledText,
  StyledCol,
  StyledDivider,
  StyledSubTitle,
} from "../DetailViewComponent";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StateDetailsComponent = ({ stateDetailId }) => {
  const [singleStateDetails, setSingleStateDetail] = useState({
    name: null,
    addedTeleMedicineCategory: [],
    pdmpResource: null
  });
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      title: "Telemedicine Category",
      width: 100,
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Consultation Type",
      width: 100,
      dataIndex: "consultationType",
      key: "consultationType",
    },
    {
      title: "Renewal Consultation Type",
      width: 100,
      dataIndex: "renewalConsultationType",
      key: "renewalConsultationType",
    },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacyName",
      key: "pharmacyName",
      width: 100,
    },
  ];

  useEffect(() => {
    fetchSingleStateView(stateDetailId);
  }, [stateDetailId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSingleStateView = (stateId) => {
    setLoading(true);
    lookUpDetails("state", stateId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const { name, consultationType, renewalConsultationType, pharmacy, pdmpResource } = res.data.payload;
          const consultationTypeDetails = [];
          if (pharmacy && pharmacy.length) {
            consultationType.forEach((eachConsultationType) => {
              const correspondingPharmacy = pharmacy.filter(
                (eachPharmacy) =>
                  eachPharmacy.categoryId === eachConsultationType.categoryId
              );
              if (correspondingPharmacy && correspondingPharmacy.length) {
                consultationTypeDetails.push({
                  ...eachConsultationType,
                  renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
                  pharmacyName: correspondingPharmacy[0].pharmacyName,
                  pharmacyId: correspondingPharmacy[0].pharmacyId,
                });
              } else {
                consultationTypeDetails.push({
                  ...eachConsultationType,
                  renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
                });
              }
            });
          } else {
            consultationType.forEach((eachConsultationType) => {
              consultationTypeDetails.push({
                ...eachConsultationType,
                renewalConsultationType: renewalConsultationType?.find(({ categoryId }) => eachConsultationType.categoryId === categoryId)?.consultationType,
              });
            });
          }
          const updatedStateDetails = {
            name: name,
            addedTeleMedicineCategory: [...consultationTypeDetails],
            pdmpResource
          };
          setSingleStateDetail(updatedStateDetails);
          message.success("Successfully loaded State details");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to fetch state details");
      });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Form name="state-details">
        <Title level={2}>State Details</Title>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="name" label="State">
              <StyledText strong>
                {singleStateDetails && singleStateDetails.name
                  ? singleStateDetails.name
                  : ""}
              </StyledText>
            </Form.Item>
            { singleStateDetails.pdmpResource && (
              <>
                PDMP Resource: 
                <StyledText strong style={{ marginLeft: 10 }}>
                  <a target="_blank" rel="noopener noreferrer" href={singleStateDetails.pdmpResource}>{singleStateDetails.pdmpResource}</a>
                </StyledText>
              </>
            )}
          </Col>
          <StyledCol span={24}>
            <StyledSubTitle level={4}>Consultation and Pharmacy Details</StyledSubTitle>
            <StyledDivider />
          </StyledCol>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={singleStateDetails.addedTeleMedicineCategory}
              scroll={{ x: 720 }}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default StateDetailsComponent;
