//import packages
import React, { useState, useEffect } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  message,
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";
import {
  addtelecategory,
  deletetelecategory,
} from "../../../api/TelecategoryAPI";
import EditTelemedicine from "./EditTelemedicine";
import AddTelemedicine from "./AddTelemedicine";
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import authorization from "../../../auth/authorization";

const { confirm } = Modal;
const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const TeleMedicineComponent = (props) => {
  const { userType } = props;
  const [Telecategory, setTelecategory] = useState([]);
  const [editId, setEditId] = useState("");
  const [columns, setColumns] = useState([
    { title: "Name", dataIndex: "name", key: "name", width: 320 },
    { title: "Slug", dataIndex: "slug", key: "slug", width: 320 },
  ]);

  const updateColumnsInListing = () => {
    const clonnedColumns = columns.slice(0);
    if (authorization.isAllowed(userType, "updateTeleMedicineCategory")) {
      clonnedColumns.push({
        title: "Edit",
        dataIndex: "",
        key: "e",
        width: 120,
        align: "center",
        render: (state, record) => (
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              onStateEdit(record.key);
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "deleteTeleMedicineCategory")) {
      clonnedColumns.push({
        title: "Delete",
        dataIndex: "",
        key: "x",
        width: 120,
        align: "center",
        render: (state, record) => (
          <Button
            shape="circle"
            icon={<DeleteFilled />}
            onClick={() => {
              showDeleteConfirmation(state, record.key);
            }}
          />
        ),
      });
    }
    setColumns([...clonnedColumns]);
  };

  const fetchTelecategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setTelecategory(stateData);
      }
    });
  };

  useEffect(() => {
    fetchTelecategory();
  }, []);

  const initCallsForComponent = () => {
    updateColumnsInListing();
  };

  // eslint-disable-next-line
  useEffect(initCallsForComponent, [userType]);

  const onAddingState = (telemedicineCategoryDetails) => {
    addtelecategory(telemedicineCategoryDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully added Telemedicine Category");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Add Telemedicine Catgeory. Please try again!"
            );
          }
        }
        fetchTelecategory();
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Add Telemedicine Catgeory. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Add Telemedicine Category. Please try again!"
          );
        }
      });
  };

  const onDeletingState = (stateId) => {
    deletetelecategory(stateId)
      .then((res) => {
        const { status } = res;
        if (status && status === 204) {
          message.success("Successfully deleted Telemedicine Category");
          setEditId();
        } else {
          const { data } = res;
          if (data && data.success) {
            message.success("Successfully deleted Telemedicine Category");
            setEditId();
          } else {
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to delete Telemedicine Catgeory. Please try again!"
              );
            }
          }
        }
        fetchTelecategory();
      })
      .catch((error) => {
        console.log(
          "================== delete telemedicine category error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Delete Telemedicine Catgeory. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Delete Telemedicine Category. Please try again!"
          );
        }
      });
  };

  const showDeleteConfirmation = (state, stateId) => {
    confirm({
      title: `Do you want to delete ${state.name} telemedicine category?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        onDeletingState(stateId);
      },
      onCancel() {},
    });
  };

  const onStateEdit = (stateId) => {
    setEditId(stateId);
  };

  return (
    <>
      <Content>
        {authorization.isAllowed(userType, "updateTeleMedicineCategory") ||
        authorization.isAllowed(userType, "createTeleMedicineCategory") ? (
          <StyledComponentWrapper>
            {editId &&
            authorization.isAllowed(userType, "updateTeleMedicineCategory") ? (
              <EditTelemedicine
                stateId={editId}
                afterStateEdit={onStateEdit}
                fetchTelecategoryAfterUpdate={fetchTelecategory}
              />
            ) : (
              <>
                {authorization.isAllowed(
                  userType,
                  "createTeleMedicineCategory"
                ) ? (
                  <AddTelemedicine addState={onAddingState} />
                ) : (
                  <></>
                )}
              </>
            )}
          </StyledComponentWrapper>
        ) : (
          <></>
        )}
        <StyledComponentWrapper>
          <Title level={2}>Telemedicine Categories</Title>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={Telecategory}
                pagination={false}
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(TeleMedicineComponent);
