/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Row,
  Col,
  Spin,
  message,
  Input,
  Button,
  InputNumber,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import styled from "styled-components";

//import API functions
import {
  getPhysicianPayments,
  updatePhysicianPaymentDetails,
  copyGlobalConsultRatesToExternalPhysician
} from "../../api/PhysiciansAPI";

const StyledSection = styled.section`
  overflow-y: auto;
  height: 560px;
  padding: 8px;
`;

const StyledHeaderSection = styled.section`
  padding: 8px;
`;

const StyledButtonWrapperSection = styled.section`
  text-align: right;
  padding: 12px 0;
`;

const StyledHeader = styled.section`
  display: flex;
  justify-content: space-between;
`;

const { Title } = Typography;

const EditExternalPhysicianPayments = ({
  physicianId,
  viewModal,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const handleModalClose = () => {
    closeModal(false);
  };

  const handlePaymentDetailsSubmit = () => {
    setLoading(true);
    const data = {
      consultRates: [...paymentDetails],
    };
    updatePhysicianPaymentDetails(physicianId, data)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully Updated Physician Payments Details");
          setEdited(false);
          fetchPhsyicianPaymentDetails();
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update physician payments details. Please try again!"
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update physician payments details. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to update physician payments details. Please try again!"
          );
        }
        setLoading(false);
      });
  };

  const fetchPhsyicianPaymentDetails = () => {
    setLoading(true);
    getPhysicianPayments(physicianId)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          console.log("========= payload ==================", payload);
          setPaymentDetails(payload);
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to fetch physician payment details. Please try again!"
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to load physician payment details. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to load physician payment details. Please try again!"
          );
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPhsyicianPaymentDetails(physicianId);
  }, [physicianId]);

  const asyncCostChange = (value, eachPaymentDetails, physicianPaymentDetails) => {
    console.log(
      "===============debouced async cost change ========================",
      value,
      eachPaymentDetails
    );
    const clonnedPaymentDetails = physicianPaymentDetails.slice(0);
    const updatedPaymentSection = clonnedPaymentDetails.find(
      (eachPayment) =>
        eachPayment.clientId === eachPaymentDetails.clientId &&
        eachPaymentDetails.telemedicineCategoryId ===
          eachPayment.telemedicineCategoryId
    );
    if (updatedPaymentSection) {
      updatedPaymentSection.async = value;
    }
    setPaymentDetails([...clonnedPaymentDetails]);
    setEdited(true);
  };

  const debounceAsyncCostChange = useCallback(
    debounce(asyncCostChange, 500),
    []
  );

  const syncCostChange = (value, eachPaymentDetails, physicianPaymentDetails) => {
    console.log(
      "===============debouced sync cost change ========================",
      value,
      eachPaymentDetails
    );
    const clonnedPaymentDetails = physicianPaymentDetails.slice(0);
    const updatedPaymentSection = clonnedPaymentDetails.find(
      (eachPayment) =>
        eachPayment.clientId === eachPaymentDetails.clientId &&
        eachPaymentDetails.telemedicineCategoryId ===
          eachPayment.telemedicineCategoryId
    );
    if (updatedPaymentSection) {
      updatedPaymentSection.sync = value;
    }
    setPaymentDetails([...clonnedPaymentDetails]);
    setEdited(true);
  };

  const debounceSyncCostChange = useCallback(debounce(syncCostChange, 500), []);

  const handleAsyncCostChange = (value, eachPaymentDetails) => {
    debounceAsyncCostChange(value, eachPaymentDetails, paymentDetails);
  };

  const handleSyncCostChange = (value, eachPaymentDetails) => {
    debounceSyncCostChange(value, eachPaymentDetails, paymentDetails);
  };

  const handleCopyGlobalConsultRates = () => {
    copyGlobalConsultRatesToExternalPhysician(physicianId)
      .then(fetchPhsyicianPaymentDetails)
      .then(() => message.success('global consult costs applied successfully'))
      .catch(() => message.error('unable to apply global consult costs'));
  }

  return (
    <Modal
      title="Edit Consult Cost for External Physician"
      visible={viewModal}
      footer={null}
      onCancel={handleModalClose}
      width={920}
      destroyOnClose
    >
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <article>
          <StyledHeader>
            <Title level={3}>Edit Consult Cost</Title>
            <Button onClick={handleCopyGlobalConsultRates}>Copy Global Consult Costs</Button>
          </StyledHeader>
          <StyledHeaderSection>
            <Row gutter={[12, 12]}>
              <Col span={8}>
                <h5>Client</h5>
              </Col>
              <Col span={8}>
                <h5>Telemedicine Category</h5>
              </Col>
              <Col span={4}>
                <h5>Async Consult Cost</h5>
              </Col>
              <Col span={4}>
                <h5>Sync Consult Cost</h5>
              </Col>
            </Row>
          </StyledHeaderSection>
          <StyledSection>
            {paymentDetails.map((eachPaymentDetails) => (
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Input
                    size="medium"
                    value={eachPaymentDetails.clientName}
                    disabled
                  />
                </Col>
                <Col span={8}>
                  <Input
                    size="medium"
                    value={eachPaymentDetails.telemedicineCategoryName}
                    disabled
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    value={eachPaymentDetails.async}
                    onChange={(value) => {
                      handleAsyncCostChange(value, eachPaymentDetails);
                    }}
                    placeholder="Async Cost"
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    value={eachPaymentDetails.sync}
                    onChange={(value) => {
                      handleSyncCostChange(value, eachPaymentDetails);
                    }}
                    placeholder="Sync Cost"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            ))}
          </StyledSection>
          <StyledButtonWrapperSection>
            <Button
              type="primary"
              size="large"
              onClick={handlePaymentDetailsSubmit}
              disabled={!edited}
            >
              Save Changes
            </Button>
          </StyledButtonWrapperSection>
        </article>
      </Spin>
    </Modal>
  );
};

export default EditExternalPhysicianPayments;
