//import packages
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import styled from "styled-components";

//import components
import AddQuestionnaire from "./AddQuestionnaire";

//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";

const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const QuestionnaireComponent = () => {
  const [clients, setClients] = useState([]);

  const fetchClients = () => {
    lookUp("client").then((res) => {
      if (res.data && res.data.payload) {
        const clientData = res.data.payload.map((eachClient) => ({
          ...eachClient,
          key: eachClient._id,
        }));
        setClients(clientData);
      }
    });
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <>
      <Content>
        <StyledComponentWrapper>
            <AddQuestionnaire
              clients={clients}
            />
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default QuestionnaireComponent;
