import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  DatePicker,
  TimePicker,
  InputNumber,
  Typography,
  Row,
  Radio,
  Col,
  Card,
  Spin,
  Checkbox,
} from "antd";
import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import { lookUp } from "../../api/LookUpAPI";
import { verifyPhysician, physicianSignUp } from "../../api/PhysiciansAPI";

const { TextArea } = Input;
const { RangePicker } = TimePicker;
const { Text } = Typography;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 40px 40px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledFlexBreaker = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const StyledFormItem = styled(Form.Item)`
  width: 47%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledFormItemName = styled(Form.Item)`
  width: 40%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const StyledRegisterButton = styled(Button)`
  margin-left: 32%;
  margin-right: 32%;
  width: 36%;
  @media (max-width: 500px) {
    margin: 0;
    width: 100%;
  }
`;

const StyledRowCalendarDays = styled(Row)`
  align-items: center;
`;

const StyledTextCalendarDays = styled(Text)`
  padding: 0 8px;
`;

const StyledTimeSlotCard = styled(Card)`
  margin: 12px 0 4px 0;
  background: unset;
  border: 1px solid #e1e6ed;
  > .ant-card-body {
    padding: 12px 8px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100% !important;
  height: 40px !important;
  background: unset !important;
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledColSelectAll = styled(Col)`
  margin-left: auto;
`;

const PhysicianRegister = () => {
  const [form] = Form.useForm();

  const [fetchstates, SetFetchstates] = useState();
  const [fetchTelemedicine, setFetchTelemedicine] = useState();
  const [fetchClients, setFetchClients] = useState();
  const [DOB, setDOB] = useState();
  const [loading, setLoading] = useState(false);
  const [daysAvailableValidatedStatus, setDaysAvailableValidatedStatus] = useState(null);
  const [availableDaysHelp, setavailableDaysHelp] = useState(null);
  const [checkboxSelect, setCheckboxSelect] = useState(false);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [fetchDoctorCredentials, setFetchDoctorCredentials] = useState();
  const [employmentStatus, setEmploymentStatus] = useState([]);
  const [question1Change, setQuestion1Change] = useState();
  const [question2Change, setQuestion2Change] = useState();
  const [question5Change, setQuestion5Change] = useState();

  const timeZoneList = moment.tz.names();

  const [availableDays, setAvailableDays] = useState([
    {
      key: "Sunday",
      label: "Sunday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Monday",
      label: "Monday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Tuesday",
      label: "Tuesday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Wednesday",
      label: "Wednesday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Thursday",
      label: "Thursday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Friday",
      label: "Friday",
      status: "unchecked",
      selectedTime: [],
    },
    {
      key: "Saturday",
      label: "Saturday",
      status: "unchecked",
      selectedTime: [],
    },
  ]);

  const handleRangePickerChange = (value, key) => {

    if (daysAvailableValidatedStatus === "error") {
      setavailableDaysHelp("");
      return setDaysAvailableValidatedStatus("");
    }

    const clonnedAvailableDays = availableDays.slice(0);
    const selectedDay = clonnedAvailableDays.find((eachDay) => eachDay.key === key);

    if (selectedDay) {
      selectedDay.selectedTime = value
        ? [...value]
        : []
    }

    setAvailableDays(clonnedAvailableDays);
  };

  const handleCheckboxChange = (event, key) => {

    if (daysAvailableValidatedStatus === "error") {
      setavailableDaysHelp("");
      return setDaysAvailableValidatedStatus("");
    }

    const clonnedAvailableDays = availableDays.slice(0);
    const selectedDay = clonnedAvailableDays.find((eachDay) => eachDay.key === key);

    if (selectedDay) {
      selectedDay.status = event.target.checked
        ? "checked"
        : "unchecked"
      
      if (!event.target.checked) {
        selectedDay.selectedTime = [];
      }
    }
    setAvailableDays(clonnedAvailableDays);
  };


  async function handleNPICheck(fName, lName, npi) {
    try {
      const { status, data } = await verifyPhysician(npi, fName, lName)

      if (status === 200) {
        if (data?.message !== "Valid NPI number") {
          message.error("NPI or first and last name is invalid");
          return false
        }
        return true
      }

      message.error("NPI verfication service failed");
      return false
    } catch ({ response }) {
      if (response?.data?.message) message.error(response.data.message)
      else message.error("Unable to verify NPI number. Please try to register after a while!");
      return false
    }
  }

  const handleDatechange = (date, dateString) => {
    setDOB(dateString);
  };

  const onFinish = async values => {
    setavailableDaysHelp("");
    setDaysAvailableValidatedStatus("");

    try {
      const validNPI = await handleNPICheck(values.firstName, values.lastName, values.npi)
      if (!validNPI) return false
    } catch (e) {
      message.error(e)
      return false
    }

    if (availableDays.every(eachDay => eachDay.status === "unchecked")) {
      setDaysAvailableValidatedStatus("error");
      setavailableDaysHelp("Please add atleast one available day");
      return false;
    } 
    
    const filteredAvailableDays = availableDays.filter(eachDay => eachDay.status === "checked");

    if (filteredAvailableDays.length && !filteredAvailableDays.every(eachDay => eachDay.selectedTime.length === 2)) {
      setDaysAvailableValidatedStatus("error");
      setavailableDaysHelp("Please add start time and end time for all the selected days");
      return false;
    } 

    const convertSpecificTimeZone = (dateTime, requiredoffset) => {
      const localTimeZone = moment.tz.guess(true);
      const dateTimeString = moment(dateTime).format("YYYY-MM-DD HH:mm");
      return moment.tz(dateTimeString, requiredoffset || localTimeZone).utc().format();
    };

    const daysAvailable = filteredAvailableDays.map(eachDay => (
      {
        day: eachDay.key,
        startTime: convertSpecificTimeZone(eachDay.selectedTime[0], values.offSet),
        endTime: convertSpecificTimeZone(eachDay.selectedTime[1], values.offSet),
      }
    ))

    const questions = [
      {
        question: "Do you have any telemedicine experience?",
        answer: values.question1,
        reason: values.question1Text,
      },

      {
        question: "Are you board-certified in primary care for adults?",
        answer: values.question2,
        reason: values.question2Text,
      },
      {
        question:
          "Do you have at least 10 years of experience post-residency in your chosen area of expertise?",
        answer: values.question3,
      },
      {
        question:
          "To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare and Protection Data Bank?",
        answer: values.question4,
      },
      {
        question:
          "Do you have any experience with Integrative and/or Alternative medical practices?",
        answer: values.question5,
        reason: values.question5Text,
      },
    ];

    setLoading(true);

    const physician = {
      ...values,
      address2: values.address2 || "",
      DOB,
      daysAvailable: JSON.stringify(daysAvailable),
      isAdmin: true,
      questions: JSON.stringify(questions),
      offSet: values.offSet
        ? moment.tz(values.offSet).utcOffset()
        : new Date().getTimezoneOffset(),
      offSetName: values.offSet || moment.tz.guess(true),
    }

    physicianSignUp(physician)
      .then(({ data }) => {
        if (data?.status) {
          // form.resetFields();

          setAvailableDays([
            {
              key: "Sunday",
              label: "Sunday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Monday",
              label: "Monday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Tuesday",
              label: "Tuesday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Wednesday",
              label: "Wednesday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Thursday",
              label: "Thursday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Friday",
              label: "Friday",
              status: "unchecked",
              selectedTime: [],
            },
            {
              key: "Saturday",
              label: "Saturday",
              status: "unchecked",
              selectedTime: [],
            },
          ]);

          return message.success("Successfully Registered Physician");
        } 

        if (data?.message)
          return message.error(data.message);

        message.error("Physician Registration Failed. Please try again!");
      }).catch((error) => {
        const { response, data } = error
        if (!response) return console.log(error)

        if (response.status === 409 && response.data.message)
          return message.error(response.data.message);

        if (data?.message)
          return message.error(data.message);
          
        message.error("Physician Registration Failed. Please try again!");
      }).finally(() => setLoading(false))
  };

  useEffect(() => {
    const responseState = [];
    const responsetelemedicine = [];
    const responseClient = [];
    const responseCredential = [];

    lookUp("state").then(({ data }) => {
      if (data) {
        data.payload.map((state) => {
          return responseState.push({
            key: state._id,
            value: state.name,
            text: state.name,
          });
        });
        SetFetchstates(responseState);
      }
    });

    lookUp("tele-medicine-category").then(({ data }) => {
      if (data) {
        data.payload.map((state) => {
          return responsetelemedicine.push({
            key: state._id,
            value: state.name,
            text: state.name,
          });
        });
        setFetchTelemedicine(responsetelemedicine);
      }
    });

    lookUp("credential").then(({ data }) => {
      if (data) {
        data.payload.map((eachCredential) => {
          return responseCredential.push({
            key: eachCredential._id,
            value: eachCredential.name,
            text: eachCredential.name,
          });
        });
      }
      
      setFetchDoctorCredentials(responseCredential);
    });

    lookUp("client").then(({ data }) => {
      if (data) {
        data.payload.map((state) => {
          return responseClient.push({
            key: state._id,
            value: state.name,
            text: state.name,
          });
        });
        setFetchClients(responseClient);
      }
    });

    lookUp("physician-group").then(({ data }) => {
      if (data) {
        const { payload } = data;
        const allowedPhysicianGroup = ["external physicians", "internal physicians", "steadymd physicians"];

        if (payload?.length) {
          const allowedPhysicianGroupList = payload.filter(({ name }) => allowedPhysicianGroup.includes(name));
          const physicianGroupData = allowedPhysicianGroupList.map(({ _id, name }) => ({ key: _id, value: _id, text: name }))
          setEmploymentStatus(physicianGroupData);
        }
      }
    });
  }, []);

  const showSelectAllOptionForSchedules = () => {
    const count = availableDays.filter(({ status, selectedTime }) => status === "checked" && selectedTime.length === 2).length
    setCheckboxSelect(count === 1);
    
    if (count === 1) setCheckedSelectAll(false);
  };

  useEffect(showSelectAllOptionForSchedules, [availableDays]);

  const onChangeApplyAllCheckbox = () => {
    setCheckedSelectAll(true);
    const selectedDay = availableDays.find(({ status, selectedTime }) => status === "checked" && selectedTime.length === 2);

    const applyAllAvailableDays = availableDays.map((itemDays) => ({
      ...itemDays,
      status: "checked",
      selectedTime: selectedDay.selectedTime,
    }));

    setAvailableDays(applyAllAvailableDays);
  };

  return (
    <StyledComponentWrapper>
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Title level={2}> Add Physician </Title>
        <Form
          form={form}
          name=" physician register"
          layout="vertical"
          size="large"
          onFinish={onFinish}
        >
          <StyledFlexBreaker>
            <Form.Item
              name="credential"
              label="Credential"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Select"
                getPopupContainer={(trigger) => trigger.parentElement}
                options={fetchDoctorCredentials?.map(({ value, text }) => ({ value, label: text }))}
              />
            </Form.Item>
            <StyledFormItemName
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please input your First Name!", whitespace: true }]}
            >
              <Input placeholder="First Name"/>
            </StyledFormItemName>
            <StyledFormItemName
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please input your Last Name!", whitespace: true }]}
            >
              <Input placeholder="Last Name"/>
            </StyledFormItemName>
          </StyledFlexBreaker>

          <Form.Item
            name="npi"
            label="NPI"
            rules={[{ required: true,  message: "Please input your NPI Number!" }]}
          >
            <InputNumber placeholder="NPI" style={{ width: "100%" }}/>
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="E-mail" />
          </Form.Item>

          <StyledFlexBreaker>
            <StyledFormItem
              name="teleMedicineCategories"
              label="Telemedicine Categories"
              rules={[{ required: true, message: "Please input your Telemedicine Categories!" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select Category"
                showArrow
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, { children }) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={fetchTelemedicine?.map(({ key, text }) => ({ value: key, label: text }))}
              />
            </StyledFormItem>

            <StyledFormItem
              name="states"
              label="States"
              rules={[{ required: true, message: "Please input your States!" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select States"
                showArrow
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, { children }) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                options={fetchstates?.map(({ key, text }) => ({ value: key, label: text }))}
              />
            </StyledFormItem>
          </StyledFlexBreaker>

          <StyledFlexBreaker>
            <StyledFormItem
              name="clients"
              label="Clients"
              rules={[{ required: true,  message: "Please Input your Clients!" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select Clients"
                showArrow
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, { children }) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                options={fetchClients?.map(({key, text }) => ({ value: key, label: text }))}
              />
            </StyledFormItem>

            <StyledFormItem
              name="DOB"
              label="Date of Birth"
              rules={[{ required: true, message: "Please Input your DOB!" }]}
            >
              <StyledDatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select DOB"
                onChange={handleDatechange}
                disabledDate={e => !!e && e.valueOf() > Date.now()}
              />
            </StyledFormItem>
          </StyledFlexBreaker>
          <Form.Item
            name="address1"
            label="Address Line 1"
            rules={[{required: true, message: "Please Input your Address!", whitespace: true}]}
          >
            <Input placeholder="Address Line 1" />
          </Form.Item>

          <Form.Item
            name="address2"
            label="Address Line 2"
            defaultValue=""
            rules={[{ whitespace: true }]}
          >
            <Input placeholder="Address Line 2" />
          </Form.Item>

          <StyledFlexBreaker>
            <StyledFormItem
              name="city"
              label="City"
              rules={[{ required: true, message: "Please Input your City!", whitespace: true }]}
            >
              <Input placeholder="city" />
            </StyledFormItem>

            <StyledFormItem
              name="state"
              label="State"
              rules={[{ required: true, message: "Please Input your State!", whitespace: true }]}
            >
              <Select
                placeholder="Select State"
                getPopupContainer={(trigger) => trigger.parentElement}
                options={fetchstates?.map(({ text }) => ({ value: text, label: text}))}
              />
            </StyledFormItem>
          </StyledFlexBreaker>

          <StyledFlexBreaker>
            <StyledFormItem
              name="zipCode"
              label="Zip Code"
              rules={[
                {
                  required: true,
                  message: "Please Input your Zip Code!",
                },
              ]}
            >
              <Input placeholder="Zip Code" style={{ width: "100%" }} />
            </StyledFormItem>

            <StyledFormItem
              name="phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please Input your Phone Number!",
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter Phone Number"
                onlyCountries={["us"]}
                disableDropdown="false"
                country="us"
              />
            </StyledFormItem>
          </StyledFlexBreaker>
          <Form.Item
            name="bio"
            label="Bio"
            rules={[
              {
                required: true,
                message: "Please Input your Bio!",
                whitespace: true,
              },
            ]}
          >
            <TextArea
              placeholder="Provide your Bio"
              style={{ height: "100px" }}
            />
          </Form.Item>

          <StyledFlexBreaker>
            <StyledFormItem
              name="employmentStatus"
              label="Employment Status"
              rules={[
                {
                  required: true,
                  message: "Please Input your Employment Status",
                },
              ]}
            >
              <Select
                placeholder="Select Employment Status"
                getPopupContainer={trigger => trigger.parentElement}
                style={{ textTransform: 'capitalize'}}
                options={employmentStatus?.map(({ value, text }) => ({ value, label: text }))}
              />
            </StyledFormItem>
            <StyledFormItem name="offSet" label="Time Zone">
              <Select
                showSearch
                allowClear={true}
                className="timezone-select"
                placeholder="Select the required Time Zone"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                options={timeZoneList?.map(zone => ({ value: zone, label: zone }))}
              />

            </StyledFormItem>
          </StyledFlexBreaker>

          <Form.Item
            name="calendardays"
            label="Available Calendar Days"
            validateStatus={daysAvailableValidatedStatus}
            required
            help={availableDaysHelp}
            rules={[
              () => ({
                validator() {
                  if (availableDays.every(eachDay => eachDay.status === "unchecked")) {
                    setDaysAvailableValidatedStatus("error");
                    setavailableDaysHelp("Please add atleast one available day");
                    return Promise.reject();
                  }

                  const filteredAvailableDays = availableDays.filter(eachDay => eachDay.status === "checked");

                  if (filteredAvailableDays.length && !filteredAvailableDays.every(eachDay => eachDay.selectedTime.length === 2)) {
                    setDaysAvailableValidatedStatus("error");
                    setavailableDaysHelp("Please add start time and end time for all the selected days");
                    return Promise.reject();
                  }

                  setDaysAvailableValidatedStatus("");
                  setavailableDaysHelp("");
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Row className="timeslot-section-wrapper">
              <Col>
                <Text>
                  Please select a day, after that add a Start time and End Time
                  to provide availability
                </Text>
              </Col>
            </Row>
            <Row>
              <StyledColSelectAll>
                <Checkbox
                  checked={checkedSelectAll && !checkboxSelect}
                  disabled={!checkboxSelect}
                  onChange={onChangeApplyAllCheckbox}
                >
                  Apply to all
                </Checkbox>
              </StyledColSelectAll>
            </Row>
            <Row className="timeslot-section-wrapper">
              {
                availableDays?.map(({
                  key,
                  status,
                  label,
                  selectedTime
                }) => (
                  <Col key={key} span={24}>
                    <StyledTimeSlotCard key={`card-${key}`} className="timeslot-card">
                      <StyledRowCalendarDays gutter={[8, 8]} justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                          <Checkbox
                            checked={status === "checked"}
                            onChange={e => handleCheckboxChange(e, key)}
                          >
                            <CalendarOutlined />
                            <StyledTextCalendarDays strong>{label}</StyledTextCalendarDays>
                          </Checkbox>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                          <StyledRangePicker
                            size="large"
                            className="register-rande-picker"
                            showTime={{ format: "h:mm a" }}
                            format="h:mm a"
                            onChange={value => handleRangePickerChange(value, key)}
                            value={selectedTime}
                            disabled={status === "unchecked"}
                          />
                        </Col>
                      </StyledRowCalendarDays>
                    </StyledTimeSlotCard>
                  </Col>
                ))
              }
            </Row>
          </Form.Item>
          <Form.Item label="Do you have any telemedicine experience?" required>
            <Form.Item name="question1" rules={[{ required: true, message: "required"}]}>
              <Radio.Group onChange={({ target }) => setQuestion1Change(target.value)}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            {
              question1Change === "yes" && (
                <Form.Item name="question1Text" rules={[{ required: true, message: "required"}]}>
                  <TextArea placeholder="Please provide details"></TextArea>
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            label="Are you board-certified in primary care for adults?"
            required
          >
            <Form.Item
              name="question2"
              rules={[{ required: true, message: "required" }]}
            >
              <Radio.Group onChange={({ target }) => setQuestion2Change(target.value)}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            {
              question2Change === "yes" && (
                <Form.Item name="question2Text" rules={[{ required: true, message: "required" } ]}>
                  <Select
                    placeholder="Select Medicine"
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    <Select.Option key="Family Medicine" value="Family Medicine">Family Medicine</Select.Option>
                    <Select.Option key="Internal Medicine" value="Internal Medicine">Internal Medicine</Select.Option>
                    <Select.Option key="Emergency Medicine" value="Emergency Medicine">Emergency Medicine</Select.Option>
                  </Select>
                </Form.Item> 
              )
            }
          </Form.Item>
          <Form.Item
            name="question3"
            label="Do you have at least 10 years of experience post-residency in your chosen area of expertise?"
            rules={[{ required: true, message: "required"}]}
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="question4"
            label="To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare and Protection Data Bank?"
            rules={[{ required: true, message: "required"}]}
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Do you have any experience with Integrative and/or Alternative medical practices?"
            required
          >
            <Form.Item name="question5" rules={[{ required: true, message: "required"}]}>
              <Radio.Group onChange={({ target }) => setQuestion5Change(target.value)}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            {
              question5Change === "yes" && (
                <Form.Item name="question5Text" rules={[{ required: true, message: "required"}]}>
                  <TextArea placeholder="Please provide details"></TextArea>
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item>
            <StyledRegisterButton
              type="primary"
              size="large"
              htmlType="submit"
              className="register-physician-form-button" //complete
            >
              Create Account
            </StyledRegisterButton>
          </Form.Item>
        </Form>
      </Spin>
    </StyledComponentWrapper>
  );
};

export default PhysicianRegister;
