export const CaseStatus = {
  CONSULT_COMPLETE: "consult complete",
  CONSULT_COMPLETE_NO_RX: "consult complete no rx",
  PENDING_PRESCRIPTION: "pending prescription",
  RX_CHANGE: "rx change",
  RX_RENEWAL: "rx renewal",
  RX_RENEWAL_CANCELLED: "rx renewal cancelled",
  ADDITIONAL_INFO_REQUIRED: "additional info required",
  INVALID_FACE_PHOTO: "invalid face photo",
  INVALID_PHOTO_ID: "invalid photo id",
  CASE_CREATED: "case created",
  PENDING_SCHEDULE: "pending schedule",
  ARCHIVE: "archive",
  CANCELLED: "cancelled",
  ASSIGNED: "assigned",
  PENDING_AUDIO_CONSULT: "pending audio consult",
  PENDING_VIDEO_CONSULT: "pending video consult",
  PENDING_REVIEW: "pending review",
  PENDING_LABS: "pending labs",
};
