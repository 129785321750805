import React from "react";

// import functions and contants
import userRolesAndRights from "../../constants/userRolesAndRights";
import authorization from "../../auth/authorization";

const UserRolesAndRightsHOComponent = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          userType={userRolesAndRights[authorization.getUserType()].userType}
          userRights={userRolesAndRights[authorization.getUserType()].rights}
        />
      );
    }
  }

  return HOC;
};

export default UserRolesAndRightsHOComponent;
