/* eslint-disable no-template-curly-in-string */
import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Select,
  message,
} from "antd";
import { addUsers } from "../../../api/UserTypeAPI";

const { Option } = Select;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const AddUser = (props) => {
  const [form] = Form.useForm();
  const { adduser, clients, userRoles } = props;
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    onAddinguser(values);
  };

  const onAddinguser = (userType) => {
    setLoading(true);
    addUsers(userType)
      .then((res) => {
        if (res.data.success) {
          message.success("Successfully Added User");
          form.resetFields();
          adduser();
          setLoading(false);
        } else {
          message.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        setLoading(false);
      });
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
    >
      <Title level={2}>Add Users</Title>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter the First Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter the Last Name" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter the E-Mail" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="phone"
            label="Phone No"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter the Phone No" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="userRoleId"
            label="User Role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size={"large"}
              placeholder="Select User Role"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {userRoles.map((item) => (
                <Option
                  key={item._id}
                  value={item._id}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Form.Item
            name="clients"
            label="Client"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              size={"large"}
              placeholder="Select Client"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {clients.map((clientItem) => (
                <Option key={clientItem._id} value={clientItem._id}>
                  {clientItem.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="primary"
              size={"large"}
              htmlType="submit"
              loading={loading}
            >
              Add
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default AddUser;
