export const parseItem = item =>  {
    if (!item?.case) return 

    const syncCasesAllocated = (item.case?.audio?.allocated || 0) + (item.case?.video?.allocated || 0)
    const syncCasesCompleted = (item.case?.audio['consult complete'] || 0) + (item.case?.video['consult complete'] || 0)
    const syncCasesNoRx = (item.case?.audio['consult complete no rx'] || 0) + (item.case?.video['consult complete no rx'] || 0)
    const syncRenewalsAllocated = (item['rx renewal']?.audio?.allocated || 0) + (item['rx renewal']?.video?.allocated || 0)
    const syncRenewalsCompleted = (item['rx renewal']?.audio['consult complete'] || 0) + (item['rx renewal']?.video['consult complete'] || 0)
    const syncRenewalsNoRx = (item['rx renewal']?.audio['consult complete no rx'] || 0) + (item['rx renewal']?.video['consult complete no rx'] || 0)
    const syncRxChangesAllocated = (item['rx change']?.audio.allocated || 0) + (item['rx change']?.video?.allocated || 0)
    const syncRxChangesCompleted = (item['rx change']?.audio['consult complete'] || 0) + (item['rx change']?.video['consult complete'] || 0)
    const syncRxChangesNoRx = (item['rx change']?.audio['consult complete no rx'] || 0) + (item['rx change']?.video['consult complete no rx'] || 0)

    const asyncCasesAllocated = item.case['store and forward']?.allocated || 0
    const asyncCasesCompleted = item.case['store and forward']['consult complete'] || 0
    const asyncCasesNoRx = item.case['store and forward']['consult complete no rx'] || 0
    const asyncRenewalsAllocated = item['rx renewal']['store and forward'].allocated || 0
    const asyncRenewalsCompleted = item['rx renewal']['store and forward']['consult complete'] || 0
    const asynRenewalsNoRx = item['rx renewal']['store and forward']['consult complete no rx'] || 0
    const asyncRxChangesAllocated = item['rx change']['store and forward']?.allocated || 0
    const asyncRxChangesCompleted = item['rx change']['store and forward']['consult complete'] || 0
    const asyncRxChangesNoRx = item['rx change']['store and forward']['consult complete no rx'] || 0

    const parsed =  [
      {
        key: item.physician ? `${item.physician}-SYNC` : `${item.client}-SYNC`,
        type: 'SYNC',
        casesAllocated: syncCasesAllocated,
        casesCompleted: syncCasesCompleted,
        casesNoRx: syncCasesNoRx,
        renewalsAllocated: syncRenewalsAllocated,
        renewalsCompleted: syncRenewalsCompleted,
        renewalsNoRx: syncRenewalsNoRx,
        rxChangesAllocated: syncRxChangesAllocated,
        rxChangesCompleted: syncRxChangesCompleted,
        rxChangesNoRx: syncRxChangesNoRx
      },
      {
        key: item.physician ? `${item.physician}-ASYNC` : `${item.client}-ASYNC`,
        type: 'ASYNC',
        casesAllocated: asyncCasesAllocated,
        casesCompleted: asyncCasesCompleted,
        casesNoRx: asyncCasesNoRx,
        renewalsAllocated: asyncRenewalsAllocated,
        renewalsCompleted: asyncRenewalsCompleted,
        renewalsNoRx: asynRenewalsNoRx,
        rxChangesAllocated: asyncRxChangesAllocated,
        rxChangesCompleted: asyncRxChangesCompleted,
        rxChangesNoRx: asyncRxChangesNoRx,
      },
      {
        key: item.physician ? `${item.physician}-TOTAL` : `${item.client}-TOTAL`,
        type: 'TOTAL',
        casesAllocated: syncCasesAllocated + asyncCasesAllocated,
        casesCompleted: syncCasesCompleted + asyncCasesCompleted,
        casesNoRx: syncCasesNoRx + asyncCasesNoRx,
        renewalsAllocated: syncRenewalsAllocated + asyncRenewalsAllocated,
        renewalsCompleted: syncRenewalsCompleted + asyncRenewalsCompleted,
        renewalsNoRx: syncRenewalsNoRx + asynRenewalsNoRx,
        rxChangesAllocated: syncRxChangesAllocated + asyncRxChangesAllocated,
        rxChangesCompleted: syncRxChangesCompleted + asyncRxChangesCompleted,
        rxChangesNoRx: syncRxChangesNoRx + asyncRxChangesNoRx
      }
    ]
    return parsed
}