import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Form, Typography, message, Table, Spin } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import { lookUpDetails } from "../../api/LookUpAPI";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const { Text } = Typography;

const ClientDetailView = ({ clientDetailId }) => {
  const [form] = Form.useForm();
  const [singleClientDetails, setSingleClientDetail] = useState();
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Telemedicine Category",
      width: 100,
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left",
    },
    {
      title: "Async Consult Rate (in $)",
      width: 100,
      dataIndex: "async",
      key: "sync-consult-rate",
      align: "center",
    },
    {
      title: "Sync Consult Rate (in $)",
      dataIndex: "sync",
      key: "sync",
      width: 100,
      align: "center",
    },
  ];

  useEffect(() => {
    setSingleClientDetail();
    setLoading(true);
    form.resetFields();
    fetchSingleClientView(clientDetailId);
  }, [clientDetailId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSingleClientView = (clientId) => {
    lookUpDetails("client", clientId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const {
            name,
            teleMedicineCategories,
            address,
            logoImage,
            enableMobile,
            teleMedicineCategoryName,
            consultRates,
            enablePrescriptionNotification,
            enableGeneralNotification,
          } = res.data.payload;
          let telemedicineCategoryList = [];
          if (teleMedicineCategories && teleMedicineCategories.length) {
            let eachMappedCategory = {};
            telemedicineCategoryList = teleMedicineCategories.map(
              (eachCategoryId) => {
                eachMappedCategory = {
                  id: null,
                  categoryName: "",
                  async: "",
                  sync: "",
                };
                eachMappedCategory.id = eachCategoryId;
                if (
                  teleMedicineCategoryName &&
                  Object.keys(teleMedicineCategoryName).length
                ) {
                  eachMappedCategory.categoryName = teleMedicineCategoryName[
                    eachCategoryId
                  ]
                    ? teleMedicineCategoryName[eachCategoryId]
                    : "";
                }
                if (consultRates && Object.keys(consultRates).length) {
                  if (consultRates[eachCategoryId]) {
                    eachMappedCategory.async = consultRates[eachCategoryId]
                      .async
                      ? consultRates[eachCategoryId].async
                      : "";
                    eachMappedCategory.sync = consultRates[eachCategoryId].sync
                      ? consultRates[eachCategoryId].sync
                      : "";
                  }
                }
                return { ...eachMappedCategory };
              }
            );
          }
          if (address) {
            const separateAddressValues = address.split(",");
            const [exactAddress, city, state, zip] = separateAddressValues;
            setSingleClientDetail({
              name: name,
              teleMedicineCategories: telemedicineCategoryList,
              exactAddress: exactAddress,
              city: city,
              state: state,
              zip: zip,
              logoImage: logoImage,
              enableMobile: enableMobile,
              enablePrescriptionNotification: enablePrescriptionNotification,
              enableGeneralNotification: enableGeneralNotification,
            });
          } else {
            setSingleClientDetail({
              name: name,
              teleMedicineCategories: telemedicineCategoryList,
              exactAddress: "",
              city: "",
              state: "",
              zip: "",
              logoImage: logoImage,
              enableMobile: enableMobile,
              enablePrescriptionNotification: enablePrescriptionNotification,
              enableGeneralNotification: enableGeneralNotification,
            });
          }
          message.success("Successfully loaded Client details");
          setLoading(false);
        }
      })
      .catch(() => {
        message.error("Unable to fetch client details ");
        setLoading(false);
      });
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Form form={form} {...layout} layout={"vertical"} name="nest-messages">
        <Title level={2}>Client Details</Title>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="clientLogo" label="Client Logo">
              {singleClientDetails && singleClientDetails.logoImage ? (
                <img
                  width="110px"
                  height="110px"
                  src={singleClientDetails.logoImage}
                  alt="client logo"
                />
              ) : (
                <UserOutlined style={{ fontSize: "50px" }} />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="name" label="Client Name">
              <span>
                {" "}
                <b>{singleClientDetails && singleClientDetails.name}</b>
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item name="exactAddress" label="Address">
              <span>
                {" "}
                <b>{singleClientDetails && singleClientDetails.exactAddress}</b>
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item name="city" label="City">
              <span className="ant-form-text">
                <b>{singleClientDetails && singleClientDetails.city}</b>
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item name="state" label="State">
              <span className="ant-form-text">
                <b>{singleClientDetails && singleClientDetails.state}</b>
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item name="zip" label="Zip">
              <span className="ant-form-text">
                <b>{singleClientDetails && singleClientDetails.zip}</b>
              </span>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="enablePhone" label="Phone Validation">
              <span>
                <b>
                  {singleClientDetails && singleClientDetails.enableMobile
                    ? "Yes"
                    : "No"}
                </b>
              </span>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="enableGeneralNotification"
              label="General Email and SMS Notification"
            >
              <span>
                <b>
                  {singleClientDetails &&
                  singleClientDetails.enableGeneralNotification
                    ? "Yes"
                    : "No"}
                </b>
              </span>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="enablePrescriptionNotification"
              label="Prescription-related Notification"
            >
              <span>
                <b>
                  {singleClientDetails &&
                  singleClientDetails.enablePrescriptionNotification
                    ? "Yes"
                    : "No"}
                </b>
              </span>
            </Form.Item>
          </Col>
          {singleClientDetails &&
          singleClientDetails.teleMedicineCategories &&
          singleClientDetails.teleMedicineCategories.length ? (
            <>
              <Col span={24}>
                <Text strong>Added Telemedicine Category</Text>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={
                    singleClientDetails &&
                    singleClientDetails.teleMedicineCategories
                      ? singleClientDetails.teleMedicineCategories
                      : []
                  }
                  scroll={{ x: 800 }}
                  pagination={false}
                />
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </Form>
    </Spin>
  );
};
export default ClientDetailView;
