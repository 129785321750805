//import packages
import React, { useState, useEffect } from "react";
import { Typography, Modal, message, Button, Spin } from "antd";
import styled from "styled-components";
// import components
import QueueConfiguration from "./QueueConfiguration";
import AddQueueConfiguration from "./AddQueueConfiguration";
import { LoadingOutlined } from "@ant-design/icons";
import { TimePeriodEnum } from "../../../constants/enums"

//import other files and API functions
import { lookUp } from "../../../api/LookUpAPI";
import {
  addCaseQueueConfiguration,
  getCaseQueueConfigurationDetails,
} from "../../../api/CasesAPI";

const { Title } = Typography;

const StyledComponentWrapper = styled.article`
  background-color: #ffffff;
  width: 100%;
  padding: 24px 28px;
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledHelperNotesSection = styled.section`
  margin-bottom: 44px;
`;

const StyledSubmitButtonSection = styled.section`
  text-align: right;
`;

const StyledHelperNoteListItem = styled.li`
  text-align: justify;
`;

const CaseQueueConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [physicianGroupList, setPhysicianGroupList] = useState([]);
  const [selectedPhysicianGroup, selectPhysicianGroup] = useState(null)
  const [physicianGroupConfiguration, setPhysicianGroupConfiguration] = useState([]);
  const [viewAddConfigurationModal, setViewAddConfigurationModal] = useState(false);
  const [unconfiguredTimePeriods, setUnconfiguredTimePeriods] = useState([])
  const [deletedList, setDeletedList] = useState([]);

  const fetchPhysicianGroup = () => {
    lookUp("physician-group")
      .then((res) => {
        if (res.data?.payload) {
          const physicianGroupData = res.data.payload.map((eachGroup) => ({
            ...eachGroup,
            physicianGroupId: eachGroup._id,
            physicianGroupName: eachGroup.name,
            key: eachGroup._id,
          }));
          setPhysicianGroupList(physicianGroupData);
        }
      })
      .catch(() => message.error( "Unable to fetch Physician Group. Please try refreshing!"));
  };

  const fetchGlobalCaseQueueConfigurationDetails = () => {
    setLoading(true);
    getCaseQueueConfigurationDetails()
      .then(res => {
        if (!res.data) throw new Error()
        const { config } = res.data;
        config?.length && setPhysicianGroupConfiguration(config)
      })
      .catch(() => message.error("Unable to load Global Case Queue Configuration. Please try again!"))
      .finally(() => setLoading(false))
  };

  const updateConfiguredTimePeriods = () => {
    const timePeriods = [TimePeriodEnum.DAY, TimePeriodEnum.WEEK, TimePeriodEnum.MONTH]
    const foundGroupConfigs = physicianGroupConfiguration.filter(group => selectedPhysicianGroup?.value === group.physicianGroupId)
    if (foundGroupConfigs) {
      const configuredPeriods = foundGroupConfigs.map(config => config.period)
      const unconfiguredPeriods = timePeriods.filter(period => !configuredPeriods.includes(period))
      setUnconfiguredTimePeriods(unconfiguredPeriods)
    } else setUnconfiguredTimePeriods(timePeriods)
  };

  useEffect(() => {
    fetchPhysicianGroup();
    fetchGlobalCaseQueueConfigurationDetails();
  }, []);

  useEffect(() => {
    updateConfiguredTimePeriods()
  }, [selectedPhysicianGroup, physicianGroupConfiguration])

  const handleConfigurationAdd = config => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(0);
    clonnedPhysicianGroupConfiguration.push({
      ...config,
      physicianGroupName: config.physicianGroupDetails.label.props.children,
      physicianGroupId: config.physicianGroupDetails.value,
      key: config.physicianGroupDetails.key,
      index: clonnedPhysicianGroupConfiguration.length
    });

    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setViewAddConfigurationModal(false);
    selectPhysicianGroup(null)
  };

  const handleConfigurationDelete = (selectedConfiguration) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(0);
    if (selectedConfiguration.id) {
      const listToDelete = deletedList.slice(0);
      listToDelete.push(selectedConfiguration.id);
      setDeletedList(listToDelete);
    }
    const findDeletingItemIndex = clonnedPhysicianGroupConfiguration.findIndex(
      (eachConfiguration) =>
        eachConfiguration.physicianGroupId ===
        selectedConfiguration.physicianGroupId
    );
    if (findDeletingItemIndex !== -1) {
      clonnedPhysicianGroupConfiguration.splice(findDeletingItemIndex, 1);
    }
    clonnedPhysicianGroupConfiguration.map((item, index) => {
      return (item.priority = index + 1);
    });
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
  };

  const handleAddConfigurationClickCallback = () => {
    setViewAddConfigurationModal(true);
  };

  const handleConfigurationPriorityChange = (updatedConfigurationList) => {
    setPhysicianGroupConfiguration([...updatedConfigurationList]);
  };

  const handleGlobalCofigurationSubmission = () => {
    setLoading(true);
    
    const config = physicianGroupConfiguration.map(({ physicianGroupId, physicianGroupName, period, quantity }) => ({ physicianGroupId, physicianGroupName, period, quantity }))
    addCaseQueueConfiguration({ config })
      .then((res) => {
        const { data } = res
        const { success } = data

        if (success) {
          message.success('Global case queue configuration updated')
          return getCaseQueueConfigurationDetails();
        }
      })
      .catch(e => {
        console.log(e)
        const { response } = e
        if (response?.data?.message)
          return message.error(response.data.message);
        
        message.error("Unable to Add Global Case Queue Configuration. Please try again!");
      })
      .finally(() => setLoading(false))
  };

  return (
    <StyledComponentWrapper>
      <StyledTitle level={2}>Case Queue Global Configurations</StyledTitle>
      <StyledHelperNotesSection>
        <Title level={4}>Helper Notes:</Title>
        <ul>
          <StyledHelperNoteListItem>
            <strong>Physician Group</strong> configuration will act as a default for all physicians 
            within said group. Physicians without a physician group configuration will experience no 
            case count limitations. To assign specific caps to individual physicians, please use the
            <strong> <a>Manage Physicians</a></strong> feature.
          </StyledHelperNoteListItem>
        </ul>
      </StyledHelperNotesSection>
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <QueueConfiguration
          configurationTitle="Physician Group Configuration"
          configurationsList={physicianGroupConfiguration}
          updateConfigurationGroup={handleConfigurationPriorityChange}
          handleAddConfigurationButtonClick={handleAddConfigurationClickCallback}
          handleConfigurationDelete={handleConfigurationDelete}
        />
        <Modal
          destroyOnClose
          width={750}
          visible={viewAddConfigurationModal}
          onCancel={() => setViewAddConfigurationModal(false)}
          footer={null}
        >
          <AddQueueConfiguration
            onConfigurationAdd={handleConfigurationAdd}
            physicianGroupList={physicianGroupList}
            unconfiguredTimePeriods={unconfiguredTimePeriods}
            setUnconfiguredTimePeriods={setUnconfiguredTimePeriods}
            selectedPhysicianGroup={selectedPhysicianGroup}
            selectPhysicianGroup={selectPhysicianGroup}
          />
        </Modal>
        <StyledSubmitButtonSection>
          <Button
            type="primary"
            size="large"
            onClick={handleGlobalCofigurationSubmission}
          >
            Submit Changes
          </Button>
        </StyledSubmitButtonSection>
      </Spin>
    </StyledComponentWrapper>
  );
};

export default CaseQueueConfiguration;
