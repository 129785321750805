/* eslint-disable no-template-curly-in-string */
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Typography, Button, Table, Modal } from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

const RNDContext = createDndContext(HTML5Backend);

const type = "DragableBodyRow";

const { Title } = Typography;
const { confirm } = Modal;

const StyledHeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const StyledConfigurationListingSection = styled.section`
  margin-bottom: 12px;
`;

const StyledPhysicianGroupNameWrapper = styled.span`
  text-transform: capitalize;
`;

const LoadBalancerConfiguration = (props) => {
  const {
    configurationTitle,
    configurationsList,
    updateConfigurationGroup,
    handleAddConfigurationButtonClick,
    handleConfigurationDelete,
    handleConfigurationEdit,
    selectablePhysicianGroupList,
  } = props;
  const [configurationData, setConfigurationData] = useState([]);

  const DragableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      item: { type, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ""}`}
        style={{ cursor: "move", ...style }}
        {...restProps}
      />
    );
  };

  const operationsTobePerformedOnComponentInit = () => {
    setConfigurationData(configurationsList);
  };

  useEffect(operationsTobePerformedOnComponentInit, [configurationsList]);

  const showDeleteConfirmation = (configuration) => {
    confirm({
      title: `Do you want to delete ${configuration.physicianGroupName} Physician Group?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        handleConfigurationDelete(configuration);
      },
      onCancel: () => {
        console.log("======== cancelled delete ===========");
      },
    });
  };

  const onConfiguratioEdit = (record) => {
    handleConfigurationEdit(record);
  };

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = configurationData[dragIndex];
      const draggedData = update(configurationData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      draggedData.map((item, index) => {
        return (item.priority = index + 1);
      });
      updateConfigurationGroup(draggedData);
    }, // eslint-disable-next-line
    [configurationData]
  );

  const columns = [
    {
      title: "Physician Group",
      dataIndex: "physicianGroupName",
      key: "physician-group",
      width: 180,
      render: (text, record) => (
        <StyledPhysicianGroupNameWrapper>
          {record.physicianGroupName}
        </StyledPhysicianGroupNameWrapper>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 150,
      align: "center",
    },
    {
      title: "Turn Around Time SLA",
      dataIndex: "turnAroundTime",
      key: "turn-around-time-sla",
      width: 200,
      align: "center",
    },
    {
      title: "Internal Turn Around Time SLA",
      dataIndex: "internalTurnAroundTime",
      key: "internal-turn-around-time-sla",
      width: 250,
      align: "center",
    },

    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      width: 100,
      align: "center",
      render: (configuration, record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            onConfiguratioEdit(record);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 100,
      align: "center",
      render: (configuration, record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            showDeleteConfirmation(record);
          }}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    handleAddConfigurationButtonClick(true);
  };
  const manager = useRef(RNDContext);
  return (
    <article>
      <StyledHeaderSection>
        <Title level={4}>
          {configurationTitle
            ? configurationTitle
            : "Physician Group Configuration"}
        </Title>
        <Button
          type="primary"
          size="large"
          icon={<PlusCircleOutlined />}
          onClick={handleAddButtonClick}
          disabled={
            selectablePhysicianGroupList &&
            selectablePhysicianGroupList.length === 0
          }
        >
          Add
        </Button>
      </StyledHeaderSection>
      <StyledConfigurationListingSection>
        <DndProvider manager={manager.current.dragDropManager}>
          <Table
            pagination={false}
            dataSource={configurationData}
            columns={columns}
            width="max-content"
            rowKey="index"
            scroll={{ x: 720 }}
            components={components}
            onRow={(record, index) => ({
              index,
              moveRow,
            })}
          />
        </DndProvider>
      </StyledConfigurationListingSection>
    </article>
  );
};

export default LoadBalancerConfiguration;
