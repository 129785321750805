/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, Button, Row, Col, Typography, message } from "antd";

//import other files and API function
import { lookUpDetails } from "../../../api/LookUpAPI";
import { updateCaseStatus } from "../../../api/CaseStatusesAPI";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledInput = styled(Input)`
  width: 47px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const EditCaseStatuses = (props) => {
  const {
    caseStatusId,
    afterCaseStatusEdit,
    fetchCaseStatusesAfterUpdate,
  } = props;
  const [form] = Form.useForm();
  const [fields, setStateDetails] = useState([
    {
      name: ["caseStatusName"],
      value: "",
    },
    {
      name: ["caseStatusColour"],
      value: "#ffffff",
    },
  ]);

  useEffect(() => {
    if (caseStatusId) {
      lookUpDetails("case-status", caseStatusId)
        .then((res) => {
          if (res.data && res.data.payload) {
            const { displayName, colourCode } = res.data.payload;
            setStateDetails([
              { name: ["caseStatusName"], value: displayName || "" },
              {
                name: ["caseStatusColour"],
                value: colourCode || "#ffffff",
              },
            ]);
            message.success(
              "Successfully loaded State details into Edit Case Status Form"
            );
          }
        })
        .catch(() => {
          message.error("Unable to fetch case status details for Editing");
        });
    }
  }, [caseStatusId]);

  const onFinish = (values) => {
    const { caseStatusName, caseStatusColour } = values;
    const caseStatusDetails = {
      displayName: caseStatusName,
      colourCode: caseStatusColour,
    };
    updateCaseStatus(caseStatusId, caseStatusDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully updated case status");
          form.resetFields();
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to update case status. Please try again!");
          }
        }
        afterCaseStatusEdit("");
        fetchCaseStatusesAfterUpdate();
      })
      .catch(() => {
        message.error("Unable to update case status. Please try again!");
      });
  };

  const onCancelingEdit = () => {
    setStateDetails([
      {
        name: ["caseStatusName"],
        value: "",
      },
      {
        name: ["caseStatusColour"],
        value: "#ffffff",
      },
    ]);
    afterCaseStatusEdit("");
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="edit-case-status"
      onFinish={onFinish}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => {
        setStateDetails(allFields);
      }}
      validateMessages={validateMessages}
    >
      <Title level={2}>Edit Case Status</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="caseStatusName"
            label="Case Status Label"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              size={"large"}
              placeholder="Enter Case Status Label"
              disabled={!caseStatusId}
            />
          </Form.Item>

          <Form.Item
            name="caseStatusColour"
            label="Change Colour Code of Status"
          >
            <StyledInput type="color" defaultValue="#ffffff" />
          </Form.Item>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="secondary"
              size={"large"}
              disabled={!caseStatusId}
              onClick={onCancelingEdit}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="primary"
              size={"large"}
              htmlType="submit"
              disabled={!caseStatusId}
            >
              Save
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default EditCaseStatuses;
