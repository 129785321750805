import React, { useState, useEffect } from "react";
import { getCaseTags, addCaseTag, removeCaseTag } from "../../api/CaseTagsAPI";
import { Select, message, Tag } from "antd";

const { Option } = Select;

const CaseTagsComponent = ({ currentTags, caseId }) => {
  const [tags, setTags] = useState(currentTags);
  const [availableTags, setAvailableTags] = useState([]);

  const fetchAvailableCaseTags = () => {
    getCaseTags()
      .then((res) => {
        if (res.data) {
          const { caseTags } = res.data;
          const mutableTags = caseTags
            .filter((tag) => !tag.immutable && tag.tag[0] !== "*")
            .map((tagObj) => tagObj.tag);

          setAvailableTags(mutableTags);
        }
      })
      .catch(() => {
        message.error("Error retrieving available case tags.");
      });
  };

  useEffect(() => {
    setTags(currentTags);
    fetchAvailableCaseTags();
  }, [currentTags]);

  function tagRender(props) {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={value[0] === "*" ? "green" : "grey"}
        onMouseDown={onPreventMouseDown}
        closable={value[0] !== "*"}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {value.replace("*", "")}
      </Tag>
    );
  }

  function handleTagChange(newCaseTagList) {
    const immutable = tags.filter((tag) => tag[0] === "*");
    const added = newCaseTagList.filter(
      (tag) => !tags.includes(tag) && tag[0] !== "*"
    );
    const removed = tags.filter(
      (tag) => !newCaseTagList.includes(tag) && tag[0] !== "*"
    );
    added.length && added.forEach((addedTag) => addCaseTag(caseId, addedTag));
    removed.length &&
      removed.forEach((removedTag) => {
        // TAGS PREFIXED WITH '*' ARE IMMUTABLE
        if (removedTag[0] !== "*") removeCaseTag(caseId, removedTag);
      });
    setTags(Array.from(new Set([...newCaseTagList, ...immutable])));
  }

  return (
    <Select
      mode="tags"
      placeholder="Case Tags"
      onChange={handleTagChange}
      value={tags}
      tagRender={tagRender}
    >
      {availableTags.length &&
        availableTags.map((tag) => (
          <Option key={tag}>{tag.name || tag}</Option>
        ))}
    </Select>
  );
};

export default CaseTagsComponent;
