import {
    Table,
    Typography,
    Button,
    Tabs,
} from "antd";
import styled from "styled-components";


const StyledTabWrapper = styled.div`
width: 100%;
max-width: 1480px;
margin: 24px auto;

@media (max-width:1800px){
  max-width: 1300px;
}

@media (max-width:1550px){
  max-width: 1080px;
}
@media (max-width:1300px){
  max-width: 950px;
}

@media (max-width: 1199px) {
  max-width: 780px;
}
@media (max-width: 788px), (max-width: 922px) {
  max-width: 712px;
}
@media (max-width: 720px) {
  max-width: 372px;
}
`;

const StyledComponentWrapper = styled.div`
background-color: #ffffff;
width: 100%;
max-width: 1480px;
padding: 24px 28px;

@media (max-width:1800px){
  max-width: 1300px;
}

@media (max-width:1550px){
  max-width: 1080px;
}
@media (max-width:1300px){
  max-width: 950px;
}

@media (max-width: 1199px) {
  max-width: 780px;
}
@media (max-width: 788px), (max-width: 922px) {
  max-width: 712px;
}
@media (max-width: 720px) {
  max-width: 372px;
}
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn[disabled] {
    border-color: #49548d;
    color: #49548d;
  }
`;


export { StyledButton, StyledComponentWrapper, StyledTabWrapper, Title, StyledTabs, StyledTable }