/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { Typography, Button, Form, Row, Col, InputNumber, Select } from "antd";
import styled from "styled-components";

const StyledFormItemWrapper = styled(Form.Item)`
  text-align: right;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledPhysicianGroupNameWrapper = styled.span`
  text-transform: capitalize;
`;

const StyledButton = styled(Button)`
  margin-left: 12px;
`;

const { Title } = Typography;
const { Option } = Select;

const AddConfiguration = (props) => {
  const [form] = Form.useForm();
  const {
    onConfigurationAdd,
    physicianGroupList,
    editingConfiguration,
    edit,
    handleEditCancel,
    handleEditSubmit,
    selectablePhysicianGroupList,
  } = props;
  const [fields, setConfigurationDetails] = useState([]);
  const [disableInternalTAT, setDisableInternalTAT] = useState(false);

  const onEditingConfigurationChange = () => {
    if (editingConfiguration) {
      const {
        physicianGroupDetails: detailsOfPhysicianGroup,
        turnAroundTime,
        internalTurnAroundTime,
        physicianGroupId,
        physicianGroupName,
      } = editingConfiguration;
      let mappedPhysicianGroupDetails = {};
      if (
        detailsOfPhysicianGroup &&
        Object.keys(detailsOfPhysicianGroup).length
      ) {
        mappedPhysicianGroupDetails = detailsOfPhysicianGroup;
      } else {
        mappedPhysicianGroupDetails = {
          key: physicianGroupId,
          physicianGroupId: physicianGroupId,
          physicianGroupName: physicianGroupName,
        };
      }
      if (physicianGroupName && physicianGroupName.includes("wheel")) {
        setDisableInternalTAT(true);
      }
      setConfigurationDetails([
        {
          name: ["physicianGroupDetails"],
          value: mappedPhysicianGroupDetails
            ? mappedPhysicianGroupDetails
            : physicianGroupId,
        },
        {
          name: ["turnAroundTime"],
          value: turnAroundTime,
        },
        {
          name: ["internalTurnAroundTime"],
          value: internalTurnAroundTime,
        },
      ]);
    }
  };

  useEffect(onEditingConfigurationChange, [editingConfiguration]);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line
      number: "${label} is not a validate number!",
    },
  };

  const handleConfigurationChange = (value) => {
    if (edit) {
      if (handleEditSubmit && typeof handleEditSubmit === "function") {
        handleEditSubmit(value);
      }
    } else {
      if (onConfigurationAdd && typeof onConfigurationAdd === "function") {
        onConfigurationAdd(value);
      }
    }
  };

  const handleConfigurationEditCancel = () => {
    if (edit) {
      if (handleEditCancel && typeof handleEditCancel === "function") {
        handleEditCancel();
      }
    }
  };

  const populateOptions = () => {
    if (edit) {
      return physicianGroupList.map((eachPhysicianGroupList) => (
        <Option
          key={eachPhysicianGroupList.key}
          value={eachPhysicianGroupList.physicianGroupId}
        >
          <StyledPhysicianGroupNameWrapper>
            {eachPhysicianGroupList.physicianGroupName}
          </StyledPhysicianGroupNameWrapper>
        </Option>
      ));
    } else {
      return selectablePhysicianGroupList.map((eachPhysicianGroupList) => (
        <Option
          key={eachPhysicianGroupList.key}
          value={eachPhysicianGroupList.physicianGroupId}
        >
          <StyledPhysicianGroupNameWrapper>
            {eachPhysicianGroupList.physicianGroupName}
          </StyledPhysicianGroupNameWrapper>
        </Option>
      ));
    }
  };

  return (
    <article>
      <Title level={4}>
        {edit ? "Edit Configuration" : "Add Configuration"}
      </Title>
      <section>
        <Form
          {...layout}
          form={form}
          layout={"vertical"}
          name="add-edit-configuration"
          onFinish={handleConfigurationChange}
          fields={fields}
          onFieldsChange={(changedFields, allFields) => {
            setConfigurationDetails(allFields);
          }}
          validateMessages={validateMessages}
        >
          <Row gutter={24}>
            <Col Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="physicianGroupDetails"
                label="Physician Group"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue, setFieldsValue }) => ({
                    validator(_, value) {
                      if (!edit) {
                        if (
                          value &&
                          value.label.props.children.includes("wheel")
                        ) {
                          setDisableInternalTAT(true);
                        } else {
                          if (disableInternalTAT) {
                            setDisableInternalTAT(false);
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  size={"large"}
                  labelInValue
                  placeholder="Select physician group"
                  disabled={edit}
                >
                  {populateOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="internalTurnAroundTime"
                label="Internal Turn Around Time SLA (in minutes)"
                rules={[
                  {
                    required: disableInternalTAT ? false : true,
                    type: "number",
                  },
                ]}
              >
                <StyledInputNumber
                  size={"large"}
                  placeholder="Enter Internal Turn Around Time in minutes"
                  min={disableInternalTAT ? "0" : "3"}
                  disabled={disableInternalTAT}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="turnAroundTime"
                label="Turn Around Time SLA (in minutes)"
                rules={[
                  {
                    required: true,
                    type: "number",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!disableInternalTAT) {
                        if (
                          !value ||
                          Number.parseInt(value, 10) -
                            Number.parseInt(
                              getFieldValue("internalTurnAroundTime"),
                              10
                            ) >=
                            2
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The Turn Around Time entered is not 2 greater than or equal to Internal Turn Around Time!"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <StyledInputNumber
                  size={"large"}
                  placeholder="Enter Turn Around Time in minutes"
                  min="5"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <StyledFormItemWrapper wrapperCol={{ span: 24 }}>
                {edit ? (
                  <StyledButton
                    type="secondary"
                    size="large"
                    onClick={handleConfigurationEditCancel}
                  >
                    Cancel
                  </StyledButton>
                ) : (
                  <></>
                )}
                <StyledButton type="primary" size="large" htmlType="submit">
                  {edit ? "Save Changes" : "Add Configuration"}
                </StyledButton>
              </StyledFormItemWrapper>
            </Col>
          </Row>
        </Form>
      </section>
    </article>
  );
};

export default AddConfiguration;
