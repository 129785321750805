/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { Typography, Button, Table, Modal } from "antd";
import {
  PlusCircleOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const { Title } = Typography;
const { confirm } = Modal;

const StyledHeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const StyledConfigurationListingSection = styled.section`
  margin-bottom: 12px;
`;

const StyledPhysicianGroupNameWrapper = styled.span`
  text-transform: capitalize;
`;

const QueueConfiguration = (props) => {
  const {
    configurationTitle,
    configurationsList,
    handleAddConfigurationButtonClick,
    handleConfigurationDelete,
  } = props;
  const [configurationData, setConfigurationData] = useState([]);

  useEffect(() => setConfigurationData(configurationsList), [configurationsList]);

  const showDeleteConfirmation = (configuration) => {
    confirm({
      title: `Do you want to delete ${configuration.physicianGroupName} Physician Group?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => handleConfigurationDelete(configuration)
    });
  };

  const columns = [
    {
      title: "Physician Group",
      dataIndex: "physicianGroupName",
      key: "physician-group",
      width: 180,
      render: (text, record) => (
        <StyledPhysicianGroupNameWrapper>
          {record.physicianGroupName}
        </StyledPhysicianGroupNameWrapper>
      ),
    },
    {
      title: "Time Period",
      dataIndex: "period",
      key: "period",
      width: 150,
      align: "center",
    },
    {
      title: "Max Case Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 200,
      align: "center",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => {
            showDeleteConfirmation(record);
          }}
        />
      ),
    },
  ];

  return (
    <article>
      <StyledHeaderSection>
        <Title level={4}>
          {configurationTitle || "Physician Group Configuration"}
        </Title>
        <Button
          type="primary"
          size="large"
          icon={<PlusCircleOutlined />}
          onClick={() => handleAddConfigurationButtonClick(true)}
        >
          Add
        </Button>
      </StyledHeaderSection>
      <StyledConfigurationListingSection>
        <Table
            pagination={false}
            dataSource={configurationData}
            columns={columns}
            width="max-content"
            style={{ textTransform: 'capitalize'}}
            rowKey="index"
            scroll={{ x: 720 }}
            onRow={(_, index) => ({ index })}
        />
      </StyledConfigurationListingSection>
    </article>
  );
};

export default QueueConfiguration;
