import React, { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import { message } from "antd";
import socket from "../api/socket";
import authorization from "../auth/authorization";
import { SocketEventEnum, CustomReportType, REPORT_TYPE } from "../constants/enums";

const CustomReportContext = createContext(null);

export const useCustomReportContext = () => {
  const context = useContext(CustomReportContext);
  if (!context) {
      throw new Error("useCustomReportContext must be used within a CustomReportSocketProvider");
  }
  return context;
}

const useReportWithSocket = ({ eventName, reportName }) => {
  const [report, setReport] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const requestId = useRef(0)

  const setLoading = (value) => {
    setIsLoading(value);
    setIsLoaded(!value);
  }

  useEffect(() => {
    if (isLoading || !isLoaded) {
      return;
    }
    if (isError) {
      message.error(`Error retrieving ${reportName} Report`);
      return;
    }
    if (report) {
      message.success(`${reportName} Report is ready`);
      return;
    }
  }, [isLoading, isLoaded, isError, report, reportName])

  useEffect(() => {
    const userId = authorization.getUserId()
    if (userId === "no user") {
      console.log("Can't create Socket Custom Report event names. No user found.")
      return;
    }

    const currentUserEventName = `${eventName}-${userId}`

    const handleSocketResponse = (_eventName) => (response) => {
      if (response.reportType !== REPORT_TYPE.CUSTOM) {
        return; //avoid socket conflict with same reportAPI
      }
      if (Number(response.requestId) !== requestId.current) {
        console.log(`Outdated response for ${_eventName}>>>>>`, { response, currentRequestId: requestId.current });
        return;
      }

      requestId.current += 1
      console.log(`Socket response for ${_eventName}>>>>>`, response);
      const payload = response.payload
      if (payload) {
        const { data, totalCount, count } = payload
        const report = data || payload
        let parsed

        try {
          parsed = JSON.parse(report)
        } catch (e) {
          parsed = report
        }

        setReport(parsed.data || parsed)
        setTotalCount(totalCount || count || parsed.data?.length || parsed.length)
      } else {
        setIsError(true)
        setReport([])
        setTotalCount(0)
      }
      setLoading(false)
      setIsLoaded(true)
    }

    socket.on('connect_error', (error) => {
      setIsError(true)
      setIsLoaded(true)
      console.error('Socket error:', error);
    });

    socket.on(currentUserEventName, handleSocketResponse(currentUserEventName))

    return () => {
      console.log(currentUserEventName, 'Custom reports Socket event listeners removed')
      socket.removeAllListeners([
        currentUserEventName,
      ]);
      socket.close();
      console.log(currentUserEventName, 'Custom reports Socket event listeners removed')
    };
  }, [eventName]);

  const clearFilter = () => {
    setReport(null)
    setTotalCount(null)
  }

  const contextData = useMemo(() => ({
    report,
    totalCount,
    isLoading,
    isLoaded,
    isError,
    requestId,
    setReport,
    setLoading,
    setTotalCount,
    clearFilter,
  }), [
    report,
    totalCount,
    isLoading,
    isLoaded,
    isError,
  ]);

  return contextData;
}

export const CustomReportSocketProvider = ({ children }) => {
  const {
    CUSTOM_CONSULT_TIME,
    CUSTOM_CONSULT_TIME_NO_RX,
    CUSTOM_CLIENTS_CASE_COUNT,
    CUSTOM_PHYSICIANS_CASE_COUNT,
    CUSTOM_PHYSICIANS_RECONCILIATION,
    CUSTOM_INCOMPLETE_CASES,
  } = CustomReportType

  const {
    GET_CONSULT_TIME_REPORT,
    GET_CONSULT_TIME_NO_RX_REPORT,
    GET_CLIENTS_CASE_COUNT_REPORT,
    GET_PHYSICIANS_CASE_COUNT_REPORT,
    GET_PHYSICIANS_RECONCILIATION_REPORT,
    GET_INCOMPLETE_CASES_REPORT
  } = SocketEventEnum

  const contextData = {
    [CUSTOM_CONSULT_TIME]: useReportWithSocket({ eventName: GET_CONSULT_TIME_REPORT, reportName: "Consult Completed-Duration" }),
    [CUSTOM_CONSULT_TIME_NO_RX]: useReportWithSocket({ eventName: GET_CONSULT_TIME_NO_RX_REPORT, reportName: "Consult Completed No RX-Duration" }),
    [CUSTOM_CLIENTS_CASE_COUNT]: useReportWithSocket({ eventName: GET_CLIENTS_CASE_COUNT_REPORT, reportName: "Clients' Case Count" }),
    [CUSTOM_PHYSICIANS_CASE_COUNT]: useReportWithSocket({ eventName: GET_PHYSICIANS_CASE_COUNT_REPORT, reportName: "Physicians' Case Count" }),
    [CUSTOM_PHYSICIANS_RECONCILIATION]: useReportWithSocket({ eventName: GET_PHYSICIANS_RECONCILIATION_REPORT, reportName: "Physician Payment Details" }),
    [CUSTOM_INCOMPLETE_CASES]: useReportWithSocket({ eventName: GET_INCOMPLETE_CASES_REPORT, reportName: "Incomplete Cases" }),
  }

  return (
    <CustomReportContext.Provider value={contextData}>
      {children}
    </CustomReportContext.Provider>
  )
}
