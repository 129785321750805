import React, { useEffect, useState, useMemo } from "react";
import { Typography, Form, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { debounce } from 'lodash';

import { getEligiblePhysiciansForReassignment  } from "../../api/PhysiciansAPI";
import ProviderTable from '../../components/ReassignModalComponent/ProviderTable'

const LabelDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:nth-child(1) {
    margin-left: auto;
  }
` 

const BulkReassign = ({ setBulkUpdateParams, selectedCases, setSubmitDisabled }) => {
    const [selectedProvider, selectProvider] = useState(null)
    const [availableProviders, setAvailableProviders] = useState([])
    const [nameSearchString, setNameSearchString] = useState('')
    
    const getEligibleProviders = async () => {
        try {
            const { data } = await getEligiblePhysiciansForReassignment(selectedCases, nameSearchString)
            setAvailableProviders(data.physicians)
        } catch (e) {
            console.log(e)
            message.error('Error fetching physicians available to take reassignment')
        }
    }

    const debouncedGetEligibleProviders = useMemo(() => debounce(getEligibleProviders, 500), [selectedCases, nameSearchString])
    useEffect(debouncedGetEligibleProviders, [selectedCases, nameSearchString])
    useEffect(() => setSubmitDisabled(!Boolean(selectedProvider)), [selectedProvider])

    const handleSelectProvider = provider => {
        selectProvider(provider)
        setBulkUpdateParams({ physicianId: provider._id })
    }

    return (
        <Form>
            <LabelDiv>
                <Typography.Text>Assign to</Typography.Text>
            </LabelDiv>
            <Form.Item name="assignTo">
                <Input 
                    prefix={<SearchOutlined />} 
                    placeholder="Last Name Search" 
                    value={nameSearchString} 
                    onChange={e => setNameSearchString(e.target.value)}
                />
            </Form.Item>
            <ProviderTable providers={availableProviders} selectProvider={handleSelectProvider} selectedProvider={selectedProvider} />
        </Form>
    )
}

export default BulkReassign
