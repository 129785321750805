import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  background-color: #ffffff;
  border-right: 1px solid #cfd4d8;
  padding: 22px 0;
  flex: 0 0 232px !important;
  max-width: 232px !important;
  min-width: 232px !important;
  width: 232px !important;
  &.ant-layout-sider-collapsed {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }
  > .ant-layout-sider-trigger {
    background-color: rgb(225, 230, 237);
  }
`;

const Sidebar = ({ collapsed, changeCollapseState, children }) => {

  const onSidebarSizeChange = (status) => {
    changeCollapseState(status);
  };

  return (
    <StyledSider
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsedWidth={0}
      collapsed={collapsed}
      onBreakpoint={onSidebarSizeChange}
    >
      {children}
    </StyledSider>
  );
};

export default Sidebar;
