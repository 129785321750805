/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, Button, Row, Col, Typography, message } from "antd";

//import other files and API function
import { lookUpDetails } from "../../../api/LookUpAPI";
import { updatetelecategory } from "../../../api/TelecategoryAPI";

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const EditTelemedicine = (props) => {
  const { stateId, afterStateEdit, fetchTelecategoryAfterUpdate } = props;
  const [form] = Form.useForm();
  const [fields, setStateDetails] = useState([
    {
      name: ["stateName"],
      value: "",
    },
  ]);

  useEffect(() => {
    lookUpDetails("tele-medicine-category", stateId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const { name } = res.data.payload;
          setStateDetails([{ name: ["stateName"], value: name || "" }]);
          message.success(
            "Successfully loaded category details into Edit telecategory Form"
          );
        }
      })
      .catch(() => {
        message.error("Unable to fetch category details for Editing");
      });
  }, [stateId]);

  const onFinish = (values) => {
    const { stateName } = values;
    const stateDetails = {
      name: stateName,
    };
    updatetelecategory(stateId, stateDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully updated Telemedicine Category");
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update Telemedicine Catgeory. Please try again!"
            );
          }
        }
        afterStateEdit("");
        fetchTelecategoryAfterUpdate();
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Edit Telemedicine Catgeory. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to Edit Telemedicine Category. Please try again!"
          );
        }
      });
  };

  const onCancelingEdit = () => {
    setStateDetails([
      {
        name: ["stateName"],
        value: "",
      },
    ]);
    afterStateEdit("");
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => {
        setStateDetails(allFields);
      }}
      validateMessages={validateMessages}
    >
      <Title level={2}>Edit Telemedicine Categories</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="stateName"
            label="Category Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter Telemedicine Category" />
          </Form.Item>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="secondary"
              size={"large"}
              onClick={onCancelingEdit}
            >
              Cancel
            </StyledButton>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Save
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default EditTelemedicine;
