import { LoadingOutlined } from "@ant-design/icons";
import { message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";

import { updateTicket } from "../../api/CaseTicketsAPI";

const getAdminsDropDownList = (currentAdmin, adminsList) => (
    [
        currentAdmin,
        ...adminsList.map(admin => dropDownItem(admin))
    ]
);

const dropDownItem = (admin, prefix, symbolPrefix, symbolSuffix) => {

    const labelWrapper = (content) =>
        prefix && symbolPrefix
            ? `${ prefix } ${ symbolPrefix } ${ content } ${ symbolSuffix }` :
            content;

    return {
        value: admin._id,
        label: `${ labelWrapper(`${ admin.firstName } ${ admin.lastName }`) }`
    }
}

const createAdminsMap = (admins) => {
    const map = {};
    admins.forEach((admin) => {
        map[admin._id] = admin;
    });
    return map;
}

const CaseAssignComponent = (props) => {
    const { ticketId, assignedRep, currentAdminInfo, admins, setAssignedRep } = props;
    const [ adminsDropDown, setAdminsDropDown ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ adminsMap, setAdminsMap ] = useState({})
    const [ adminByRepId, setAdminByRepId ] = useState(null)

    useEffect(() => {
        if (!admins.length) {
            return;
        }

        let assignedToTicketAdmin = null;
        let currentAdmin = null;

        const otherAdmins = admins.reduce((result, admin) => {
            if (admin.email === assignedRep?.email) {
                assignedToTicketAdmin = admin;
            }
            if (admin.email === currentAdminInfo.userEmail) {
                currentAdmin = admin;
                return result;
            }
            result.push(admin);
            return result;
        }, []);

        assignedToTicketAdmin && setAdminByRepId(assignedToTicketAdmin._id);
        setAdminsDropDown(getAdminsDropDownList(
            dropDownItem(currentAdmin, 'me', '(', ')'),
            otherAdmins,
        ))
        setAdminsMap(createAdminsMap(admins));
    }, [admins, assignedRep, currentAdminInfo])


    const handleTakeAssignment = async (adminId) => {
        const admin = adminsMap[adminId];
        setLoading(true);
        try {
            await updateTicket({ rep: { userEmail: admin.email }, ticketId })
            setAdminByRepId(admin._id);
            setAssignedRep( (rep) => ({ ...rep, email: admin.email }) );
            message.success(`Ticket has been assigned to ${ admin.firstName } ${ admin.lastName } successfully`)
        } catch (e) {
            message.error('Error taking assignment of ticket');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            Assigned to: <Spin spinning={ loading } indicator={ <LoadingOutlined/> }>
            <Select showSearch
                    optionFilterProp="label"
                    options={ adminsDropDown }
                    onChange={ handleTakeAssignment }
                    value={ adminByRepId }
                    placeholder="Please select"
                    style={ { width: 170, marginTop: -5 } }>
            </Select>
        </Spin>
        </>
    )
};

export default CaseAssignComponent;
