/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Typography } from "antd";
import styled from "styled-components";

//import common functions
import isValidUrl from "../../utils/isValidURL";

const PriorityStatus = {
    RED: 2,
    YELLOW: 3,  // currently also red
    GREEN: 1,
    DEFAULT: 0
}

const { Text } = Typography;

const StyledAnswerText = styled(Text)`
  color: #7eae80 !important;
`;

const StyledIconGroup = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

const StyledIcon = styled.img`
    width: 60px;
    height: 60px;
`;

const StyledIconWrapper = styled.div`
    margin: 10px;
`;

const StyledIconText = styled(Text)`
    padding-left: 10px;
    color: #7eae80 !important;
`;

const parseIconDescription = (str) => {
    let len = str.length;
    let lastChar = str[len-1];
    const isLetter = RegExp(/^\p{L}/,'u');
    
    if (!isLetter.test(lastChar)) {
        return str.substring(0, len-1);
    } else {
        return str;
    }
}

const parseIconsString = (str) => {
    let flag = false;
    let stack = [];
    let icon = {};
    let word = '';

    // Discard text before first icon (I.e. first '[' char)
    for (let i=str.indexOf('[')+1; i < str.length; i++) {
        let c = str[i];

        if (c === ']') {
            icon.src = word;
            word = '';
            // If at least one URL was found, presume string is valid icon string
            flag = true;
        } else if (c === '[') {
            icon.description = parseIconDescription(word.trim());
            word = '';
            stack.push(icon);
            icon = {};
        } else if (i === str.length-1) {
            word += c;
            icon.description = parseIconDescription(word.trim());
            stack.push(icon);
        } else {
            word += c;
        }
    };

    return flag ? stack : [];
}

const FinalIconAnswerView = ({ icons, answerIndex }) => {
    return (
        <>
        <StyledAnswerText className="sub-content-color" strong>
            {`Answer ${answerIndex}: `}
        </StyledAnswerText>
        <StyledIconGroup>
            {icons.map(icon => (
                isValidUrl(icon.src) ? (
                    <StyledIconWrapper>
                        <StyledIcon
                            src={icon.src}
                            alt={`${icon}-list-answer`}
                        />
                        <StyledIconText
                            strong
                        >{`${icon.description}`}</StyledIconText>
                    </StyledIconWrapper>
                ) : (
                    ""
                )
            ))}
        </StyledIconGroup>
        </>
    );
}

const FinalAnswerView = ({ answer, answerIndex, priority }) => {
    const icons = parseIconsString(answer.toString());

    let answerColor = undefined;
    if (priority == PriorityStatus.RED || priority == PriorityStatus.YELLOW) {
        answerColor = "danger";
    } else if (priority == PriorityStatus.GREEN) {
        answerColor = undefined;
    }

    /**
     * Three possible answer formats:
     * 1. A single URL to a patient image (E.g. picture of hair or skin)
     * 2. A list of 1 or more icon URLs wrapped in [] with a brief description (E.g. [<icon_url>] Fight Breakouts,)
     * 3. Plain text 
     */
    return (
        <div key={`each-answer-${answerIndex}`}>
            {isValidUrl(answer) ? (
                <>
                    <StyledAnswerText className="sub-content-color" strong>
                        {`Answer ${answerIndex}: `}
                    </StyledAnswerText>
                    <img
                        src={answer}
                        alt={`${answer}-list-answer`}
                        className="case-view-mif-image"
                    />
                </>
            ) : icons?.length > 0 ? (
                <FinalIconAnswerView icons={icons} answerIndex={answerIndex} />
            ) : (
                answerColor ? (
                    <Text
                        type={answerColor}
                        strong
                    >{`Answer ${answerIndex}: ${answer}`}</Text>
                ) : (
                    <StyledAnswerText
                        className="sub-content-color"
                        strong
                    >{`Answer ${answerIndex}: ${answer}`}</StyledAnswerText>
                )
            )}
        </div>
    );
};

const MIFAnswerView = ({ answer, answerIndex, priority }) => {
  return (
    <>
      {answer && Array.isArray(answer) ? (
        <>
          {answer.map((eachAnswer, index) => (
            <FinalAnswerView
              key={`${eachAnswer}-${answerIndex}-${index + 1}`}
              answer={eachAnswer}
              answerIndex={`${answerIndex}.${index + 1}`}
              priority={priority}
            />
          ))}
        </>
      ) : (
        <FinalAnswerView answer={answer} answerIndex={answerIndex} priority={priority} />
      )}
    </>
  );
};

export default MIFAnswerView;
