/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getCaseMessages } from "../../api/ChatRoomAPi";
import MessageComponent from "../../components/CaseMessageComponent/MessageComponent";
import moment from "moment";
import styled from "styled-components";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const StyledMessageWrapper = styled.div`
  height: calc(100vh - 176px);
  overflow-y: auto;
`;

const StyledMessageListWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 4px 0;
  background: #e8ecef;
`;

const StyledNoMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  background: #e8ecef;
`;

const CaseMessageView = ({ caseDetails }) => {
  const { caseId } = caseDetails;
  const [chatMessages, setChatMessages] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchCaseChatMessages(caseId);
  }, [caseDetails]);

  const fetchCaseChatMessages = (caseId) => {
    setLoading(true);
    getCaseMessages(caseId)
      .then((res) => {
        if (res.data.payload) {
          const { messages } = res.data.payload;
          setChatMessages(messages);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to fetch case messages!");
      });
  };
  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <StyledMessageWrapper>
          {chatMessages && chatMessages.length ? (
            <StyledMessageListWrapper>
              {chatMessages.map((eachMessage) => (
                <MessageComponent
                  key={eachMessage._id}
                  avatarUrl={eachMessage.profileImage}
                  name={`${eachMessage.firstName} ${eachMessage.lastName}`}
                  time={
                    eachMessage.created
                      ? moment(eachMessage.created).format("LLL")
                      : moment().format("LLL")
                  }
                  messageText={eachMessage.message}
                  imageUrl={eachMessage.key ? eachMessage.key : null}
                  isPatient={eachMessage.isPatient}
                />
              ))}
            </StyledMessageListWrapper>
          ) : (
            <StyledNoMessageWrapper>
              <span>No messages yet</span>
            </StyledNoMessageWrapper>
          )}
        </StyledMessageWrapper>
      </Spin>
    </>
  );
};

export default CaseMessageView;
