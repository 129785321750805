import styled from "styled-components";
import { Calendar, Menu } from "antd";
import {
  TeamOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  DatabaseOutlined,
  UserAddOutlined,
  RadarChartOutlined,
  AuditOutlined,
  BookOutlined,
  BugOutlined,
  BulbOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined
} from "@ant-design/icons";

const StyledMenu = styled(Menu)`
  &.ant-menu {
    background-color: #ffffff;
  }
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(225, 230, 237);
  }
`;

const StyledSpan = styled.span`
  padding: 0 8px;
`;

const StyledUnorderedListOutlined = styled(UnorderedListOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledTeamOutlined = styled(TeamOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledContainerOutlined = styled(ContainerOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledDatabaseOutlined = styled(DatabaseOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledUserAddOutlined = styled(UserAddOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;
const StyledHeatMapOutlined = styled(RadarChartOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;
const StyledAuditOutlined = styled(AuditOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;
const StyledBookOutlined = styled(BookOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledBugOutlined = styled(BugOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledBulbOutlined = styled(BulbOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`
const StyledCalendarOutlined = styled(CalendarOutlined)`
  margin-right: unset !important;
  font-size: 16px !important;
`;

export {
  StyledContainerOutlined,
  StyledUnorderedListOutlined,
  StyledTeamOutlined,
  StyledSpan,
  StyledMenu,
  StyledDatabaseOutlined,
  StyledUserAddOutlined,
  StyledHeatMapOutlined,
  StyledAuditOutlined,
  StyledBookOutlined,
  StyledBugOutlined,
  StyledBulbOutlined,
  StyledExclamationCircleOutlined,
  StyledCalendarOutlined
};
