/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Spin,
  message,
  Upload,
  Input,
  Select,
  Button,
  InputNumber,
  Tabs,
  Switch
} from "antd";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Editor } from '@tinymce/tinymce-react';

//import API functions
import {
  getPhysicianDetails,
  physicianProfileUpdate,
} from "../../api/PhysiciansAPI";
import { dateOfBirthFormatter } from "../../utils/dateFormatter";

// import components
import { StyledAvatarWrapperColumn } from "./PhysicianDetailsComponent";
import ImageCropperModal from '../ImageCropperModal';
import PhysicianAvailability from "./PhysicianAvailability";
import PhysicianQueueConfigComponent from "./PhysicianQueueConfigComponent"
import config from '../../config';

const { TabPane } = Tabs;

const { apiKeys } = config;

const StyledUpload = styled(Upload)`
  > .ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    margin: 20px auto 20px 2.5%;
    border-radius: 50%;
  }
  > .ant-upload-select-picture-card > .ant-upload {
    padding: 5px;
  }
  @media (max-width: 450px) {
    > .ant-upload-select-picture-card {
      width: 120px;
      height: 120px;
      margin: 20px auto 20px 2.5%;
    }
  }
`;

const StyledProfilePicInnerSection = styled.div`
  position: relative;
`;

const StyledProfilePicEditSection = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  color: #fff;
  background-color: #7bad7b;
  padding: 10%;
  border-radius: 25%;
`;

const PhysicianDetails = ({
  physicianId,
  viewModal,
  closeModal,
  clients,
  states,
  tags,
  employmentStatus,
  telemedicineCategory,
  doctorCredentials,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [physician, setPhysician] = useState(null)
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [DOB, setDOB] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [bio, setBio] = useState("");
  const [updatePic, setUpdatePic] = useState();
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [licensedStates, setLicensedStates] = useState([]);
  const [credential, setCredential] = useState();
  const [selectedTelemedicineCategory, setSelectedTelemedicineCategory] = useState([]);
  const [isCropping, setIsCropping] = useState(false);
  const [croppingImgSrc, setCroppingImgSrc] = useState();
  const [active, setActive] = useState(true);
  const [status, setStatus] = useState(null)
  const [form] = Form.useForm();

  const [selectedEmploymentStatus, setEmploymentStatus] = useState(null);

  const handleClientChange = (selectedItems) => {
    setSelectedClients(selectedItems);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleTelemedicineChange = (selectedItems) => {
    setSelectedTelemedicineCategory(selectedItems);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleStateChange = (selectedItems) => {
    setSelectedStates(selectedItems);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleFieldUpdate = (setter) => (value) => {
    setter(value);
    if (!edited) {
      setEdited(true);
    }
  }

  const handleModalClose = () => {
    closeModal(false);
  };

  const handlePhoneNumberChange = (event) => {
    setPhone(event.target.value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleStateAddressChange = (selectedItem) => {
    setState(selectedItem);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleZipCodeChange = ({ target }) => {
    setZipCode(target.value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleBioChange = (value) => {
    setBio(value);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleCredentialChange = (selectedCredential) => {
    setCredential(selectedCredential);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleMeetingLinkChange = (event) => {
    setMeetingLink(event.target.value);
    if (!edited) { setEdited(true); }
  };

  const handleEmploymentStatusChange = (selectedItem) => {
    setEmploymentStatus(selectedItem);
    if (!edited) {
      setEdited(true);
    }
  };

  const handleActiveChange = (value) => {
    setActive(value);
    setStatus(value ? "active" : "archive")
    if (!edited) {
      setEdited(true);
    }
  }

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const fetchPhysicianDetails = () => {
    setLoading(true);
    setImageLoading(true);
    getPhysicianDetails(physicianId)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          setPhysician(payload)

          if (payload.profilePic1) {
            setProfilePic(`${payload.profilePic1}?v=${Date.now()}`);
          }

          if (payload.credential) {
            setCredential(payload.credential);
          }
          if (payload.firstName) {
            setFirstName(payload.firstName);
          }
          if (payload.lastName) {
            setLastName(payload.lastName);
          }
          if (payload.email) {
            setEmail(payload.email);
          }
          if (payload.phone) {
            setPhone(payload.phone);
          }
          if (payload.DOB) {
            setDOB(payload.DOB);
          }
          if (payload.address1) {
            setAddress1(payload.address1);
          }
          if (payload.address2) {
            setAddress2(payload.address2);
          }

          if (payload.state) {
            setState(payload.state);
          }

          if (payload.city) {
            setCity(payload.city);
          }

          if (payload.zipCode) {
            setZipCode(payload.zipCode);
          }
          if (payload.teleMedicineCategoryIds.length) {
            setSelectedTelemedicineCategory(payload.teleMedicineCategoryIds);
          }
          if (payload.clientIds.length) {
            setSelectedClients(payload.clientIds);
          }
          if (payload.stateIds.length) {
            setSelectedStates(payload.stateIds);
          }
          if(payload.licensedStates.length) {
            setLicensedStates(payload.licensedStates.map(state => state._id));
          }
          if (payload.bio) {
            setBio(payload.bio);
          }
          if (payload.physicianGroup) {
            setEmploymentStatus(payload.physicianGroup);
          }
          if (payload.meetingLink) {
            setMeetingLink(payload.meetingLink);
          }
          setActive(payload.active || false);

          setStatus(payload.status || null)

          if (payload.tags) {
            form.setFieldsValue({ tags: payload.tags });
          }
        } else {
          message.error("Unable to load Physician Details");
        }
        setLoading(false);
        setImageLoading(false);
      })
      .catch(() => {
        message.error("Unable to load Physician Details");
        setLoading(false);
        setImageLoading(false);
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      return callback(reader.result);
    });
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (profileUrl) => {
        setCroppingImgSrc(profileUrl);
        setIsCropping(true);
        setImageLoading(false);
        if (!edited) {
          setEdited(true);
        }
      });
    }
  };

  const beforeSave = () => {
    if (meetingLink && !isValidHttpUrl(meetingLink)) {
      message.error('Meeting link must be a valid URL with a "http" or "https" prefix');
      return false;
    }

    return true;
  }

  const onSavingChanges = (values) => {
    if (!beforeSave()) return;
    setLoading(true);
    const data = new FormData();

    if (updatePic) {
      data.append("profilePic1", updatePic);
    }
    data.append("phone", phone);
    if (DOB) {
      data.append("DOB", DOB);
    }
    data.append("address1", address1);
    data.append("address2", address2);
    data.append("city", city);
    data.append("state", state);
    data.append("zipCode", zipCode);
    data.append("bio", bio);
    data.append("states", selectedStates);
    data.append('licensedStates', licensedStates);
    data.append("clients", selectedClients);
    data.append("teleMedicineCategories", selectedTelemedicineCategory);
    data.append("physicianId", physicianId);
    data.append("employmentStatus", selectedEmploymentStatus);
    data.append("credential", credential);
    data.append("meetingLink", meetingLink);
    data.append("active", active);
    data.append('tags', JSON.stringify(values.tags))
    data.append("status", status)

    physicianProfileUpdate(data)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully Updated Physician Details");
          setEdited(false);
          fetchPhysicianDetails();
          handleModalClose()
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update physician details. Please try again!"
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("================== error ========================", error);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to update physician details. Please try again!"
            );
          }
        } else {
          message.error(
            "Unable to update physician details. Please try again!"
          );
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPhysicianDetails(physicianId);
  }, [physicianId]);

  return (
    <Modal
      title="Edit Physician"
      visible={viewModal}
      footer={null}
      onCancel={handleModalClose}
      width='50vw'
      centered
      destroyOnClose
    >
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Tabs>
          <TabPane tab='Details' key='1'>
            <Form
              {...layout}
              layout={"vertical"}
              validateMessages={validateMessages}
              name="physician_details"
              onFinish={onSavingChanges}
              form={form}
            >
              <Row gutter={24}>
                <StyledAvatarWrapperColumn span={24}>
                  <Form.Item label="Profile Picture">
                    <StyledUpload
                      customRequest={dummyRequest}
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      className="myaccount-profile-pic"
                    >
                      {profilePic ? (
                        imageLoading ? (
                          <div>
                            <LoadingOutlined />
                          </div>
                        ) : (
                          <StyledProfilePicInnerSection>
                            <img
                              src={profilePic}
                              alt="avatar"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                              }}
                            />
                            <StyledProfilePicEditSection>
                              <EditOutlined />
                            </StyledProfilePicEditSection>
                          </StyledProfilePicInnerSection>
                        )
                      ) : (
                        <div>
                          {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div className="ant-upload-text">Upload Photo</div>
                        </div>
                      )}
                    </StyledUpload>
                  </Form.Item>
                </StyledAvatarWrapperColumn>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Form.Item label="Credential">
                    <Select
                      size="large"
                      value={credential}
                      placeholder="Select"
                      onChange={handleCredentialChange}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {doctorCredentials &&
                        doctorCredentials.map((eachCredential) => (
                          <Select.Option
                            key={eachCredential._id}
                            value={eachCredential.value}
                          >
                            {eachCredential.value}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                  <Form.Item label="First Name">
                    <Input size="large" value={firstName} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                  <Form.Item label="Last Name">
                    <Input size="large" value={lastName} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Email">
                    <Input size="large" value={email} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Phone">
                    <Input
                      size="large"
                      value={phone}
                      onChange={handlePhoneNumberChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Date of birth">
                    <Input value={DOB ? dateOfBirthFormatter(DOB) : ""} disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Address Line 1">
                    <Input
                      placeholder="Address Line 1"
                      value={address1}
                      onChange={handleAddress1Change}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Address Line 2 ">
                    <Input
                      placeholder="Address Line 2"
                      value={address2}
                      onChange={handleAddress2Change}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="City">
                    <Input
                      value={city}
                      placeholder="City"
                      onChange={handleCityChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="State">
                    <Select
                      value={state}
                      onChange={handleStateAddressChange}
                      placeholder="State"
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {states.map((eachState) => (
                        <Select.Option key={eachState.key} value={eachState.name}>
                          {eachState.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>


                <Col span={24}>
                  <Form.Item label="Zip Code">
                    <Input
                      value={zipCode}
                      placeholder="Zip Code"
                      onChange={handleZipCodeChange}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Clients" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      mode="multiple"
                      value={selectedClients}
                      placeholder="Select Clients"
                      showArrow
                      onChange={handleClientChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {clients.map((eachClient) => (
                        <Select.Option key={eachClient.key} value={eachClient.key}>
                          {eachClient.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Telemedicine Category"
                    rules={[{ required: true }]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      value={selectedTelemedicineCategory}
                      placeholder="Select Categories"
                      showArrow
                      onChange={handleTelemedicineChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {telemedicineCategory.map((eachCategory) => (
                        <Select.Option
                          key={eachCategory.key}
                          value={eachCategory.key}
                        >
                          {eachCategory.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="States" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      mode="multiple"
                      value={selectedStates}
                      placeholder="Select States"
                      showArrow
                      onChange={handleStateChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {states.map((eachState) => (
                        <Select.Option key={eachState.key} value={eachState.key}>
                          {eachState.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Licensed States">
                    <Select
                      value={licensedStates}
                      onChange={handleFieldUpdate(setLicensedStates)}
                      size="large"
                      mode="multiple"
                      placeholder="Select States"
                      showArrow
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {states.map((eachState) => (
                        <Select.Option key={eachState.key} value={eachState._id}>
                          {eachState.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Meeting link">
                    <Input
                      placeholder=""
                      value={meetingLink}
                      onChange={handleMeetingLinkChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Employment Status"
                    rules={[{ message: "Please Input your status" },
                    ]}
                  >
                    <Select
                      value={selectedEmploymentStatus}
                      onChange={handleEmploymentStatusChange}
                      placeholder="Select Employment Status"
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {employmentStatus &&
                        employmentStatus.map((item) => {
                          return (
                            <Select.Option key={item.key} value={item.value}>
                              {item.text}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="tags" label="Tags">
                    <Select mode='multiple' style={{ textTransform: 'capitalize'}}>
                      {tags.map(tag => (
                        <Select.Option 
                          key={tag} 
                          value={tag}
                          style={{ textTransform: 'capitalize'}}
                        >
                            {tag}
                      </Select.Option>))
                    }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Bio">
                    <Editor
                      apiKey={apiKeys.tinyEditorKey}
                      init={{
                        height: 350,
                        toolbar: true,
                        menubar: false,
                        statusbar: false,
                      }}
                      value={bio}
                      onEditorChange={(value) => handleBioChange(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ width: "100%" }}>
                    <span style={{ marginLeft: '10px' }}>Active</span>
                    <Switch checked={active} onChange={handleActiveChange} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ width: "100%" }}>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={!edited}
                    >
                      Save Changes
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          <TabPane tab='Availability' key='2'>
            <PhysicianAvailability physicianId={physicianId} />
          </TabPane>
          <TabPane tab='Queue Config' key='3'>
            <PhysicianQueueConfigComponent physician={physician} setPhysician={setPhysician}/>
          </TabPane>
        </Tabs>
        <ImageCropperModal
          isVisible={isCropping}
          setIsVisible={setIsCropping}
          imgSrc={croppingImgSrc}
          setImgSrc={(img) => {
            setUpdatePic(img);
            setProfilePic(URL.createObjectURL(img));
          }}
        />
      </Spin >
    </Modal >
  );
};

export default PhysicianDetails;


// TODO: is employment status always == physician group