import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Menu, Badge } from "antd";
import {
  StyledContainerOutlined,
  StyledUnorderedListOutlined,
  StyledTeamOutlined,
  StyledSpan,
  StyledMenu,
  StyledDatabaseOutlined,
  StyledHeatMapOutlined,
  StyledAuditOutlined,
  StyledBookOutlined,
  StyledBugOutlined,
  StyledBulbOutlined,
  StyledExclamationCircleOutlined,
  StyledCalendarOutlined,
} from "../components/MenuComponents";
//import components
import UserRolesAndRightsHOComponent from "../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import authorization from "../auth/authorization";
import config from '../config';

const MainMenu = ({
  collapsed,
  changeCollapseState,
  userType,
  notificationCount,
}) => {
  const history = useHistory();
  let location = useLocation();

  const [selectedMenuItem, setSelectedMenuItem] = useState(["lists"]);

  const setSelectedRoute = (pathname) => {
    const seperatedPathName = pathname.split("/");
    let selectedMenuForPath = "";
    switch (seperatedPathName[1]) {
      case "physicians":
        selectedMenuForPath = "physicians";
        break;
      case "dictionary":
        selectedMenuForPath = "lists";
        break;
      case "cases":
        selectedMenuForPath = "cases";
        break;
      case "report":
        selectedMenuForPath = "report";
        break;
      case "report-charts":
        selectedMenuForPath = "charts";
        break;
      case "case-tickets":
        selectedMenuForPath = "tickets";
        break;
      case "articles":
        selectedMenuForPath="articles";
        break;
      case 'report-bug':
        selectedMenuForPath='report-bug';
        break;
      case 'request-feature':
        selectedMenuForPath='request-feature';
        break;
      case 'appointments':
        selectedMenuForPath='appointments';
        break;
      default:
        selectedMenuForPath = "charts";
    }
    setSelectedMenuItem([`${selectedMenuForPath}`]);
  };

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  const getRoute = (key) => {
    switch (key) {
      case "lists":
        return {
          route: "/dictionary",
          selectedMenu: ["lists"],
        };
      case "physicians":
        return {
          route: "/physicians",
          selectedMenu: ["physicians"],
        };
      case "cases":
        return {
          route: "/cases",
          selectedMenu: ["cases"],
        };

      case "report":
        return {
          route: "/report",
          selectedMenu: ["report"],
        };

      case "charts":
        return {
          route: "/report-charts",
          selectedMenu: ["charts"],
        };
      case "tickets":
        return {
          route: "/case-tickets",
          selectedMenu: ["charts"],
        };
      case "articles":
        return{
          route:"/articles",
          selectedMenu:["articles"]
        }
      case "report-bug":
        return {
          route: '/report-bug',
          selectedMenu: ['report-bug']
        }
      case "request-feature":
        return {
          route: '/request-feature',
          selectedMenu: ['request-feature']
        }
      case "appointments":
        return {
          route: '/appointments',
          selectedMenu: ['appointments']
        }
      default:
        return {
          route: "/report-charts",
          selectedMenu: ["charts"],
        };
    }
  };

  const onMenuItemClick = (menuItemClicked) => {
    const { key } = menuItemClicked;
    const goToRoute = getRoute(key);
    setSelectedMenuItem(goToRoute.selectedMenu);
    history.push(goToRoute.route);
  };

  return (
    <StyledMenu
      onClick={onMenuItemClick}
      defaultSelectedKeys={["lists"]}
      selectedKeys={selectedMenuItem}
      mode="inline"
    >
      {authorization.hasAccessToModule(userType, [
        "consultCompleteReport",
        "consultNoRXReport",
        "clientCasesReport",
        "physicianCaseReport",
        "physicianPaymentReport",
      ]) ? (
        <Menu.Item key="charts">
          <StyledHeatMapOutlined />
          <StyledSpan>Reports</StyledSpan>
        </Menu.Item>
      ) : (
        <></>
      )}
      {authorization.hasAccessToModule(userType, [
        "createClient",
        "getCategoryOfClient",
        "updateClient",
        "deleteClient",
        "getAdminUserClients",
        "getAdminUserClientDetails",
        "createState",
        "updateState",
        "deleteState",
        "searchState",
        "getStates",
        "createTeleMedicineCategory",
        "updateTeleMedicineCategory",
        "deleteTeleMedicineCategory",
        "getTeleMedicineCategory",
        "createCaseStatus",
        "updateCaseStatus",
        "deleteCaseStatus",
        "getMessageTemplate",
        "getMessageTemplateDetails",
        "saveMessageTemplate",
        "updateMessageTemplate",
        "deleteMessageTemplate",
        "getAdminUsers",
        "adminUserView",
        "registerAdminUser",
        "adminUserUpdate",
        "adminUserDelete",
      ]) ? (
        <Menu.Item key="lists">
          <StyledUnorderedListOutlined />
          <StyledSpan>Manage Lists</StyledSpan>
        </Menu.Item>
      ) : (
        <></>
      )}
      {authorization.hasAccessToModule(userType, [
        "getPhysicianForAdmin",
        "physicianModerationByAdmin",
        "registerPhysician",
        "getPhysicianDetailsForAdmin",
        "updatePhysicianDetails",
      ]) ? (
        <Menu.Item key="physicians">
          <StyledTeamOutlined />
          <StyledSpan>Manage Physicians</StyledSpan>
        </Menu.Item>
      ) : (
        <></>
      )}
      {authorization.hasAccessToModule(userType, [
        "getCasesDetails",
        "updateCase",
        "caseStatusModification",
        "getCases",
      ]) ? (
        <Menu.Item key="cases">
          <StyledContainerOutlined />
          <StyledSpan>Manage Cases</StyledSpan>
        </Menu.Item>
      ) : (
        <></>
      )}
      {
        authorization.hasAccessToModule(userType, [
          'getCasesDetails',
          'getCases'
        ]) && <Menu.Item key='appointments'>
          <StyledCalendarOutlined />
          <StyledSpan>Manage Appointments</StyledSpan>
        </Menu.Item>
      }
      {authorization.hasAccessToModule(userType, [
        "consultCompleteReport",
        "consultNoRXReport",
        "clientCasesReport",
        "physicianCaseReport",
        "physicianPaymentReport",
      ]) ? (
        <Menu.Item key="report">
          <StyledDatabaseOutlined />
          <StyledSpan>QA</StyledSpan>
        </Menu.Item>
      ) : (
        <></>
      )}
      {authorization.hasAccessToModule(userType, ["caseTicketListing"]) ? (
        <Menu.Item key="tickets">
           <StyledAuditOutlined />
          <Badge count={notificationCount}>
            <StyledSpan>Case Tickets</StyledSpan>
          </Badge>
        </Menu.Item>
      ) : (
        <></>
      )}

      <Menu.Item key="articles">
        <StyledBookOutlined/>
        <StyledSpan>Articles</StyledSpan>
      </Menu.Item>

      <Menu.Item key="report-bug">
        <StyledBugOutlined/>
        <StyledSpan>
          Report Bug
        </StyledSpan>
      </Menu.Item>

      <Menu.Item key="request-feature">
        <StyledBulbOutlined/>
        <StyledSpan>
          Request Feature
        </StyledSpan>
      </Menu.Item>
      <Menu.Item key="sha" onClick={() => window.navigator?.clipboard?.writeText?.(config.LATEST_SHA)}>
        <StyledSpan>
          sha: #{config.LATEST_SHA}
        </StyledSpan>
      </Menu.Item>
    </StyledMenu>
  );
};

export default UserRolesAndRightsHOComponent(MainMenu);
