import axiosInstance from "./AxiosInstance";
import queryString from "query-string";
import config from "../config";
import qs from "qs"

const { apiAppendPath } = config;

export const getPhysiciansV2 = ({ physicianGroup, status, active }={}) => {
    const params = {};

    if (physicianGroup) {
        params.physicianGroup = physicianGroup;
    }

    if (status) {
        params.status = status;
    }

    if (active) {
        params.active = active
    }

    return axiosInstance.get('/physicians', { params });
}

/**
 * @typedef {Object} filters
 * @property {'all'|'active'|'deactive'|'rejected'|'moderation'} fetchType physician status
 * @property {string} physicianGroup physicianGroup id
 * @property {string} client client id
 * @property {string} teleMedicineCategory teleMedicineCategory id
 * @property {string} state state id
 * @property {string} searchTerm string to search by first name/last name/email
 * 
 * @param {filters} filters query params constructor
 */
export const getPhysicians = (filters={ fetchType: 'all' }) => {
    const queryParamDict = {
        fetchType: 'fetch-type',
        physicianGroup: 'physician-group',
        client: 'client',
        teleMedicineCategory: 'telemedicine-category',
        state: 'state',
        tags: 'tags',
        searchTerm: 'search-term',
        active: 'active'
    }

    const params = Object.entries(filters).reduce((params, [key, value]) => {
        const queryParam = queryParamDict[key];
        if (queryParam) {
            params[queryParam] = value;
        }

        return params;
    }, {})


    return axiosInstance.get(`${apiAppendPath.physicianAppendPath}/physicians`, { params });
}

export const getPhysicianDetails = (physicianId) => {
    return axiosInstance.get(
        `${apiAppendPath.physicianAppendPath}/physician/${physicianId}`
    );
};

export const updatePhysicianDetails = (physicianId, data) => {
    return axiosInstance.patch(
        `${apiAppendPath.physicianAppendPath}/physician/${physicianId}`,
        data
    );
};


export const verifyPhysician = (npi, firstname, lastname) => {
    return axiosInstance.get(
        `${apiAppendPath.physicianApiAppendPath}/physician/verify-npi`, {
        params: {
            npi: npi,
            firstName: firstname,
            lastName: lastname,
        }
    });
}

export const physicianSignUp = (model) => {
    var bodyFormData = new FormData();
    for (var key in model) {
        bodyFormData.append(key, model[key]);
    }

    return axiosInstance.post(
        `${apiAppendPath.physicianApiAppendPath}/physician`,
        bodyFormData,
    );
}


export const getScheduleDays = (physicianId) => {
    return axiosInstance.get(`${apiAppendPath.physicianAppendPath}/get-availability`, {
        params: {
            id: physicianId,
        }
    });
}

export const updateScheduleDays = (data) => {
    return axiosInstance.put(`${apiAppendPath.physicianAppendPath}/update-schedules`, data);
}


export const physicianProfileUpdate = (data) => {
    return axiosInstance.put(
        `${apiAppendPath.physicianApiAppendPath}/physician`,
        data,
        {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }
    );
};

export const getPhysicianPayments = (physicianId) => {
    return axiosInstance.get(`${apiAppendPath.physicianAppendPath}/view-physician-payments/${physicianId}`);
}

export const updatePhysicianPaymentDetails = (physicianId, data) => {
    return axiosInstance.patch(
        `${apiAppendPath.physicianAppendPath}/edit-physician-payments/${physicianId}`,
        data
    );
};

export const updateBulkExternalPhysiciansConsultRates = (physicians, consultRates) => {
    return axiosInstance.put(`/physicians/external/consult-rates`, { physicians, consultRates });
}

export const copyGlobalConsultRatesToExternalPhysician = (physicianId) => {
    return axiosInstance.patch(`/physicians/external/${physicianId}/global-consult-rates`);
}

export const getAvailability = (physicianId) => {
    return axiosInstance.get(`/physicians/${physicianId}/availability`);
}

export const addTimeFrame = (physicianId, availabilityType, timeFrame) => {
    return axiosInstance.post(`/physicians/${physicianId}/availability/time-frame`, { [availabilityType]: timeFrame });
}

export const deleteTimeFrame = (physicianId, availabilityType, timeFrameId) => {
    return axiosInstance.delete(`/physicians/${physicianId}/availability/time-frame`, { 
        params: { [availabilityType]: timeFrameId } 
    });
}

export const updateTimeFrame = (physicianId, availabilityType, timeFrameId, updates) => {
    return axiosInstance.put(`/physicians/${physicianId}/availability/time-frame`, { [availabilityType]: { timeFrameId, ...updates } });
}

export const updateTimeZone = (physicianId, timeZone) => {
    return axiosInstance.put(`/physicians/${physicianId}/availability/time-zone`, { preferredIANATimeZoneName: timeZone });
}

export const getPhysiciansAsAdmin = params => {
    return axiosInstance.get(`/admin-physician/physicians`, 
        {
            params, 
            paramsSerializer: params => qs.stringify(params)
        }
    )
}

export const getEligiblePhysiciansForReassignment = (caseIds, name) => {
    let uri = `/physicians/reassign-eligible`
    return axiosInstance.post(uri, { caseIds, name });
}