const userRolesAndRights = {
  "super admin": {
    userType: "super admin",
    rights: [
      "assignPhysician",
      "getPhysicianForAdmin",
      "getPhysicianDetailsForAdmin",
      "updateExternalPhysicianPaymentDetails",
      "physicianModerationByAdmin",
      "getAdminUsers",
      "adminUserView",
      "registerAdminUser",
      "adminUserUpdate",
      "adminUserDelete",
      "getAdminUserClients",
      "getAdminUserClientDetails",
      "adminUserResetPassword",
      "getVideoAudioToken",
      "updatePatientDetailsByClient",
      "getCaseByClient",
      "updateCaseDetailsByClient",
      "getVideoAudioTokenByClient",
      "getTimeSlotsByClient",
      "scheduleByClient",
      "rescheduleByClient",
      "getChatsOfCaseByClient",
      "uploadImageByClient",
      "sentMessageByClient",
      "getCases",
      "getCasesDetails",
      "updateCase",
      "caseStatusModification",
      "caseConsultationTypeModification",
      "createCase",
      "createCaseStatus",
      "updateCaseStatus",
      "deleteCaseStatus",
      "getChatsOfCase",
      "uploadImageChats",
      "sentMessage",
      "getChatsOfPhysician",
      "clearNotification",
      "createClient",
      "getCategoryOfClient",
      "updateClient",
      "deleteClient",
      "getMessageTemplate",
      "getMessageTemplateDetails",
      "saveMessageTemplate",
      "updateMessageTemplate",
      "deleteMessageTemplate",
      "getPhysicianDetails",
      "updatePhysicianDetails",
      "getPhysicianCases",
      "getPhysicianCaseDetails",
      "updateCaseStatusByPhysician",
      "getPhysicianAvailability",
      "updatePrescription",
      "rxchangeCase",
      "consultCompleteReport",
      "consultNoRXReport",
      "clientCasesReport",
      "physicianCaseReport",
      "physicianPaymentReport",
      "incompleteCasesReport",
      "getPhysicianConsultation",
      "getPhysicianTimeSlots",
      "scheduleConsultation",
      "rescheduleConsultation",
      "createState",
      "updateState",
      "deleteState",
      "searchState",
      "getStates",
      "getTeleMedicineCategory",
      "createTeleMedicineCategory",
      "updateTeleMedicineCategory",
      "deleteTeleMedicineCategory",
      "getUserRoles",
      "getUserRoleDetails",
      "createUserRole",
      "updateUserRoleDetails",
      "deleteUserRoleDetails",
      "updateWheelStatus",
      "registerPhysician",
      "caseStatusModification",
      "caseTicketListing",
      "internalPhysicianChat",
    ],
    allowedUsers: [
      "admin",
      "admin support",
      "manager",
      "provider",
      "steadymd admin support",
    ],
  },
  admin: {
    userType: "admin",
    rights: [
      "assignPhysician",
      "internalPhysicianChat",
      "getPhysicianForAdmin",
      "getPhysicianDetailsForAdmin",
      "updateExternalPhysicianPaymentDetails",
      "physicianModerationByAdmin",
      "getAdminUsers",
      "adminUserView",
      "registerAdminUser",
      "adminUserUpdate",
      "adminUserDelete",
      "getAdminUserClients",
      "getAdminUserClientDetails",
      "adminUserResetPassword",
      "getVideoAudioToken",
      "updatePatientDetailsByClient",
      "getCaseByClient",
      "updateCaseDetailsByClient",
      "getVideoAudioTokenByClient",
      "getTimeSlotsByClient",
      "scheduleByClient",
      "rescheduleByClient",
      "getChatsOfCaseByClient",
      "uploadImageByClient",
      "sentMessageByClient",
      "getCases",
      "getCasesDetails",
      "updateCase",
      "caseStatusModification",
      "caseConsultationTypeModification",
      "createCase",
      "getChatsOfCase",
      "uploadImageChats",
      "sentMessage",
      "getChatsOfPhysician",
      "clearNotification",
      "getMessageTemplate",
      "getMessageTemplateDetails",
      "getPhysicianDetails",
      "updatePhysicianDetails",
      "getPhysicianCases",
      "getPhysicianCaseDetails",
      "updateCaseStatusByPhysician",
      "getPhysicianAvailability",
      "updatePrescription",
      "rxchangeCase",
      "consultCompleteReport",
      "consultNoRXReport",
      "clientCasesReport",
      "physicianCaseReport",
      "physicianPaymentReport",
      "incompleteCasesReport",
      "getPhysicianConsultation",
      "getPhysicianTimeSlots",
      "scheduleConsultation",
      "rescheduleConsultation",
      "searchState",
      "getStates",
      "getTeleMedicineCategory",
      "getUserRoles",
      "getUserRoleDetails",
      "createUserRole",
      "updateUserRoleDetails",
      "deleteUserRoleDetails",
      "updateWheelStatus",
      "registerPhysician",
      "caseStatusModification",
      "caseTicketListing",
    ],
    allowedUsers: ["manager"],
  },
  "admin support": {
    userType: "admin support",
    rights: [
      "internalPhysicianChat",
      "consultCompleteReport",
      "consultNoRXReport",
      "clientCasesReport",
      "physicianCaseReport",
      "physicianPaymentReport",
      "getCases",
      "getCasesDetails",
      "getPhysicianForAdmin",
      "getPhysicianDetailsForAdmin",
      "getMessageTemplate",
      "getMessageTemplateDetails",
      "getAdminUserClients",
      "getAdminUserClientDetails",
      "adminUserResetPassword",
      "getStates",
      "getTeleMedicineCategory",
      "caseTicketListing",
    ],
    allowedUsers: [],
  },
  manager: {
    userType: "manager",
    rights: [
      "consultCompleteReport",
      "consultNoRXReport",
      "clientCasesReport",
      "physicianCaseReport",
      "physicianPaymentReport",
      "getCases",
      "getCasesDetails",
      "getAdminUserClients",
      "getAdminUserClientDetails",
      "adminUserResetPassword",
    ],
    allowedUsers: [],
  },
  provider: {
    userType: "provider",
    rights: [
      "getPhysicianForAdmin",
      "getPhysicianDetailsForAdmin",
      "getCases",
      "getCasesDetails",
      "updatePhysicianDetails",
      "updatePhysicianSchedule",
      "getPhysicianAvailability",
      "updateWheelStatus",
    ],
    allowedUsers: [],
  },
  "steadymd admin support": {
    userType: "steadymd admin support",
    rights: [
      "internalPhysicianChat",
      "consultCompleteReport",
      "consultNoRXReport",
      "clientCasesReport",
      "physicianCaseReport",
      "physicianPaymentReport",
      "getCases",
      "getCasesDetails",
      "getPhysicianForAdmin",
      "getPhysicianDetailsForAdmin",
      "getMessageTemplate",
      "getMessageTemplateDetails",
      "getAdminUserClients",
      "getAdminUserClientDetails",
      "adminUserResetPassword",
      "getStates",
      "getTeleMedicineCategory",
      "updateStedyMdPhysicianDetails",
      "caseTicketListing",
    ],
    allowedUsers: [],
  },
};

export default userRolesAndRights;
