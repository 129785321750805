import { Col, Avatar, Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

const StyledAvatar = styled(Avatar)`
  background: #ffffff;
  color: #7BAD7E;
`;

const StyledAvatarWrapperColumn = styled(Col)`
  text-align: center;
  padding: 12px;
`;

const StyledTextArea = styled(TextArea)`
  &.ant-input-disabled {
    color: #7BAD7E;
    font-weight: 600;
    background-color: #ffffff;
  }
`;

export { StyledAvatar, StyledAvatarWrapperColumn, StyledTextArea };