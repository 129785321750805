import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config

export const addState = data => {
    return axiosInstance.post(
        `${apiAppendPath.stateAppendPath}/state`,
        data
    );
};

export const getAllStates = (searchTerm) => {
    return axiosInstance.get(
        `${apiAppendPath.stateAppendPath}/state?search=${searchTerm}`
    );
};

export const updateState = (stateId, data) => {
    return axiosInstance.put(
        `${apiAppendPath.stateAppendPath}/state/${stateId}`,
        data
    );
};

export const deleteState = stateId => {
    return axiosInstance.delete(
        `${apiAppendPath.stateAppendPath}/state/${stateId}`
    );
};

export const bulkUpdatePharmacy = (states, categories, pharmacy) => {
    return axiosInstance.patch(`${apiAppendPath.stateAppendPath}/bulk-update-pharmacy`, { 
        states,
        categories,
        pharmacy
    })
}

export const bulkUpdateCasePharmacies = (stateId, categoryIds) => {
    return axiosInstance.post(`/states/${stateId}/update-cases`, { categoryIds });
}