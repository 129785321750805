import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config

export const getPharmacies = name => {
    return axiosInstance.get(
        `${apiAppendPath.prescriptionsAppendPath}/pharmacy?name=${name}`
    );
};

export const getPharmacyDetails = pharmacyId => {
    return axiosInstance.get(
        `${apiAppendPath.prescriptionsAppendPath}/pharmacy/${pharmacyId}`
    );
};