import React, { useState, useEffect } from "react";
import { Form, Col, Select, Row, Button } from "antd";
import { lookUp } from "../../api/LookUpAPI";
import styled from "styled-components";

const StyledCol = styled(Col)`
  display: flex;
  .ant-form-item {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const { useForm } = Form;

const AllocationPhysicianFilter = ({ fetchPhysicianChartReportData }) => {
  const [form] = useForm();
  const [fetchTelemedicine, setFetchTelemedicine] = useState();
  const [fetchClients, setFetchClients] = useState();
  const [isFilter, setIsFilter] = useState(false);
  useEffect(() => {
    const responsetelemedicine = [];
    const responseClient = [];
    lookUp("tele-medicine-category").then((res) => {
      res.data.payload.map((category) => {
        return responsetelemedicine.push({
          key: category._id,
          value: category.name,
          text: category.name,
        });
      });
      setFetchTelemedicine(responsetelemedicine);
    });

    lookUp("client").then((res) => {
      res.data.payload.map((client) => {
        return responseClient.push({
          key: client._id,
          value: client.name,
          text: client.name,
        });
      });
      setFetchClients(responseClient);
    });
  }, []);

  const onClearFilter = () => {
    form.resetFields();
    setIsFilter(false);
    fetchPhysicianChartReportData();
  };

  const applyFilterOnChange = () => {
    setIsFilter(true);
  };

  const onApplyFilterData = (value) => {
    fetchPhysicianChartReportData(value);
  };

  return (
    <Form
      form={form}
      onFieldsChange={applyFilterOnChange}
      name="physician-chart-filter"
      layout={"vertical"}
      onFinish={onApplyFilterData}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Form.Item name="telemedecineCategory" label="Category">
            <Select
              placeholder="Filter by telemedicine category"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {fetchTelemedicine &&
                fetchTelemedicine.map((eachCategory) => (
                  <Select.Option
                    key={eachCategory.key}
                    value={eachCategory.key}
                  >
                    {eachCategory.value}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Form.Item name="client" label="Clients">
            <Select
              placeholder="Filter by Clients"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {fetchClients &&
                fetchClients.map((eachClient) => (
                  <Select.Option key={eachClient.key} value={eachClient.key}>
                    {eachClient.value}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <StyledCol xs={8} sm={8} md={4} lg={3} xl={2}>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </StyledCol>
        <StyledCol xs={8} sm={8} md={4} lg={3} xl={2}>
          <Form.Item>
            <Button
              type="secondary"
              disabled={!isFilter}
              onClick={() => onClearFilter()}
            >
              Clear
            </Button>
          </Form.Item>
        </StyledCol>
      </Row>
    </Form>
  );
};

export default AllocationPhysicianFilter;
