import moment from "moment-timezone";
const converterTimeZone = (utcTime, localTimeZone, requiredTimeZone) => {
  if (localTimeZone === requiredTimeZone) {
    return utcTime;
  } else {
    const toRequiredTimeZone = moment
      .utc(utcTime, "YYYYMMDD HH:mm")
      .clone()
      .tz(requiredTimeZone)
      .format("YYYY-MM-DD HH:mm");
    const toLocalTimeZone = moment
      .tz(toRequiredTimeZone, localTimeZone)
      .utc()
      .format();
    return toLocalTimeZone;
  }
};

export default converterTimeZone;
