import React, { createContext, useContext, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { message } from "antd";
import moment from "moment-timezone";
import socket from "../api/socket";
import authorization from "../auth/authorization";
import { SocketEventEnum } from "../constants/enums";

const {
  GET_CASE_REPORT,
  GET_CONSULT_TYPE_REPORT,
  GET_COMPLETION_COUNTS_REPORT,
} = SocketEventEnum

const PhysicianReportContext = createContext(null);

export const usePhysicianReportContext = () => {
  const context = useContext(PhysicianReportContext);
  if (!context) {
      throw new Error("usePhysicianReportContext must be used within a PhysicianReportSocketProvider");
  }
  return context;
}

export const PhysicianReportSocketProvider = ({ children }) => {
  const [caseReport, setCaseReport] = useState(null)
  const [consultReport, setConsultReport] = useState(null)
  const [completionCounts, setCompletionCounts] = useState(null)
  const [loadingCaseReport, setLoadingCaseReport] = useState(false)
  const [loadingConsultReport, setLoadingConsultReport] = useState(false)
  const [loadingCompletionCounts, setLoadingCompletionCounts] = useState(false)
  const [isLoadedCaseReport, setIsLoadedCaseReport] = useState(false)
  const [isLoadedConsultReport, setIsLoadedConsultReport] = useState(false)
  const [isLoadedCompletionCounts, setIsLoadedCompletionCounts] = useState(false)
  const [isError, setIsError] = useState(false)

  const requestId = useRef({ caseReport: 0, consultReport: 0, completionCounts: 0 })

  const [physicians, setPhysicians] = useState([]);
  const [currentPhysicianId, setCurrentPhysicianId] = useState(null);

  const [currentReportDate, setCurrentReportDate] = useState(null)
  const formattedCurrentReportDate = moment(currentReportDate).format('YYYY-MM')

  const resetData = () => {
    setCaseReport(null)
    setConsultReport(null)
    setCompletionCounts(null)
    setIsError(false)
  }

  const setLoading = (value) => {
    setLoadingCaseReport(value);
    setLoadingConsultReport(value);
    setLoadingCompletionCounts(value);

    setIsLoadedCaseReport(!value)
    setIsLoadedConsultReport(!value)
    setIsLoadedCompletionCounts(!value)
  }

  const hasReports = !!(caseReport && consultReport && completionCounts)
  const loading = loadingCaseReport || loadingConsultReport || loadingCompletionCounts
  const isLoaded = isLoadedCaseReport && isLoadedConsultReport && isLoadedCompletionCounts

  const currentPhysician = physicians.find((physician) => physician._id === currentPhysicianId)
  const currentPhysicianName = currentPhysician ? `${currentPhysician.firstName} ${currentPhysician.lastName}` : "All Physicians"

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (isError) {
      message.error(`Error retrieving Report for ${currentPhysicianName} ${formattedCurrentReportDate}`);
      return;
    }
    if (hasReports) {
      message.success(`Report is ready for ${currentPhysicianName} ${formattedCurrentReportDate}`);
      return;
    }
  }, [isLoaded, isError, hasReports, formattedCurrentReportDate, currentPhysicianName])


  useEffect(() => {
    const userId = authorization.getUserId()
    if (userId === "no user") {
      console.log("Socket can't connect to Physician Report events. No user found")
      return;
    }

    const getUserEventName = (eventName) => `${eventName}-${userId}`
    const caseReportEventName = getUserEventName(GET_CASE_REPORT)
    const consultReportEventName = getUserEventName(GET_CONSULT_TYPE_REPORT)
    const completionCountsEventName = getUserEventName(GET_COMPLETION_COUNTS_REPORT)

    socket.on(caseReportEventName, (response) => {
      if (Number(response.requestId) !== requestId.current.caseReport) {
        console.log(
          `Outdated response for ${caseReportEventName}>>>>>`,
          { response, currentRequestId: requestId.current.caseReport },
        );
        return;
      }

      requestId.current.caseReport += 1
      console.log(`Socket response for ${caseReportEventName}>>>>>`, response);
      const data = response.payload?.data
      if (data) {
        setCaseReport(data[0] || [])
      } else {
        setIsError(true)
        message.error("Error occured while fetching monthly open cases report");
      }
      setLoadingCaseReport(false)
      setIsLoadedCaseReport(true)
    })

    socket.on(consultReportEventName, (response) => {
      if (Number(response.requestId) !== requestId.current.consultReport) {
        console.log(
          `Outdated response for ${consultReportEventName}>>>>>`,
          { response, currentRequestId: requestId.current.consultReport },
        );
        return;
      }

      requestId.current.consultReport += 1
      console.log(`Socket response for ${consultReportEventName}>>>>>`, response);
      if (!response.reports) {
        message.error("Error occured while fetching monthly consult report");
        setLoadingConsultReport(false)
        setIsLoadedConsultReport(true)
        setIsError(true)
        return;
      };
      const { newConsultTypeReport, openConsultTypeReport } = response.reports
      let newConsults, openConsults

      if (newConsultTypeReport && newConsultTypeReport.length) {
        newConsults = { type: "New Cases" };
        newConsultTypeReport.forEach((consult) => {
          newConsults[consult._id] = consult.cases;
        });
      }

      if (openConsultTypeReport && openConsultTypeReport.length) {
        openConsults = { type: "Open Cases" };
        openConsultTypeReport.forEach((consult) => {
          openConsults[consult._id] = consult.cases;
        });
      }
      setConsultReport([newConsults, openConsults])
      setLoadingConsultReport(false)
      setIsLoadedConsultReport(true)
    })

    socket.on(completionCountsEventName, (response) => {
      if (Number(response.requestId) !== requestId.current.completionCounts) {
        console.log(
          `Outdated response for ${completionCountsEventName}>>>>>`,
          { response, currentRequestId: requestId.current.completionCounts },
        );
        return;
      }

      requestId.current.completionCounts += 1
      console.log(`Socket response for ${completionCountsEventName}>>>>>`, response);
      const data = response.payload?.data
      if (data) {
        setCompletionCounts(data[0] || { totals : {} })
      } else {
        setIsError(true)
        message.error("Error occured while fetching monthly completion counts");
      }
      setLoadingCompletionCounts(false)
      setIsLoadedCompletionCounts(true)
    })

    return () => {
      socket.removeAllListeners([
        GET_CASE_REPORT,
        GET_CONSULT_TYPE_REPORT,
        GET_COMPLETION_COUNTS_REPORT,
      ]);
      socket.close();
      console.log("Physician Report Socket event listeners removed")
    };
  }, []);

  const cancelRequest = useCallback(() => {
    requestId.current.caseReport += 1
    requestId.current.consultReport += 1
    requestId.current.completionCounts += 1
    setLoading(false)
  }, [])

  const contextData = useMemo(() => ({
    caseReport,
    consultReport,
    completionCounts,
    loading,
    isLoaded,
    isError,
    formattedCurrentReportDate,
    currentPhysicianId,
    physicians,
    currentPhysicianName,
    requestId,
    cancelRequest,
    setCurrentReportDate,
    setCurrentPhysicianId,
    setPhysicians,
    setLoading,
    resetData,
  }), [
    caseReport,
    consultReport,
    completionCounts,
    loading,
    isLoaded,
    isError,
    formattedCurrentReportDate,
    currentPhysicianId,
    physicians,
    currentPhysicianName,
    cancelRequest,
  ])

  return (
    <PhysicianReportContext.Provider value={contextData}>{children}</PhysicianReportContext.Provider>
  )
}
