export const setLocalStorage = (key, val) => window.localStorage.setItem(key, JSON.stringify(val))

export const getLocalStorage = (key, prop) => {
  const storage = window.localStorage.getItem(key)
  if (storage) {
    try {
      return JSON.parse(storage)[prop]
    } catch(e) {
      return null
    }
  }
  return null
}
