import React, { useState, useEffect } from "react";
import { Table, Row, Col, Typography, message } from "antd";
import styled from "styled-components";
import moment from "moment";
import { getPhysiciansV2 } from "../../api/PhysiciansAPI";
import { getUserClients } from "../../api/UserTypeAPI"
import { generateSpreadsheet } from '../../utils/generateAllocatedReportSpreadsheet'
import { parseItem } from '../../utils/parseAllocatedReportLineItem'
import CustomAllocatedFilter from './CustomAllocatedFilter'

const { Column, ColumnGroup } = Table
const { Title, Text } = Typography

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }

  & .ant-spin-container {
    background: none;
  }

  & h2 {
    margin-left: 45px;
    margin-bottom: 0;
  }
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledTable = styled(Table)`
  overflow: hidden;
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }

  & .ant-table-bordered tbody tr td {
    border-right: none;
  }

  & .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th:first-of-type {
    background: none;
  }

  & .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th:not(:first-of-type) {
    background: #7BAD7E;
    color: #fff;
  }

  & .ant-table.ant-table-bordered table thead > tr > th {
   font-weight: bolder;
  }

  & .ant-table-expanded-row {
    height: 110px;
  }

  & .ant-table-expanded-row > td {
    background: none;
  }

  & .ant-table-cell.ant-table-row-expand-icon-cell {
    opacity: 0;
  }

  & .child-table td:first-of-type {
    font-size: 12px;
    font-weight: bold;
  }

  & table table td:not(:nth-of-type(1)) {
    text-align: center;
    border-right: none;
    color: #666 !important;
    font-weight: bolder;
  }

  & table tr td:not(:first-of-type) {
    background: #A2BD9E;
    color: #fff;
    font-weight: bolder;
    border-right: none;
  }

  & .child-table table * {
    border: none;
  }

  & .child-table tr {
    color: #666;
  }

  & .child-table tr:nth-of-type(1) {
    background: #D7E3D5;
  }

  & .child-table tr:nth-of-type(2) {
    background: #D7E3D5;
    color: #666;
  }

  & .child-table tr:nth-of-type(3) {
    background: #BDD0BA;
    color: #666;
  }

  & .ant-table-row.ant-table-row-level-0:hover td {
    background: #A2BD9E;
  }

  & .ant-table-row.ant-table-row-level-0:hover > td {
    cursor: default !important;
  }


`;

const columns = [
  {
    dataIndex: 'type',
    key: 'type',
    width: '16%'
  },
  {
    dataIndex: 'casesAllocated',
    key: 'casesAllocated',
    width: '9.33%'
  }, {
    dataIndex: 'casesCompleted',
    key: 'casesCompleted',
    width: '9.33%'
  },
  {
    dataIndex: 'casesNoRx',
    key: 'casesNoRx',
    width: '9.33%'
  },
  {
    dataIndex: 'renewalsAllocated',
    key: 'renewalsAllocated',
    width: '9.33%'
  },
  {
    dataIndex: 'renewalsCompleted',
    key: 'renewalsCompleted',
    width: '9.33%'
  }, {
    dataIndex: 'renewalsNoRx',
    key: 'renewalsNoRx',
    width: '9.33%'
  },
  {
    dataIndex: 'rxChangesAllocated',
    key: 'rxChangesAllocated',
    width: '9.33%'
  },
  {
    dataIndex: 'rxChangesCompleted',
    key: 'rxChangesCompleted',
    width: '9.33%'
  },
  {
    dataIndex: 'rxChangesNoRx',
    key: 'rxChangesNoRX',
    width: '9.33%'
  }
];

const ReportAllocated = ({
  selectedReport,
  report,
  loading,
  handleFetchReport,
}) => {
  const [physicianIds, setPhysicianIds] = useState([])
  const [clientIds, setClientIds] = useState([])
  const [filterType, setFilterType] = useState(null)
  const [filterDate, setFilterDate] = useState(null)
  const [filteredReport, setFilteredReport] = useState(report || [])
  const [count, setCount] = useState(0)

  const isGeneratedReport = selectedReport.includes('generated')
  const isPhysicianReport = selectedReport.includes('physicians')
  const isClientReport = selectedReport.includes('clients')

  useEffect(() => {
    (async function () {
      try {
        const { data } = await getPhysiciansV2({ status: "active", active: true });
        if (!data?.physicians) throw new Error();
        const physicianIdList = data.physicians.map(({ _id }) => _id)
        setPhysicianIds(physicianIdList);
      } catch (e) {
        console.log(e.response?.data?.error || e)
        message.error("Error retriving physicians");
      }

      try {
        const { data } = await getUserClients();
        const clients = data?.payload
        if (!clients) throw new Error();
        const clientIdList = clients.map(({ _id }) => _id)
        setClientIds(clientIdList);
      } catch (e) {
        console.log(e.response?.data?.error || e)
        message.error("Error retriving clients");
      }
    })()
  }, [])

  useEffect(() => {
    setFilteredReport(report || [])
    setCount(report?.length || 0)
  }, [report])

  const onSearch = async (filter = {}) => {
    if (!isGeneratedReport) {
      const filterTypes = {
        date: moment(filter.date).format("MM-DD-YYYY"),
        day: moment(filter.date).format("MM-DD-YYYY"),
        month: moment(filter.date).format("MM-01-YYYY"),
        year: moment(filter.date).format("01-01-YYYY")
      }

      filter["filter"] = filterTypes[filter.filterType]

      if (!filter["filter"]) {
        filter["startDate"] = moment(filter.date)
          .startOf("week")
          .format("MM-DD-YYYY");
        filter["endDate"] = moment(filter.date)
          .endOf("week")
          .format("MM-DD-YYYY");
      }

      setFilterDate(moment(filter.date).format("YYYY-MM-DD") || 'custom')
      setFilterType(filter.filterType || `${moment(filter["startDate"]).format("YYYY-MM-DD")}-${moment(filter["endDate"]).format("YYYY-MM-DD")}`)

      const params = {
        filter: filter.filter,
        filterType: filter.filterType,
      }

      if (isClientReport) {
        params.clientIds = clientIds
      }
      else if (isPhysicianReport) {
        params.physicianIds = physicianIds
      }
      handleFetchReport(params)
    } else {
      setFilteredReport(report)
    }
  };

  const generateDocName = () => {
    const reportType = isClientReport ? 'client' : 'physician'
    return `${filterDate}-${reportType}-${filterType}`
  }

  const generateTableTitle = () => {
    let title = ['day', 'date', 'week'].includes(filterType)
      ? moment(filterDate).format('MMMM  D, YYYY')
      : moment(filterDate).format(
        filterType === 'month'
          ? 'MMMM YYYY'
          : 'YYYY'
      )
    filterType === 'week' && (title = 'Week of ' + moment(title).format('MMMM D,  YYYY'))
    return <Text style={{ fontWeight: 700, marginLeft: '45px', textTransform: 'capitalize' }}>{title}</Text>
  }

  return (
    <StyledComponentWrapper id='page'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <StyledTitle level={2}>{isPhysicianReport ? 'Physicians' : 'Clients'}' Case Count</StyledTitle>
          {filterDate && generateTableTitle()}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CustomAllocatedFilter
            onSearch={onSearch}
            onExportToXLSX={() => generateSpreadsheet(generateDocName(), filteredReport, parseItem)}
            hasData={filteredReport?.length > 0}
          />
        </Col>
        <Col span={24}>
          <StyledTable
            id='data-table'
            scroll={false}
            bordered
            expandable={{
              expandedRowRender: item => (
                <Table
                  className='child-table'
                  columns={columns}
                  dataSource={parseItem(item)}
                  pagination={false}
                  showHeader={false}
                  bordered={false}
                />),
              expandedRowKeys: [...filteredReport].map(o => o.key),
              showExpandColumn: false,
              expandIcon: () => <></>
            }}
            dataSource={!loading && [...filteredReport]}
            pagination={{
              total: count,
              showTotal: () => `Total ${count} ${selectedReport.split('-')[1]}`,
              pageSizeOptions: ["5", "10", "20", "50", "100"],
              showSizeChanger: true
            }}
          >
            <ColumnGroup>
              {isPhysicianReport && <Column title="Physician" dataIndex="physician" key="physician" width="16%" />}
              {!isPhysicianReport && <Column title="Client" dataIndex="client" key="client" width="16%" />}
            </ColumnGroup>
            <ColumnGroup title="Cases" align="center" width="28%">
              <Column title="Allocated" key="cases-allocated" align="center" width="9%" />
              <Column title="Completed" key="cases-cc" align="center" width="9%" />
              <Column title="No Rx" key="cases-ccnorx" align="center" width="9%" />
            </ColumnGroup>
            <ColumnGroup title="Renewals" align="center" width="28%">
              <Column title="Allocated" key="renewals-allocated" align="center" width="9%" />
              <Column title="Completed" key="renewals-cc" align="center" width="9%" />
              <Column title="No Rx" key="renewals-ccnorx" align="center" width="9%" />
            </ColumnGroup>
            <ColumnGroup title="Rx Changes" align="center" width="28%">
              <Column title="Allocated" key="changes-allocated" align="center" width="9%" />
              <Column title="Completed" key="changes-cc" align="center" width="9%" />
              <Column title="No Rx" key="changes-ccnorx" align="center" width="9%" />
            </ColumnGroup>
          </StyledTable>
        </Col>
      </Row>
    </StyledComponentWrapper>
  );
};

export default ReportAllocated;
