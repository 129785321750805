import React from "react";
import { Statistic, Card, Row, Col, Spin } from "antd";
import styled from 'styled-components'

const StyledStatistic = styled(Statistic)`
  height: 80px;


 & .ant-statistic-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
 }

 & .ant-statistic-content-suffix {
  display: flex;
  justifyContent: 'center';
  alignItems: 'center';
 }

 & .ant-spin {
  margin: 0 auto;
  margin-top: 10px;
 }

 & .ant-statistic-content-value {
  opacity: ${props => props.loading ? '0' : '1'}
 }
`

const StatisticsCards = ({ loading, completedCases, completedRenewals, completedRxChanges }) => {
  return (
    <div>
      <Row>
        <Col span={8}>
          <Card style={{ boxShadow: "4px 4px 12px #ccc", margin: "0 5px 0 0", background: '#fff' }}>
            <StyledStatistic
              title="Completed Cases"
              suffix={loading && <Spin/>}
              value={Number(completedCases) || '-'}
              valueStyle={{ color: "#1890ff"}}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ boxShadow: "4px 4px 12px #ccc", margin: "0 0 0 5px", background: '#fff' }}>
            <StyledStatistic
              title="Completed Rx Renewals"
              suffix={loading && <Spin/>}
              value={Number(completedRenewals) || '-'}
              valueStyle={{ color: "#1890ff" }}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ boxShadow: "4px 4px 12px #ccc", margin: "0 0 0 5px", background: '#fff' }}>
            <StyledStatistic
              title="Completed Rx Changes"
              suffix={loading && <Spin/>}
              value={Number(completedRxChanges) || '-'}
              valueStyle={{ color: "#1890ff" }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsCards;
