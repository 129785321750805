import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const createArticle = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.learningManagementSystemAppendPath}/create-article`,
    data
  );
};

export const getArticles = (params) => {
  return axiosInstance.get(
    `${apiAppendPath.learningManagementSystemAppendPath}/article-list`,
    { params: params }
  );
};

export const articleView = (id) => {
  return axiosInstance.get(
    `${apiAppendPath.learningManagementSystemAppendPath}/view-article/${id}`
  );
};

export const editArticle = (data, id) => {
  return axiosInstance.patch(
    `${apiAppendPath.learningManagementSystemAppendPath}/edit-article/${id}`,
    data
  );
};

export const deleteArticle = (params, id) => {
  return axiosInstance.delete(
    `${apiAppendPath.learningManagementSystemAppendPath}/delete-article/${id}`,
    { params: params }
  );
};

export const uploadMedia = (model) => {
  var bodyFormData = new FormData();
  for (var key in model) {
      bodyFormData.append(key, model[key]);
  }
  return axiosInstance.post(
    `${apiAppendPath.learningManagementSystemAppendPath}/upload-media`,
    bodyFormData
  );
};
