export const CaseFilterMap = {
  TELEMEDICINE_CATEGORY_ID: 'teleMedicineCategoryId',
  STATE_ID: 'stateId',
  CASE_ID: 'caseId',
  PATIENT_FIRST_NAME: 'patientFirstName',
  PATIENT_LAST_NAME: 'patientLastName',
  TYPE: 'type',
  CREATED_DATE: 'createdDate',
  UPDATED_DATE: 'updatedDate',
  STATUS: 'status',
  CASE_TAGS: 'caseTags',
  BOOKMARKED: 'bookmarked',
  PHYSICIAN_ID: 'physicianId',
}

export const CaseQueryParamMap = {
  TELEMEDICINE_CATEGORY_ID: 'telemedicine-category-id',
  STATE_ID: 'state-id',
  CASE_ID: 'case-id',
  PATIENT_FIRST_NAME: 'patient-first-name',
  PATIENT_LAST_NAME: 'patient-last-name',
  TYPE: 'type',
  CREATED_DATE: 'created-date',
  UPDATED_DATE: 'updated-date',
  STATUS: 'status',
  CASE_TAGS: 'case-tags',
  BOOKMARKED: 'bookmarked',
  PHYSICIAN_ID: 'physician-id',

  SEARCH_TERM: 'search-term',
  ADDITIONAL_STATUS: 'additional-status',
  CONSULTATION_START: 'consultation-start',
  LAST_UPDATED: 'last-updated',
}

export const CaseFilterToQueryParamMap = {
  [CaseFilterMap.TELEMEDICINE_CATEGORY_ID]: CaseQueryParamMap.TELEMEDICINE_CATEGORY_ID,
  [CaseFilterMap.STATE_ID]: CaseQueryParamMap.STATE_ID,
  [CaseFilterMap.CASE_ID]: CaseQueryParamMap.CASE_ID,
  [CaseFilterMap.PATIENT_FIRST_NAME]: CaseQueryParamMap.PATIENT_FIRST_NAME,
  [CaseFilterMap.PATIENT_LAST_NAME]: CaseQueryParamMap.PATIENT_LAST_NAME,
  [CaseFilterMap.TYPE]: CaseQueryParamMap.TYPE,
  [CaseFilterMap.CREATED_DATE]: CaseQueryParamMap.CREATED_DATE,
  [CaseFilterMap.UPDATED_DATE]: CaseQueryParamMap.UPDATED_DATE,
  [CaseFilterMap.STATUS]: CaseQueryParamMap.STATUS,
  [CaseFilterMap.CASE_TAGS]: CaseQueryParamMap.CASE_TAGS,
  [CaseFilterMap.BOOKMARKED]: CaseQueryParamMap.BOOKMARKED,
  [CaseFilterMap.PHYSICIAN_ID]: CaseQueryParamMap.PHYSICIAN_ID,
}
