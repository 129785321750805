import { useState }  from 'react';
import { bulkUpdateCasePharmacies } from "../../../api/StatesAPI";

export function useBulkCasePharmacyUpdate() {
  const [updatedCategories, setUpdatedCategories] = useState(new Set());

  const addCategory = (categoryId) => setUpdatedCategories(new Set(updatedCategories).add(categoryId));
  const removeCategory = (categoryId) => {
    updatedCategories.delete(categoryId);
    setUpdatedCategories(new Set(updatedCategories));
  }

  const _bulkUpdatedCasePharmacies = async (stateId) => {
    return bulkUpdateCasePharmacies(stateId, Array.from(updatedCategories.values()));
  }

  return { addCategory, removeCategory, hasCategoryUpdates: !!updatedCategories.size, bulkUpdateCasePharmacies: _bulkUpdatedCasePharmacies };
}