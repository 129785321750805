import React from "react";

const LogoIcon = ({ width, height }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 513.31 132.65"
        width={width}
        height={height}
      >
        <title>Asset 24</title>
        <g>
          <g>
            <path
              fill="#7bad7e"
              d="M124.38,114.88v17.77H122.1V114.88h-6.5v-2.2h15.31v2.2Z"
            />
            <path
              fill="#7bad7e"
              d="M147.91,114.88v6.56H158v2.2H147.91v6.84h11.64v2.17H145.63v-20h13.55v2.2Z"
            />
            <path fill="#7bad7e" d="M186.64,130.48v2.17h-11v-20h2.28v17.79Z" />
            <path
              fill="#7bad7e"
              d="M204,114.88v6.56H214.1v2.2H204v6.84h11.64v2.17H201.75v-20H215.3v2.2Z"
            />
            <path
              fill="#7bad7e"
              d="M246,132.65v-8.79H234v8.79h-2.28v-20H234v9h12v-9h2.28v20Z"
            />
            <path
              fill="#7bad7e"
              d="M268.05,114.88v6.56h10.07v2.2H268.05v6.84h11.64v2.17H265.77v-20h13.55v2.2Z"
            />
            <path
              fill="#7bad7e"
              d="M297.48,127.6l-2.22,5h-2.42l9-20h2.37l8.93,20H310.6l-2.22-5Zm5.42-12.32-4.45,10.13h9Z"
            />
            <path fill="#7bad7e" d="M338.66,130.48v2.17h-11v-20h2.28v17.79Z" />
            <path
              fill="#7bad7e"
              d="M359.85,114.88v17.77h-2.28V114.88h-6.5v-2.2h15.31v2.2Z"
            />
            <path
              fill="#7bad7e"
              d="M395.41,132.65v-8.79h-12v8.79H381.1v-20h2.28v9h12v-9h2.28v20Z"
            />
          </g>
          <polygon
            fill="#7bad7e"
            points="0 27.81 11.75 27.81 29 67.06 52.25 14.31 54 14.31 52.5 18.81 56.25 18.81 58.25 14.31 54.56 14.31 55.94 10.88 51.5 10.88 53.81 5.88 58.25 5.88 56.31 10.56 61.31 10.56 62.81 5.31 69.19 5.31 71.19 0 77.08 0 74.44 5.25 69.94 5.13 68 10.56 62.31 10.81 29.75 82.81 27.5 82.81 4.75 31.81 0 31.81 0 27.81"
          />
          <path
            fill="#171c2f"
            d="M228.6,37.52V82.67h-7.92V37.52H205.84V29.46h37.67v8.06Z"
          />
          <path
            fill="#171c2f"
            d="M264.43,70.66l-4.5,12h-8.26l20.75-53.21h8.12l20.54,53.21h-8.46l-4.5-12ZM276.25,39l-8.86,23.64h17.72Z"
          />
          <path
            fill="#171c2f"
            d="M346.23,34.25l-3.29,7.91c-5.1-3.5-10.21-4.94-13.76-4.94-4.63,0-7.65,2-7.65,5.55,0,11.63,25.38,5.4,25.31,24.55,0,9.5-7.39,15.36-17.72,15.36a27.15,27.15,0,0,1-19.2-8.44l3.42-7.75c4.83,5,10.88,7.75,15.91,7.75,5.51,0,8.79-2.36,8.79-6.46,0-11.86-25.38-5.25-25.38-24.17,0-9.12,6.92-14.82,17.12-14.82A28.09,28.09,0,0,1,346.23,34.25Z"
          />
          <g>
            <path
              fill="#171c2f"
              d="M77.49,37V51.51h24.8v8.15H77.49V74.52h28.57v8.16H68.94V28.82h36.25V37Z"
            />
            <rect
              fill="#171c2f"
              x="66.18"
              y="28.68"
              width="11.34"
              height="3.83"
            />
          </g>
          <g>
            <path
              fill="#171c2f"
              d="M142.48,66.56a19.63,19.63,0,0,1-2.15.08H129.19v16h-7.92V29.46h19.07c12,0,18.93,6.61,18.93,18.17,0,8.51-3.49,14.52-9.73,17.26l10.54,17.79h-9Zm-2.15-8c7.25,0,11.41-3.5,11.41-10.72,0-7-4.16-10.34-11.41-10.34H129.19V58.57Z"
            />
            <rect
              fill="#171c2f"
              x="118.94"
              y="29.65"
              width="11.34"
              height="3.83"
            />
          </g>
          <g>
            <path fill="#171c2f" d="M180.4,82.67V29.46h7.92V82.67Z" />
            <rect
              fill="#171c2f"
              x="176.19"
              y="29.54"
              width="11.34"
              height="3.83"
            />
          </g>
          <g>
            <path
              fill="#171c2f"
              d="M435.39,82.67l-.08-39.76L417.85,77.12h-6.08L394.3,42.91V82.67h-8.77V29.46h11.06l18.25,35.35L433,29.46h11V82.67Z"
            />
            <rect
              fill="#171c2f"
              x="381.69"
              y="29.51"
              width="11.34"
              height="3.83"
            />
          </g>
          <g>
            <path
              fill="#171c2f"
              d="M485.34,29.46c16.22,0,28,11.17,28,26.61s-11.82,26.61-28.2,26.61H463.17V29.46ZM472.28,74.61h13.29c10.51,0,18.54-7.68,18.54-18.47s-8.27-18.62-18.93-18.62h-12.9Z"
            />
            <rect
              fill="#171c2f"
              x="460.46"
              y="29.51"
              width="11.34"
              height="3.83"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default LogoIcon;
