import { useState, useEffect } from 'react';

const getDefaultState = (key, parser, initialState) => {
  return window.localStorage.getItem(key)
  ? parser(window.localStorage.getItem(key))
  : initialState
}

const useLocalStorage = (key, initialState, parser = (v) => v, serializer = JSON.stringify) => {
  const [state, setState] = useState(() => getDefaultState(key, parser, initialState))

  const removeItem = () => {
    setState(null);
    window.localStorage.removeItem(key);
  }

  useEffect(() => {
    window.localStorage.setItem(key, serializer(state));
  }, [key, state, serializer])

  return [state, setState, removeItem];
}

export default useLocalStorage;
