import React from 'react';
import { Timeline } from 'antd';

const hasAtLeastEightAndLessThanSixtyFourCharacters = (password) => password.length >= 8 && password.length <= 64;
const hasAtLeastOneLowerAndOneUpperCaseLetter = (password) => /[a-z]/.test(password) && /[A-Z]/.test(password);
const hasAtLeastOneNumber = password => /\d/.test(password);
const hasAtLeastOneSpecialCharacter = password => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
const hasLessThanFourRepeatingCharacters = password => !/(.)\1{3,}/.test(password);
const hasLessThanFourSequentialLetters = password => !/(abcd)|(bcde)|(cdef)|(defg)|(efgh)|(fghi)|(ghij)|(hijk)|(ijkl)|(jklm)|(klmn)|(lmno)|(mnop)|(nopq)|(opqr)|(pqrs)|(qrst)|(rstu)|(stuv)|(tuvw)|(uvwx)|(vwxy)|(wxyz)/i.test(password);
const hasLessThanFourSequentialNumbers = password => !/(1234)|(2345)|(3456)|(4567)|(5678)|(6789)/.test(password);
const hasLessThanFourSequentialCharacters = password => hasLessThanFourSequentialLetters(password) && hasLessThanFourSequentialNumbers(password);

export const isValidPassword = (password) => {
	return hasAtLeastEightAndLessThanSixtyFourCharacters(password) &&
		hasAtLeastOneLowerAndOneUpperCaseLetter(password) &&
		hasAtLeastOneNumber(password) &&
		hasAtLeastOneSpecialCharacter(password) &&
		hasLessThanFourRepeatingCharacters(password) &&
		hasLessThanFourSequentialLetters(password) &&
		hasLessThanFourSequentialNumbers(password)
}

export const PasswordChecker = ({ password }) => {
	return <Timeline>
		<Timeline.Item color={ hasAtLeastEightAndLessThanSixtyFourCharacters(password) ? 'green' : 'red'}>
			<p>has at least 8 - 64 characters</p>
		</Timeline.Item>
		<Timeline.Item color={ hasAtLeastOneLowerAndOneUpperCaseLetter(password) ? 'green' : 'red'}>
			<p>has at least 1 lower case and 1 upper case letter</p>
		</Timeline.Item>
		<Timeline.Item color={ hasAtLeastOneNumber(password) ? 'green' : 'red'}>
			<p>has at least 1 number</p>
		</Timeline.Item>
		<Timeline.Item color={ hasAtLeastOneSpecialCharacter(password) ? 'green' : 'red'}>
			<p>has at least 1 special character {`(!@#$%^&*()_+-=[]{};':",.<>/? )`}</p>
		</Timeline.Item>
		<Timeline.Item color={ hasLessThanFourRepeatingCharacters(password) ? 'green' : 'red'}>
			<p>no more than 3 repeating characters (aaaa / 1111)</p>
		</Timeline.Item>
		<Timeline.Item color={ hasLessThanFourSequentialCharacters(password) ? 'green' : 'red'}>
			<p>no more than 3 sequential characters (abcd/1234)</p>
		</Timeline.Item>
	</Timeline>
}