import React, { useEffect, useState } from "react";

import { getTicketChat, sendChatMessage } from "../../api/TicketChatAPI";
import { getCaseTicketClearNotification, updateTicket } from "../../api/CaseTicketsAPI";
import { getUsersListByStatus } from "../../api/UserTypeAPI";

// import components
import AdminPhysicianChatListingComponent
  from "../../components/AdminPhysicianChatListingComponent/AdminPhysicianChatListingComponent";
import MessageHeaderComponent from "../../components/CaseMessageComponent/MessageHeaderComponent";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CasePhysicianChat = ({ caseDetails, userDetails, getCaseTicketNotificationCount }) => {
  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageList, setMessageList] = useState({data: Array(0), totalCount: 0});
  const [chatRoomId, setChatRoomId] = useState();
  const userEmail = window.localStorage.getItem("userEmail");
  const { physicianName, physicianId } = caseDetails;
  const [assignedRep, setAssignedRep] = useState({})
  const [caseId, setCaseId] = useState();
  const [patient, setPatient] = useState();
  const [ticketId, setTicketId] = useState();
  const [admins, setAdmins] = useState([]);

  const fetchAdmins = async () => {
    try {
      const { data } = await getUsersListByStatus('active')
      if (!data.payload?.length) {
          message.warn("No active admin users found");
          return;
      }
      setAdmins(data.payload);
    } catch (error) {
      console.log("Error fetching admin users", error);
      message.error("Unable to fetch admin users");
    }
};

  const fetchMessageList = () => {
    setMessageListLoading(true);
    getTicketChat({ caseId: caseDetails.caseId })
      .then((res) => {
        const { data } = res;
        if (data.success && data.payload) {
          const { payload } = data;
          const messageListData = payload;
          payload.data = payload.data.reverse();
          setMessageList(messageListData);

          if (payload.data.length) {
            setAssignedRep(payload.data[0].ticket.rep)
            setTicketId(payload.data[0].ticketId)
          }
        }
      })
      .catch((error) => {
        console.log('======= Chat Room Error :: Fetch messages =======', error);
      })
      .finally(() => {
        setMessageListLoading(false);
      });
  };

  const getCaseTicketClearNotifications = (roomId) => {
    const params = { ticketId: roomId };
    getCaseTicketClearNotification(params)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          console.log("Notification Count cleared on viewing message");
          getCaseTicketNotificationCount();
        } else {
          console.log("Unable to clear Notification Count");
        }
      })
      .catch((error) => {
        console.log("Unable to clear Notification Count");
      });
  };

  const clearTicketNotifications = () => {
    getCaseTicketClearNotifications(chatRoomId);
  };

  // handling message sending
  const handleMessageSending = (data) => {

    const messageData = {
      ...data,
      caseId: data.chatRoomId,
      ticketId: data.chatRoomId,
      message: data.message,
    };

    sendChatMessage(messageData)
      .then((res) => {
        if (res.data.success) {
          getCaseTicketNotificationCount();
        }
      })
      .then(() => fetchMessageList())
      .catch((error) => {
        const { response } = error;
        if (response) {
          if (response && response.data.message) {
            message.error(response.data.message);
          } else {
            message.error("unable to send Message");
          }
        } else {
          message.error("Unable to send message");
        }
        console.log(
          "=================== Chat Room Error :: Add messages =====================",
          error
        );
      });
  };

  useEffect(() => {
    setChatRoomId(messageList.data.at(0)?.ticketId || caseDetails.caseId)
  }, [messageList]);

  useEffect(() => {
    if (caseDetails) {
      fetchMessageList();
      fetchAdmins();
      setPatient(caseDetails.patient);
      setCaseId(caseDetails.caseId);
    }// eslint-disable-next-line
  }, [caseDetails]);

  const physician = {
    id: physicianId,
    name: physicianName,
  }

  const patientName = patient ? ((patient.firstName || "") + " " + (patient.lastName || "")).trim() : "";

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageListLoading}
        indicator={<LoadingOutlined spin />}
      >
        <MessageHeaderComponent
          avatarUrl={caseDetails.photo}
          physician={physician}
          patient={patientName}
          userDetails={userDetails}
          assignedRep={assignedRep}
          setAssignedRep={setAssignedRep}
          caseId={caseId}
          ticketId={ticketId}
          admins={admins}
        />
        <AdminPhysicianChatListingComponent
          roomId={chatRoomId}
          authorId={userEmail}
          messages={messageList}
          allowImageUpload
          sendMessage={handleMessageSending}
          clearTicketNotifications={clearTicketNotifications}
          updateTicket={updateTicket}
        />
      </Spin>
    </>
  );
};

export default CasePhysicianChat;
