import React from "react";
import { Typography, Modal, Button, Row, Col, Upload } from "antd";
import { useState } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { FilePdfOutlined, PlusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { StyledTitle } from "../../components/LayoutComponent";
import { getSignedUploadUrl, addAdditionalDocument } from '../../api/CasesAPI';
import axios from 'axios';

//import common functions
import isValidUrl from "../../utils/isValidURL";
const { Text } = Typography;

const StyledDocumentModalImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledImage = styled.img`
  width: 188px;
  height: 188px;
  cursor: pointer;
`;

const StyledImageCol = styled(Col)`
  margin: 0 16px 16px 0;
`;

const StyledNotAvailableText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
`;

const StyledFilePdfIcon = styled(FilePdfOutlined)`
  font-size: 188px;
  color: #7bad7b;
`;

const StyledDocumentsContainer = styled.div`
    width: 100%;
    margin-bottom: 30px;
`

const StyledAdditionalDocumentsFlexbox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const StyledAdditionalDocumentImage = styled.img`
    padding: 5px;
    width: 188px;
    height: 188px;
    cursor: pointer;
`;

const StyledAdditionalDocumentButton = styled(Button)`
    padding: 5px;
    margin: 0px 5px;
    width: 188px;
    height: 188px;
    cursor: pointer;
`;

const DocumentView = ({ caseDetails, fetchCaseDetails }) => {
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [uploadUrl, setUploadUrl] = useState(null);

  const modalOpen = (photo) => {
    new Promise(function (resolve, reject) {
      setTimeout(() => resolve(1), 1000); // (*)
    })
      .then(function () {
        setImageUrl(photo);
      })
      .then(function () {
        setVisible(true);
      });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const openPdf = () => {
    isValidUrl(caseDetails.labReport) &&
      window.open(caseDetails.labReport, "_blank");
  };

  const listAdditionalDocuments = (documents={}) => {
    let results = [];
    for (const [key, value] of Object.entries(documents)) {
        if (isValidUrl(value)) {
          const fileName = new URL(value).pathname.split('/').pop();

          if (fileName.toLowerCase().endsWith('.pdf')) {
            results.push(
              <StyledAdditionalDocumentButton
                type="ghost"
                title={fileName}
                icon={<FilePdfOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />}
                onClick={() => window.open(value, "_blank")}
              />
            )
          }
          else {
            results.push(
              <StyledAdditionalDocumentImage
                key={key}
                src={value}
                alt={fileName}
                title={fileName}
                onClick={() => modalOpen(value)}
              />
            );
          }
        }
    }

    results.push(
      <Upload
        listType="picture"
        customRequest={async ({ file, onSuccess, onError, onProgress }) => {

          const getArrayBufferFromFile = async () => new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsArrayBuffer(file)
          })

          const data = await getArrayBufferFromFile();

          return axios.put(uploadUrl, data, {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              onProgress({ percent: Math.floor((loaded * 100) / total) })
            } 
          }).then((res) => onSuccess(res.data))
            .catch((err) => onError(err, err.response?.body))
        }}
        action={uploadUrl}
        onChange={(info) => {
          if (info.file.status === 'done') {
            const url = new URL(uploadUrl);
              addAdditionalDocument(caseDetails.caseId, `${url.origin}${url.pathname}`)
                .then(fetchCaseDetails)
          }
        }}
        beforeUpload={(file) => getSignedUploadUrl(caseDetails.caseId, file.name)
          .then(res => setUploadUrl(res.data.signedUploadUrl))
        }
      >
        <StyledAdditionalDocumentButton
          type="default"
          className="additional-documents-item"
          title="Upload additional document"
          icon={<PlusCircleOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />}
        />
      </Upload>
    );

    return results;
}

const documents = listAdditionalDocuments(caseDetails.documents);

  return (
    <div className="document-view-body">
      <div className="document-view-parts">
        <StyledTitle level={4}>Patient Photo & Id</StyledTitle>
        <Row>
          <StyledImageCol>
            {isValidUrl(caseDetails.patient.photo) === true ? (
              <StyledImage
                src={caseDetails.patient.photo}
                alt="patient detail"
                onClick={() => modalOpen(caseDetails.patient.photo)}
              />
            ) : (
              <StyledNotAvailableText strong>
                No photo Available
              </StyledNotAvailableText>
            )}
          </StyledImageCol>
          <StyledImageCol>
            {isValidUrl(caseDetails.patient.idProof) === true ? (
              <Col>
                <StyledImage
                  src={caseDetails.patient.idProof}
                  alt="patient detail"
                  onClick={() => modalOpen(caseDetails.patient.idProof)}
                />
              </Col>
            ) : (
              <StyledNotAvailableText strong>
                No Id Proof Available
              </StyledNotAvailableText>
            )}
          </StyledImageCol>
        </Row>

        <Modal
          visible={visible}
          closable={false}
          onCancel={handleCancel}
          width="70vw"
          centered
          bodyStyle={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          footer={[
            <Button key="back" type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <StyledDocumentModalImage>
            <ReactPanZoom alt="sample image" image={imageUrl} />
          </StyledDocumentModalImage>
        </Modal>
      </div>
      <hr></hr>
      <StyledDocumentsContainer>
        <StyledTitle level={4}>Patient Lab Report</StyledTitle>

        {isValidUrl(caseDetails.labReport) ? (
          <StyledFilePdfIcon onClick={() => openPdf()} />
        ) : (
          <StyledNotAvailableText strong>
            No Lab report Available
          </StyledNotAvailableText>
        )}
      </StyledDocumentsContainer>
      <StyledDocumentsContainer>
            <StyledTitle level={4}>Additional Documents</StyledTitle>
            {documents && documents.length !== 0 ? (
                <StyledAdditionalDocumentsFlexbox className="additional-documents-container">
                    {documents}
                </StyledAdditionalDocumentsFlexbox>
            ) : (
                <StyledNotAvailableText strong>
                    No Additional Documents Available
                </StyledNotAvailableText>
            )}
        </StyledDocumentsContainer>
    </div>
  );
};

export default DocumentView;
