import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getConsultCompleteReport = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.reportAppendPath}/consult-time`, {
    params,
    ...config,
  });
};

export const getConsultCompleteNoRXReport = (params, config = {}) => {
  return axiosInstance.get(
    `${apiAppendPath.reportAppendPath}/consult-time-no-rx`,
    { params, ...config }
  );
};

export const getClientCaseCount = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.reportAppendPath}/clients-case-count`,
    data
  );
};

export const getPhysiciansCaseCount = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.reportAppendPath}/physicians-case-count`,
    data
  );
};

export const getPhysicianPayments = (body, config = {}) => {
  return axiosInstance.post(
    `${apiAppendPath.reportAppendPath}/physicians-reconciliation`,
    body,
    config,
  );
};

export const getReport = (params) => {
  return axiosInstance.get(`${apiAppendPath.reportAppendPath}/report`, {
    params,
  });
};

export const getIncompleteCases = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.physicianApiAppendPath}/cases/`, {
    params,
    ...config,
  });
};

export const getPhysicianReportingDashboardCaseReport = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/case`, {
    params,
    ...config,
  });
}

export const getPhysicianReportingDashboardConsultReport = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/consult-type`, {
    params,
    ...config,
  });
}

export const getPhysicianCaseCompletionCounts = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/physician-case-completion-counts?`, {
    params,
    ...config,
  });
}

export const getPhysicianCaseCompletionCountsV2 = params => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/physician-case-completion-counts-v2`, {
    params
  });
}

export const getAllPhysiciansCaseCompletionCounts = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/all-physicians-case-completion-counts`, {
    params,
    ...config,
  });
}

export const getPhysicianAverageCaseHandleTimes = params => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/physician-case-average-handle-time`, {
    params
  });
}

export const getAllPhysiciansAverageCaseHandleTimes = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/all-physicians-case-average-handle-time`, {
    params,
    ...config,
  });
}

export const getClientCaseCompletionCounts = params => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/client-case-completion-counts`, {
    params
  });
}

export const getClientCaseCompletionCountsV2 = params => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/client-case-completion-counts-v2`, {
    params
  });
}

export const getAllClientsCaseCompletionCounts = (params, config = {}) => {
  return axiosInstance.get(`${apiAppendPath.v2ReportAppendPath}/all-clients-case-completion-counts`, {
    params,
    ...config,
  });
}
