import React, { useState, useEffect } from 'react';
import { Layout, Card, Form, Spin, Button } from 'antd';
import moment from 'moment-timezone';
import { LoadingOutlined } from '@ant-design/icons';
import {
  getConsultCompleteReport,
  getConsultCompleteNoRXReport,
  getAllPhysiciansCaseCompletionCounts,
  getAllClientsCaseCompletionCounts,
  getPhysicianPayments,
  getReport,
  getIncompleteCases,
} from '../../api/ReportApi';
import { useCustomReportContext } from '../../context/CustomReportSocketContext';
import { CustomReportType, REPORT_TYPE } from '../../constants/enums';
import ReportMenu from './ReportMenu';
import Report from './Report';

const { Content } = Layout;

const ReportType = {
  ...CustomReportType,
  PHYSICIAN_REPORTING_DASHBOARD: 'physician-reporting-dashboard',
  PHYSICIANS_QUEUE_PERFORMANCE: 'physicians-queue-performance',
}

const getCurrentReportFetcher = (selectedReportType) => {
  switch (selectedReportType) {
    case CustomReportType.CUSTOM_CONSULT_TIME:
      return getConsultCompleteReport
    case CustomReportType.CUSTOM_CONSULT_TIME_NO_RX:
      return getConsultCompleteNoRXReport
    case CustomReportType.CUSTOM_CLIENTS_CASE_COUNT:
      return getAllClientsCaseCompletionCounts
    case CustomReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
      return getAllPhysiciansCaseCompletionCounts
    case CustomReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
      return getPhysicianPayments
    case CustomReportType.CUSTOM_INCOMPLETE_CASES:
      return getIncompleteCases
    default:
      return
  }
}

const Reports = ({ userType }) => {
  const [form] = Form.useForm();

  const customReportContext = useCustomReportContext()

  const consultTimeContext = customReportContext[CustomReportType.CUSTOM_CONSULT_TIME]
  const consultTimeNoRXContext = customReportContext[CustomReportType.CUSTOM_CONSULT_TIME_NO_RX]
  const clientsCaseCountContext = customReportContext[CustomReportType.CUSTOM_CLIENTS_CASE_COUNT]
  const physiciansCaseCountContext = customReportContext[CustomReportType.CUSTOM_PHYSICIANS_CASE_COUNT]
  const physiciansReconciliationContext = customReportContext[CustomReportType.CUSTOM_PHYSICIANS_RECONCILIATION]
  const incompleteCasesContext = customReportContext[CustomReportType.CUSTOM_INCOMPLETE_CASES]

  const [selectedReport, setSelectedReport] = useState(ReportType.CUSTOM_CONSULT_TIME)
  const [reportPeriod, setReportPeriod] = useState('month')
  const [reportYear, setReportYear] = useState(moment().get('year'))
  const [reportPeriodNumber, setReportPeriodNumber] = useState(moment().subtract(1, 'month').get('month'))
  const [report, setReport] = useState()
  const [totalCount, setTotalCount] = useState()

  const [filterParams, setFilterParams] = useState({
    index: 1,
    count: 10,
    filterType: "month",
    filter: moment().subtract(1, 'month').format("MM-DD-YYYY")
  });

  useEffect(() => {
    setReport([])
    setTotalCount(0)
    if (![ReportType.PHYSICIAN_REPORTING_DASHBOARD, ReportType.PHYSICIANS_QUEUE_PERFORMANCE].includes(selectedReport)) {
      const currentYear = moment().get('year')
      if (reportPeriod !== 'month' || reportYear !== currentYear) {
        setReportPeriod('month')
        setReportYear(currentYear)
      }
    }
  }, [selectedReport, reportPeriod, reportYear])

  useEffect(() => {
    setReportPeriodNumber(moment().subtract(1, reportPeriod).get(reportPeriod))
  }, [reportPeriod])

  const getCurrentSetIsLoading = (selectedReportType) => (v) => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        consultTimeContext.setLoading(v)
        break
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        consultTimeNoRXContext.setLoading(v)
        break
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        clientsCaseCountContext.setLoading(v)
        break
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        physiciansCaseCountContext.setLoading(v)
        break
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        physiciansReconciliationContext.setLoading(v)
        break
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        incompleteCasesContext.setLoading(v)
        break
      default: (() => {})()
    }
  }

  const getCurrentRequestId = (selectedReportType) => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        return consultTimeContext.requestId
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        return consultTimeNoRXContext.requestId
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        return clientsCaseCountContext.requestId
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        return physiciansCaseCountContext.requestId
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        return physiciansReconciliationContext.requestId
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        return incompleteCasesContext.requestId
      default:
        return
    }
  }

  const currentRequestId = getCurrentRequestId(selectedReport)

  const fetchReport = (params) => {
    let currentFetcher = getCurrentReportFetcher(selectedReport)
    let currentParams = {
      ...params,
      reportType: REPORT_TYPE.CUSTOM,
    }
    if (selectedReport.includes('generated')) {
      currentFetcher = getReport
      currentParams = {
        selectedReport,
        reportPeriod,
        reportYear,
        reportPeriodNumber,
        reportType: REPORT_TYPE.GENERATED,
      }
    }
    getCurrentSetIsLoading(selectedReport)(true)
    currentFetcher({
      ...currentParams,
      requestId: currentRequestId.current,
    }, {
      timeout: 10_000, //abort request. response get by Sockets
    });
  }

  const handleFetchReport = params => {
    setFilterParams(params)
    fetchReport(params)
  }

  const getCurrentReport = (selectedReportType) => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        return consultTimeContext.report
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        return consultTimeNoRXContext.report
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        return clientsCaseCountContext.report
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        return physiciansCaseCountContext.report
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        return physiciansReconciliationContext.report
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        return incompleteCasesContext.report
      default:
        return report
    }
  }

  const getCurrentTotalCount = (selectedReportType) => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        return consultTimeContext.totalCount
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        return consultTimeNoRXContext.totalCount
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        return clientsCaseCountContext.totalCount
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        return physiciansCaseCountContext.totalCount
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        return physiciansReconciliationContext.totalCount
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        return incompleteCasesContext.totalCount
      default:
        return totalCount
    }
  }

  const getCurrentIsLoading = (selectedReportType) => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        return consultTimeContext.isLoading
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        return consultTimeNoRXContext.isLoading
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        return clientsCaseCountContext.isLoading
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        return physiciansCaseCountContext.isLoading
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        return physiciansReconciliationContext.isLoading
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        return incompleteCasesContext.isLoading
      default:
        return false
    }
  }

  const clearFilter = () => {
    setReport([])
    setTotalCount(0)
  }

  const getCurrentClearFilter = (selectedReportType) => () => {
    switch (selectedReportType) {
      case ReportType.CUSTOM_CONSULT_TIME:
        consultTimeContext.clearFilter()
        break
      case ReportType.CUSTOM_CONSULT_TIME_NO_RX:
        consultTimeNoRXContext.clearFilter()
        break
      case ReportType.CUSTOM_CLIENTS_CASE_COUNT:
        clientsCaseCountContext.clearFilter()
        break
      case ReportType.CUSTOM_PHYSICIANS_CASE_COUNT:
        physiciansCaseCountContext.clearFilter()
        break
      case ReportType.CUSTOM_PHYSICIANS_RECONCILIATION:
        physiciansReconciliationContext.clearFilter()
        break
      case ReportType.CUSTOM_INCOMPLETE_CASES:
        incompleteCasesContext.clearFilter()
        break
      default:
        clearFilter()
    }
  }

  const cancelLoading = () => {
    getCurrentSetIsLoading(selectedReport)(false)
    currentRequestId.current += 1
  }

  const currentLoading = getCurrentIsLoading(selectedReport)

  return (
    <Content>
      <Card>
        <ReportMenu
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          userType={userType}
          form={form}
        />
        <Spin
          size="large"
          spinning={currentLoading}
          tip={<span>Continue your Work.<br/> You will be notified when Report is ready</span>}
          indicator={
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <LoadingOutlined />
              <Button style={{ marginTop: 60 }} onClick={cancelLoading} children="Cancel Loading" />
            </div>
          }
        >
          <Report
            selectedReport={selectedReport}
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
            reportYear={reportYear}
            setReportYear={setReportYear}
            reportPeriodNumber={reportPeriodNumber}
            setReportPeriodNumber={setReportPeriodNumber}
            report={getCurrentReport(selectedReport)}
            totalCount={getCurrentTotalCount(selectedReport)}
            filterParams={filterParams}
            clearFilter={getCurrentClearFilter(selectedReport)}
            setFilterParams={setFilterParams}
            handleFetchReport={handleFetchReport}
            loading={currentLoading}
          />
        </Spin>
      </Card>
    </Content>
  );
};

export default Reports;
