/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState } from "react";
import { Form, Row, Col, Select, Popover, Badge } from "antd";
import { FilterOutlined } from "@ant-design/icons";

//import components
import {
  StyledButton,
  StyledButtonWrap,
  StyledFilterButton,
  StyledForm,
} from "../../components/PhysicianComponent/PhysicianFilter";

const PhysicianFilter = ({
  clients,
  states,
  telemedicineCategory,
  tags,
  onFilter,
}) => {
  const [filterNotification, setFilterNotification] = useState(false);
  const [selectedClients, setSelectedClients] = useState("");
  const [selectedStates, setSelectedStates] = useState("");
  const [
    selectedTelemedicineCategory,
    setSelectedTelemedicineCategory,
  ] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);

  const handleClientFilterChange = (selectedItems) => {
    setSelectedClients(selectedItems);
  };

  const handleTelemedicineFilterChange = (selectedItems) => {
    setSelectedTelemedicineCategory(selectedItems);
  };

  const handleStateFilterChange = (selectedItems) => {
    setSelectedStates(selectedItems);
  };

  const handleStatusFilterChange = (selectedItems) => {
    setSelectedStatus(selectedItems);
  };

  const handleFilterChange = () => {
    const filterOptions = {
      fetchType: selectedStatus,
      client: selectedClients,
      telemedicineCategory: selectedTelemedicineCategory,
      state: selectedStates,
      tags: selectedTags
    };
    setFilterNotification(true);
    onFilter(filterOptions);
  };

  const handleFilterClear = () => {
    const filterOptions = {
      fetchType: "all",
      client: "",
      telemedicineCategory: "",
      state: "",
      tags: []
    };
    setSelectedClients("");
    setSelectedStates("");
    setSelectedTelemedicineCategory("");
    setSelectedStatus("all");
    setSelectedTags([]);
    setFilterNotification(false);
    onFilter(filterOptions);
  };

  return (
    <Popover
      placement="bottomRight"
      autoAdjustOverflow={false}
      title="Filter By"
      content={
        <StyledForm name="physician_details" layout={"vertical"}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Clients">
                <Select
                  size={"large"}
                  value={selectedClients}
                  placeholder="Filter by Client"
                  onChange={handleClientFilterChange}
                >
                  {clients.map((eachClient) => (
                    <Select.Option key={eachClient.key} value={eachClient.key}>
                      {eachClient.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Telemedicine Category">
                <Select
                  size={"large"}
                  value={selectedTelemedicineCategory}
                  placeholder="Filter by category"
                  onChange={handleTelemedicineFilterChange}
                >
                  {telemedicineCategory.map((eachCategory) => (
                    <Select.Option
                      key={eachCategory.key}
                      value={eachCategory.key}
                    >
                      {eachCategory.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="States">
                <Select
                  size={"large"}
                  value={selectedStates}
                  placeholder="Filter by State"
                  onChange={handleStateFilterChange}
                >
                  {states.map((eachState) => (
                    <Select.Option key={eachState.key} value={eachState.key}>
                      {eachState.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Status">
                <Select
                  size={"large"}
                  value={selectedStatus}
                  placeholder="Filter by Status"
                  onChange={handleStatusFilterChange}
                >
                  <Select.Option key={"all"} value={"all"}>
                    All
                  </Select.Option>
                  <Select.Option key={"deactive"} value={"deactive"}>
                    DEACTIVE
                  </Select.Option>
                  <Select.Option key={"rejected"} value={"rejected"}>
                    REJECTED
                  </Select.Option>
                  <Select.Option key={"approved"} value={"active"}>
                    ACTIVE
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='tags' label='Tags'>
                <Select
                  mode='multiple'
                  size={"large"}
                  value={selectedTags}
                  placeholder="Filter by Tags"
                  onChange={setSelectedTags}
                >
                  {tags.map(tag => <Select.Option key={tag} value={tag}>{tag}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <StyledButtonWrap span={24}>
              <Form.Item>
                <StyledButton
                  type="secondary"
                  size={"large"}
                  onClick={handleFilterClear}
                >
                  Clear
                </StyledButton>
                <StyledButton
                  type="primary"
                  size={"large"}
                  onClick={handleFilterChange}
                >
                  Filter
                </StyledButton>
              </Form.Item>
            </StyledButtonWrap>
          </Row>
        </StyledForm>
      }
      trigger="click"
    >
      <Badge dot={filterNotification}>
        <StyledFilterButton
          type="primary"
          size={"large"}
          icon={<FilterOutlined />}
        />
      </Badge>
    </Popover>
  );
};

export default PhysicianFilter;
