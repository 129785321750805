/* eslint-disable no-template-curly-in-string */
import React from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Select,
  InputNumber,
} from "antd";

const { Option } = Select;
const { TextArea } = Input;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 76%;
`;

const AddMedicationsAndDirections = (props) => {
  const [form] = Form.useForm();
  const { addMedicationsAndDirections, telemedicineCategory } = props;
  const onFinish = (values) => {
    const data = {...values}
    if(data.dosage) {
      data.name = `${data.medication} - ${data.dosage}mg`
    } else {
      data.name = data.medication
    }
    addMedicationsAndDirections(data);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Form
      {...layout}
      form={form}
      layout={"vertical"}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Title level={2}>Add Medications And Directions</Title>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form.Item
            name="medication"
            label="Medication"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size={"large"} placeholder="Enter the name of medicine" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Dosage">
            <Form.Item name="dosage" noStyle>
              <StyledInputNumber size="large" min={1} max={9000}  placeholder="Enter dosage"/>
            </Form.Item>
            <span className="ant-form-text"> mg</span>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="teleMedicineCategories"
            label="Telemedicine Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select size={"large"} mode="multiple" placeholder="Select Telemedicine Category">
              {telemedicineCategory.map((eachTelemedicineCategory) => (
                <Option
                  key={eachTelemedicineCategory._id}
                  value={eachTelemedicineCategory._id}
                >
                  {eachTelemedicineCategory.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="directions"
            label="Usage Direction"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea size={"large"} placeholder="Enter Usage Directions" />
          </Form.Item>
        </Col>

        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton type="primary" size={"large"} htmlType="submit">
              Add
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default AddMedicationsAndDirections;
