//import packages
import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";

// import components
import GlobalConfiguration from "./GlobalConfiguration";
import StateConfiguration from "./StateConfiguration";
import CaseQueueConfiguration from "../CaseQueueConfigurations/GlobalConfiguration"

//import other files and API functions

const { TabPane } = Tabs;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const GlobalLoadBalancerConfiguration = () => {
  return (
    <>
      <StyledTabWrapper>
        <StyledTabs type="card">
          <TabPane tab="Global Configuration" key="global-configuration">
            <GlobalConfiguration />
          </TabPane>
          <TabPane tab="State Configuration" key="state-configuration">
            <StateConfiguration />
          </TabPane>
           <TabPane tab="Case Queue Configuration" key="case-queue-configuration">
            <CaseQueueConfiguration />
          </TabPane>
        </StyledTabs>
      </StyledTabWrapper>
    </>
  );
};

export default GlobalLoadBalancerConfiguration;
