import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Form,
  InputNumber,
  Table,
  Button,
  message
} from "antd";

import { getPhysicians, updateBulkExternalPhysiciansConsultRates } from '../../../api/PhysiciansAPI';
import { lookUp } from '../../../api/LookUpAPI';

const { Content } = Layout;
const { useForm } = Form;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const FlexedButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const ConsultRates = () => {
  const [form] = useForm();
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [consultRatesSource, categoriesIdLookup] = useMemo(() => {
    const categoriesIdLookup = categories.reduce((lookup, category) => {
      if (!(category._id in lookup)) {
        lookup[category._id] = category;
      }

      return lookup;
    }, {})

    const consultRatesSource = [];
    for (let client of clients) {
      for (let categoryId of client.teleMedicineCategories) {
        const category = categoriesIdLookup[categoryId];
        if (category) {
          consultRatesSource.push({ client: client.name, category: category.name, key: category._id });
          category.clientId = client._id;
        }
      }
    }

    return [consultRatesSource, categoriesIdLookup];
  }, [clients, categories]);

  const handleApply = (values) => {
    const { consultRates: consultRatesObj, physicians } = values;
    const consultRates = selectedCategories.map((categoryId) => ({
      teleMedicineCategoryId: categoryId,
      clientId: categoriesIdLookup[categoryId].clientId,
      async: consultRatesObj[categoryId].async,
      sync: consultRatesObj[categoryId].sync,
    }))
    
    updateBulkExternalPhysiciansConsultRates(physicians, consultRates)
      .then(res => message.success(res.data.message))
      .catch(() => message.error('Unable to update the consult rates'));
  }


  useEffect(() => {
    lookUp('client')
      .then(res => setClients(res.data.payload))
      .then(() => lookUp('tele-medicine-category'))
      .then(res => setCategories(res.data.payload));

    lookUp('physician-group')
      .then(res => res.data.payload.find(group => group.name === 'external physicians'))
      .then((group) => getPhysicians({ physicianGroup: group._id }))
      .then(res => setPhysicians(res.data.payload));
  }, [])


  return (
    <Content>
      <StyledComponentWrapper>
        <Form 
          form={form}
          onFinish={handleApply}
          scrollToFirstError
        >
          <h2>Categories</h2>

          <Form.Item 
            name='consultRates' 
            initialValue={{}}
            rules={[
              { 
                validator: () => selectedCategories.length 
                  ? Promise.resolve() 
                  : Promise.reject(new Error('Must select at least 1 category')) 
              }
            ]}
          >
            <Table
              bordered
              pagination={false}
              columns={[
                {
                  key: 'client',
                  dataIndex: 'client',
                  title: 'Client'
                },
                {
                  key: 'category',
                  dataIndex: 'category',
                  title: 'Category'
                },
                {
                  key: 'async',
                  dataIndex: 'async',
                  title: 'Async',
                  render: (_, record) => {
                    const selectedCategoriesLookup = new Set(selectedCategories);
                    const isSelected = selectedCategoriesLookup.has(record.key);

                    return <Form.Item
                      name={['consultRates', record.key, 'async']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (isSelected && !value) return Promise.reject(new Error('Invalid consult rate for 1 or more of the selected categories'));
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InputNumber formatter={(rate) => `$${rate}`} disabled={!isSelected} />
                    </Form.Item>
                  }
                },
                {
                  key: 'sync',
                  dataIndex: 'sync',
                  title: 'Sync',
                  render: (_, record) => {
                    const selectedCategoriesLookup = new Set(selectedCategories);
                    const isSelected = selectedCategoriesLookup.has(record.key);
                    return <Form.Item
                      name={['consultRates', record.key, 'sync']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (isSelected && !value) return Promise.reject(new Error('Invalid consult rate for 1 or more of the selected categories'));
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InputNumber formatter={(rate) => `$${rate}`} disabled={!isSelected} />
                    </Form.Item>
                  }
                },
              ]}
              dataSource={consultRatesSource}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedCategories,
                onChange: setSelectedCategories
              }}
            />

          </Form.Item>
          <Form.Item 
            name='physicians' 
            initialValue={[]}
            rules={[
              {
                validator: () => selectedPhysicians.length 
                  ? Promise.resolve() 
                  : Promise.reject(new Error('Must select at least 1 physician')) 
              }
            ]}>
          <h2>Physicians</h2>
          <Table
            columns={[
              {
                key: 'credential',
                dataIndex: 'credential',
                title: 'Credential',
                sorter: (physician1, physician2) => {
                  if (physician1.credential < physician2.credential) return 1;
                  if (physician1.credential > physician2.credential) return -1;
                  return 0;
                }
              },
              {
                key: 'firstName',
                dataIndex: 'firstName',
                title: 'First Name',
                sorter: (physician1, physician2) => {
                  if (physician1.firstName < physician2.firstName) return 1;
                  if (physician1.firstName > physician2.firstName) return -1;
                  return 0;
                }
              },
              {
                key: 'lastName',
                dataIndex: 'lastName',
                title: 'Last Name',
                defaultSortOrder: 'descend',
                sorter: (physician1, physician2) => {
                  if (physician1.lastName < physician2.lastName) return 1;
                  if (physician1.lastName > physician2.lastName) return -1;
                  return 0;
                }
              }
            ]}
            dataSource={physicians.map(physician => ({
              key: physician._id,
              credential: physician.credential,
              firstName: physician.firstName,
              lastName: physician.lastName
            }))}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedPhysicians,
              onChange: (physicians) => {
                form.setFieldsValue({ physicians });
                setSelectedPhysicians(physicians);
              }
            }}
            pagination={false}
          />
          </Form.Item>
          <FlexedButtonContainer>
            <Button htmlType='submit' type='primary'>Apply</Button>
          </FlexedButtonContainer>
        </Form>
      </StyledComponentWrapper>
    </Content>
  )
}

export default ConsultRates;