import axiosInstance from "./AxiosInstance";

export const lookUp = type => {
    return axiosInstance.get(
        `/lookup/${type}`
    );
};

export const lookUpDetails = (type, id) => {
    return axiosInstance.get(
        `/lookup/${type}/${id}`
    );
};
