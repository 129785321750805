import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;


export const getCaseChartReports=(data)=>{
    return axiosInstance.post(
        `${apiAppendPath.reportChartsAppendPath}/state-case`,data
    )
}



export const getPhysicianChartReports=(data)=>{
    return axiosInstance.post(
        `${apiAppendPath.reportChartsAppendPath}/physician-case`,data
    )
}