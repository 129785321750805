import React, { useState, useMemo } from "react";
import { Table, Button, Modal, Select, Input } from "antd";
import TemplateForm from './TemplateForm';
import {
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { StyledFiltersSection } from './styled';
import { useTelemedicineCategoryContext } from "../../../context/TelemedicineCategoryContext";


const TemplatesTable = ({ 
  type, 
  templates, 
  templateFilters, 
  caseStatuses,
  physicians,
  setTemplateFilters,
  handleFinish
}) => {
  const [templateFormIsOpen, setTemplateFormIsOpen] = useState(false);
  const [templateFormMode, setTemplateFormMode] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const { telemedicineCategories } = useTelemedicineCategoryContext()
  
  const selectedTemplate = useMemo(() => {
    return templates.find(template => template._id === selectedTemplateId) || {}; 
  }, [templates, selectedTemplateId])

  const openTemplateForm = (mode, template) => {
    setTemplateFormMode(mode);
    setTemplateFormIsOpen(true);
    setSelectedTemplateId(template._id);
  }

  const _handleFinish = () => {
    handleFinish()
    setTemplateFormIsOpen(false)
  }

  return (
    <>
      <StyledFiltersSection>
        <div style={{ display: 'flex', gap: '10px'}}>
            <Select 
              allowClear
              style={{ width: '200px'}} 
              placeholder="Template Owner"
              onChange={(value) => setTemplateFilters(filters => ({ ...filters, owner: value }))} dropdownMatchSelectWidth={false}
            >
              <Select.Option value='admin'>Admin</Select.Option>
              {physicians?.map((physician) => <Select.Option key={physician._id} value={physician._id}>{physician.firstName} {physician.lastName}</Select.Option>)}
            </Select>
          {type === 'case' && (
            <>
                <Select 
                  allowClear
                  style={{ width: '200px'}} 
                  placeholder="Telemedicine Category"
                  onChange={(value) => setTemplateFilters(filters => ({ ...filters, categoryIds: value && [value] }))} 
                  dropdownMatchSelectWidth={false}
                >
                  {telemedicineCategories?.map(category => <Select.Option key={category._id} value={category._id}>{category.name}</Select.Option>)}
                </Select>

                <Select 
                  allowClear
                  style={{ width: '200px'}}
                   placeholder="Case Status"
                   onChange={(value) => setTemplateFilters(filters => ({ ...filters, status: value }))}
                  dropdownMatchSelectWidth={false}
                >
                  {caseStatuses?.map(status => <Select.Option key={status._id} value={status.name}>{status.name}</Select.Option>)}
                </Select>
            </>
          )}
        </div>
          <Input 
            style={{ width: '200px'}}
            value={templateFilters.searchValue} 
            placeholder="Search"
            onChange={({ target }) => setTemplateFilters(filters => ({ ...filters, search: target.value }))}
          />
      </StyledFiltersSection>
      <Table
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: '20%',
            filteredValue: [templateFilters]
          },
          ...(
            type === 'case'
              ? [{
                  title: "Telemedicine Category",
                  dataIndex: "teleMedicineCategories",
                  key: "telemedicine-category",
                  width: '20%',
                  render: val => <span style={{ textTransform: 'capitalize' }}>{val}</span>
              },
                {
                  title: "Assigned Case Status",
                  dataIndex: "caseStatus",
                  key: "case-status",
                  width: '20%',
                  render: val => <span style={{ textTransform: 'capitalize' }}>{val}</span>
                }]
              : []
          ),
          {
            title: 'Owner',
            render: (template) => <span style={{ textTransform: 'capitalize' }}>{!!template.owner ? `${template.owner.firstName} ${template.owner.lastName}` : 'Admin'}</span>
          },
          {
            title: 'Actions',
            align: "center",
            render: (template) => (
              <>
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => openTemplateForm('View', template)}
                  style={{ marginRight: 15 }}
                />
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => openTemplateForm('Edit', template)}
                />
              </>
            ),
          }
        ]}
        dataSource={templates}
        pagination={false}
        scroll={{ y: '70vh', x: true }}
      />
      <Modal
        maskClosable
        centered
        width='50vw'
        closable={false}
        footer={null}
        visible={templateFormIsOpen}
        onCancel={() => setTemplateFormIsOpen(false)}
        destroyOnClose
      >
        <TemplateForm
          mode={templateFormMode}
          type={type}
          telemedicineCategories={telemedicineCategories}
          template={selectedTemplate}
          handleFinish={_handleFinish}
        />
      </Modal>
    </>
  );
}

export default TemplatesTable;