/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect, createContext } from "react";
import { useParams, useHistory } from "react-router";
import { Spin, message, Tabs, Layout, Button } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";

//import API functions
import { getCaseDetails, getRxRequestNotes } from "../../api/CasesAPI";
import CaseInformation from "./CaseInformation";
import CasePatientDetails from "./CasePatientDetails";
import DocumentView from "./DocumentView";
import CaseRequestMedication from "./CaseRequestMedication";
import CaseMessageView from "./CaseMessageView";
import CasePhysicianNotes from "./CasePhysicianNotes";
import CasePatientHistory from "./CasePatientHistory";
import CasePhysicianChat from "./CasePhysicianChat";
import { Labs } from "./Labs"
import authorization from "../../auth/authorization";
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import MIFList from "./MIFList";

const { TabPane } = Tabs;
const { Content } = Layout;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
  .ant-tabs-content {
    background: white;
    padding: 30px;
    margin-bottom: 40px;
  }
`;

const StyledBackButton = styled(Button)`
  margin-bottom: 30px;
  background-color: white;
`;

export const CaseContext = createContext({
  caseDetails: {},
  renewals: [],
  latestRxRenewalRequest: null,
});

const CaseDetails = ({
  userType,
  userDetails,
  getCaseTicketNotificationCount,
}) => {
  let { id } = useParams();

  const [caseDetails, setCaseDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [caseId, setCaseId] = useState(id);
  const [renewals, setRenewals] = useState([]);
  const latestRxRenewalRequest = renewals[0];

  let history = useHistory();

  const fetchCaseDetails = () => {
    setLoading(true);
    getCaseDetails(caseId)
      .then(async (res) => {
        if (res.data && res.data.payload) {
          const rxRequestNotes = (await getRxRequestNotes(caseId)).data.payload;
          const caseData = res.data.payload[0];
          const rxRequestNotesMap = new Map(rxRequestNotes.map(note => [`${note.rxRequest.requestType}+${note.rxRequest.createdAt}`, note]));

          caseData.requests?.map(request => {
            request.note = rxRequestNotesMap.get(`${request.requestType}+${request.createdAt}`) || null;
          });

          setCaseDetails(caseData);
          setRenewals(
            (caseData.rxRenewalRequests || [])
              .filter((renewal) => renewal.status !== "CANCELED")
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          );
          message.success("Successfully loaded Case Details");
        } else {
          message.error("Unable to load Case details. Please try again!");
        }
        setLoading(false);
      })
      .catch(() => {
        message.error("Unable to load Case details. Please try again!");
        setLoading(false);
      });
  };

  const buttonClickBackToCases = () => {
    history.push("/cases");
  };
  useEffect(() => {
    fetchCaseDetails();
  }, [caseId]);

  useEffect(() => {
    if (caseDetails) {
      const value = window.localStorage.getItem("physicianChatKey");
      if (value) {
        setActiveKey("8");
        window.localStorage.removeItem("physicianChatKey");
      }
    }
  }, [caseDetails]);

  return (
    <CaseContext.Provider
      value={{ caseDetails, renewals, latestRxRenewalRequest }}
    >
      <Content>
        <StyledTabWrapper>
          <StyledBackButton
            size="large"
            onClick={() => buttonClickBackToCases()}
          >
            <LeftOutlined />
            Back to Cases
          </StyledBackButton>
          <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
            {caseDetails && (
              <StyledTabs
                activeKey={activeKey}
                onTabClick={(key) => setActiveKey(key)}
                type="card"
              >
                <TabPane tab="Case Details" key="1">
                  <CaseInformation
                    caseId={id}
                    caseDetails={caseDetails}
                  />
                  {caseDetails.status === "consult complete" ||
                  caseDetails.status === "consult complete no rx" ? (
                    <CaseRequestMedication
                      caseDetails={caseDetails}
                      fetchCaseDetails={fetchCaseDetails}
                      setLoading={setLoading}
                      caseId={caseId}
                    />
                  ) : (
                    ""
                  )}
                </TabPane>
                <TabPane tab="Medical Intake Form" key="2">
                  <CasePatientDetails
                    caseDetails={caseDetails && caseDetails}
                  />
                  <hr></hr>
                  <MIFList caseDetails={caseDetails} />
                  {/* <MIFView
                  answeredQuestions={caseDetails.mif && caseDetails.mif}
                /> */}
                </TabPane>
                <TabPane tab="Patient Documents" key="3">
                  <DocumentView
                    caseDetails={caseDetails}
                    fetchCaseDetails={fetchCaseDetails}
                  />
                </TabPane>
                <TabPane tab="Patient History" key="4">
                  <CasePatientHistory
                    currentCaseId={caseDetails.caseId}
                    patientId={caseDetails.patientId}
                    resetTabs={setActiveKey}
                    setCaseId={setCaseId}
                    setActiveKey={setActiveKey}
                  />
                </TabPane>
                <TabPane tab="Messages" key="5">
                  <CaseMessageView caseDetails={caseDetails} />
                </TabPane>
                <TabPane tab="Physician Notes" key="6">
                  <CasePhysicianNotes caseId={caseId} />
                </TabPane>
                <TabPane tab="Labs" key="7">
                  <Labs caseDetails={caseDetails}/>
                </TabPane>
                {authorization.isAllowed(userType, "internalPhysicianChat") && (
                  <TabPane tab="Physician Chat" key="8">
                    <CasePhysicianChat
                      getCaseTicketNotificationCount={
                        getCaseTicketNotificationCount
                      }
                      caseDetails={caseDetails}
                      userDetails={userDetails}
                    />
                  </TabPane>
                )}
              </StyledTabs>
            )}
          </Spin>
        </StyledTabWrapper>
      </Content>
    </CaseContext.Provider>
  );
};

export default UserRolesAndRightsHOComponent(CaseDetails);
