import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";

import { Typography, Row, Col, DatePicker, Select, Tooltip, message, Button, Spin } from "antd";
import { QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import styled from "styled-components";

import { getPhysiciansV2 } from "../../api/PhysiciansAPI";
import {
  getPhysicianReportingDashboardCaseReport,
  getPhysicianReportingDashboardConsultReport,
  getPhysicianCaseCompletionCounts
} from "../../api/ReportApi";

import ReportPieChart from "./ReportPieChartComponent";
import ReportClusterChart from "./ReportClusterChartComponent";
import StatisticCards from "./StatisticsCardsComponent";
import { CaseStatus } from "../../constants/CaseStatus";
import { ConsultationTypeEnum, REPORT_TYPE } from '../../constants/enums'
import { usePhysicianReportContext } from "../../context/PhysicianReportSocketContext";

const {
  RX_RENEWAL,
  RX_CHANGE,
  CONSULT_COMPLETE,
  CONSULT_COMPLETE_NO_RX,
  ADDITIONAL_INFO_REQUIRED,
  INVALID_FACE_PHOTO,
  INVALID_PHOTO_ID
} = CaseStatus
const { STORE_AND_FORWARD } = ConsultationTypeEnum

const { Title, Text } = Typography;

const Container = styled.div`
  padding-top: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & h1 {
    margin-bottom: 0;
  }
`;

const ReportingTitle = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & span {
    margin-bottom: 5px;
  }
`;

const Filter = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

const Column = ({ children }) => (
  <Col
    className="gutter-row"
    xs={{ span: 24 }}
    sm={{ span: 24 }}
    md={{ span: 18 }}
    lg={{ span: 20 }}
    xl={{ span: 20 }}
  >{children}</Col>
)

const Reports = () => {
  const [selectedPhysician, selectPhysician] = useState(null);
  const [selectedDate, selectDate] = useState(moment().subtract(1, 'month'));

  const {
    caseReport,
    consultReport,
    completionCounts,
    loading,
    isLoaded,
    isError,
    formattedCurrentReportDate,
    currentPhysicianId,
    physicians,
    setCurrentReportDate,
    setCurrentPhysicianId,
    currentPhysicianName,
    requestId,
    cancelRequest,
    setPhysicians,
    setLoading,
    resetData,
  } = usePhysicianReportContext()

  const retrievePhysicians = useCallback(async () => {
    if (physicians.length) {
      return;
    }
    try {
      const response = await getPhysiciansV2({ status: "active", active: true });
      if (!response.data?.physicians) throw new Error();
      setPhysicians(response.data?.physicians);
    } catch (e) {
      console.log(e.response?.data?.error || e)
      message.error("Error retriving physicians");
    }
  }, [setPhysicians, physicians]);

  useEffect(() => {
    retrievePhysicians();
  }, [retrievePhysicians]);

  const hasReports = !!(caseReport && consultReport && completionCounts)
  const formattedReportDate = moment(selectedDate).format('YYYY-MM')
  const isSameDate = hasReports && (formattedCurrentReportDate === formattedReportDate) && (currentPhysicianId === selectedPhysician)

  const currentReportDateTitle = (loading
    ? "Loading..."
    : (isError
      ? `Error retrieving Report for ${currentPhysicianName} ${formattedCurrentReportDate}`
      : (isLoaded && hasReports
        ? `Report for ${currentPhysicianName} ${formattedCurrentReportDate}`
        : "No Date choosen to get Report")
    )
  )

  const retrieveReports = async () => {
    if (!selectedDate) {
      return;
    }
    if (isSameDate) {
      message.success(`Report for ${currentPhysicianName} ${formattedCurrentReportDate} is ready`);
      return;
    }
    setLoading(true)
    resetData()
    Promise
    .all([
      retrieveCaseReport(),
      retrieveConsultReport(),
      retrievePhysicianCaseCompletionCounts()
    ])
    .finally(() => {
      setCurrentReportDate(selectedDate)
      setCurrentPhysicianId(selectedPhysician)
    })
  }

  const retrieveCaseReport = () => {
    getPhysicianReportingDashboardCaseReport({
      filterType: "month",
      physicianId: selectedPhysician,
      date: selectedDate.utc().toISOString(),
      requestId: requestId.current.caseReport,
      reportType: REPORT_TYPE.PHYSICIAN,
    }, {
      timeout: 10_000,
    });
  };

  const retrieveConsultReport = () => {
    getPhysicianReportingDashboardConsultReport({
      physicianId: selectedPhysician,
      date: selectedDate.utc().toISOString(),
      requestId: requestId.current.consultReport,
      reportType: REPORT_TYPE.PHYSICIAN,
    }, {
      timeout: 10_000,
    });
  };

  const retrievePhysicianCaseCompletionCounts = () => {
    getPhysicianCaseCompletionCounts({
      physicianId: selectedPhysician,
      'start-date': selectedDate.startOf('month').utc().format('MM-DD-YYYY'),
      'end-date': selectedDate.endOf('month').utc().format('MM-DD-YYYY'),
      requestId: requestId.current.completionCounts,
      reportType: REPORT_TYPE.PHYSICIAN,
    }, {
      timeout: 10_000,
    });
  }

  let completedCases, completedRenewals, completedRxChanges, acceptanceRateData, consultReportCompletions

  if (completionCounts) {
    completedCases = completionCounts?.totals.case
    completedRenewals = completionCounts?.totals[RX_RENEWAL]
    completedRxChanges = completionCounts?.totals[RX_CHANGE]

    acceptanceRateData =
      completionCounts.totals[CONSULT_COMPLETE] &&
      completionCounts.totals[CONSULT_COMPLETE_NO_RX] &&
      completionCounts.totals[CONSULT_COMPLETE] + completionCounts?.totals[CONSULT_COMPLETE_NO_RX] > 0
        ? ({
          [CONSULT_COMPLETE]: completionCounts?.totals[CONSULT_COMPLETE],
          [CONSULT_COMPLETE_NO_RX]: completionCounts?.totals[CONSULT_COMPLETE_NO_RX]
        })
        : {}

    if (completionCounts.totals) {
      consultReportCompletions = { type: 'Completed Cases' }
      completionCounts.totals.audio && (consultReportCompletions.audio = completionCounts.totals.audio)
      completionCounts.totals[STORE_AND_FORWARD] && (consultReportCompletions[STORE_AND_FORWARD] = completionCounts.totals[STORE_AND_FORWARD])
      completionCounts.totals.video && (consultReportCompletions.video = completionCounts.totals.video)
      if (Object.keys(consultReportCompletions).length === 1) {
        consultReportCompletions = undefined
      }
    }
  }

  return (
    <Container className="article-wrapper">
      <Spin
        size="large"
        spinning={loading}
        tip={<span>Continue your Work.<br/> You will be notified when Report is ready</span>}
        indicator={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LoadingOutlined />
            <Button style={{ marginTop: 60 }} onClick={cancelRequest} children="Cancel Loading" />
          </div>
        }
      >
      <Row gutter={[16, 16]} justify="space-around">
        <Column>
          <Header>
            <ReportingTitle>
              <Title level={1}>Monthly Reports</Title>
              <Text>*Reporting cut-offs based on Central timezone</Text>
            </ReportingTitle>
            <Filter>
              <Select
                style={{ width: "50%" }}
                size="large"
                placeholder="Select Physician"
                value={selectedPhysician}
                showArrow
                onChange={selectPhysician}
                disabled={loading}
              >
                <Select.Option key='all' value={null}>All</Select.Option>
                {physicians.map((physician) => (
                  <Select.Option key={physician._id} value={physician._id}>
                    {`${physician.firstName} ${physician.lastName}`}
                  </Select.Option>
                ))}
              </Select>

              <DatePicker
                style={{ width: "50%", background: '#fff' }}
                onChange={val => selectDate(val.utc())}
                defaultValue={selectedDate}
                picker="month"
                allowClear={false}
                disabledDate={(date) => date > moment().endOf("month")}
                disabled={loading}
              />
            </Filter>
          </Header>
        </Column>
      </Row>
      <Row gutter={[16,16]} justify="space-around" style={{ marginTop: 8 }}>
          <Column>
            <Button
              type="primary"
              size="large"
              style={{ width: "33%" }}
              disabled={loading}
              onClick={retrieveReports}
              children="Get Report"
            />
          </Column>
        </Row>
        <Row gutter={[16,16]} justify="space-around" style={{ paddingTop: 16 }}>
          <Column>
            <ReportingTitle>
              <Title level={3}>{currentReportDateTitle}</Title>
            </ReportingTitle>
          </Column>
        </Row>
      <Row gutter={[16, 16]} wrap justify="space-around">
        <Column>
          <StatisticCards
            loading={loading}
            completedCases={completedCases}
            completedRenewals={completedRenewals}
            completedRxChanges={completedRxChanges}
          />
        </Column>
      </Row>
      <Row gutter={[16, 16]} wrap justify="space-around">
        <Column>
          <ReportPieChart
            id="acceptanceRate"
            title="Acceptance Rate"
            data={acceptanceRateData}
            fields={[CONSULT_COMPLETE, CONSULT_COMPLETE_NO_RX]}
            value="cases"
            category="status"
            legendPosition="bottom"
          />
        </Column>
        <Column>
          <ReportPieChart
            id="openCases"
            title="Open Cases (End of Period)"
            data={caseReport}
            fields={[
              ADDITIONAL_INFO_REQUIRED,
              INVALID_FACE_PHOTO,
              INVALID_PHOTO_ID,
              RX_CHANGE,
              RX_RENEWAL
            ]}
            value="cases"
            category="status"
            legendPosition="left"
          />
        </Column>
        <Column>
          <ReportClusterChart
            id="consultType"
            title={
              <>
                Consultation Type
                <Tooltip
                  overlayStyle={{ maxWidth: '450px' }}
                  overlayInnerStyle={{ color: '#fff !important'}}
                  title={
                    <div style={{ color: '#fff'}}>
                      <strong>New cases:</strong> cases created within the period.
                      <br />
                      <strong>Open cases:</strong> all non-completed cases at the end of the period.
                      <br />
                      <strong>Completed cases:</strong> cases completed within period.
                    </div>
                  }>
                  <span style={{ position: 'relative', bottom: '5px', left: '5px'}}><QuestionCircleOutlined style={{ fontSize: '15px', color: 'rgb(24, 144, 255)'}}/></span>
                </Tooltip>
              </>
            }
            data={consultReport && [...consultReport, consultReportCompletions ]}
            value="cases"
            category="type"
          />
        </Column>
      </Row>
      </Spin>
    </Container>
  );
};

export default Reports;
