import React from "react";
import styled from "styled-components";
import { Layout, Row, Col, Button, Avatar, Typography } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

//import icons
import LogoIcon from "../assets/icons/LogoIcon";

//import component
import UserOptions from "./UserOptions";
import config from '../config';



//import functions
import checkSuperAdminToken from "../utils/checkSuperAdminToken";

const { Header } = Layout;
const { Text } = Typography;

const StyledHeader = styled(Header)`
  background-color: #ffffff;
  border-bottom: 1px solid #cfd4d8;
  padding: unset;
  height: unset;
`;

const StyledHeaderRow = styled(Row)`
  font-size: 24px;
  font-weight: 700;
  color: #49548d;
`;

const StyledSidebarControlWrapper = styled(Col)`
  display: none;
  padding: 0 12px;
  border-right: 1px solid #cfd4d8;
  @media (max-width: 992px) {
    display: block;
  }
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const StyledColumn = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  font-size: 96px;
  text-align: center;
  width: 232px;
  @media (max-width: 992px) {
    width: unset;
  }
`;

const StyledSwitchColumn = styled(Col)`
  width: 150px;
  text-align: center;

  .ant-btn {
    border-radius: 24px;
  }
  .ant-btn .ant-typography {
    color: white;
  }

  @media (max-width: 600px) {
    .ant-btn .ant-typography {
      display: none;
    }

    .ant-btn {
      border-radius: 50%;
      padding: 0px 8px 0px 8px;
    }

    width: fit-content;
    margin-right: 8px;
  }
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: unset;
  &.ant-btn-icon-only.ant-btn-lg > * {
    font-size: 24px;
  }
`;

const StyledAvatar = styled(Avatar)`
  color: #7bad7e;
  background: #f4f4f4;
`;

const Username = styled.p`
  display: inline-block;
  margin: 0 8px;
  font-size: 16px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SpaceColumn = styled(Col)`
  flex: 1;
`;

const UserOptionColWrapper = styled(Col)`
  display: flex;
  align-items: center;
  text-align: right;
  padding: 0 12px;
  border-left: 1px solid #cfd4d8;
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const MainHeader = ({ userDetails, changeSidebarCollapseState }) => {
  const { userEmail } = userDetails;
  const onSidebarCollapseStateChange = () => {
    changeSidebarCollapseState((previousSidebarState) => {
      return !previousSidebarState;
    });
  };

  const switchButtonClick = () => {
    const superAdminToken = window.localStorage.getItem("superAdminToken");
    const superAdminEmail = window.localStorage.getItem("superAdminEmail");
    const superAdminType = window.localStorage.getItem("superAdminType");

    window.localStorage.setItem("userToken", superAdminToken);
    window.localStorage.setItem("userEmail", superAdminEmail);
    window.localStorage.setItem("type", superAdminType);
    window.localStorage.removeItem("superAdminToken");
    window.localStorage.removeItem("superAdminEmail");
    window.localStorage.removeItem("superAdminType");

    window.location.href = "/dictionary/users";
  };

  return (
    <StyledHeader>
      <StyledHeaderRow>
        <StyledSidebarControlWrapper>
          <StyledButton
            icon={<MenuOutlined />}
            size="large"
            onClick={onSidebarCollapseStateChange}
          />
        </StyledSidebarControlWrapper>
        <StyledColumn>
          <LogoIcon width="172px" height="72px" />
          <span style={{ marginLeft: 70, lineHeight: 'normal', fontSize: '.5rem' }} >#:{config.LATEST_SHA}</span>
        </StyledColumn>
        <SpaceColumn />

        {checkSuperAdminToken() && (
          <StyledSwitchColumn>
            <Button
              type="primary"
              icon={<UserSwitchOutlined />}
              onClick={() => switchButtonClick()}
            >
              <Text>Switch</Text>
            </Button>
          </StyledSwitchColumn>
        )}

        <UserOptionColWrapper>
          <UserOptions userDetails={userDetails}>
            <StyledAvatar icon={<UserOutlined />} />
            <Username>{userEmail}</Username>
          </UserOptions>
        </UserOptionColWrapper>
      </StyledHeaderRow>
    </StyledHeader>
  );
};

export default MainHeader;
