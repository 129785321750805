/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Spin, message, Typography, Collapse } from "antd";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import HTMLReactParser from 'html-react-parser';

//import API functions
import { getPhysicianDetails } from "../../api/PhysiciansAPI";
import {
  dateOfBirthFormatter,
  dateTimeFormatter,
} from "../../utils/dateFormatter";

// import components
import {
  StyledAvatar,
  StyledAvatarWrapperColumn,
  StyledTextArea,
} from "../../components/PhysicianComponent/PhysicianDetailsComponent";

import {
  StyledDivider,
  StyledCol,
  StyledSubTitle,
  StyledText,
  StyledModal,
} from "../../components/DetailViewComponent";
const { Title, Text } = Typography;
const { Panel } = Collapse;

const StyledFormItem = styled(Form.Item)`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledAnswerText = styled(Text)`
  color: #7eae80 !important;
`;

const PhysicianDetails = ({ physicianId, viewModal, closeModal }) => {
  const [physicianDetails, setPhysicianDetails] = useState({});
  const [physicianQuestions, setPhysicianQuestions] = useState();
  const [loading, setLoading] = useState(false);

  const fetchPhsyicianDetails = () => {
    setLoading(true);
    getPhysicianDetails(physicianId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const physicianData = res.data.payload;
          setPhysicianDetails(physicianData);
        } else {
          message.error("Unable to load Physician Details");
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error("Unable to load Physician Details");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPhsyicianDetails(physicianId);
  }, [physicianId]);

  useEffect(() => {
    if (physicianDetails.additionalInfo) {
      setPhysicianQuestions(JSON.parse(physicianDetails.additionalInfo));
    }
  }, [physicianDetails]);

  const handleModalClose = () => {
    setPhysicianDetails({});
    closeModal(false);
  };

  const activeQuestionKey = (length) => {
    var arrayOfKeys = [];
    for (var i = 0; i < length; i++) {
      arrayOfKeys.push(i);
    }
    return arrayOfKeys;
  };

  return (
    <StyledModal
      title={<Title level={3}>Physician Details</Title>}
      visible={viewModal}
      footer={null}
      onCancel={handleModalClose}
      width={750}
      destroyOnClose
    >
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Form name="physician_details">
          <Row gutter={24}>
            <StyledAvatarWrapperColumn span={24}>
              {physicianDetails.profilePic1 ? (
                <StyledAvatar
                  size={180}
                  alt="profile"
                  src={physicianDetails.profilePic1}
                />
              ) : (
                <StyledAvatar size={180} icon={<UserOutlined />} />
              )}
            </StyledAvatarWrapperColumn>
            <StyledCol span={24}>
              <StyledSubTitle level={4}>Personal Information</StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item label="Credential">
                <StyledText>
                  {physicianDetails.credential && physicianDetails.credential}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
              <Form.Item label="First Name">
                <StyledText>{physicianDetails.firstName}</StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
              <Form.Item label="Last Name">
                <StyledText>{physicianDetails.lastName}</StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item label="Email">
                <StyledText>{physicianDetails.email}</StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item label="Phone">
                <StyledText>{physicianDetails.phone}</StyledText>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Date of birth">
                <StyledText>
                  {physicianDetails.DOB
                    ? dateOfBirthFormatter(physicianDetails.DOB)
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item name="address1" label="Address Line 1">
                <StyledText>{physicianDetails.address1}</StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item name="address2" label="Address Line 2 ">
                <StyledText>{physicianDetails.address2}</StyledText>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <StyledFormItem name="city" label="City">
                <StyledText>{physicianDetails.city}</StyledText>
              </StyledFormItem>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <StyledFormItem name="state" label="State">
                <StyledText>{physicianDetails.state}</StyledText>
              </StyledFormItem>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <StyledFormItem name="zipCode" label="Zip Code">
                <StyledText>{physicianDetails.zipCode}</StyledText>
              </StyledFormItem>
            </Col>

            <StyledCol span={24}>
              <StyledSubTitle level={4}>Account Details</StyledSubTitle>
              <StyledDivider />
            </StyledCol>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label="Created Date">
                <StyledText>
                  {physicianDetails.createDate
                    ? dateTimeFormatter(physicianDetails.createDate)
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Account Status">
                <StyledText>
                  {physicianDetails.status
                    ? physicianDetails.status.toUpperCase()
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <StyledCol span={24}>
              <StyledSubTitle level={4}>Professional Details</StyledSubTitle>
              <StyledDivider />
            </StyledCol>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Employment Status">
                <StyledText>
                  {physicianDetails.employmentStatus
                    ? physicianDetails.employmentStatus.toUpperCase()
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="NPI">
                <StyledText>
                  {physicianDetails.npi ? physicianDetails.npi : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Meeting link">
                {physicianDetails.meetingLink}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Telemedicine Categories">
                {physicianDetails.teleMedicineCategories &&
                  physicianDetails.teleMedicineCategories.length &&
                  physicianDetails.teleMedicineCategories.map(
                    (eachCategory, index) => (
                      <StyledText key={`${eachCategory}-${index}`}>
                        {eachCategory}
                        {physicianDetails.teleMedicineCategories.length !==
                        index + 1 ? (
                          <>,&nbsp;</>
                        ) : (
                          ""
                        )}
                      </StyledText>
                    )
                  )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Clients">
                {physicianDetails.clients &&
                  physicianDetails.clients.length &&
                  physicianDetails.clients.map((eachClient, index) => (
                    <StyledText key={`${eachClient}-${index}`}>
                      {eachClient}
                      {physicianDetails.clients.length !== index + 1 ? (
                        <>,&nbsp;</>
                      ) : (
                        ""
                      )}
                    </StyledText>
                  ))}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="States">
                {physicianDetails.states &&
                  physicianDetails.states.length &&
                  physicianDetails.states.map((eachState, index) => (
                    <StyledText key={`${eachState}-${index}`}>
                      {eachState}
                      {physicianDetails.states.length !== index + 1 ? (
                        <>,&nbsp;</>
                      ) : (
                        ""
                      )}
                    </StyledText>
                  ))}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Licensed States">
                {physicianDetails.licensedStates?.length &&
                  physicianDetails.licensedStates.map((eachState, index) => (
                    <StyledText key={`${eachState}-${index}`}>
                      {eachState.name}
                      {physicianDetails.licensedStates.length !== index + 1 ? (
                        <>,&nbsp;</>
                      ) : (
                        ""
                      )}
                    </StyledText>
                  ))}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="tags" label="Tags">
                {physicianDetails.tags?.map((tag, i) => <StyledText key={tag}>{tag}{i !== physicianDetails.tags.length - 1 && ', '}</StyledText>)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Bio" />
                {!!physicianDetails.bio && HTMLReactParser(physicianDetails.bio)}
            </Col>
            <Col span={24}>
              {physicianQuestions && (
                <Collapse
                  bordered={false}
                  defaultActiveKey={activeQuestionKey(
                    physicianQuestions.length
                  )}
                  expandIconPosition="right"
                >
                  {physicianQuestions.map((eachQuestionAnswer, parentIndex) => (
                    <Panel
                      header={
                        <Text strong>{`Question ${parentIndex + 1}:   ${
                          eachQuestionAnswer.question
                        }`}</Text>
                      }
                      key={`${parentIndex}`}
                    >
                      <Col>
                        <StyledAnswerText strong>
                          {`Answer ${parentIndex + 1} : ${
                            eachQuestionAnswer.answer
                          } `}
                        </StyledAnswerText>
                      </Col>
                      <Col>
                        {eachQuestionAnswer.reason && (
                          <StyledAnswerText strong>
                            {`Reason Notes : ${eachQuestionAnswer.reason}`}
                          </StyledAnswerText>
                        )}
                      </Col>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Col>
          </Row>
        </Form>
      </Spin>
    </StyledModal>
  );
};

export default PhysicianDetails;
