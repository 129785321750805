//import packages
import React, { useState, useEffect } from "react";
import { Layout, Table, Row, Col, Typography, Button } from "antd";
import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";

//import components
import EditCaseStatuses from "./EditCaseStatuses";

//import other files and API function
import { lookUp } from "../../../api/LookUpAPI";

const { Content } = Layout;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 980px;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const ColourDiv = styled.div`
  border-left: solid 4px ${(props) => props.inputColor || "white"};
  padding-left: 4px;
`;

const CaseStatusComponent = () => {
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [editId, setEditId] = useState("");

  const fetchCaseStatuses = () => {
    lookUp("case-status").then((res) => {
      if (res.data && res.data.payload) {
        const caseStatusData = res.data.payload.map((eachCaseStatus) => ({
          ...eachCaseStatus,
          key: eachCaseStatus._id,
        }));
        setCaseStatuses(caseStatusData);
      }
    });
  };

  useEffect(() => {
    fetchCaseStatuses();
  }, []);

  const onCaseStatusEdit = (caseStatusId) => {
    setEditId(caseStatusId);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: 250,
      render: (caseStatus, record) => (
        <ColourDiv inputColor={caseStatus.colourCode}>
          {caseStatus.displayName}
        </ColourDiv>
      ),
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "e",
      align: "center",
      width: 150,
      render: (caseStatus, record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            onCaseStatusEdit(record.key);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Content>
        <StyledComponentWrapper>
          <EditCaseStatuses
            caseStatusId={editId}
            afterCaseStatusEdit={onCaseStatusEdit}
            fetchCaseStatusesAfterUpdate={fetchCaseStatuses}
          />
        </StyledComponentWrapper>
        <StyledComponentWrapper>
          <Title level={2}>Case Statuses</Title>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={caseStatuses}
                pagination={false}
              />
            </Col>
          </Row>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default CaseStatusComponent;
