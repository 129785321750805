import React from "react";
import styled from "styled-components";
import { Drawer } from "antd";

const StyledDrawer = styled(Drawer)`
  > .ant-drawer-content-wrapper > .ant-drawer-content {
    background-color: #ffffff;
  }
  > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
    padding: 56px 0;
  }
  > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-header-no-title > .ant-drawer-close {
    right: unset;
    left: 0;
    color: #49548d;
    font-weight: 700;
  }
  > .ant-drawer-mask {
    background-color: unset;
  }`;

const MainDrawer = ({ sidebarDrawerStatus, changeSidebarDrawerState, children }) => {
  const onDrawerClose= () => {
    changeSidebarDrawerState();
  };
  return (
    <StyledDrawer
      visible={sidebarDrawerStatus}
      closable
      maskClosable
      width="220px"
      placement="left"
      onClose={onDrawerClose}
      getContainer={false}
    >
      {children}
    </StyledDrawer>
  );
};

export default MainDrawer;
