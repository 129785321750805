import React, { useState, useEffect } from "react";
import { Spin, Table, Button, Typography, message } from "antd";
import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddQueueConfiguration from "./AddQueueConfiguration"
import { TimePeriodEnum } from "../../constants/enums"
import { updatePhysicianDetails } from "../../api/PhysiciansAPI";

const { Title } = Typography

const StyledConfigurationListingSection = styled.section`
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    gap: 20px;
`;

const StyledHeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & h4 {
    margin-bottom: 0;
  }
  
`;

const StyledSubmitButtonSection = styled.section`
  text-align: right;
`;

const PhysicianQueueConfigComponent = ({ physician, setPhysician }) => {
  const [physicianQueueConfiguration, setPhysicianQueueConfiguration] = useState(physician.queueConfig || []);
  const [viewAddConfigurationModal, setViewAddConfigurationModal] = useState(false);
  const [unconfiguredTimePeriods, setUnconfiguredTimePeriods] = useState([])
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => updateConfiguredTimePeriods(), [physicianQueueConfiguration])

  const updateConfiguredTimePeriods = () => {
    const timePeriods = [TimePeriodEnum.DAY, TimePeriodEnum.WEEK, TimePeriodEnum.MONTH]
    const configuredPeriods = physicianQueueConfiguration.map(config => config.period)
    const unconfiguredPeriods = timePeriods.filter(period => !configuredPeriods.includes(period))
    setUnconfiguredTimePeriods(unconfiguredPeriods)
  }

  const handleConfigurationAdd = config => {
    setPhysicianQueueConfiguration([config, ...physicianQueueConfiguration]);
    setHasBeenUpdated(true)
    setViewAddConfigurationModal(false);
  };

  const handleConfigurationDelete = configElement => {
    const updatedConfig = physicianQueueConfiguration.filter(config => configElement.period !== config.period)
    setHasBeenUpdated(true)
    setPhysicianQueueConfiguration(updatedConfig);
  };

  const handledSaveConfiguration = async () => {
    setLoading(true)
    try {
      const { data } = await updatePhysicianDetails(physician._id, { queueConfig: physicianQueueConfiguration })
      setPhysician(data.payload)
      setHasBeenUpdated(false)
      message.success('Physician queue config updated')
    } catch (e) {
      message.error('Error updating physician queue config')
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "Time Period",
      dataIndex: "period",
      key: "period",
      width: 150,
      align: "center",
    },
    {
      title: "Max Case Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 200,
      align: "center",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={() => handleConfigurationDelete(record)}
        />
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
       <StyledConfigurationListingSection>
      <StyledHeaderSection>
        <Title level={4}>Physician Queue Configuration</Title>
        <Button
          type="primary"
          size="large"
          icon={<PlusCircleOutlined />}
          style={{ marginLeft: 'auto' }}
          onClick={() => setViewAddConfigurationModal(true)}
          disabled={unconfiguredTimePeriods.length === 0}
        >
          Add
        </Button>
      </StyledHeaderSection>

      <Table
        pagination={false}
        dataSource={physicianQueueConfiguration}
        columns={columns}
        style={{ textTransform: 'capitalize' }}
        rowKey="index"
        onRow={(_, index) => ({ index })}
      />
      <StyledSubmitButtonSection>
        <Button
          type="primary"
          size="large"
          onClick={handledSaveConfiguration}
          disabled={!hasBeenUpdated}
        >
          Submit Changes
        </Button>
      </StyledSubmitButtonSection>
      <AddQueueConfiguration
        viewAddConfigurationModal={viewAddConfigurationModal}
        setViewAddConfigurationModal={setViewAddConfigurationModal}
        unconfiguredTimePeriods={unconfiguredTimePeriods}
        onConfigurationAdd={handleConfigurationAdd}
        edit={false}
      />
    </StyledConfigurationListingSection>
    </Spin>
   
  )
}

export default PhysicianQueueConfigComponent