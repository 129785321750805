/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Typography,
  Button,
  message,
  Spin,
  Modal,
} from "antd";
import styled from "styled-components";
import {
  DeleteFilled,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import EditorComponent from "./EditorCompoenet";
import ArticletFilter from "./ArticleFilter";
import { lookUp } from "../../api/LookUpAPI";

import {
  createArticle,
  getArticles,
  deleteArticle,
  editArticle,
} from "../../api/LmsAPI";
import { useHistory } from "react-router";

const { Content } = Layout;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;
const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  background-color: #ffffff;
  padding: 24px 28px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1200px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;
const { confirm } = Modal;
const Articles = () => {
  const [articleList, setArticleList] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [operationStatus, setOperationStatus] = useState();
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [articleTotalCount, setArticleTotalCount] = useState();
  const [articleloading, setArticleLoading] = useState(false);
  const [editorData, setEditorData] = useState();
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState();
  const [articleCategories, setArticleCategories] = useState([]);
  const intialParams = {
    page: 1,
    limit: 10,
  };

  let history = useHistory();

  const fetchTelemedicineCategory = () => {
    const responsetelemedicine = [];
    lookUp("tele-medicine-category").then((res) => {
      res.data.payload.map((state) => {
        return responsetelemedicine.push({
          key: state._id,
          value: state.name,
          text: state.name,
        });
      });
      setTelemedicineCategory(responsetelemedicine);
    });
  };

  const fetchArticleCategory = () => {
    const responseArticleCategory = [];
    lookUp("article-category").then((res) => {
      res.data.payload.map((state) => {
        return responseArticleCategory.push({
          key: state._id,
          value: state.name,
          text: state.name,
        });
      });
      setArticleCategories(responseArticleCategory);
    });
  };

  useEffect(() => {
    fetchTelemedicineCategory();
    fetchArticleCategory();
    if (!showEditor) {
      fetchArticlesList(intialParams);
    }
  }, []);

  const columns = [
    {
      title: "Article Id",
      dataIndex: "_id",
      key: "_id",
      width: 270,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 260,
    },
    {
      title: "Article Category",
      dataIndex: "articleCategoryName",
      key: "article-category",
      width: 200,
    },
    {
      title: "Category",
      dataIndex: "teleMedicineCategoryName",
      key: "category-name",
      width: 200,
    },
    {
      title: "View",
      dataIndex: "",
      key: "view",
      width: 100,
      align: "center",
      render: (record) => (
        <Button
          shape="circle"
          icon={<FileTextOutlined />}
          onClick={(event) => {
            onViewClick(record);
          }}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "edit",
      width: 100,
      align: "center",
      render: (record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={(event) => onEditClick(record)}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "delete",
      width: 100,
      align: "center",
      render: (record) => (
        <Button
          shape="circle"
          icon={<DeleteFilled />}
          onClick={(event) => {
            showDeleteConfirmation(event, record);
          }}
        />
      ),
    },
  ];

  const showDeleteConfirmation = (event, record) => {
    event.stopPropagation();
    confirm({
      title: `Do you want to delete the article?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        deleteArticleData(record);
      },
      onCancel() {},
    });
  };

  const createArticleData = (data) => {
    setArticleLoading(true);
    createArticle(data)
      .then((res) => {
        setArticleLoading(false);
        const { data } = res;
        if (data.payload) {
          message.success("Successfully created Article");
          setShowEditor(false);
          setTablePageSize(10);
          setCurrentPage(1);
          fetchArticlesList(intialParams);
        } else {
          message.error("Unable to create Article,Please try again!");
        }
      })
      .catch((error) => {
        const { response } = error;
        setArticleLoading(false);
        if (response.data) {
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error("Unable to create Article,please try again!");
          }
        } else {
          message.error("Unable to create Article,please try again!");
        }
      });
  };

  const fetchArticlesList = (params) => {
    setArticleLoading(true);
    getArticles(params)
      .then((res) => {
        setArticleLoading(false);
        const { data } = res;
        if (data && data.payload) {
          setArticleTotalCount(data.payload.count);
          setArticleList(data.payload.data);
          message.success("Successfully fetched articles");
        } else {
          message.error("Unable to fetch articles");
        }
      })
      .catch(() => {
        message.error("Unable to fetch articles");
        setArticleLoading(false);
      });
  };

  const onChangePage = (value, pageSize) => {
    setArticleLoading(true);
    setCurrentPage(value);
    const paramsData = filterParams ? filterParams : {};
    paramsData["limit"] = pageSize;
    paramsData["page"] = value;
    fetchArticlesList(paramsData);
  };

  const onPageSizeChange = (current, size) => {
    setArticleLoading(true);
    setTablePageSize(size);
    setCurrentPage(1);
    const paramsData = filterParams ? filterParams : {};
    paramsData["limit"] = size;
    paramsData["page"] = 1;
    fetchArticlesList(paramsData);
  };

  const deleteArticleData = (data) => {
    const params = {
      _id: data._id,
    };
    deleteArticle(params, data._id)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success("Successfully deleted the article");
          setTablePageSize(10);
          setCurrentPage(1);
          fetchArticlesList(intialParams);
        } else {
          message.error("Unable to delete the article,Please try agian!");
        }
      })
      .catch(() => {
        message.error("Unable to delete the article,Please try again!");
      });
  };

  const editArtcileData = (data) => {
    setArticleLoading(true);
    editArticle(data, editorData.articleId)
      .then((res) => {
        setArticleLoading(false);
        const { data } = res;
        if (data && data.payload) {
          setTablePageSize(10);
          setCurrentPage(1);
          fetchArticlesList(intialParams);
          setShowEditor(false);
          message.success("Successfully edited articles");
        } else {
          message.error("Unable to edit Article,please try again!");
        }
      })
      .catch((error) => {
        const { response } = error;
        setArticleLoading(false);
        if (response.data) {
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error("Unable to edit Article,please try again!");
          }
        } else {
          message.error("Unable to edit Article,please try again!");
        }
      });
  };

  const navigateToEditor = () => {
    setShowEditor(true);
    setOperationStatus("create");
    setEditorData();
  };

  const onViewClick = (value) => {
    history.push(`/articles/${value._id}`);
  };

  const onEditClick = (value) => {
    setShowEditor(true);
    setOperationStatus("edit");
    const data = {
      articleId: value._id,
      title: value.title,
      description: value.description,
      articleCategory: value.articleCategoryName.toLowerCase(),
      teleMedicineCategory: value.teleMedicineCategoryName,
      editorHtmlData: value.editorHtmlData,
    };

    setEditorData(data);
  };

  const onFilterData = (filterValue) => {
    const paramsData = filterValue ? filterValue : {};
    paramsData["limit"] = 10;
    setTablePageSize(10);
    paramsData["page"] = 1;
    setFilterParams(filterValue);
    fetchArticlesList(paramsData);
  };

  const onClearFilter = () => {
    setFilterParams();
    setTablePageSize(10);
    setCurrentPage(1);
    fetchArticlesList(intialParams);
  };

  return (
    <Spin spinning={articleloading}>
      <Content>
        <StyledTabWrapper>
          {!showEditor ? (
            <Row>
              <StyledCol span={24}>
                <Title level={2}>Articles</Title>
                <Button type="primary" size="large" onClick={navigateToEditor}>
                  {" "}
                  <PlusOutlined /> Add
                </Button>
              </StyledCol>
              <Col span={24}>
                <ArticletFilter
                  telemedicineCategory={telemedicineCategory}
                  articleCategories={articleCategories}
                  onFilterData={onFilterData}
                  onClear={onClearFilter}
                />
              </Col>

              <Col span={24}>
                <StyledTable
                  columns={columns && columns}
                  dataSource={articleList && articleList}
                  pagination={{
                    total: articleTotalCount && articleTotalCount,
                    showTotal: (totalCount) => `Total ${totalCount} articles`,
                    current: currentPage,
                    pageSize: tablePageSize,
                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                    showSizeChanger: true,
                    onChange: onChangePage,
                    onShowSizeChange: onPageSizeChange,
                  }}
                  scroll={{ x: "max-content" }}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <StyledCol span={24}>
                <Title level={2}>
                  {operationStatus === "create"
                    ? "Create Article"
                    : "Edit Article"}
                </Title>
                <Button size="large" onClick={() => setShowEditor(false)}>
                  Cancel
                </Button>
              </StyledCol>
              <Col span={24}>
                <EditorComponent
                  setShowEditor={setShowEditor}
                  operationStatus={operationStatus}
                  telemedicineCategory={telemedicineCategory}
                  createArticleData={createArticleData}
                  editArtcileData={editArtcileData}
                  editorData={editorData}
                />
              </Col>
            </Row>
          )}
        </StyledTabWrapper>
      </Content>
    </Spin>
  );
};

export default Articles;
