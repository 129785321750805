export const CaseTicketTagsEnum = {
    NEW_RX_MISMATCH: "new rx - mismatch",
    INCOMPLETE_MIF: "incomplete mif",
    MEDICATIONS_CONFIRMATION: "medications confirmation",
    SYSTEM_ERROR: "system error",
    REOPEN_CASE: "reopen case",
    PHARMACY_CONFIRMATION: "pharmacy confirmation"
};

export const PatientFieldEnum = {
    firstName: "First Name",
    lastName: "Last Name",
    dob: "Date of Birth",
    email: "Email",
    phone: "Phone Number",
    gender: "Gender",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    state: "State",
    zipCode: "Postal Code",
    heightIn: "Height (in)",
    heightFt: "Height (ft)",
    weight: "Weight",
    age: "Age"
  };

export const ReportTypeEnum = {
    CONSULT_COMPLETE: 'consult complete',
    CONSULT_COMPLETE_NO_RX: 'consult complete no rx',
    CLIENT_CASE_COUNT: 'client case count',
    PHYSICIAN_CASE_COUNT: 'physician case count',
    PHYSICIAN_PAYMENTS: 'physician payments'
}

export const PhysicianStatusEnum = {
  ACTIVE: "active",
  DELETE: "deleted",
  ARCHIVE: "archived",
}

export const PhysicianTagsEnum = {
    NEEDS_REVIEW: 'needs review',
    REVIEWER: 'reviewer'
  }

export const ConsultationTypeEnum = {
    VIDEO: 'video',
    AUDIO: 'audio',
    STORE_AND_FORWARD: 'store and forward'
}

export const CaseStatusColorCodeEnum = {
	'consult complete': '#de0365',
	'consult complete no rx': '#293191',
	'pending prescription': '#27adbf',
	'rx change': '#b4048a',
	'rx renewal': '#b44a2e',
	'rx renewal cancelled': '#fa30ce',
	'additional info required': '#0b19fe',
	'ivalid face photo': '#2cef59',
	'invalid photo id': '#0a9ba9',
	'case created': '#f10ba6',
	'pending schedule': '#5da7a3',
	'cancelled': '#fa30ce',
	'assigned': 'green',
	'pending video consult': '#f95424',
	'pending audio consult': '#fa7cdc',
	'refer': '#335CFF'
}

export const CaseStatusEnum = {
  CONSULT_COMPLETE: "consult complete",
  CONSULT_COMPLETE_NO_RX: "consult complete no rx",
  PENDING_PRESCRIPTION: "pending prescription",
  RX_CHANGE: "rx change",
  RX_RENEWAL: "rx renewal",
  RX_RENEWAL_CANCELLED: "rx renewal cancelled",
  ADDITIONAL_INFO_REQUIRED: "additional info required",
  INVALID_FACE_PHOTO: "invalid face photo",
  INVALID_PHOTO_ID: "invalid photo id",
  CASE_CREATED: "case created",
  PENDING_SCHEDULE: "pending schedule",
  ARCHIVE: "archive",
  CANCELLED: "cancelled",
  ASSIGNED: "assigned",
  PENDING_AUDIO_CONSULT: "pending audio consult",
  PENDING_VIDEO_CONSULT: "pending video consult",
  PENDING_REVIEW: "pending review",
};

export const TimePeriodEnum = {
	SECOND: 'second',
	MINUTE: 'minute',
	HOUR: 'hour',
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	YEAR: 'year'
}

export const BulkActionEnum = {
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  DELETE: 'delete',
  RELEASE: 'release',
  REASSIGN: 'reassign',
  STATUS: 'status',
  CONSULTATION_TYPE: 'consultationType',
  EXPORT: 'export'
}

export const PrescriptionStatusEnum = {
  ERRORS: "errors",
  PRESCRIBED_WITH_ERRORS: "prescribed with errors",
  WAITING: "waiting",
  SUCCESS: "success",
  PENDING_PRESCRIPTION: "pending prescription",
  NOT_PRESCRIBED: 'not prescribed'
}

export const TemplateTypeEnum = {
  CASE: 'case',
  MESSAGE: 'message',
  PRESCRIPTION: 'prescription',
  VISIT: 'visit'
}

export const RxRenewalStatusEnum = {
	PENDING: 'PENDING',
	COMPLETED: 'COMPLETED',
	CANCELED: 'CANCELED',
}

export const RequestStatusEnum = {
  ...RxRenewalStatusEnum,
  PENDING_LAB_ORDER: 'PENDING LAB ORDER',
  PENDING_LAB_RESULTS: 'PENDING LAB RESULTS'
}

export const LabResourceTypeEnum = {
  REPORT: 'report',
  ORDER: 'order'
}

export const SocketEventEnum = {
	GET_CONSULT_TYPE_REPORT: "GetConsultTypeReport",
	GET_COMPLETION_COUNTS_REPORT: "GetCompletionCountsReport",
	GET_CASE_REPORT: "GetCaseReport",

  GET_CONSULT_TIME_REPORT: "GetConsultTimeReport",
	GET_CONSULT_TIME_NO_RX_REPORT: "GetConsultTimeNoRxReport",
  GET_CLIENTS_CASE_COUNT_REPORT: "GetClientsCaseCountReport",
  GET_PHYSICIANS_CASE_COUNT_REPORT: "GetPhysiciansCaseCountReport",
  GET_PHYSICIANS_RECONCILIATION_REPORT: "GetPhysiciansReconciliationReport",
  GET_INCOMPLETE_CASES_REPORT: "GetIncompleteCasesReport",

  GET_PHYSICIANS_AVERAGE_CASE_HANDLE_TIME_REPORT: "GetPhysiciansAverageCaseHandleTimeReport",
}

export const REPORT_TYPE = {
  CUSTOM: 'custom',
  QUEUE: 'queue',
  GENERATED: 'generated',
  PHYSICIAN: 'physician',
}

export const CustomReportType = {
  CUSTOM_CONSULT_TIME: 'custom-consult-time',
  CUSTOM_CONSULT_TIME_NO_RX: 'custom-consult-time-no-rx',
  CUSTOM_CLIENTS_CASE_COUNT: 'custom-clients-case-count',
  CUSTOM_PHYSICIANS_CASE_COUNT: 'custom-physicians-case-count',
  CUSTOM_PHYSICIANS_RECONCILIATION: 'custom-physicians-reconciliation',
  CUSTOM_INCOMPLETE_CASES: 'custom-incomplete-cases',
}

export const LOCAL_STORAGE_KEY = {
  SelectedPhysicians: 'queueReportSelectedPhysicians',
  Timespan: 'queueReportTimespan',
  Date: 'queueReportDate',
  CompletionCounts: 'queueReportCompletionCounts',
  HandleTime: 'queueReportHandleTime',
}
