import React,{useCallback,useState,useEffect} from "react";
import { Form, Row, Col, Select, Button, Input, DatePicker } from "antd";
import styled from "styled-components";
import { debounce } from "lodash";
import { lookUp } from "../../api/LookUpAPI";
const moment = require("moment");

const { RangePicker } = DatePicker;

const StyledButton = styled(Button)`
width: 80px;
margin-top: 28px;

`;

const StyledRangePicker = styled(RangePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

const ListFilter = ({ 
  onFilter,
  clearFilter,
}) => {
  const [form] = Form.useForm();

  const [fetchTelemedicine, setFetchTelemedicine] = useState();
  const [fetchClients, setFetchClients] = useState();
  

  const onSearch = () => {
    const data = form.getFieldsValue();
    const filter = {};
    filter["telemedicine-category"] = data.telemedicinecategory;
    filter["client-id"] = data.clients;
    filter["physician-id"] = data.physicianid; 
    filter["start-date"]  =data.createddate&&moment(data.createddate[0]).format("MM-DD-YYYY");
    filter["end-date"]=data.createddate&&moment(data.createddate[1]).format("MM-DD-YYYY");
    onFilter(filter);
  };
  
  // eslint-disable-next-line
  const debounceId = useCallback(debounce(onSearch, 3000), []);

  const onClearFilter = () => {
    form.resetFields();
    clearFilter();
  };


  useEffect(()=>{
    const responsetelemedicine = [];
    const responseClient = [];
    lookUp("tele-medicine-category").then((res) => {
        res.data.payload.map((state) => {
          return responsetelemedicine.push({
            key: state._id,
            value: state.name,
            text: state.name,
          });
        });
        setFetchTelemedicine(responsetelemedicine);
      });
  

      
    lookUp("client").then((res) => {
        res.data.payload.map((state) => {
          return responseClient.push({
            key: state._id,
            value: state.name,
            text: state.name,
          });
        });
        setFetchClients(responseClient);
      });

  },[])


  return (
    <Form form={form} name="physician_details" layout={"vertical"} style={{ width: '100%'}}>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={6} lg={6} xl={5}>
          <Form.Item name="physicianid" label="Physician Id">
            <Input placeholder="Filter by Physician Id" getPopupContainer={(trigger) => trigger.parentElement} onChange={debounceId}  />
          </Form.Item>
        </Col>
      
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Item name="telemedicinecategory" label="Category">
            <Select allowClear placeholder="Filter by telemedicine category" getPopupContainer={(trigger) => trigger.parentElement}>
              {fetchTelemedicine&&fetchTelemedicine.map((eachCategory) => (
                <Select.Option key={eachCategory.key} value={eachCategory.key}>
                  {eachCategory.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Item name="clients" label="Clients">
            <Select allowClear placeholder="Filter by Clients" getPopupContainer={(trigger) => trigger.parentElement} >
              {fetchClients&&fetchClients.map((eachClient) => (
                <Select.Option key={eachClient.key} value={eachClient.key}>
                  {eachClient.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Item name="createddate" label="Created Date Range">
          <StyledRangePicker getPopupContainer={(trigger) => trigger.parentElement}/>
          </Form.Item>
        </Col>
        {/* Use the clear button for common filter clearing. Also update the sytle of clear button to match the UI */}
              
      </Row>
      <Row>
          <Col xs={12} sm={12} md={6} lg={3} xl={4}>
            <div style={{ display: 'flex', gap: '10px'}}>
            <Form.Item>
              <StyledButton
                type="secondary"  
                style={{ border: 'none', margin: '0'}}           
                onClick={() => onClearFilter()}
              >
                Clear
              </StyledButton>
            </Form.Item>
            <Form.Item>
              <Button type='primary' style={{ margin: '0', border: 'none'}} onClick={onSearch}>Search</Button>
            </Form.Item>
            </div>
          </Col>
      </Row>
    </Form>
  );
};

export default ListFilter;
