import React, { useState, useEffect } from "react";
import { Row, Col, Select, Typography, message } from "antd";
import styled from "styled-components";
import { getPhysiciansAsAdmin } from "../../api/PhysiciansAPI";
import { lookUp } from "../../api/LookUpAPI";
import { months } from "../../constants/months";
import moment from "moment-timezone";

const { Text } = Typography;

const FilterContainer = styled.div`
  width: 100%;
  justify-content: left;
  margin-bottom: 20px;
  & .ant-select {
    width: 100%;
  }
`;

const FilterHeader = styled(Text)`
  font-weight: bold;
  display: inline-block;
  margin: 5px 0;
`;

const YearSelect = ({ reportYear, setReportYear }) => {
  const beginYear = 2021;
  const currentYear = moment().year();
  const yearsArray = Array.from(
    { length: currentYear - beginYear + 1 },
    (v, k) => k + beginYear
  );

  return (
    <Select
      value={reportYear}
      defaultValue={reportYear}
      onChange={setReportYear}
    >
      {yearsArray.map((year) => (
        <Select.Option key={year} value={year}>
          {year}
        </Select.Option>
      ))}
    </Select>
  );
};

const QuarterSelect = ({ reportPeriodNumber, setReportPeriodNumber }) => (
  <Select
    label="Report Quarter"
    value={reportPeriodNumber}
    defaultValue={reportPeriodNumber}
    onChange={setReportPeriodNumber}
  >
    <Select.Option key="1" value={1}>
      Q1
    </Select.Option>
    <Select.Option key="2" value={2}>
      Q2
    </Select.Option>
    <Select.Option key="3" value={3}>
      Q3
    </Select.Option>
    <Select.Option key="4" value={4}>
      Q4
    </Select.Option>
  </Select>
);

const MonthSelect = ({ reportPeriodNumber, setReportPeriodNumber }) => (
  <Select
    value={reportPeriodNumber}
    defaultValue={reportPeriodNumber}
    onChange={setReportPeriodNumber}
  >
    {Object.entries(months).map(([monthName, monthObj]) => {
      return (
        <Select.Option key={monthObj.key} value={monthObj.value}>
          {monthName}
        </Select.Option>
      );
    })}
  </Select>
);

const WeekSelect = ({
  reportPeriodNumber,
  setReportPeriodNumber,
  reportYear,
}) => (
  <Select
    value={reportPeriodNumber}
    onChange={setReportPeriodNumber}
  >
    {[...Array(52).keys()].map((val) => {
      const weekNumber = val + 1; // non-zero index
      const startDay = moment().year(reportYear).day("sunday").week(weekNumber);
      const endDay = moment().year(reportYear).day("saturday").week(weekNumber);
      const range = `${startDay.format(
        "MMMM"
      )} ${startDay.date()} - ${endDay.format("MMMM")} ${endDay.date()}`;
      return (
        <Select.Option key={val} value={weekNumber}>
          Week {weekNumber} ({range})
        </Select.Option>
      );
    })}
  </Select>
);

const PeriodSelect = ({ period, setReportPeriod }) => {
  const periods = ["week", "month", "quarter", "year"];
  return (
    <Select
      style={{ textTransform: "capitalize" }}
      value={period}
      placeholder="Month"
      onChange={setReportPeriod}
    >
      {periods.map((p) => (
        <Select.Option
          style={{ textTransform: "capitalize" }}
          key={p}
          value={p}
        >{`${p}ly`}</Select.Option>
      ))}
    </Select>
  );
};

const PhysicianSelect = ({ physicians, selectPhysician }) => {
  return (
    <Select
      style={{ textTransform: "capitalize" }}
      mode="multiple"
      placeholder="Physician"
      onChange={selectPhysician}
    >
      {physicians?.length &&
        physicians.map((physician) => (
          <Select.Option
            key={physician._id}
            value={physician._id}
          >{`${physician.firstName} ${physician.lastName}`}</Select.Option>
        ))}
    </Select>
  );
};

const CategorySelect = ({ categories, selectCategory }) => {
  return (
    <Select
      style={{ textTransform: "capitalize" }}
      mode="multiple"
      placeholder="Category"
      onChange={selectCategory}
    >
      {categories?.length &&
        categories.map((category) => (
          <Select.Option key={category.key} value={category.key}>
            {category.text}
          </Select.Option>
        ))}
    </Select>
  );
};

const ClientSelect = ({ clients, selectClient }) => {
  return (
    <Select
      style={{ textTransform: "capitalize" }}
      mode="multiple"
      placeholder="Client"
      onChange={selectClient}
    >
      {clients?.length &&
        clients.map((client) => (
          <Select.Option key={client.key} value={client.key}>
            {client.text}
          </Select.Option>
        ))}
    </Select>
  );
};

const PeriodNumberSelect = props => {
  switch (props.period) {
    case "week":
      return<WeekSelect {...props} />
    case "month":
      return <MonthSelect { ...props} />
    case "quarter":
      return <QuarterSelect { ...props } />
    default:
      return <></>;
  }
};

const StateSelect = ({
  states,
  selectState
}) => {
  return (
    <Select
      style={{ textTransform: "capitalize" }}
      placeholder="State"
      allowClear
      onChange={selectState}
    >
      {states?.length &&
        states.map((state) => (
          <Select.Option key={state.key} value={state.key}>
            {state.name}
          </Select.Option>
        ))}
    </Select>
  );
};

const ListFilter = ({
  physicians,
  categories,
  clients,
  selectPhysician,
  selectCategory,
  selectClient,
}) => (
  <>
    <Col xs={24}> <FilterHeader>Filters</FilterHeader></Col>
    <Col xs={8}>
      <PhysicianSelect
        physicians={physicians}
        selectPhysician={selectPhysician}
      />
    </Col>
    <Col xs={8}>
      <CategorySelect categories={categories} selectCategory={selectCategory} />
    </Col>
    <Col xs={8}>
      <ClientSelect clients={clients} selectClient={selectClient} />
    </Col>
  </>
);

const PaymentsFilter = ({ states, setReportState }) => (
  <>
    <Col xs={24}> <FilterHeader>Filters</FilterHeader></Col>
    <Col xs={8}>
      <StateSelect states={states} selectState={setReportState} />
    </Col>
  </>
);

const GeneratedReportFilter = ({
  type,
  onFilter,
  reportPeriod,
  setReportPeriod,
  reportYear,
  setReportYear,
  reportPeriodNumber,
  setReportPeriodNumber,
  states
}) => {
  const [physicians, setPhysicians] = useState([]);
  const [selectedPhysicians, selectPhysician] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClients, selectClient] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, selectCategory] = useState([]);
  const [reportState, setReportState] = useState(null)

  useEffect(() => {
    onFilter({ selectedPhysicians, selectedClients, selectedCategories, reportState });
  }, [selectedPhysicians, selectedClients, selectedCategories, reportPeriod, reportYear, reportPeriodNumber, reportState]);
  
  const fetchPhysicians = async () => {
    try {
      const response = await getPhysiciansAsAdmin({
        fields: ["firstName", "lastName", "_id"],
      });
      const physicians = response.data?.payload;
      setPhysicians(physicians);
    } catch (e) {
      message.error("Error fetching physicians list");
    }
  };

  useEffect(() => {
    fetchPhysicians();
    lookUp("tele-medicine-category").then((res) => {
      const categories = res.data?.payload.map((state) => {
        return {
          key: state._id,
          value: state.name,
          text: state.name,
        };
      });
      setCategories(categories);
    });

    lookUp("client").then((res) => {
      const clients = res.data?.payload.map((client) => {
        return {
          key: client._id,
          value: client.name,
          text: client.name,
        };
      });
      setClients(clients);
    });
  }, []);

  return (
    <FilterContainer layout={"vertical"}>
      <Row gutter={24}>
        <Col xs={24}>
          <FilterHeader>Reporting Period</FilterHeader>
        </Col>
        <Col xs={6}>
          <PeriodSelect
            period={reportPeriod}
            setReportPeriod={setReportPeriod}
          />
        </Col>
        <Col xs={6}>
          <YearSelect reportYear={reportYear} setReportYear={setReportYear} />
        </Col>
        <Col xs={6}>
          <PeriodNumberSelect
            period={reportPeriod}
            reportPeriodNumber={reportPeriodNumber}
            setReportPeriodNumber={setReportPeriodNumber}
            reportYear={reportYear}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        { ['generated-consult-time', 'generated-consult-time-no-rx'].includes(type) && <ListFilter
          physicians={physicians}
          categories={categories}
          clients={clients}
          selectPhysician={selectPhysician}
          selectCategory={selectCategory}
          selectClient={selectClient}
        /> }
        { type === 'physician-payments' && <PaymentsFilter
          setReportState={setReportState}
          states={states}
        /> }
      </Row>
      
    </FilterContainer>
  );
};

export default GeneratedReportFilter;
