/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Spin,
  Layout,
  Typography,
  Divider,
  Form,
  Row,
  Col,
  Button,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment-timezone";

// import CSS
import "./ScheduledDays.css";

// import components
import GeneralDays from "./GeneralDays";
import OffDays from "./OffDays";
import SpecialDays from "./SpecialDays";
import {
  StyledComponentWrapper,
  StyledHeaderWrapper,
} from "../../components/LayoutComponent";

// import APIs
import { getScheduleDays, getPhysicianDetails } from "../../api/PhysiciansAPI";

const { Content } = Layout;
const { Title } = Typography;

const StyledDivider = styled(Divider)`
  padding: 1px;
  background: #f0f2f5;
`;

const SchedulesComponent = () => {
  const { id: physicianId } = useParams();
  const history = useHistory();
  const [physicianDetails, setPhysicianDetails] = useState({});
  const [generalDays, setGeneraldays] = useState([]);
  const [offDays, setOffDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);
  const [dataEdited, setdataEdited] = useState(true);
  const [timeZone, setTimeZone] = useState();
  const [offSet, setOffSet] = useState();

  const fetchPhsyicianDetails = () => {
    getPhysicianDetails(physicianId).then((res) => {
      if (res.data && res.data.payload) {
        const physicianData = res.data.payload;
        setPhysicianDetails(physicianData);
      }
    });
  };

  useEffect(() => {
    fetchPhsyicianDetails(physicianId);
  }, []);

  useEffect(() => {
    if (dataEdited) {
      getScheduleDays(physicianId)
        .then((res) => {
          setGeneraldays(res.data.payload.daysAvailable);
          setOffDays(res.data.payload.offDays);
          setSpecialDays(res.data.payload.specificDates);
          setdataEdited(false);
          if (res.data.payload.offSet) {
            setOffSet(res.data.payload.offSet);
          } else {
            if (res.data.payload.offSetName) {
              setOffSet(moment.tz(res.data.payload.offSetName).utcOffset());
            } else {
              setOffSet(new Date().getTimezoneOffset());
            }
          }
          setTimeZone(res.data.payload.offSetName);
        })
        .catch((e) => {});
    }
  }, [dataEdited]);

  const editChange = () => {
    setdataEdited(true);
  };

  const handleBackToPhysicianList = () => {
    history.push("/physicians");
  };

  return (
    <>
      <Content>
        <StyledHeaderWrapper>
          <Button
            type="primary"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              handleBackToPhysicianList();
            }}
          >
            Back to Physicians List
          </Button>
        </StyledHeaderWrapper>
        <StyledComponentWrapper>
          <Title level={2}>
            <b>Scheduled Days</b>
          </Title>
          <Spin size="large" spinning={dataEdited}>
            <Form name="physician_details">
              <Title level={4}>
                <b>Physician Details</b>
              </Title>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="First Name">
                    <span>{physicianDetails.firstName}</span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Last Name">
                    <span className="ant-form-text">
                      {physicianDetails.lastName}
                    </span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="Email">
                    <span className="ant-form-text">
                      {physicianDetails.email}
                    </span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item label="NPI">
                    <span className="ant-form-text">
                      {physicianDetails.npi ? physicianDetails.npi : ""}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <StyledDivider />
            <Title level={4}>
              <b>Time Zone</b> : {timeZone}
            </Title>

            <StyledDivider />
            <GeneralDays
              physicianId={physicianId}
              generalDays={generalDays}
              setdataEdited={editChange}
              offSet={offSet}
              timeZone={timeZone}
            />
            <StyledDivider />
            <OffDays
              physicianId={physicianId}
              offDay={offDays}
              setdataEdited={editChange}
              offSet={offSet}
            />
            <StyledDivider />
            <SpecialDays
              physicianId={physicianId}
              specialDates={specialDays}
              setdataEdited={editChange}
              offSet={offSet}
              timeZone={timeZone}
            />
          </Spin>
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default SchedulesComponent;
