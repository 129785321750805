/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  Row,
  Col,
  Typography,
  Select,
  message,
  Input,
} from "antd";

// import APIs
import { addQuestionnaire } from "../../../api/QuestionnaireAPI";
import { getTelemedicineCategoryOfClient } from "../../../api/ClientsAPI";

const { TextArea } = Input;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  margin: 8px 6px;
  width: 96px;
  &.ant-btn-primary[disabled] {
    color: #e8ecef;
  }
`;

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const AddQuestionnaire = (props) => {
  const { clients } = props;

  const [selectedClients, setSelectedClients] = useState(null);
  const [
    selectedTelemedicineCategory,
    setSelectedTelemedicineCategory,
  ] = useState(null);
  const [
    telemedicineCategoryLoading,
    setTelemedicineCategoryLoading,
  ] = useState(false);
  const [questionnaireJSON, setquestionnaireJSON] = useState(null);
  const [JSONDataValidation, setJSONDataValidationStatus] = useState("success");
  const [JSONDataValidationMessage, setJSONDataValidationMessage] = useState(
    ""
  );
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);

  const fetchTelecategory = (selectedClient) => {
    getTelemedicineCategoryOfClient(selectedClient)
      .then((res) => {
        if (
          res.data &&
          res.data.payload &&
          res.data.payload.teleMedicineCategories
        ) {
          const telemedicineData = res.data.payload.teleMedicineCategories.map(
            (eachMedicineCategory) => ({
              ...eachMedicineCategory,
              key: eachMedicineCategory._id,
            })
          );
          setTelemedicineCategory(telemedicineData);
        }
        setTelemedicineCategoryLoading(false);
      })
      .catch(() => {
        setTelemedicineCategoryLoading(false);
      });
  };

  const handleClientChange = (selectedItems) => {
    setSelectedClients(selectedItems);
    setTelemedicineCategoryLoading(true);
    fetchTelecategory(selectedItems);
  };

  const handleTelemedicineChange = (selectedItems) => {
    setSelectedTelemedicineCategory(selectedItems);
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onQuestionnaireAddClick = () => {
    let questionnaireJSONData = {};
    try {
      questionnaireJSONData = JSON.parse(questionnaireJSON);
      setJSONDataValidationStatus("success");
      setJSONDataValidationMessage("");
      console.log("========== Questionnaire JSON Parsing successful =========");
    } catch (error) {
      console.log(
        "========== Questionnaire JSON Parsing Error =========",
        error
      );
      setJSONDataValidationStatus("error");
      setJSONDataValidationMessage("Please provide JSON in the format");
      return false;
    }

    const questionnaireData = {};
    questionnaireData.questions = questionnaireJSONData;
    questionnaireData.clientId = selectedClients;
    questionnaireData.categoryId = selectedTelemedicineCategory;

    addQuestionnaire(questionnaireData)
      .then((res) => {
        if (res.status === 200) {
          setSelectedClients(null);
          setSelectedTelemedicineCategory(null);
          setquestionnaireJSON(null);
          setJSONDataValidationStatus("success");
          setJSONDataValidationMessage("");
          message.success("Successfully uploaded questionnaire");
        } else {
          message.warning("Unable upload questionnaire");
        }
      })
      .catch((error) => {
        message.error("Error occured while uploading questionnaire");
      });
  };

  const handleJSONDataChange = (event) => {
    setquestionnaireJSON(event.target.value);
  };

  return (
    <Form {...layout} layout={"vertical"} name="nest-messages">
      <Title level={2}>Upload Questionnaire</Title>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Clients">
            <Select
              size={"large"}
              value={selectedClients}
              placeholder="Filter by Client"
              onChange={handleClientChange}
            >
              {clients.map((eachClient) => (
                <Select.Option key={eachClient.key} value={eachClient.key}>
                  {eachClient.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Telemedicine Category">
            <Select
              size={"large"}
              value={selectedTelemedicineCategory}
              placeholder="Filter by category"
              onChange={handleTelemedicineChange}
              loading={telemedicineCategoryLoading}
            >
              {telemedicineCategory.map((eachCategory) => (
                <Select.Option key={eachCategory.key} value={eachCategory.key}>
                  {eachCategory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Input JSON for Questionnaire"
            validateStatus={JSONDataValidation}
            help={JSONDataValidationMessage}
          >
            <TextArea
              rows={15}
              value={questionnaireJSON}
              placeholder="Please copy paste the JSON data here"
              onChange={handleJSONDataChange}
            />
          </Form.Item>
        </Col>
        <ButtonContainer span={24}>
          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <StyledButton
              type="primary"
              size={"large"}
              disabled={
                !(
                  selectedClients &&
                  selectedTelemedicineCategory &&
                  questionnaireJSON
                )
              }
              onClick={onQuestionnaireAddClick}
            >
              Submit
            </StyledButton>
          </Form.Item>
        </ButtonContainer>
      </Row>
    </Form>
  );
};

export default AddQuestionnaire;
