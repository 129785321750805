/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Tabs, Typography } from "antd";

// import components
import MIFView from "./MIFView";
import { dateOfBirthFormatter } from "../../utils/dateFormatter";

const { TabPane } = Tabs;
const { Text } = Typography;

const MIFList = ({ caseDetails }) => {
    const latestRxRenewalRequest = caseDetails?.rxRenewalRequests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

    return (
        <>
            {Object.keys(caseDetails).length ? (
                <>
                    {latestRxRenewalRequest ? (
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab={`Renewal MIF (${dateOfBirthFormatter(latestRxRenewalRequest.createdAt)})`} key="1">
                                <MIFView answeredQuestions={latestRxRenewalRequest.mif} />
                            </TabPane>
                            <TabPane tab={`MIF (${dateOfBirthFormatter(caseDetails.createdDate)})`} key="2" style={{ marginLeft: "50px" }}>
                                <MIFView answeredQuestions={caseDetails.mif} />
                            </TabPane>
                        </Tabs>
                    ): (
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab="MIF" key="1">
                                <MIFView answeredQuestions={caseDetails.mif} />
                            </TabPane>
                        </Tabs>
                    )}
                </>
            ) : (
                <div className="align-text-center">
                    <Text>Medical Intake Form not available</Text>
                </div>
            )}
        </>
    );
};

export default MIFList;
