//import packages
import React, { useState, useEffect } from "react";
import { Typography, Modal, message, Button, Spin } from "antd";
import styled from "styled-components";
// import components
import LoadBalancerConfiguration from "./LoadBalancerConfiguration";
import AddConfiguration from "./AddConfiguration";
import { LoadingOutlined } from "@ant-design/icons";

//import other files and API functions
import { lookUp } from "../../../api/LookUpAPI";
import {
  getLoadBalancerConfigurationDetails,
  addLoadBalancerConfiguration,
} from "../../../api/LoadBalancerConfigurationAPI";

const { Title } = Typography;

const StyledComponentWrapper = styled.article`
  background-color: #ffffff;
  width: 100%;
  padding: 24px 28px;
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledHelperNotesSection = styled.section`
  margin-bottom: 44px;
`;

const StyledSubmitButtonSection = styled.section`
  text-align: right;
`;

const StyledHelperNoteListItem = styled.li`
  text-align: justify;
`;

const GlobalConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [physicianGroupList, setPhysicianGroupList] = useState([]);
  const [
    physicianGroupConfiguration,
    setPhysicianGroupConfiguration,
  ] = useState([]);
  const [viewAddConfigurationModal, setViewAddConfigurationModal] = useState(
    false
  );
  const [editingConfiguration, setConfigurationToEdit] = useState(null);
  const [
    configurationSubmissionDisabled,
    setConfigurationSubmissionDisabled,
  ] = useState(true);
  const [viewEditConfigurationModal, setViewEditConfigurationModal] = useState(
    false
  );
  const [
    physicianGroupListForSelection,
    setPhysicianGroupListForSelection,
  ] = useState([]);
  const [deletedList, setDeletedList] = useState([]);

  const fetchPhysicianGroup = () => {
    lookUp("physician-group")
      .then((res) => {
        if (res.data && res.data.payload) {
          const physicianGroupData = res.data.payload.map((eachGroup) => ({
            ...eachGroup,
            physicianGroupId: eachGroup._id,
            physicianGroupName: eachGroup.name,
            key: eachGroup._id,
          }));
          setPhysicianGroupList(physicianGroupData);
        }
      })
      .catch((error) => {
        message.error(
          "Unable to fetch Physician Group. Please try refreshing!"
        );
      });
  };

  const fetchGlobalLoadBalancerConfigurationDetails = () => {
    setLoading(true);
    getLoadBalancerConfigurationDetails()
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload && payload.length) {
            setPhysicianGroupConfiguration(payload);
          }
          message.success(
            "Successfully fetched Global Load Balancer Configuration"
          );
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to load Global Load Balancer Configuration. Please try again!"
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(
          "Unable to load Global Load Balancer Configuration. Please try again!"
        );
      });
  };

  const handleComponentInit = () => {
    fetchPhysicianGroup();
    fetchGlobalLoadBalancerConfigurationDetails();
  };

  useEffect(handleComponentInit, []);

  const updatePhysicianGroupListForSelection = () => {
    const checkAddedPhysician = (accumulator, currentGroup) => {
      const foundPhysicianGroup = physicianGroupConfiguration.find(
        (eachSelectedGroup) =>
          currentGroup.physicianGroupId === eachSelectedGroup.physicianGroupId
      );
      if (!foundPhysicianGroup) {
        accumulator.push(currentGroup);
      }
      return accumulator;
    };
    const physicianGroupForSelection = physicianGroupList.reduce(
      checkAddedPhysician,
      []
    );
    setPhysicianGroupListForSelection(physicianGroupForSelection);
  };

  useEffect(updatePhysicianGroupListForSelection, [
    physicianGroupList,
    physicianGroupConfiguration,
  ]);

  const handleConfigurationAdd = (values) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    clonnedPhysicianGroupConfiguration.push({
      ...values,
      internalTurnAroundTime:
        !values.internalTurnAroundTime &&
        values.physicianGroupDetails.label.props.children.includes("wheel")
          ? 0
          : values.internalTurnAroundTime,
      physicianGroupName: values.physicianGroupDetails.label.props.children,
      physicianGroupId: values.physicianGroupDetails.value,
      key: values.physicianGroupDetails.key,
      index: clonnedPhysicianGroupConfiguration.length,
      priority: clonnedPhysicianGroupConfiguration.length + 1,
    });
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setViewAddConfigurationModal(false);
    setConfigurationSubmissionDisabled(false);
  };

  const handleConfigurationDelete = (selectedConfiguration) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    if (selectedConfiguration.id) {
      const listToDelete = deletedList.slice(0);
      listToDelete.push(selectedConfiguration.id);
      setDeletedList(listToDelete);
    }
    const findDeletingItemIndex = clonnedPhysicianGroupConfiguration.findIndex(
      (eachConfiguration) =>
        eachConfiguration.physicianGroupId ===
        selectedConfiguration.physicianGroupId
    );
    if (findDeletingItemIndex !== -1) {
      clonnedPhysicianGroupConfiguration.splice(findDeletingItemIndex, 1);
    }
    clonnedPhysicianGroupConfiguration.map((item, index) => {
      return (item.priority = index + 1);
    });
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setConfigurationSubmissionDisabled(false);
  };

  const handleConfigurationEdit = (selectedConfiguration) => {
    setConfigurationToEdit(selectedConfiguration);
    setViewEditConfigurationModal(true);
  };

  const handleAddConfigurationClickCallback = () => {
    setViewAddConfigurationModal(true);
  };

  const handleConfigurationEditCancel = () => {
    setViewEditConfigurationModal(false);
    setConfigurationToEdit(null);
  };

  const handleConfigurationEditSave = (values) => {
    const clonnedPhysicianGroupConfiguration = physicianGroupConfiguration.slice(
      0
    );
    const itemFind = clonnedPhysicianGroupConfiguration.find(
      (eachConfiguration) =>
        eachConfiguration.physicianGroupId ===
        (values.physicianGroupDetails.value ||
          values.physicianGroupDetails.physicianGroupId)
    );
    if (itemFind) {
      itemFind.turnAroundTime = values["turnAroundTime"];
      itemFind.internalTurnAroundTime = values["internalTurnAroundTime"];
    }
    setPhysicianGroupConfiguration([...clonnedPhysicianGroupConfiguration]);
    setConfigurationToEdit(null);
    setViewEditConfigurationModal(false);
    setConfigurationSubmissionDisabled(false);
  };

  const handleConfigurationPriorityChange = (updatedConfigurationList) => {
    setPhysicianGroupConfiguration([...updatedConfigurationList]);
    setConfigurationSubmissionDisabled(false);
  };

  const handleGlobalCofigurationSubmission = () => {
    const physicianGroupData = [];
    if (physicianGroupConfiguration.length === 0) {
      return false;
    } else {
      setLoading(true);
      physicianGroupConfiguration.map((item) => {
        return physicianGroupData.push({
          physicianGroupId: item.physicianGroupId,
          priority: item.priority,
          turnAroundTime: item.turnAroundTime,
          internalTurnAroundTime: item.internalTurnAroundTime,
          physicianGroupName: item.physicianGroupName,
          id: item.id,
        });
      });
      const responseData = {
        loadBalancerConfig: physicianGroupData,
        deletedIds: deletedList,
      };
      addLoadBalancerConfiguration(responseData)
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            setConfigurationSubmissionDisabled(true);
            fetchGlobalLoadBalancerConfigurationDetails();
            message.success(
              "Successfully Added Global Load Balancer Configuration"
            );
            setDeletedList([]);
          } else {
            if (data && data.message) {
              message.error(data.message);
              setDeletedList([]);
            } else {
              message.error(
                "Unable to Add Global Load Balancer Configuration. Please try again!"
              );
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(
            "================== error ========================",
            error
          );
          const { response } = error;
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to Add Global Load Balancer Configuration. Please try again!"
              );
            }
          } else {
            message.error(
              "Unable to Add Global Load Balancer Configuration. Please try again!"
            );
          }
          setLoading(false);
        });
    }
  };

  return (
    <StyledComponentWrapper>
      <StyledTitle level={2}>Load Balancer Global Configurations</StyledTitle>
      <StyledHelperNotesSection>
        <Title level={4}>Helper Notes:</Title>
        <ol>
          <StyledHelperNoteListItem>
            <strong>Priority</strong> will be used while assigning the cases to
            a physician group. For example, If we assign{" "}
            <b>Internal Physicians Group</b> with the highest priority, then the
            created cases will get assigned to Internal Physicians Group first.
            The physician group with <strong>lower priority value</strong> is
            considered as the <strong>high prior physician group</strong> and
            will be choosen first for case assignment.
          </StyledHelperNoteListItem>
          <StyledHelperNoteListItem>
            <strong>Turn Around Time SLA</strong> will be added for each
            physician group. So that, if the assigned cases to a specific{" "}
            <b>physician group</b> don't make any progress within the provided{" "}
            <strong>Turn Around Time SLA</strong>, the allocated case will be
            reassigned to the next prior <b>physician group</b>.
          </StyledHelperNoteListItem>
          <StyledHelperNoteListItem>
            <strong>Internal Turn Around Time SLA</strong> will be added for
            each physician group. So that, if the assigned cases to a specific{" "}
            <b>physician</b> within a <b>physician group</b> doesn't start the
            consult within the provided{" "}
            <strong>Internal Turn Around Time SLA</strong>, the allocated case
            will be reassigned to another <b>physician</b> within the{" "}
            <b>physician group</b>.
          </StyledHelperNoteListItem>
        </ol>
      </StyledHelperNotesSection>
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <LoadBalancerConfiguration
          configurationTitle="Physician Group Configuration"
          configurationsList={physicianGroupConfiguration}
          updateConfigurationGroup={handleConfigurationPriorityChange}
          handleAddConfigurationButtonClick={
            handleAddConfigurationClickCallback
          }
          handleConfigurationDelete={handleConfigurationDelete}
          handleConfigurationEdit={handleConfigurationEdit}
          selectablePhysicianGroupList={physicianGroupListForSelection}
        />
        <Modal
          destroyOnClose
          width={750}
          visible={viewAddConfigurationModal}
          onCancel={() => setViewAddConfigurationModal(false)}
          footer={[]}
        >
          <AddConfiguration
            onConfigurationAdd={handleConfigurationAdd}
            physicianGroupList={physicianGroupList}
            edit={false}
            selectablePhysicianGroupList={physicianGroupListForSelection}
          />
        </Modal>
        {editingConfiguration ? (
          <Modal
            destroyOnClose
            width={750}
            visible={viewEditConfigurationModal}
            onCancel={() => {
              setConfigurationToEdit(null);
              setViewEditConfigurationModal(false);
            }}
            footer={[]}
          >
            <AddConfiguration
              onConfigurationAdd={handleConfigurationAdd}
              physicianGroupList={physicianGroupList}
              selectablePhysicianGroupList={physicianGroupListForSelection}
              editingConfiguration={editingConfiguration}
              edit={true}
              handleEditCancel={handleConfigurationEditCancel}
              handleEditSubmit={handleConfigurationEditSave}
            />
          </Modal>
        ) : (
          <></>
        )}
        <StyledSubmitButtonSection>
          <Button
            type="primary"
            size="large"
            disabled={
              configurationSubmissionDisabled ||
              physicianGroupConfiguration.length === 0
            }
            onClick={handleGlobalCofigurationSubmission}
          >
            Submit Changes
          </Button>
        </StyledSubmitButtonSection>
      </Spin>
    </StyledComponentWrapper>
  );
};

export default GlobalConfiguration;
