import React from "react";
import { Row, Col, Typography, Form } from "antd";
import { StyledText, StyledTitle } from "../../components/LayoutComponent";
const { Text } = Typography;

const CaseRxRenewalMedicines = ({ caseDetails }) => {
    const latestRxRenewalRequest = caseDetails?.rxRenewalRequests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
    return (
    <>
      <Row className="case-details-row-wrapper">
        <Col span={24}>
          <StyledTitle level={4}>
            Medication Requested through Rx-Renewal
          </StyledTitle>
        </Col>
        {latestRxRenewalRequest &&
        Object.keys(latestRxRenewalRequest).length ? (
          <>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Drug Name">
                <StyledText strong>
                  {latestRxRenewalRequest.drugName
                    ? latestRxRenewalRequest.drugName
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Dosage">
                <StyledText strong>
                  {latestRxRenewalRequest.dosage
                    ? latestRxRenewalRequest.dosage
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Dispense">
                <StyledText strong>
                  {latestRxRenewalRequest.dispense
                    ? latestRxRenewalRequest.dispense
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Refills">
                <StyledText strong>
                  {latestRxRenewalRequest.refills
                    ? latestRxRenewalRequest.refills
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Days Supply">
                <StyledText strong>
                  {latestRxRenewalRequest.daysSupply
                    ? latestRxRenewalRequest.daysSupply
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Quantity">
                <StyledText strong>
                  {latestRxRenewalRequest.pillCount
                    ? latestRxRenewalRequest.pillCount
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="NDC">
                <StyledText strong>
                  {latestRxRenewalRequest.ndc
                    ? latestRxRenewalRequest.ndc
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Reason Note">
                <StyledText strong>
                  {latestRxRenewalRequest.rxChangeReason
                    ? latestRxRenewalRequest.rxChangeReason
                    : ""}
                </StyledText>
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col className="align-text-center" span={24}>
            <Text>No Rx Renewal Request yet</Text>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CaseRxRenewalMedicines;
