import React, { useState, useEffect, useCallback } from "react";
import { Table, Row, Col, Typography } from "antd";
import styled from "styled-components";
import moment from "moment";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import GeneratedReportFilter from "./GeneratedReportFilter";
import CustomListFilter from "./CustomListFilter";

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;

  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1100px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;
const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  width: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const columns = [
  {
    title: "CaseId",
    dataIndex: "caseId",
    key: "case-id",
    width: 280,
    fixed: "left",
  },
  {
    title: "Physician Name",
    dataIndex: "physicianName",
    key: "physician-name",
    width: 250,
  },
  {
    title: "Patient Name",
    dataIndex: "patientName",
    key: "patient-name",
    width: 240,
  },
  {
    title: "Assigned Date",
    dataIndex: "",
    width: 200,
    key: "assigned-date",
    render: (eachCase) => (
      <StyledDiv>
        {eachCase.assignedDate
          ? dateTimeFormatter(eachCase.assignedDate)
          : ""}
      </StyledDiv>
    ),
  },
  {
    title: "Total Duration (in hrs)",
    dataIndex: "totalDuration",
    key: "total-duration",
    align: "center",
  },
];

const ReportList = ({
  selectedReport,
  reportPeriod,
  setReportPeriod,
  reportYear,
  setReportYear,
  reportPeriodNumber,
  setReportPeriodNumber,
  report,
  totalCount,
  filterParams,
  clearFilter,
  handleFetchReport
}) => {
  const [filteredReport, setFilteredReport] = useState([]);
  const [count, setCount] = useState(0)
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const isGeneratedReport = selectedReport.includes('generated')

  const mapReport = useCallback((reportData) => {
    if (!reportData) {
      setFilteredReport([]);
      setCount(0);
      return;
    }
    const mappedData = Object.values(reportData).map((value) => {
      return {
        key: value.caseId,
        caseId: value.caseId,
        physicianName: value.physicianName,
        physicianId: value.physicianId,
        clientId: value.clientId,
        categoryId: value.categoryId,
        patientName: value.patientName,
        assignedDate: value.startTime,
        totalDuration: value.totalDuration,
      };
    });

    setFilteredReport(mappedData)
    setCount(totalCount)
  }, [totalCount])

  useEffect(() => {
    mapReport(report)
  }, [report, mapReport])

  const onFilter = async (filter = {}) => {
    const index = filter.index || 1
    const count = filter.count || 10
    setCurrentPage(index);
    setTablePageSize(count);

    if (isGeneratedReport) {
      const startIndex = (filter.index - 1) * filter.count
      const endIndex = startIndex + filter.count
      const visibleCases = report.slice(startIndex, endIndex)
      mapReport(visibleCases)
    } else {

      switch (filter.filterType) {
        case "date":
        case "day":
          filter.filterType = "day"
          filter["filter"] = moment(filter.date).format("MM-DD-YYYY");
          break
        case "month":
          filter["filter"] = moment(filter.date).format("MM-01-YYYY");
          break
        case "year":
          filter["filter"] = moment(filter.date).format("01-01-YYYY");
          break
        default: // "week"
          filter["startDate"] = moment(filter.date)
            .startOf("week")
            .format("MM-DD-YYYY");
          filter["endDate"] = moment(filter.date)
            .endOf("week")
            .format("MM-DD-YYYY");
      }

      const params = {
        ...filter,
        index,
        count
      }

      handleFetchReport(params);
    }
  };

  const onChangePage = (index, size) => {
    setCurrentPage(index);
    if (selectedReport.includes('generated')) {
      const startIndex = (index - 1) * size
      const endIndex = startIndex + size
      const visibleCases = report.slice(startIndex, endIndex)
      mapReport(visibleCases)
    } else {
      handleFetchReport({
        ...filterParams,
        count: size,
        index
      });
    }
  };

  const onPageSizeChange = (_, size) => {
    setCurrentPage(1);
    setTablePageSize(size);
    if (selectedReport.includes('generated')) {
      const visibleCases = report.slice(0, size)
      mapReport(visibleCases)
    } else {
      handleFetchReport({
        ...filterParams,
        count: size,
        index: 1
      });
    }
  };

  return (
      <StyledComponentWrapper>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {["generated-consult-time", "custom-consult-time"].includes(selectedReport) && (
              <Title level={2}>Consult Completed-Duration</Title>
            )}
            {["generated-consult-time-no-rx", "custom-consult-time-no-rx"].includes(selectedReport) && (
              <Title level={2}>Consult Completed No RX-Duration</Title>
            )}
          </Col>

          {isGeneratedReport ? (
            <GeneratedReportFilter
              selectedReport={selectedReport}
              onFilter={onFilter}
              clearFilter={clearFilter}
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
              reportYear={reportYear}
              setReportYear={setReportYear}
              reportPeriodNumber={reportPeriodNumber}
              setReportPeriodNumber={setReportPeriodNumber}
            />
          ) : (
            <CustomListFilter onFilter={onFilter} clearFilter={clearFilter} />
          )}

          <Col span={24}>
            <StyledTable
              columns={columns}
              dataSource={[...filteredReport]}
              pagination={{
                total: count,
                showTotal: () => `Total ${count} Cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </StyledComponentWrapper>
  );
};

export default ReportList;
