//import packages
import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Modal, Tabs, message, Button } from "antd";
import {
  ExclamationCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

//import components
import PhysicianDetails from "./PhysicianDetails";
import EditExternalPhysicianPayment from "./EditExternalPhysicianPayments";
import AllPhysicians from "./AllPhysicians";
import {
  StyledButton,
  StyledComponentWrapper,
  StyledTabWrapper,
  StyledTable,
  StyledTabs,
  Title,
} from "../../components/PhysicianComponent/CommonPhysicianComponents";
import PhysicianEditComponent from "../../components/PhysicianComponent/PhysicianEditComponent";
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import authorization from "../../auth/authorization";
import { getPhysicians, updatePhysicianDetails } from "../../api/PhysiciansAPI";
import { lookUp } from "../../api/LookUpAPI";
import PhysicianRegister from "./PhysicianRegister";

const { confirm } = Modal;
const { Content } = Layout;
const { TabPane } = Tabs;

const PhysicianComponent = (props) => {
  const { userType } = props;
  const [physicians, setPhysicians] = useState([]);
  const [physicianId, setPhysicianId] = useState("");
  const [editPhysicianId, setEditPhysicianId] = useState(null);
  const [viewPhysicianDetails, setPhysicianDetailsModal] = useState(false);
  const [editPhysicianDetails, setPhysicianEditModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [states, setStates] = useState([]);
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [doctorCredentials, setDoctorCredentials] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState([]);
  const [consultRatePhysicianId, setConsultRatePhysicianId] = useState("");
  const [consultRateEditingModal, setConsultRateEditingModal] = useState(false);
  const [tags, setTags] = useState([]);

  const fetchPhsyicians = () => {
    getPhysicians({ fetchType: 'moderation' }).then((res) => {
      if (res.data && res.data.payload) {
        const physicianData = res.data.payload.map((eachPhysician) => ({
          ...eachPhysician,
          key: eachPhysician._id,
        }));
        setPhysicians(physicianData);
      }
    });
  };

  const fetchStates = () => {
    lookUp("state").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setStates(stateData);
      }
    });
  };

  const fetchDoctorCredentials = () => {
    lookUp("credential").then((res) => {
      if (res.data && res.data.payload) {
        const doctorCredentialData = res.data.payload.map((eachCredential) => ({
          ...eachCredential,
          key: eachCredential._id,
          value: eachCredential.name,
        }));
        setDoctorCredentials(doctorCredentialData);
      }
    });
  };
  const fetchClients = () => {
    lookUp("client").then((res) => {
      if (res.data && res.data.payload) {
        const clientData = res.data.payload.map((eachClient) => ({
          ...eachClient,
          key: eachClient._id,
        }));
        setClients(clientData);
      }
    });
  };

  const fetchTelemedicineCategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setTelemedicineCategory(stateData);
      }
    });
  };

  const updatePhysician = (physicianId, statusDetails) => {
    updatePhysicianDetails(physicianId, statusDetails)
      .then((res) => {
        if (res.status === 200) {
          message.success("Successfully updated physician status");
        } else {
          message.warning("Unable update physician status");
        }
        fetchPhsyicians();
      })
      .catch(() => {
        message.error("Error occured while updating physician status");
      });
  };

  const fetchPhysicianGroup = () => {
    lookUp("physician-group").then((res) => {
      if (res && res.data) {
        const { payload } = res.data;
        if (payload && payload.length) {
          const physicianGroupData = payload.map((eachPhysicianGroup) => {
            return {
              key: eachPhysicianGroup._id,
              value: eachPhysicianGroup._id,
              text: eachPhysicianGroup.name,
            };
          });
          setEmploymentStatus(physicianGroupData);
        }
      }
    });
  };

  const fetchPhysicianTags = () => {
    lookUp('physician-tags').then(res => {
      setTags(res.data?.payload || [])
    })
  }

  useEffect(() => {
    fetchPhsyicians();
    fetchStates();
    fetchClients();
    fetchTelemedicineCategory();
    fetchDoctorCredentials();
    fetchPhysicianGroup();
    fetchPhysicianTags();
  }, []);

  const showApproveConfirmation = (event, physician, physicianId) => {
    event.stopPropagation();
    confirm({
      title: `Do you want to approve physician ${physician.firstName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        updatePhysician(physicianId, { status: "activate" });
      },
      onCancel() {},
    });
  };

  const showRejectConfirmation = (event, physician, physicianId) => {
    event.stopPropagation();
    confirm({
      title: `Do you want to reject physician ${physician.firstName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        updatePhysician(physicianId, { status: "reject" });
      },
      onCancel() {},
    });
  };

  const onTableRowClick = (event, record) => {
    setPhysicianId(record.key);
    setPhysicianDetailsModal(true);
  };

  const onEditPhysicianClick = (event, record) => {
    setEditPhysicianId(record.key);
    setPhysicianEditModal(true);
  };

  const onCancelPhysicianConsultRateEdit = () => {
    setConsultRatePhysicianId("");
    setConsultRateEditingModal(false);
  };

  const onEditPhysicianConsultRate = (event, record) => {
    setConsultRatePhysicianId(record.key);
    setConsultRateEditingModal(true);
  };

  const columns = [
    { title: "FirstName", dataIndex: "firstName", key: "firstname" },
    { title: "LastName", dataIndex: "lastName", key: "lastname" },
    { title: "Credential", dataIndex: "credential", key: "credential" },
    {
      title: "Details",
      dataIndex: "",
      key: "v",
      width: 100,
      align: "center",
      render: (event, record) => (
        <Button
          shape="circle"
          icon={<FileTextOutlined />}
          onClick={() => {
            onTableRowClick(event, record);
          }}
        />
      ),
    },
    {
      title: "Approve",
      dataIndex: "",
      key: "approve",
      align: "center",
      render: (physician, record) => (
        <StyledButton
          shape="circle"
          disabled={physician.status === "approved"}
          icon={<CheckOutlined />}
          onClick={(event) => {
            showApproveConfirmation(event, physician, record.key);
          }}
        />
      ),
    },
    {
      title: "Reject",
      dataIndex: "",
      key: "reject",
      align: "center",
      render: (physician, record) => (
        <StyledButton
          shape="circle"
          disabled={physician.status === "rejected"}
          icon={<CloseOutlined />}
          onClick={(event) => {
            showRejectConfirmation(event, physician, record.key);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            {authorization.isAllowed(userType, "getPhysicianForAdmin") && (
              <TabPane tab="All" key="key-All">
                <AllPhysicians
                  clients={clients}
                  states={states}
                  tags={tags}
                  telemedicineCategory={telemedicineCategory}
                  onItemClick={onTableRowClick}
                  onEditPhysicianClick={onEditPhysicianClick}
                  onEditPhysicianConsultRate={onEditPhysicianConsultRate}
                />
              </TabPane>
            )}
            {authorization.isAllowed(
              userType,
              "physicianModerationByAdmin"
            ) && (
              <TabPane tab="Moderation" key="key-Moderation">
                <StyledComponentWrapper>
                  <Title level={2}>Physician Moderation</Title>
                  <Row>
                    <Col span={24}>
                      <StyledTable
                        columns={columns}
                        dataSource={physicians}
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </StyledComponentWrapper>
              </TabPane>
            )}
            {authorization.isAllowed(userType, "registerPhysician") && (
              <TabPane tab="Add" key="add-physician">
                <PhysicianRegister />
              </TabPane>
            )}
          </StyledTabs>
        </StyledTabWrapper>
        {physicianId && viewPhysicianDetails && (
          <PhysicianDetails
            physicianId={physicianId}
            viewModal={viewPhysicianDetails}
            closeModal={setPhysicianDetailsModal}
          />
        )}
        {editPhysicianId && editPhysicianDetails && (
          <PhysicianEditComponent
            clients={clients}
            states={states}
            tags={tags}
            doctorCredentials={doctorCredentials}
            telemedicineCategory={telemedicineCategory}
            employmentStatus={employmentStatus}
            physicianId={editPhysicianId}
            viewModal={editPhysicianDetails}
            closeModal={setPhysicianEditModal}
          />
        )}
        {consultRatePhysicianId && consultRateEditingModal && (
          <EditExternalPhysicianPayment
            physicianId={consultRatePhysicianId}
            viewModal={consultRateEditingModal}
            closeModal={onCancelPhysicianConsultRateEdit}
          />
        )}
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(PhysicianComponent);
