import React, { useEffect, useState } from "react";
import "./ScheduledDays.css";
import GeneralDays from "./GeneralDays";
import AvailabilityTable, { AVAILABILITY_TYPES } from "./AvailabilityTable";
import * as PhysicianApi from "../../api/PhysiciansAPI";
import { Select, Modal, Button, message } from "antd";
import moment from "moment-timezone";

const timeZoneList = moment.tz.names();

const PhysicianAvailability = ({ physicianId }) => {
  const [availability, setAvailability] = useState({ 
    general: [],
    special: [],
    off: [],
    preferredIANATimeZoneName: moment.tz.guess()
  });
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  const getAvailability = () => {
    PhysicianApi.getAvailability(physicianId)
      .then(res => {
        setAvailability(res.data.availability);
        setSelectedTimeZone(res.data.availability.preferredIANATimeZoneName);
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line default-case
        switch (err?.response.status) {
          case 404:
            message.warning({ content: 'No existing availability found' });
            return PhysicianApi.updateTimeZone(physicianId, selectedTimeZone)
        }
      })
  }

  const addTimeFrame = (availabilityType, timeFrame) => {
    PhysicianApi.addTimeFrame(physicianId, availabilityType, timeFrame)
      .then(res => setAvailability(res.data.availability))
      .then(() => message.success(`${availabilityType} time frame added successfully`))
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line default-case
        switch (err?.response?.data.statusCode) {
          case 409:
            message.error({ content: 'Cannot schdule overlapping time frames' })
        }
      })
  }

  const updateTimeFrame = (availabilityType, timeFrameId, updates) => {
    PhysicianApi.updateTimeFrame(physicianId, availabilityType, timeFrameId, updates)
      .then(res => setAvailability(res.data.availability))
      .then(() => message.success(`${availabilityType} time frame updated successfully`))
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line default-case
        switch (err?.response?.data.statusCode) {
          case 404:
            message.error({ content: 'Time frame not found' });
            break;
          case 409:
            message.error({ content: 'Cannot schdule overlapping time frames' })
            break;
        }
      })

  }

  const deleteTimeFrame = (availabilityType, timeFrameId) => {
    PhysicianApi.deleteTimeFrame(physicianId, availabilityType, timeFrameId)
      .then(res => setAvailability(res.data.availability))
      .then(() => message.success(`${availabilityType} time frame deleted successfully`))
      .catch(console.error)
  }

  const updateTimeZone = () => Modal.confirm({ 
    centered: true, 
    title: 'Preferred Time Zone',
    content: 'Would you like to update your preferred time zone?',
    onOk: () => {
      PhysicianApi
        .updateTimeZone(physicianId, selectedTimeZone)
        .then((res) => setAvailability(res.data.availability))
        .then(() => message.success('updated time zone successfully'))
        .catch(console.error)
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAvailability, []);

  useEffect(() => {
    moment.tz.setDefault(availability.preferredIANATimeZoneName);
  }, [availability.preferredIANATimeZoneName])

  return (
    <main>
      <section style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 20 }}>
        {selectedTimeZone !== availability.preferredIANATimeZoneName && <Button type='primary' onClick={updateTimeZone}>Update</Button> }
        <Select
          showSearch
          className="timezone-select"
          placeholder="Select a time zone"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp="children"
          value={selectedTimeZone}
          onChange={setSelectedTimeZone}
        >
          {timeZoneList.map((timeZone) => (
            <Select.Option key={timeZone} value={timeZone}>{timeZone}</Select.Option>)
          )}
        </Select>
      </section>
      <section>
        <GeneralDays 
          generalAvailability={availability.general} 
          addTimeFrame={addTimeFrame} 
          updateTimeFrame={updateTimeFrame}
          deleteTimeFrame={deleteTimeFrame} 
          timeZone={selectedTimeZone} 
        />
        <AvailabilityTable 
          availabilityType={AVAILABILITY_TYPES.SPECIAL} 
          timeFrames={availability.special} 
          addTimeFrame={addTimeFrame} 
          deleteTimeFrame={deleteTimeFrame} 
          timeZone={selectedTimeZone} 
        />
        <AvailabilityTable 
          availabilityType={AVAILABILITY_TYPES.OFF} 
          timeFrames={availability.off} 
          addTimeFrame={addTimeFrame} 
          deleteTimeFrame={deleteTimeFrame} 
          timeZone={selectedTimeZone} 
        />
      </section>
    </main>
  );
};

export default PhysicianAvailability;
