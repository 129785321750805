import React, { useState } from "react";
import { Form, Select, Input, DatePicker, Button, Tooltip } from "antd";
import styled from "styled-components";
import moment from 'moment-timezone'

import { TableOutlined, FileImageOutlined } from "@ant-design/icons";

const StyledForm = styled(Form)`
  width: fit-content;
  margin-left: auto;

  & .ant-select-selector {
    width: 80px !important;
  }

  & .ant-select-selection-item {
    text-transform: capitalize !important;
  }
 `;

const StyledDatePicker = styled(DatePicker)`
  background: white;
  width: 100%;
  > .ant-picker-input .ant-picker-clear:hover {
    color: unset !important;
  }
`;

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    width: 100% !important;
  }
`

const AllocatedFilter = ({ 
  onSearch,
  onExportToXLSX,
  onExportToPDF,
  hasData
}) => {
  const [date, setDate] = useState(moment().subtract(1, 'month').startOf('month'))
  const [filterType, setFilterType] = useState("month")

  const handleSearch = () => {
    onSearch({ filterType, date })
  }
  
  return (
    <StyledForm>
      <Input.Group compact>
        <Form.Item name="type" label="Period" style={{ marginLeft: '20px', width: '150px'}} >
          <StyledSelect defaultValue={filterType} onChange={val => setFilterType(val)}>
            <Select.Option value="date">Date</Select.Option>
            <Select.Option value="week" >Week</Select.Option>
            <Select.Option value="month">Month</Select.Option>
            <Select.Option value="year">Year</Select.Option>
          </StyledSelect>
        </Form.Item>
        <Form.Item name="pickerValue" style={{ marginLeft: '20px', textTransform: 'capitalize'}} label={filterType}>
          <StyledDatePicker 
            defaultValue={date}
            picker={filterType} 
            onChange={val => setDate(val)} 
            allowClear={false}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: '32px', marginLeft: '10px'}}>
          <Button type='primary' onClick={handleSearch}>Search</Button>
        </Form.Item>
        <Form.Item style={{ marginTop: '32px', marginLeft: '10px'}}>
          <Tooltip title="Export to XSLX">
            <Button type='primary' onClick={onExportToXLSX} disabled={!hasData}><TableOutlined /></Button>
          </Tooltip>
        </Form.Item>
      </Input.Group>
    </StyledForm>
  );
};

export default AllocatedFilter;
