import React, { createContext, useContext, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { message } from "antd";
import socket from "../api/socket";
import authorization from "../auth/authorization";
import { getPhysiciansV2 } from "../api/PhysiciansAPI";
import { SocketEventEnum, LOCAL_STORAGE_KEY, REPORT_TYPE } from "../constants/enums";
import { getLocalStorage, setLocalStorage } from "../utils/localStorage";

const {
  GET_PHYSICIANS_CASE_COUNT_REPORT,
  GET_PHYSICIANS_AVERAGE_CASE_HANDLE_TIME_REPORT,
} = SocketEventEnum

const reportDataHandler = (fetchedData, physicianIds, append, localStorageKey) => (currentData) => {
  let newData = fetchedData
  if (append) {
    newData = currentData
      .filter(item => !physicianIds.includes(item.key))
      .concat(fetchedData)
  }
  setLocalStorage(localStorageKey, { data: newData })
  return newData
}

const PhysicianQueueContext = createContext(null);

export const usePhysicianQueueContext = () => {
  const context = useContext(PhysicianQueueContext);
  if (!context) {
      throw new Error("usePhysicianQueueContext must be used within a PhysicianQueueSocketProvider");
  }
  return context;
}

export const PhysicianQueueSocketProvider = ({ children }) => {
  const [completionCounts, setCompletionCounts] = useState(getLocalStorage(LOCAL_STORAGE_KEY.CompletionCounts, 'data') || null)
  const [handleTimes, setHandleTimes] = useState(getLocalStorage(LOCAL_STORAGE_KEY.HandleTime, 'data') || null)
  const [loadingCompletionCounts, setLoadingCompletionCounts] = useState(false)
  const [loadingHandleTimes, setLoadingHandleTimes] = useState(false)
  const [isLoadedCompletionCounts, setIsLoadedCompletionCounts] = useState(false)
  const [isLoadedHandleTimes, setIsLoadedHandleTimes] = useState(false)
  const [isError, setIsError] = useState(false)

  const requestId = useRef({ completionCounts: 0, averageCaseHandleTimes: 0 })
  const isAppend = useRef(false)
  const fetchedPhysicianIds = useRef([])

  const [physicians, setPhysicians] = useState([]);
  const [selectedPhysicians, setSelectedPhysicians] = useState(getLocalStorage(LOCAL_STORAGE_KEY.SelectedPhysicians, 'selectedPhysicians') || []);

  const resetData = () => {
    setCompletionCounts(null)
    setHandleTimes(null)
    setIsError(false)
  }

  const handleData = (handler) => {
    setCompletionCounts(handler)
    setHandleTimes(handler)
  }

  const setLoading = (value) => {
    setLoadingCompletionCounts(value);
    setLoadingHandleTimes(value);

    setIsLoadedCompletionCounts(!value)
    setIsLoadedHandleTimes(!value)
  }

  const hasReports = !!(handleTimes && completionCounts)
  const loading = loadingHandleTimes || loadingCompletionCounts
  const isLoaded = isLoadedHandleTimes && isLoadedCompletionCounts

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (isError) {
      message.error(`Error retrieving Physician Queue Report`);
      return;
    }
    if (hasReports) {
      message.success(`Physician Queue Report is ready`);
      return;
    }
  }, [isLoaded, isError, hasReports])

  useEffect(() => {
    (async () => {
      try {
        const res = await getPhysiciansV2({ status: "active", active: true });
        const _physicians = res.data?.physicians
        if (!_physicians) throw new Error();
        setPhysicians(_physicians);
        setSelectedPhysicians(selectedPhysicians => selectedPhysicians.length ? selectedPhysicians : _physicians)
      } catch (e) {
        console.log(e.response?.data?.error || e)
        message.error("Error retriving physicians");
      }
    })()
  }, [setPhysicians]);

  useEffect(() => {
    const userId = authorization.getUserId()
    if (userId === "no user") {
      console.log("Socket can't connect to Physician Report events. No user found")
      return;
    }

    const getUserEventName = (eventName) => `${eventName}-${userId}`
    const completionCountsEventName = getUserEventName(GET_PHYSICIANS_CASE_COUNT_REPORT)
    const averageCaseHandleTimesEventName = getUserEventName(GET_PHYSICIANS_AVERAGE_CASE_HANDLE_TIME_REPORT)

    socket.on(completionCountsEventName, (response) => {
      if (response.reportType !== REPORT_TYPE.QUEUE) {
        return; //avoid socket conflict with same reportAPI
      }
      if (Number(response.requestId) !== requestId.current.completionCounts) {
        console.log(
          `Outdated response for ${completionCountsEventName}>>>>>`,
          { response, currentRequestId: requestId.current.completionCounts },
        );
        return;
      }

      requestId.current.completionCounts += 1
      console.log(`Socket response for ${completionCountsEventName}>>>>>`, response);
      const fetchedCompletionCounts = response.payload?.data
      if (fetchedCompletionCounts) {
        setCompletionCounts(reportDataHandler(fetchedCompletionCounts, fetchedPhysicianIds.current, isAppend.current, LOCAL_STORAGE_KEY.CompletionCounts))
      } else {
        setIsError(true)
        message.error("Error occured while fetching monthly completion counts");
      }
      setLoadingCompletionCounts(false)
      setIsLoadedCompletionCounts(true)
    })

    socket.on(averageCaseHandleTimesEventName, (response) => {
      if (response.reportType !== REPORT_TYPE.QUEUE) {
        return; //avoid socket conflict with same reportAPI
      }
      if (Number(response.requestId) !== requestId.current.averageCaseHandleTimes) {
        console.log(
          `Outdated response for ${averageCaseHandleTimesEventName}>>>>>`,
          { response, currentRequestId: requestId.current.averageCaseHandleTimes },
        );
        return;
      }

      requestId.current.averageCaseHandleTimes += 1
      console.log(`Socket response for ${averageCaseHandleTimesEventName}>>>>>`, response);
      const fetchedHandleTime = response.payload?.data
      if (fetchedHandleTime) {
        setHandleTimes(reportDataHandler(fetchedHandleTime, fetchedPhysicianIds.current, isAppend.current, LOCAL_STORAGE_KEY.HandleTime))
      } else {
        setIsError(true)
        message.error("Error occured while fetching monthly average case handle times");
      }
      setLoadingHandleTimes(false)
      setIsLoadedHandleTimes(true)
    })

    return () => {
      socket.removeAllListeners([
        GET_PHYSICIANS_CASE_COUNT_REPORT,
        GET_PHYSICIANS_AVERAGE_CASE_HANDLE_TIME_REPORT,
      ]);
      socket.close();
      console.log("Physician Queue Socket event listeners removed")
    };
  }, []);

  const cancelRequest = useCallback(() => {
    requestId.current.completionCounts += 1
    requestId.current.averageCaseHandleTimes += 1
    setLoading(false)
  }, [])

  const contextData = useMemo(() => ({
    completionCounts,
    handleTimes,
    loading,
    physicians,
    selectedPhysicians,
    requestId,
    isAppend,
    fetchedPhysicianIds,
    cancelRequest,
    setSelectedPhysicians,
    setPhysicians,
    setLoading,
    resetData,
    handleData,
  }), [
    completionCounts,
    handleTimes,
    loading,
    physicians,
    selectedPhysicians,
    cancelRequest,
  ])

  return (
    <PhysicianQueueContext.Provider value={contextData}>{children}</PhysicianQueueContext.Provider>
  )
}
